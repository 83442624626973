import React from 'react'
import styles from './donut.module.scss'
import { compose, produce_key } from 'helpers'

type DonutProps = {
  /** If spin in true, progress is ignored. */
  spin?: boolean
  /** 0-100 progress value. Default = 0. */
  progress?: number
  /** If a className is provided, donut size will be controlled by it, or its
   *  parent.  */
  className?: string
}

export function Donut(props: DonutProps) {
  const mask_id = produce_key('donut_mask_')
  const { spin, progress } = props
  const should_spin = spin && (progress === undefined || progress === 100)
  const arc_length = should_spin ? 25 : (progress ?? 0)
  return (
    <svg
      viewBox='0 0 100 100'
      className={compose(
        styles.donut,
        should_spin && styles.spin,
        props.className ?? styles.donut_size
      )}>
      <defs>
        <mask id={mask_id}>
          <circle cx='50' cy='50' r='50' fill='white' />
          <circle cx='50' cy='50' r='40' fill='black' />
        </mask>
      </defs>
      <circle
        mask={`url(#${mask_id})`}
        className={styles.pie}
        // total perimeter: 314.159265 (r=50)
        strokeDasharray={`${314.159265 * arc_length / 100} 314.159265`}
        cx='50'
        cy='50'
        r='50' />
    </svg>
  )
}
