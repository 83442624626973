import React from 'react'
import { compose } from 'helpers'

import { Logout } from './logout'
import styles from './top-bar.module.scss'
import { Logo } from 'components'
import { get_white_label_host } from 'app/_router'
import { logos_by_host } from 'components/logo/logos-by-host'

export function TopBar() {
  const host = get_white_label_host()
  const { top_bar_wrapper_css } = logos_by_host[host]

  return (
    <nav className={styles.top_bar}>
      <div className={compose(styles.toolbar, styles.grow)} />
      <div className={top_bar_wrapper_css}>
        <Logo color='WHITE' parent='top_bar' />
      </div>
      <div className={styles.system_toolbar}>
        <Logout />
      </div>
    </nav>
  )
}
