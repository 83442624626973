import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type GqlAccessLevel =
  /** User can both read and mutate data. */
  | 'MUTATION'
  /** User cannot access this info in any way. */
  | 'NONE'
  /** User can only read information, never perform any mutation. */
  | 'QUERY';

export type GqlAccessPolicy = {
  /** What kind of actions the user can perform in the listed scopes. */
  access_level: GqlAccessLevel;
  /** Access policy id */
  id: Scalars['ID'];
  /** Access policy client id */
  id_cliente: Scalars['ID'];
  /** Group for which this policy is aplicable. */
  id_grupo_unidades: Scalars['ID'];
  /** Name of the group for which this policy is aplicable. */
  nome_grupo_unidades: Scalars['String'];
  /** What functionality the user can interact with */
  scopes: Array<Maybe<GqlAccessPolicyScope>>;
  /** Product for which the access policy is aplicable */
  service_name: GqlServiceName;
  /** Access policy user id */
  user_id: Scalars['ID'];
};

export type GqlAccessPolicyScope =
  /**
   * User can manage other users, branches and gruops. (Can only manage billing
   * users if has billing and admin simultaneously in scopes)
   */
  | 'ADMIN'
  /** User can interact with billing functions. */
  | 'BILLING'
  /** User can perform core app functions. */
  | 'CORE';

export type GqlBase64FileType =
  /** csv file to flag vehicles as active/inactive. */
  | 'ATIVAR_INATIVAR_VEICULOS_CSV'
  /** csv file with vehicles metadate info. */
  | 'LISTA_METADADOS_VEICULOS_CSV'
  /** csv file with client branches administrative info. */
  | 'LISTA_UNIDADES_CSV'
  /** csv file with vehicles info. */
  | 'LISTA_VEICULOS_CSV';

/** Results from a base64 file finished being uploaded. */
export type GqlBase64Uploaded = {
  /** Id of the client related to the data being inserted. */
  id_cliente: Scalars['String'];
  /** Id of the request that initiated the data insertion. */
  request_id: Scalars['String'];
};

export type GqlCasingOptions =
  | 'INSENSITIVE'
  | 'SENSITIVE';

/** A customer. */
export type GqlCliente = {
  /** Customer's unique id. */
  id: Scalars['ID'];
  /** Customer's name. */
  nome: Scalars['String'];
  /** Root cnpj used to group branches. Numbers only, no special chars allowed. */
  raiz_cnpj_grupos?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Contains information for one bill to be paid, as extracted by a scrape job. */
export type GqlConta = {
  /** Neighborhood where the branch is. */
  bairro_unidade?: Maybe<Scalars['String']>;
  /** City where the branch is. */
  cidade_unidade?: Maybe<Scalars['String']>;
  /** CNPJ of the branch that in the contract. */
  cnpj_unidade?: Maybe<Scalars['String']>;
  /** Barcode, it it could be retrieved from the website. */
  codigo_barra?: Maybe<Scalars['String']>;
  /** Consumer unit in the branch that receives the contracted service. */
  consumidor?: Maybe<Scalars['String']>;
  /** Date when payment was made OR the first time the system retrieved data and bill was not open. */
  data_pagamento?: Maybe<Scalars['String']>;
  /** Month whne the service was provided. */
  data_vencimento?: Maybe<Scalars['String']>;
  /** DateTime when the latest info for this bill was extracted. */
  extracted_at?: Maybe<Scalars['String']>;
  /** Id of the pdf saved in S3 for this bill. */
  file_id?: Maybe<Scalars['ID']>;
  /** S3 url to access the bill pdf. */
  file_url?: Maybe<Scalars['String']>;
  /** Name of the saved pdf. */
  filename?: Maybe<Scalars['String']>;
  /** Identifyes one single conta. */
  id: Scalars['ID'];
  /** Client that owns this conta. */
  id_cliente?: Maybe<Scalars['ID']>;
  /** Supplier that generated the bill. */
  id_fornecedor?: Maybe<Scalars['ID']>;
  /** Branch that in the contract. */
  id_unidade?: Maybe<Scalars['ID']>;
  /** UTC datetime when the info was inserted into the database. */
  inserted_at: Scalars['String'];
  /** Month whne the service was provided. */
  mes_referencia?: Maybe<Scalars['String']>;
  /** Friendly client name. */
  nome_cliente?: Maybe<Scalars['String']>;
  /** Friendly name of the suplier. */
  nome_fornecedor?: Maybe<Scalars['String']>;
  /** Firndly name of the branch. */
  nome_unidade?: Maybe<Scalars['String']>;
  /** Product name as defined by the supplier. */
  produto?: Maybe<Scalars['String']>;
  /** Bucket where the pdf was saved. */
  s3_bucket?: Maybe<Scalars['String']>;
  s3_key?: Maybe<Scalars['String']>;
  /** Identifyes one single conta. */
  scrape_id?: Maybe<Scalars['ID']>;
  /** It will be 'Pago' or any other status that comes from the website. */
  status?: Maybe<Scalars['String']>;
  /** Type atoms are :autos, :agua, :energia, :gas, :telecom */
  sub_tipo_fornecedor?: Maybe<Scalars['String']>;
  /** Type atoms are :detran, :servico_essencial */
  tipo_fornecedor?: Maybe<Scalars['String']>;
  /** Product type as defined by the supplier. */
  tipo_produto?: Maybe<Scalars['String']>;
  /** UTC datetime of the last update done to this data. */
  updated_at: Scalars['String'];
  /** Original value. */
  valor?: Maybe<Scalars['Float']>;
  /** Value that was paid. */
  valor_pago?: Maybe<Scalars['Float']>;
};

export type GqlCredencial = {
  /** Friendly name of the credencial. */
  nome: Scalars['String'];
  /** Commom key name, used to join equivalent tipo_internos. */
  tipo: Scalars['String'];
  /** Credential key name, as expected by the spider. */
  tipo_interno: Scalars['String'];
  /** Value to be sent to the spider. */
  valor: Scalars['String'];
};

/** One credential field. */
export type GqlCredentialField = {
  /** Friendly name of the credential. */
  nome?: Maybe<Scalars['String']>;
  /** Commom name of the credential type, as used in the application. */
  tipo?: Maybe<Scalars['String']>;
  /** Name of the credential as used by one specific the website. */
  tipo_interno?: Maybe<Scalars['String']>;
  /** Value of the credential */
  valor?: Maybe<Scalars['String']>;
};

/** All insertion errors related to a csv based request. */
export type GqlCsvInsertionErrors = {
  /** List of errors occurred during the data insertion. */
  errors: Array<GqlInsertionErrors>;
  /** Id of the request that initiated the data insertion. */
  request_id: Scalars['String'];
};

export type GqlCsvLineIssuesStatus =
  /** First status once csv line decoding failed */
  | 'FAILED'
  /** User decided to ignore line */
  | 'IGNORED'
  /** User submitted updated line version for decoding */
  | 'RETRIED';

export type GqlDadosVeiculo = {
  /** Year when the vehicle was built. */
  ano_fabricacao?: Maybe<Scalars['String']>;
  /** (?) ask Yuri. */
  categoria?: Maybe<Scalars['String']>;
  chassi?: Maybe<Scalars['String']>;
  /** City where the vihicle is registered. */
  cidade?: Maybe<Scalars['String']>;
  /** CNPJ Unidade */
  cnpj_unidade?: Maybe<Scalars['String']>;
  /** (?) ask Yuri. */
  frota?: Maybe<Scalars['String']>;
  metadado_01?: Maybe<Scalars['String']>;
  metadado_02?: Maybe<Scalars['String']>;
  metadado_03?: Maybe<Scalars['String']>;
  metadado_04?: Maybe<Scalars['String']>;
  /** License plate */
  placa?: Maybe<Scalars['String']>;
  /** (?) ask Yuri. */
  tipo?: Maybe<Scalars['String']>;
  /** State where the vehicle is registered. */
  uf?: Maybe<Scalars['String']>;
};

/** Status of the data returned from a scrape job. */
export type GqlDataCollectedStatus =
  /** Execution was cancelled or interrupted, results will not be received */
  | 'NOT_RECEIVED'
  /** Results were received without errors */
  | 'RECEIVED'
  /** Results were received but some error happened during execution */
  | 'RECEIVED_WITH_ERRORS'
  /** Job was initiated but still did not return. */
  | 'WAITING';

export type GqlDataTypes =
  | 'BINARY_ID'
  | 'BOOLEAN'
  | 'DATE'
  | 'FLOAT'
  | 'INTEGER'
  | 'MAP'
  | 'MAPS_LIST'
  | 'NAIVE_DATETIME'
  | 'STRING'
  | 'UTC_DATETIME';

export type GqlDebitoRestricao = {
  /** Maual cancelation uct datetime  */
  canceled_at?: Maybe<Scalars['String']>;
  /** List of certificates in s3 */
  certificate_files?: Maybe<Array<GqlS3File>>;
  /** Results found for divida_ativa. Null => info not provided by this fornecedor. */
  divida_ativa?: Maybe<GqlSituationResult>;
  /** Results found for dpvat. Null => info not provided by this fornecedor. */
  dpvat?: Maybe<GqlSituationResult>;
  /** Files boundle PDF in s3 */
  files_bundle?: Maybe<GqlS3File>;
  /** Unique id of the situation results */
  id: Scalars['ID'];
  /** Id of the client that owns this restricao. */
  id_cliente: Scalars['ID'];
  /** If of the supplier scraped by the spider. */
  id_fornecedor?: Maybe<Scalars['ID']>;
  /** Id of the verfificacao that generated this info. */
  id_verificacao: Scalars['ID'];
  /** Results found for inspecao_veicular. Null => info not provided by this fornecedor. */
  inspecao_veicular?: Maybe<GqlSituationResult>;
  /** Results found for ipva. Null => info not provided by this fornecedor. */
  ipva?: Maybe<GqlSituationResult>;
  /** Results found for licenciamento. Null => info not provided by this fornecedor. */
  licenciamento?: Maybe<GqlSituationResult>;
  /** Results found for multas. Null => info not provided by this fornecedor. */
  multas?: Maybe<GqlSituationResult>;
  /** Friendly name of the supplier that has this data. */
  nome_fornecedor?: Maybe<Scalars['String']>;
  /** uct datetime when data_collected was received. */
  received_at?: Maybe<Scalars['String']>;
  /** Enriched error information related to the request process. */
  request_error?: Maybe<GqlRequestError>;
  /** utc datetime of the . */
  requested_at?: Maybe<Scalars['String']>;
  /** What should be done given the result of this job. */
  required_action?: Maybe<GqlEnrichedRequiredAction>;
  /** Results found for restricoes. Null => info not provided by this fornecedor. */
  restricoes?: Maybe<GqlSituationResult>;
  /** Job id on Zyte to easily find log information: https://https://app.zyte.com/p/{scrape_job_id}/logs. */
  scrape_job_id?: Maybe<Scalars['String']>;
  /** Error info, as returned from scraper. */
  scrape_response_errors?: Maybe<Array<GqlScrapeResponseError>>;
  /** Name of the spider that executed the job. */
  scraper_name?: Maybe<Scalars['String']>;
  /** List of scrennshots in s3. */
  screenshot_files?: Maybe<Array<GqlS3File>>;
  /** Status of the requst made for the spider responsible for generating this info. */
  status: GqlStatusVerificacao;
  /** Results found for vistorias. Null => info not provided by this fornecedor. */
  vistorias?: Maybe<GqlSituationResult>;
};

export type GqlDetranCronotacografo = {
  ano_fabricacao_veiculo?: Maybe<Scalars['String']>;
  bairro_unidade?: Maybe<Scalars['String']>;
  categoria_veiculo?: Maybe<Scalars['String']>;
  cep_unidade?: Maybe<Scalars['String']>;
  cidade_unidade?: Maybe<Scalars['String']>;
  cnpj_unidade?: Maybe<Scalars['String']>;
  data_emissao?: Maybe<Scalars['String']>;
  data_validade?: Maybe<Scalars['String']>;
  data_validade_ultimo_certificado_ativo?: Maybe<Scalars['String']>;
  documento?: Maybe<Scalars['String']>;
  extracted_at?: Maybe<Scalars['String']>;
  first_extracted_at?: Maybe<Scalars['String']>;
  gru?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  id_cliente?: Maybe<Scalars['ID']>;
  id_fornecedor?: Maybe<Scalars['ID']>;
  id_unidade?: Maybe<Scalars['ID']>;
  id_veiculo?: Maybe<Scalars['ID']>;
  /** UTC datetime when the info was inserted into the database. */
  inserted_at: Scalars['String'];
  metadado_veiculo_01?: Maybe<Scalars['String']>;
  metadado_veiculo_02?: Maybe<Scalars['String']>;
  metadado_veiculo_03?: Maybe<Scalars['String']>;
  metadado_veiculo_04?: Maybe<Scalars['String']>;
  metadado_veiculo_consolidado?: Maybe<Scalars['String']>;
  nome_cliente?: Maybe<Scalars['String']>;
  nome_fornecedor?: Maybe<Scalars['String']>;
  nome_unidade?: Maybe<Scalars['String']>;
  numero_doc?: Maybe<Scalars['String']>;
  placa_veiculo?: Maybe<Scalars['String']>;
  protocolo?: Maybe<Scalars['String']>;
  renavam_veiculo?: Maybe<Scalars['String']>;
  scrape_id?: Maybe<Scalars['ID']>;
  situacao?: Maybe<Scalars['String']>;
  sub_tipo_fornecedor?: Maybe<Scalars['String']>;
  tipo_fornecedor?: Maybe<Scalars['String']>;
  tipo_veiculo?: Maybe<Scalars['String']>;
  uf_unidade?: Maybe<Scalars['String']>;
  /** UTC datetime of the last update done to this data. */
  updated_at: Scalars['String'];
};

export type GqlDetranDebito = {
  /** Build year of the car that has this fine. */
  ano_fabricacao_veiculo?: Maybe<Scalars['String']>;
  /** Year for which the ebito is due. */
  ano_referencia?: Maybe<Scalars['String']>;
  /** Neighborhood of the branch of the client that owns this car. */
  bairro_unidade?: Maybe<Scalars['String']>;
  /** Id related to boleto_file_url */
  boleto_file_id?: Maybe<Scalars['String']>;
  /** Url of the captured boleto file. */
  boleto_file_url?: Maybe<Scalars['String']>;
  /** Category of the car that has this fine. */
  categoria_veiculo?: Maybe<Scalars['String']>;
  /** Zip code of the branch of the client that owns this car. */
  cep_unidade?: Maybe<Scalars['String']>;
  /** City of the branch of the client that owns this car. */
  cidade_unidade?: Maybe<Scalars['String']>;
  /** CNPJ of the branch of the client that owns this car. */
  cnpj_unidade?: Maybe<Scalars['String']>;
  /** Barcode found in the website. */
  codigo_barra?: Maybe<Scalars['String']>;
  /** Date the debt was payed. */
  data_pagamento?: Maybe<Scalars['String']>;
  /** Due date of the fine. */
  data_vencimento?: Maybe<Scalars['String']>;
  /** Payment due date. */
  data_vencimento_boleto?: Maybe<Scalars['String']>;
  /** When this information was captured. */
  extracted_at?: Maybe<Scalars['String']>;
  /** Flag to consider the value in the total sum. */
  flag_somar_ao_total?: Maybe<Scalars['Boolean']>;
  /** Debt unique id */
  id: Scalars['ID'];
  /** Client that owns this car. */
  id_cliente?: Maybe<Scalars['ID']>;
  /** Id of the debt that consolidate all installments */
  id_debito: Scalars['ID'];
  /** Id of the public service that had this info */
  id_fornecedor?: Maybe<Scalars['ID']>;
  /** Branch of the client that owns this car. */
  id_unidade?: Maybe<Scalars['ID']>;
  /** Id of the car that has this fine. */
  id_veiculo?: Maybe<Scalars['ID']>;
  /** UTC datetime when the info was inserted into the database. */
  inserted_at: Scalars['String'];
  /** Official instructions url, from the scraped website. */
  instrucoes_url?: Maybe<Scalars['String']>;
  metadado_veiculo_01?: Maybe<Scalars['String']>;
  metadado_veiculo_02?: Maybe<Scalars['String']>;
  metadado_veiculo_03?: Maybe<Scalars['String']>;
  metadado_veiculo_04?: Maybe<Scalars['String']>;
  metadado_veiculo_consolidado?: Maybe<Scalars['String']>;
  /** Name of the client that owns this car. */
  nome_cliente?: Maybe<Scalars['String']>;
  /** Name of the public service that had this info */
  nome_fornecedor?: Maybe<Scalars['String']>;
  /** Name of the branch of the client that owns this car. */
  nome_unidade?: Maybe<Scalars['String']>;
  /** Unico, Parcelado. */
  opcao_pagamento?: Maybe<Scalars['String']>;
  /** Id related to other_file_url */
  other_file_id?: Maybe<Scalars['String']>;
  /** Any other file containing tax informarion, that is not a boleto. */
  other_file_url?: Maybe<Scalars['String']>;
  /** Identifies what installment this debt refers to. */
  parcela?: Maybe<Scalars['String']>;
  /** License plate of the car that has this fine. */
  placa_veiculo?: Maybe<Scalars['String']>;
  /** Renavam of the car that has this fine. */
  renavam_veiculo?: Maybe<Scalars['String']>;
  /** Id of the job that retrieved this debt */
  scrape_id: Scalars['ID'];
  /** Pago, Atrasada, A vencer */
  status?: Maybe<Scalars['String']>;
  /** Whatever comes from the spider and is converted to status */
  status_original?: Maybe<Scalars['String']>;
  /** Type atoms are :autos, :agua, :energia, :gas, :telecom */
  sub_tipo_fornecedor?: Maybe<Scalars['String']>;
  /** Possible atoms are: :ipva | :dpva | :licenciamento | :seguro_obrigatorio | :ipva_dpvat_licenciamento */
  tipo_debito?: Maybe<Scalars['String']>;
  /** Type atoms are :detran, :servico_essencial */
  tipo_fornecedor?: Maybe<Scalars['String']>;
  /** Type of the car that has this fine. */
  tipo_veiculo?: Maybe<Scalars['String']>;
  /** State of the branch of the client that owns this car. */
  uf_unidade?: Maybe<Scalars['String']>;
  /** UTC datetime of the last update done to this data. */
  updated_at: Scalars['String'];
  /** Value to be payed. */
  valor?: Maybe<Scalars['Float']>;
  /** Value actually payed. */
  valor_pago?: Maybe<Scalars['Float']>;
  /** If false, this entry should be ignored as the vehicle was deactivated for this date. */
  veiculo_ativo_periodo?: Maybe<Scalars['Boolean']>;
};

export type GqlDetranMulta = {
  /** AIT code of the fine. */
  ait?: Maybe<Scalars['String']>;
  /** (?) ask Yuri */
  ait_origem?: Maybe<Scalars['String']>;
  /** Build year of the car that has this fine. */
  ano_fabricacao_veiculo?: Maybe<Scalars['String']>;
  /** Neighborhood of the branch of the client that owns this car. */
  bairro_unidade?: Maybe<Scalars['String']>;
  /** Refernce to the law used as basis for the fine to be applied. */
  base_legal_infracao?: Maybe<Scalars['String']>;
  boleto_file_id?: Maybe<Scalars['String']>;
  /** Payment document file url. */
  boleto_file_url?: Maybe<Scalars['String']>;
  /** Category of the car that has this fine. */
  categoria_veiculo?: Maybe<Scalars['String']>;
  /** Zip code of the branch of the client that owns this car. */
  cep_unidade?: Maybe<Scalars['String']>;
  /** City of the branch of the client that owns this car. */
  cidade_unidade?: Maybe<Scalars['String']>;
  classe_infracao?: Maybe<Scalars['String']>;
  /** CNPJ of the branch of the client that owns this car. */
  cnpj_unidade?: Maybe<Scalars['String']>;
  /** Barcode found in the website. */
  codigo_barra?: Maybe<Scalars['String']>;
  /** Code as definied in the CBT. It identifyes the type of fine. */
  codigo_infracao?: Maybe<Scalars['String']>;
  /** Whatever comes from the spider and is converted to codigo_infracao */
  codigo_infracao_original?: Maybe<Scalars['String']>;
  /** Code of the public service responsible for managing this fine. */
  codigo_orgao_autuador?: Maybe<Scalars['String']>;
  /** Date when the fine was issued. */
  data_emissao?: Maybe<Scalars['String']>;
  /** Time the fine was generated. */
  data_hora_infracao?: Maybe<Scalars['String']>;
  /** Last date when the responsible by the fine should be pointed. */
  data_limite_indicacao?: Maybe<Scalars['String']>;
  /** Last date for an appeal to be filed.  */
  data_limite_recurso?: Maybe<Scalars['String']>;
  /** Date the fine was payed. */
  data_pagamento?: Maybe<Scalars['String']>;
  /** Due date of the fine. */
  data_vencimento?: Maybe<Scalars['String']>;
  /** Payment due date. */
  data_vencimento_boleto?: Maybe<Scalars['String']>;
  /** Description of the sitution that triggered the fine. */
  descricao_infracao?: Maybe<Scalars['String']>;
  /** Whatever comes from the spider and is converted to descricao_infracao */
  descricao_infracao_original?: Maybe<Scalars['String']>;
  /** Description of the public service responsible for managing this fine. */
  descricao_orgao_autuador?: Maybe<Scalars['String']>;
  /** State of the public service responsible for managing this fine. */
  estado_orgao_autuador?: Maybe<Scalars['String']>;
  /** :autuacao, :multa or :recurso */
  etapa?: Maybe<Scalars['String']>;
  /** When this information was captured. */
  extracted_at?: Maybe<Scalars['String']>;
  /** Multiplier factor of the NIC, if this fine is a NIC. */
  fator_multiplicador?: Maybe<Scalars['String']>;
  /** First time this information was captured. */
  first_extracted_at?: Maybe<Scalars['String']>;
  /** Fine unique id */
  id: Scalars['ID'];
  /** Client that owns this car. */
  id_cliente?: Maybe<Scalars['ID']>;
  /** Id of the public service that had this info */
  id_fornecedor?: Maybe<Scalars['ID']>;
  /** Branch of the client that owns this car. */
  id_unidade?: Maybe<Scalars['ID']>;
  /** Id of the car that has this fine. */
  id_veiculo?: Maybe<Scalars['ID']>;
  indicacao_condutor_file_id?: Maybe<Scalars['String']>;
  /** Conductor blaming file url. */
  indicacao_condutor_file_url?: Maybe<Scalars['String']>;
  infrator_infracao?: Maybe<Scalars['String']>;
  /** UTC datetime when the info was inserted into the database. */
  inserted_at: Scalars['String'];
  /** Official Instructions on fines payment. */
  instrucoes_url?: Maybe<Scalars['String']>;
  list_ait_renainf?: Maybe<Array<Scalars['String']>>;
  /** Address where the fine was applied. */
  local_infracao?: Maybe<Scalars['String']>;
  metadado_01?: Maybe<Scalars['String']>;
  metadado_veiculo_01?: Maybe<Scalars['String']>;
  metadado_veiculo_02?: Maybe<Scalars['String']>;
  metadado_veiculo_03?: Maybe<Scalars['String']>;
  metadado_veiculo_04?: Maybe<Scalars['String']>;
  metadado_veiculo_consolidado?: Maybe<Scalars['String']>;
  /** City where the fine was applied. */
  municipio_infracao?: Maybe<Scalars['String']>;
  /** Name of the client that owns this car. */
  nome_cliente?: Maybe<Scalars['String']>;
  /** Name of the public service that had this info */
  nome_fornecedor?: Maybe<Scalars['String']>;
  /** Name of the branch of the client that owns this car. */
  nome_unidade?: Maybe<Scalars['String']>;
  /** Used by embarcadores */
  numero_processo?: Maybe<Scalars['String']>;
  /** Public service responsible for managing this fine. */
  orgao_infracao?: Maybe<Scalars['String']>;
  other_file_id?: Maybe<Scalars['String']>;
  /** Url of a file provided by the website that is not a payment document nor conductor blaming. */
  other_file_url?: Maybe<Scalars['String']>;
  /** License plate of the car that has this fine. */
  placa_veiculo?: Maybe<Scalars['String']>;
  /** How many points go to the blamed conductor. */
  pontos_infracao?: Maybe<Scalars['Int']>;
  /** Renainf code of the fine. */
  renainf?: Maybe<Scalars['String']>;
  /** Renavam of the car that has this fine. */
  renavam_veiculo?: Maybe<Scalars['String']>;
  /** Id of the job that retrieved this fine */
  scrape_id?: Maybe<Scalars['ID']>;
  /** Pago, Atrasada, A vencer */
  status?: Maybe<Scalars['String']>;
  /** Whatever comes from the spider and is converted to status */
  status_original?: Maybe<Scalars['String']>;
  /** Type atoms are :autos, :agua, :energia, :gas, :telecom */
  sub_tipo_fornecedor?: Maybe<Scalars['String']>;
  subclasse_infracao?: Maybe<Scalars['String']>;
  /** Type atoms are :detran, :servico_essencial */
  tipo_fornecedor?: Maybe<Scalars['String']>;
  /** Original vs NIC */
  tipo_multa_infracao?: Maybe<Scalars['String']>;
  /** Type of the car that has this fine. */
  tipo_veiculo?: Maybe<Scalars['String']>;
  /** State of the branch of the client that owns this car. */
  uf_unidade?: Maybe<Scalars['String']>;
  /** UTC datetime of the last update done to this data. */
  updated_at: Scalars['String'];
  /** Value of the fine. */
  valor?: Maybe<Scalars['Float']>;
  /** Value with discount of the fine. */
  valor_com_desconto?: Maybe<Scalars['Float']>;
  valor_estimado_infracao?: Maybe<Scalars['Float']>;
  /** Value that was paid. */
  valor_pago?: Maybe<Scalars['Float']>;
  /** If false, this entry should be ignored as the vehicle was deactivated for this date. */
  veiculo_ativo_periodo?: Maybe<Scalars['Boolean']>;
};

export type GqlDetranVeiculo = {
  /** Year when the vehicle was built. */
  ano_fabricacao?: Maybe<Scalars['String']>;
  /** The is included in the periodic scraping schedule. */
  ativo?: Maybe<Scalars['Boolean']>;
  /** (?) ask Yuri. */
  categoria?: Maybe<Scalars['String']>;
  chassi?: Maybe<Scalars['String']>;
  /** City where the vihicle is registered. */
  cidade?: Maybe<Scalars['String']>;
  /** Tax id of Client' branch */
  cnpj_unidade?: Maybe<Scalars['String']>;
  /** Date from which all multas and taxas will be inactive. */
  data_inativacao?: Maybe<Scalars['String']>;
  /** (?) ask Yuri. */
  frota?: Maybe<Scalars['String']>;
  /** Mesmo que grupo_veicular_1. Agrupamento administrativo definido pelo cliente. */
  grupo_hierarquico_01?: Maybe<Scalars['String']>;
  /** Mesmo que grupo_veicular_2. Agrupamento administrativo definido pelo cliente. */
  grupo_hierarquico_02?: Maybe<Scalars['String']>;
  /** Mesmo que grupo_veicular_3. Agrupamento administrativo definido pelo cliente. */
  grupo_hierarquico_03?: Maybe<Scalars['String']>;
  /** Mesmo que grupo_veicular_4. Agrupamento administrativo definido pelo cliente. */
  grupo_hierarquico_04?: Maybe<Scalars['String']>;
  /** Vehicle id */
  id?: Maybe<Scalars['ID']>;
  /** Client that owns this vehicle */
  id_cliente?: Maybe<Scalars['ID']>;
  /** Client' branch that owns the vehicle */
  id_unidade?: Maybe<Scalars['ID']>;
  metadado_01?: Maybe<Scalars['String']>;
  metadado_02?: Maybe<Scalars['String']>;
  metadado_03?: Maybe<Scalars['String']>;
  metadado_04?: Maybe<Scalars['String']>;
  metadado_veiculo_consolidado?: Maybe<Scalars['String']>;
  /** License plate */
  placa?: Maybe<Scalars['String']>;
  /** Imutable vehicle document. */
  renavam?: Maybe<Scalars['String']>;
  /** (?) ask Yuri. */
  tipo?: Maybe<Scalars['String']>;
  /** State where the vehicle is registered. */
  uf?: Maybe<Scalars['String']>;
};

export type GqlDetranZmrcAutorizacao = {
  ae?: Maybe<Scalars['String']>;
  ano_fabricacao_veiculo?: Maybe<Scalars['String']>;
  area_de_restricao?: Maybe<Scalars['String']>;
  atividade_condicao?: Maybe<Scalars['String']>;
  bairro_unidade?: Maybe<Scalars['String']>;
  cep_unidade?: Maybe<Scalars['String']>;
  cidade_unidade?: Maybe<Scalars['String']>;
  cnpj_credencial?: Maybe<Scalars['String']>;
  cnpj_unidade?: Maybe<Scalars['String']>;
  data_inicio?: Maybe<Scalars['String']>;
  data_termino?: Maybe<Scalars['String']>;
  deferido?: Maybe<Scalars['String']>;
  extracted_at?: Maybe<Scalars['String']>;
  first_extracted_at?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  id_cliente?: Maybe<Scalars['ID']>;
  id_fornecedor?: Maybe<Scalars['ID']>;
  id_requerimento?: Maybe<Scalars['String']>;
  id_unidade?: Maybe<Scalars['ID']>;
  id_veiculo?: Maybe<Scalars['String']>;
  /** UTC datetime when the info was inserted into the database. */
  inserted_at: Scalars['String'];
  metadado_veiculo_01?: Maybe<Scalars['String']>;
  metadado_veiculo_02?: Maybe<Scalars['String']>;
  metadado_veiculo_03?: Maybe<Scalars['String']>;
  metadado_veiculo_04?: Maybe<Scalars['String']>;
  metadado_veiculo_consolidado?: Maybe<Scalars['String']>;
  motivo_indeferimento?: Maybe<Scalars['String']>;
  nome_cliente?: Maybe<Scalars['String']>;
  nome_fornecedor?: Maybe<Scalars['String']>;
  nome_unidade?: Maybe<Scalars['String']>;
  numero_requerimento?: Maybe<Scalars['String']>;
  observacao_indeferimento?: Maybe<Scalars['String']>;
  placa_veiculo?: Maybe<Scalars['String']>;
  renavam_veiculo?: Maybe<Scalars['String']>;
  scrape_id?: Maybe<Scalars['ID']>;
  sub_tipo_fornecedor?: Maybe<Scalars['String']>;
  tipo_fornecedor?: Maybe<Scalars['String']>;
  uf_unidade?: Maybe<Scalars['String']>;
  /** UTC datetime of the last update done to this data. */
  updated_at: Scalars['String'];
  veiculo_ativo_periodo?: Maybe<Scalars['String']>;
};

export type GqlDetranZmrcByVeiculo = {
  areas_autorizadas?: Maybe<Scalars['String']>;
  data_inicio?: Maybe<Scalars['String']>;
  data_termino?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  id_requerimento?: Maybe<Scalars['String']>;
  inserted_at: Scalars['String'];
  numero_requerimento?: Maybe<Scalars['String']>;
  placa_veiculo?: Maybe<Scalars['String']>;
  qtd_autorizacoes?: Maybe<Scalars['String']>;
  renavam_veiculo?: Maybe<Scalars['String']>;
  status_requerimento?: Maybe<Scalars['String']>;
  updated_at: Scalars['String'];
};

export type GqlDetranZmrcRequerimento = {
  atividade_condicao?: Maybe<Scalars['String']>;
  bairro_unidade?: Maybe<Scalars['String']>;
  cep_unidade?: Maybe<Scalars['String']>;
  cidade_unidade?: Maybe<Scalars['String']>;
  cnpj_credencial?: Maybe<Scalars['String']>;
  cnpj_unidade?: Maybe<Scalars['String']>;
  data_analise_encerrada?: Maybe<Scalars['String']>;
  data_doc_recebido?: Maybe<Scalars['String']>;
  data_requerimento?: Maybe<Scalars['String']>;
  em_analise?: Maybe<Scalars['String']>;
  extracted_at?: Maybe<Scalars['String']>;
  first_extracted_at?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  id_cliente?: Maybe<Scalars['ID']>;
  id_fornecedor?: Maybe<Scalars['ID']>;
  id_unidade?: Maybe<Scalars['ID']>;
  /** UTC datetime when the info was inserted into the database. */
  inserted_at: Scalars['String'];
  nome?: Maybe<Scalars['String']>;
  nome_cliente?: Maybe<Scalars['String']>;
  nome_fornecedor?: Maybe<Scalars['String']>;
  nome_unidade?: Maybe<Scalars['String']>;
  numero_requerimento?: Maybe<Scalars['String']>;
  placas_cadastradas?: Maybe<Array<Maybe<Scalars['String']>>>;
  placas_nao_cadastradas?: Maybe<Array<Maybe<Scalars['String']>>>;
  representante?: Maybe<Scalars['String']>;
  scrape_id?: Maybe<Scalars['ID']>;
  status_original?: Maybe<Scalars['String']>;
  sub_tipo_fornecedor?: Maybe<Scalars['String']>;
  tipo?: Maybe<Scalars['String']>;
  tipo_fornecedor?: Maybe<Scalars['String']>;
  uf_unidade?: Maybe<Scalars['String']>;
  /** UTC datetime of the last update done to this data. */
  updated_at: Scalars['String'];
};

/** Metadada for a file requested to be created in the backend and put into S3 for download. */
export type GqlDownloadRequest = {
  content_type?: Maybe<Scalars['String']>;
  failure_reason?: Maybe<Scalars['String']>;
  /** File name as defined by the user. */
  filename: Scalars['String'];
  id: Scalars['ID'];
  inserted_at: Scalars['String'];
  s3_key: Scalars['String'];
  /** Url to be used for download. */
  signed_url?: Maybe<Scalars['String']>;
  /** Status of the requested file. */
  status: GqlDownloadRequestStatus;
  updated_at: Scalars['String'];
  user_id: Scalars['String'];
};

/** Status of a file requested to be created and put into S3 for downlad. */
export type GqlDownloadRequestStatus =
  /** file is no longer available for download */
  | 'EXPIRED'
  /** one of the above stages failed */
  | 'FAILED'
  /** s3 file cleanup concluded */
  | 'FINISHED'
  /** process started (user submitted query wiht args and options) */
  | 'STARTED'
  /** query finished streaming from db to S3 */
  | 'UPLOADED';

export type GqlEnrichedRequiredAction = {
  /** Ultra short message that may be used as button/link text. */
  action_message: Scalars['String'];
  /** Required action identificator */
  action_type: GqlRequiredAction;
};

/** Group of branches fields available for the user to manually update.. */
export type GqlEntryGrupoUnidades = {
  nome: Scalars['String'];
  raiz_cnpj?: Maybe<Scalars['String']>;
};

/** Unidade fields available for the user to manually update. */
export type GqlEntryUnidade = {
  /** Neighborhood. */
  bairro?: Maybe<Scalars['String']>;
  /** Zip code */
  cep?: Maybe<Scalars['String']>;
  /** City where the branch is located. */
  cidade?: Maybe<Scalars['String']>;
  /** Unique cnpj of this branch */
  cnpj: Scalars['String'];
  /** Street name. */
  logradouro?: Maybe<Scalars['String']>;
  /** Friandly name of this branch */
  nome: Scalars['String'];
  /** Brazilian state where the branch is located. */
  uf?: Maybe<Scalars['String']>;
};

export type GqlEntryUser = {
  email?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id_unidade?: Maybe<Scalars['ID']>;
  password?: Maybe<Scalars['ID']>;
  telephone?: Maybe<Scalars['String']>;
};

/** Type of the file being uploaded. Contract with the frontend - change must be alaways in both sides. */
export type GqlFileType =
  /** A csv with metadados related to exidting multas. */
  | 'METADADOS_MULTAS'
  /** A csv with request parameters to tun multiple scrapes in bulk. */
  | 'SCRAPING_REQUEST'
  /** A csv with request parameters that require constraints to be sent before execution. */
  | 'SCRAPING_REQUEST_WITH_CONSTRATINTS';

/**
 * Injected by the ParseFilters middlewere. Result from the query parser
 * interpretation of the field filter_terms. Options expected by the business logic
 * to define filters to be applied.
 */
export type GqlFilter = {
  /** Same value as in filter_terms. */
  casing?: Maybe<GqlCasingOptions>;
  /** Same value as in filter_terms. */
  field_id: Scalars['String'];
  /** Parsed from the value filter_terms (staring with !). */
  negation?: Maybe<Scalars['Boolean']>;
  /** Parsed from the combination of value and type in filter_terms. */
  operation: GqlOperationOptions;
  /** List of values cast to string, to be uncast back by the query builder function. Extracted from the value in filter_terms. */
  value?: Maybe<Array<Scalars['String']>>;
};

/** Terms written by the user to define filters to be applied. */
export type GqlFilterTerms = {
  /** Defines if a string filter should be case sensitive or insentitive. */
  casing?: Maybe<GqlCasingOptions>;
  /** Original Ecto data type. */
  data_type: GqlDataTypes;
  /** Field where the terms should be searched. */
  field_id: Scalars['String'];
  /** Terms entered by the user in search terms notation. */
  value: Scalars['String'];
};

/** Credentials to be used in one spider with spider identification. */
export type GqlFornecedorCredencial = {
  /** Credentials to be sent to this spider. */
  credenciais: Array<GqlCredencial>;
  /** Name of the spider to be called. */
  scraper_name: Scalars['String'];
};

/** Credentials to be used in one spider with spider identification. */
export type GqlFornecedorCredencialInput = {
  /** Credentials to be sent to this spider. */
  credenciais: Array<GqlInputCredencial>;
  /** Name of the spider to be called. */
  scraper_name: Scalars['String'];
};

/** Fornecedor that can be used in the situacao_veicular product. */
export type GqlFornecedorSituacaoVeicular = {
  /** Semicolon separated list of credential keys. */
  credenciais: Array<GqlCredencial>;
  /** User friendly name of the spider. */
  nome: Scalars['String'];
  /** Human friendly (not user friendly) name of the scraper as used in all cards and execution managers. */
  scraper_name: Scalars['String'];
  /** Determines the level of the spider: nacional, estadual, municipal. */
  sub_tipo: GqlSpiderDetranSubtype;
  /** Determines in what product the spider can be used. */
  tipo: GqlSpiderType;
  /** State initials or BR for nationals. */
  uf: Scalars['String'];
  /** What url can be used to manually access this service. */
  url_servico?: Maybe<Scalars['String']>;
  /**
   * Indicates if this scraper has a complete list of vehicles for its UF. If so, a
   * WRONG_CREDENTIALS error means the vehicle does not exist or it is not
   * registered in this UF.
   */
  validador_credencial?: Maybe<Scalars['Boolean']>;
};

/** Group of branches. */
export type GqlGrupoUnidades = {
  id: Scalars['ID'];
  id_cliente: Scalars['ID'];
  ids_unidades: Array<Maybe<Scalars['String']>>;
  nome: Scalars['String'];
  raiz_cnpj?: Maybe<Scalars['String']>;
  tipo: GqlGrupoUnidadesType;
  unidades: Array<GqlUnidade>;
};

/** Grupos unidades have different natures, depending on how the were created. */
export type GqlGrupoUnidadesType =
  /** Contains all unidades - created automaticaly */
  | 'ALL'
  /** Equivalent to one unidade  - created automaticaly */
  | 'SINGLE'
  /** Arbitrary group of unidade - created by the user */
  | 'SUBSET';

/** Issue that prevented a csv line to be parsed. */
export type GqlImportacoesCsvIssue = {
  content_type: Scalars['String'];
  error_message: Scalars['String'];
  file_id: Scalars['ID'];
  file_type: Scalars['String'];
  filename: Scalars['String'];
  header_text: Scalars['String'];
  id: Scalars['ID'];
  id_cliente: Scalars['ID'];
  line_text: Scalars['String'];
  path: Scalars['String'];
  status: GqlCsvLineIssuesStatus;
};

/** Input of credencial to be used in one spider. */
export type GqlInputCredencial = {
  /** Credential key name, as expected by the spider. */
  tipo_interno: Scalars['String'];
  /** Value to be sent to the spider. */
  valor: Scalars['String'];
};

/** Errors found when trying to insert one row from a data sheet that was uploaded by the user. */
export type GqlInsertionErrors = {
  /** Number of the row in the uploaded file where this error can be found. */
  line_number: Scalars['String'];
  /** Descriptive error message, used for simple errors with no messages in the payload. */
  message?: Maybe<Scalars['String']>;
  /** List of fields that had errors in this line. */
  payload?: Maybe<Array<GqlIntertionErrorPayload>>;
  /** Type of issue that generated the error */
  type: GqlInsertionErrorType;
};

/** Type of issue that generated the error */
export type GqlInsertionErrorType =
  | 'NOT_FOUND'
  | 'UNAUTHENTICATED'
  | 'UNAUTHORIZED'
  | 'VALIDATION_FAILURE';

/** Errors found in one cell from a data sheet that was uploaded by the user. */
export type GqlIntertionErrorPayload = {
  field: Scalars['String'];
  messages: Array<Maybe<Scalars['String']>>;
  value?: Maybe<Scalars['String']>;
};

/** Properties of a single file. */
export type GqlLocalFileProps = {
  file_name: Scalars['String'];
  /** Nature of the file contents. */
  file_type: GqlFileType;
};

/** Types of filter operation to be used. */
export type GqlOperationOptions =
  /** Between the two terms provided. (value is a touple) */
  | 'BETWEEN'
  /** Value is equal to the provided term. */
  | 'EQ'
  /** Greater than the term. */
  | 'GT'
  /** Greater than or equal to the term. */
  | 'GTE'
  /** Value equals to least one term in the list. (value is a list) */
  | 'IN'
  /** Value is empty. */
  | 'IS_NULL'
  /** Use the LIKE SQL function to search the term. */
  | 'LIKE'
  /** Lower than the term. */
  | 'LT'
  /** Lower than or equal to the term. */
  | 'LTE'
  /** Value is not equal to the provided term. */
  | 'NEQ';

export type GqlOrderBy = {
  direction: GqlOrderByDirection;
  field_id: Scalars['String'];
};

export type GqlOrderByDirection =
  | 'ASC'
  | 'DESC';

/** Instructions on how to build the query to populate a spreasheet. */
export type GqlQueryArgs = {
  /** If true, return unique values only. */
  distinct?: Maybe<Scalars['Boolean']>;
  /** List of filters to be applied. */
  filter_list?: Maybe<Array<GqlFilter>>;
  /**
   * List of terms, up to one per column in select_list, written by the user to
   * define filters to be applied. When passed in list_sheet a query, it is parsed
   * to produce the filter_list to be consume by the business logic.
   */
  filter_terms_list?: Maybe<Array<GqlFilterTerms>>;
  /** Maximum number of fields to be returned. */
  limit?: Maybe<Scalars['Int']>;
  /** Position of the first field to be returned. */
  offset?: Maybe<Scalars['Int']>;
  /** List of fields to be used in ordering the results. */
  order_by_list?: Maybe<Array<GqlOrderBy>>;
  /**
   * List of fields to have their data returned. If not provided, will be injected
   * by the ParseSelectList middleware, using the graphql selected return fields.
   */
  select_list?: Maybe<Array<Scalars['String']>>;
};

/** Query options to be sent to a list function so its results are filtered, ordered and/or grouped. */
export type GqlQueryOpts = {
  /** If true, return unique values only. */
  distinct?: Maybe<Scalars['Boolean']>;
  /** List of filters to be applied. */
  filter_list?: Maybe<Array<GqlFilter>>;
  /** Maximum number of fields to be returned. */
  limit?: Maybe<Scalars['Int']>;
  /** Position of the first field to be returned. */
  offset?: Maybe<Scalars['Int']>;
  /** List of fields to be used in ordering the results. */
  order_by_list?: Maybe<Array<GqlOrderBy>>;
  /**
   * List of fields to have their data returned. If not provided, will be injected
   * by the ParseSelectList middleware, using the graphql selected return fields.
   */
  select_list?: Maybe<Array<Scalars['String']>>;
};

/** Enriched error information related to the request process. */
export type GqlRequestError = {
  /** Friendly message in PT-BR, to be shown to users. */
  error_message: Scalars['String'];
  /** Error types used by all spiders. */
  error_type: Scalars['String'];
  /** Indicates if this type of error can be retried. Not if this specific error should be retryed. */
  retryable?: Maybe<Scalars['Boolean']>;
};

/** What should be done given the result of a job. */
export type GqlRequiredAction =
  /** Fix the credentials before running the job again. */
  | 'FIX_CREDENTIALS'
  /** Nothing need to be done. The job was successfully completed. */
  | 'NONE'
  /** Notify development team because website has probably changed and needs adjustments ASAP */
  | 'NOTIFY_DEVS'
  /** Remove credential and check state before running the job again. */
  | 'REMOVE_CREDENTIAL'
  /** Run the job again, something broke in the middle of the process. */
  | 'RETRY'
  /** Scraper not found or under maintenance. */
  | 'SCRAPER_NOT_FOUND';

export type GqlRootMutationType = {
  /** Add a single group of branches to an existing customer. */
  add_entry_grupo_unidades: GqlGrupoUnidades;
  /** Add a single branch to an existing customer. */
  add_entry_unidade: GqlUnidade;
  /** Add a single user. */
  add_entry_user: GqlUser;
  /**
   * Add the navigation constraints to an existing scraping request that has at
   * least one line with both has_navigation_constraints: true and a valid
   * navigation_constraints_key.
   */
  add_navigation_constraints_to_csv_request: Scalars['String'];
  /** Remove one branch into a subset group of branches. */
  add_unidade_into_grupo_unidades: GqlSuccess;
  /** Add multiple branches to an existing customer. */
  add_unidades: Array<GqlUnidade>;
  /** Changes one vehicle's data fields. */
  atualizar_veiculo: Scalars['String'];
  /** Cancel all pending jobs for a scraping request. */
  cancel_scraping_request: Scalars['String'];
  /** Stops vehicle verification for a group of suppliers in the context of an already created verification id. */
  cancelar_verificacao: GqlSuccess;
  /** Create a new customer. */
  create_cliente: GqlCliente;
  /** Request creation of a SPED MA csv file for download containg all data returned by the query as defined in query_args. */
  create_sheet_csv: GqlDownloadRequest;
  /** Requests an S3 presigned upload url. */
  create_upload_urls: Array<GqlUploadProps>;
  /** Informs the backend that a file upload has ended. */
  end_upload: Scalars['String'];
  /** Send a fixed version of a csv row for reprocessing. */
  fix_csv_issue?: Maybe<Scalars['String']>;
  /** Signal the csv issue will not be fixed. */
  ignore_csv_issue?: Maybe<Scalars['String']>;
  /** Makes a vehicle and all its data incative from data_inativacao onwards. */
  inativar_veiculo: Scalars['String'];
  /** Makes a vehicle and all its data active. */
  reativar_veiculo: Scalars['String'];
  /** Remove group of branches. */
  remove_entry_grupo_unidades: GqlSuccess;
  /** Remove branch. */
  remove_entry_unidade: GqlSuccess;
  /** Remove user. */
  remove_entry_user: GqlSuccess;
  /** Remove one branch from a subset group of branches. */
  remove_unidade_from_grupo_unidades: GqlSuccess;
  /** Request to remake a vehicle verification for a group of suppliers in the context of an already created verification id. */
  resolicitar_verificacao: GqlVerificacao;
  /** Create a new scraping request with all scrapes that returned errors that are required to be retryed. */
  retry_scraping_request_with_errors: Scalars['String'];
  /** Sign in a user */
  signin: GqlSession;
  /** Create a new user */
  signup: GqlSession;
  /** Request a vehicle verification. Returns the verification id. */
  solicitar_verificacao: GqlVerificacao;
  /** Update group of branches. */
  update_entry_grupo_unidades: GqlSuccess;
  /** Update branch. */
  update_entry_unidade: GqlSuccess;
  /** Update user. */
  update_entry_user: GqlSuccess;
  /** Upload a file converted to base64 string, returns a request_id. */
  upload_base64: GqlBase64Uploaded;
};


export type GqlRootMutationTypeAddEntryGrupoUnidadesArgs = {
  id_cliente: Scalars['ID'];
  new_entry: GqlEntryGrupoUnidades;
};


export type GqlRootMutationTypeAddEntryUnidadeArgs = {
  id_cliente: Scalars['ID'];
  new_entry: GqlEntryUnidade;
};


export type GqlRootMutationTypeAddEntryUserArgs = {
  id_cliente: Scalars['ID'];
  new_entry: GqlEntryUser;
};


export type GqlRootMutationTypeAddNavigationConstraintsToCsvRequestArgs = {
  encoded_navigation_constraints: Scalars['String'];
  scraping_request_id: Scalars['ID'];
};


export type GqlRootMutationTypeAddUnidadeIntoGrupoUnidadesArgs = {
  id_grupo_unidades: Scalars['ID'];
  id_unidade: Scalars['ID'];
};


export type GqlRootMutationTypeAddUnidadesArgs = {
  cnpj_unidades: Array<Scalars['String']>;
  id_cliente: Scalars['ID'];
};


export type GqlRootMutationTypeAtualizarVeiculoArgs = {
  dados_alterados: GqlDadosVeiculo;
  id_cliente: Scalars['String'];
  id_veiculo: Scalars['String'];
};


export type GqlRootMutationTypeCancelScrapingRequestArgs = {
  scraping_request_id: Scalars['ID'];
};


export type GqlRootMutationTypeCancelarVerificacaoArgs = {
  id_cliente: Scalars['String'];
  id_verificacao: Scalars['String'];
};


export type GqlRootMutationTypeCreateClienteArgs = {
  cnpj_unidades?: Maybe<Array<Scalars['String']>>;
  nome: Scalars['String'];
};


export type GqlRootMutationTypeCreateSheetCsvArgs = {
  filename: Scalars['String'];
  id_cliente: Scalars['String'];
  query_args: GqlQueryArgs;
  sheet_name: GqlSheetName;
};


export type GqlRootMutationTypeCreateUploadUrlsArgs = {
  file_list: Array<GqlLocalFileProps>;
  id_cliente: Scalars['String'];
  json_encoded_attributes?: Maybe<Scalars['String']>;
};


export type GqlRootMutationTypeEndUploadArgs = {
  file_id: Scalars['String'];
};


export type GqlRootMutationTypeFixCsvIssueArgs = {
  csv_issue_id: Scalars['String'];
  fixed_values: Array<Scalars['String']>;
};


export type GqlRootMutationTypeIgnoreCsvIssueArgs = {
  csv_issue_id: Scalars['String'];
};


export type GqlRootMutationTypeInativarVeiculoArgs = {
  data_inativacao: Scalars['String'];
  id_cliente: Scalars['String'];
  id_veiculo: Scalars['String'];
};


export type GqlRootMutationTypeReativarVeiculoArgs = {
  id_cliente: Scalars['String'];
  id_veiculo: Scalars['String'];
};


export type GqlRootMutationTypeRemoveEntryGrupoUnidadesArgs = {
  entry_id: Scalars['ID'];
};


export type GqlRootMutationTypeRemoveEntryUnidadeArgs = {
  entry_id: Scalars['ID'];
};


export type GqlRootMutationTypeRemoveEntryUserArgs = {
  entry_id: Scalars['ID'];
};


export type GqlRootMutationTypeRemoveUnidadeFromGrupoUnidadesArgs = {
  id_grupo_unidades: Scalars['ID'];
  id_unidade: Scalars['ID'];
};


export type GqlRootMutationTypeResolicitarVerificacaoArgs = {
  id_cliente: Scalars['String'];
  id_verificacao: Scalars['String'];
  lista_fornecedor_credenciais: Array<GqlFornecedorCredencialInput>;
};


export type GqlRootMutationTypeRetryScrapingRequestWithErrorsArgs = {
  scraping_request_id: Scalars['ID'];
};


export type GqlRootMutationTypeSigninArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type GqlRootMutationTypeSignupArgs = {
  email: Scalars['String'];
  full_name: Scalars['String'];
  password: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
};


export type GqlRootMutationTypeSolicitarVerificacaoArgs = {
  baixar_boletos: Scalars['Boolean'];
  id_cliente: Scalars['String'];
  lista_fornecedor_credenciais: Array<GqlFornecedorCredencialInput>;
  placa: Scalars['String'];
  renavam: Scalars['String'];
};


export type GqlRootMutationTypeUpdateEntryGrupoUnidadesArgs = {
  entry_id: Scalars['ID'];
  updated_entry: GqlEntryGrupoUnidades;
};


export type GqlRootMutationTypeUpdateEntryUnidadeArgs = {
  entry_id: Scalars['ID'];
  updated_entry: GqlEntryUnidade;
};


export type GqlRootMutationTypeUpdateEntryUserArgs = {
  entry_id: Scalars['ID'];
  updated_entry: GqlEntryUser;
};


export type GqlRootMutationTypeUploadBase64Args = {
  base64_file: Scalars['String'];
  file_type: GqlBase64FileType;
  id_cliente: Scalars['String'];
};

export type GqlRootQueryType = {
  /**
   * count the number of rows that would be returned by calling list_sheet with the
   * same filters defined in query_opts. Or no filters if query_args not passed.
   */
  count_sheet_rows: Scalars['Int'];
  get_current_user: GqlUser;
  /** Retrieve one grupo unidade and a list of its unidades. */
  get_entry_grupo_unidades: GqlGrupoUnidades;
  /** Retrieve one unidade. */
  get_entry_unidade: GqlUnidade;
  /** Retrieve one grupo unidade with a list of its unidades. */
  get_grupo_unidades: GqlGrupoUnidades;
  /** Retrieve the list of customers the current user has access to. */
  get_user_id_hack: Scalars['String'];
  /** Retrieves one verification. */
  get_verificacao: GqlVerificacao;
  /** Checks if situacao veicular should be made temporarily unavailable for end users */
  is_situacao_veicular_inactive: Scalars['Boolean'];
  /** List all access policies for a given unidade */
  list_access_policies_by_unidade: GqlAccessPolicy;
  /** List all downloads with status :started or :uploaded for this user. */
  list_active_download_requests: Array<GqlDownloadRequest>;
  /** Retrieve the list of customers the current user has access to. */
  list_clientes: Array<GqlCliente>;
  /** List pending csv issues. */
  list_csv_issues: Array<GqlImportacoesCsvIssue>;
  /** List all debitos of one verification. */
  list_debitos: Array<GqlDetranDebito>;
  /** List all results of one verification. */
  list_debitos_restricoes: Array<GqlDebitoRestricao>;
  /** Retrieve the list of grupos_unidades under one client that can be edited by the user. */
  list_editable_grupos_unidades: Array<GqlGrupoUnidades>;
  /** List all fornecedores that are available for nada consta. */
  list_fornecedores_situacao_veicular: Array<GqlFornecedorSituacaoVeicular>;
  /** Retrieve the list of all grupos_unidades under one client. */
  list_grupos_unidades: Array<GqlGrupoUnidades>;
  /** List all multas of one verification. */
  list_multas: Array<GqlDetranMulta>;
  /** List of `contas` captured by the spiders. */
  list_sheet_conta: Array<GqlConta>;
  /** List of cronotacografos for all tracked vehicles. */
  list_sheet_detran_cronotacografo: Array<GqlDetranCronotacografo>;
  /** List of debts captured for all vehicles of one client. */
  list_sheet_detran_debito: Array<GqlDetranDebito>;
  /** List of fines captured for all vehicles of one client. */
  list_sheet_detran_multa: Array<GqlDetranMulta>;
  /** List of vehicles owned by the client. */
  list_sheet_detran_veiculo: Array<GqlDetranVeiculo>;
  /** List of zmrc autorizations for all tracked vehicles. */
  list_sheet_detran_zmrc_autorizacao: Array<GqlDetranZmrcAutorizacao>;
  /** List of zmrc requirements and autorizations by vehicles. */
  list_sheet_detran_zmrc_by_veiculo: Array<GqlDetranZmrcByVeiculo>;
  /** List of zmrc requirements by a given client branches. */
  list_sheet_detran_zmrc_requerimento: Array<GqlDetranZmrcRequerimento>;
  /** List of executed scrapes. */
  list_sheet_scrape: Array<GqlScrape>;
  /** List of scraping requests created for bulk execution. */
  list_sheet_scraping_request: Array<GqlScrapingRequest>;
  /** List of client branches. */
  list_sheet_unidade: Array<GqlUnidade>;
  /** Retrieve the list of grupos_unidades that have only one unidade in it. */
  list_single_grupos_unidades: Array<GqlGrupoUnidades>;
  /** Retrieve the list of unidades for a given customer. */
  list_unidades: Array<GqlUnidade>;
  /** List all downloads with status :started or :uploaded for this user. */
  list_upload_status: Array<GqlUploadedCsvStatus>;
  list_users: Array<GqlUser>;
  /** List all verifications for one client. */
  list_verificacoes: Array<GqlVerificacao>;
};


export type GqlRootQueryTypeCountSheetRowsArgs = {
  id_cliente: Scalars['String'];
  query_args?: Maybe<GqlQueryArgs>;
  sheet_name: GqlSheetName;
};


export type GqlRootQueryTypeGetEntryGrupoUnidadesArgs = {
  entry_id: Scalars['ID'];
};


export type GqlRootQueryTypeGetEntryUnidadeArgs = {
  entry_id: Scalars['ID'];
};


export type GqlRootQueryTypeGetGrupoUnidadesArgs = {
  id_grupo_unidades: Scalars['ID'];
};


export type GqlRootQueryTypeGetVerificacaoArgs = {
  id_verificacao: Scalars['String'];
};


export type GqlRootQueryTypeListAccessPoliciesByUnidadeArgs = {
  id_unidade: Scalars['ID'];
};


export type GqlRootQueryTypeListActiveDownloadRequestsArgs = {
  user_id: Scalars['String'];
};


export type GqlRootQueryTypeListCsvIssuesArgs = {
  id_cliente: Scalars['String'];
};


export type GqlRootQueryTypeListDebitosArgs = {
  id_verificacao: Scalars['String'];
  query_opts?: Maybe<GqlQueryOpts>;
};


export type GqlRootQueryTypeListDebitosRestricoesArgs = {
  id_cliente: Scalars['String'];
  id_verificacao: Scalars['String'];
};


export type GqlRootQueryTypeListEditableGruposUnidadesArgs = {
  id_cliente: Scalars['ID'];
};


export type GqlRootQueryTypeListGruposUnidadesArgs = {
  id_cliente: Scalars['ID'];
};


export type GqlRootQueryTypeListMultasArgs = {
  id_verificacao: Scalars['String'];
  query_opts?: Maybe<GqlQueryOpts>;
};


export type GqlRootQueryTypeListSheetContaArgs = {
  id_cliente: Scalars['String'];
  query_args: GqlQueryArgs;
};


export type GqlRootQueryTypeListSheetDetranCronotacografoArgs = {
  id_cliente: Scalars['String'];
  query_args: GqlQueryArgs;
};


export type GqlRootQueryTypeListSheetDetranDebitoArgs = {
  id_cliente: Scalars['String'];
  query_args: GqlQueryArgs;
};


export type GqlRootQueryTypeListSheetDetranMultaArgs = {
  id_cliente: Scalars['String'];
  query_args: GqlQueryArgs;
};


export type GqlRootQueryTypeListSheetDetranVeiculoArgs = {
  id_cliente: Scalars['String'];
  query_args: GqlQueryArgs;
};


export type GqlRootQueryTypeListSheetDetranZmrcAutorizacaoArgs = {
  id_cliente: Scalars['String'];
  query_args: GqlQueryArgs;
};


export type GqlRootQueryTypeListSheetDetranZmrcByVeiculoArgs = {
  id_cliente: Scalars['String'];
  query_args: GqlQueryArgs;
};


export type GqlRootQueryTypeListSheetDetranZmrcRequerimentoArgs = {
  id_cliente: Scalars['String'];
  query_args: GqlQueryArgs;
};


export type GqlRootQueryTypeListSheetScrapeArgs = {
  id_cliente: Scalars['String'];
  query_args: GqlQueryArgs;
};


export type GqlRootQueryTypeListSheetScrapingRequestArgs = {
  id_cliente: Scalars['String'];
  query_args: GqlQueryArgs;
};


export type GqlRootQueryTypeListSheetUnidadeArgs = {
  id_cliente: Scalars['String'];
  query_args: GqlQueryArgs;
};


export type GqlRootQueryTypeListSingleGruposUnidadesArgs = {
  id_cliente: Scalars['ID'];
};


export type GqlRootQueryTypeListUnidadesArgs = {
  id_cliente: Scalars['ID'];
};


export type GqlRootQueryTypeListUploadStatusArgs = {
  id_cliente: Scalars['String'];
};


export type GqlRootQueryTypeListUsersArgs = {
  id_cliente: Scalars['ID'];
};


export type GqlRootQueryTypeListVerificacoesArgs = {
  id_cliente: Scalars['String'];
  query_opts?: Maybe<GqlQueryOpts>;
};

export type GqlRootSubscriptionType = {
  /** Pings back to the frontend when an uploaded base64 file has its contents finished being inserted into the database. */
  csv_base64_data_inserted: GqlCsvInsertionErrors;
  /** Tracks progress of data transfer from csv into the database. */
  csv_data_inserted: GqlUploadedCsvStatus;
  /** A new debito_restricao changed under this verificacao. */
  debito_restricao_changed: GqlDebitoRestricao;
  /** List of debitos_restricoes placeholders was created */
  debitos_restricoes_created: Array<GqlDebitoRestricao>;
  /** A file requested for download had its status changed. */
  download_request_changed: GqlDownloadRequest;
  /** All jobs in a verificacao have finished. */
  verificacao_changed: GqlVerificacao;
};


export type GqlRootSubscriptionTypeCsvBase64DataInsertedArgs = {
  id_cliente: Scalars['String'];
};


export type GqlRootSubscriptionTypeCsvDataInsertedArgs = {
  id_cliente: Scalars['String'];
};


export type GqlRootSubscriptionTypeDebitoRestricaoChangedArgs = {
  id_verificacao: Scalars['String'];
};


export type GqlRootSubscriptionTypeDebitosRestricoesCreatedArgs = {
  id_verificacao: Scalars['String'];
};


export type GqlRootSubscriptionTypeDownloadRequestChangedArgs = {
  user_id: Scalars['String'];
};


export type GqlRootSubscriptionTypeVerificacaoChangedArgs = {
  id_verificacao: Scalars['String'];
};

export type GqlS3File = {
  content_type?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  s3_bucket: Scalars['String'];
  s3_key: Scalars['String'];
  url: Scalars['String'];
  utc_time_of_capture: Scalars['String'];
};

/** Lifecycle and status information of a single scrape (one spider call). */
export type GqlScrape = {
  /** Credentials used in this job. */
  credenciais?: Maybe<Array<Maybe<GqlCredentialField>>>;
  /** Contents of the data_collected payload `errors` key, if any. */
  data_collected_errors?: Maybe<Array<Maybe<GqlScrapeResponseError>>>;
  /** UTC datetime when the meta data was made available to the user. */
  data_collected_received_at?: Maybe<Scalars['String']>;
  /** Can be :waiting, :received, :received_with_errors */
  data_collected_status?: Maybe<GqlDataCollectedStatus>;
  /** Should any upload be received? */
  expect_upload?: Maybe<Scalars['Boolean']>;
  /**
   * Number of unique file_ids found in data_collected, they are expected to be
   * uploaded to S3. The spider must call the data_uploaded webhook once for each file.
   */
  expected_files_count?: Maybe<Scalars['Int']>;
  /** Number of files that presented some error, could have been uploaded or not. */
  failed_files_received_count?: Maybe<Scalars['Int']>;
  /** One of :scrapinghub_api_request, :scraper_not_found, :data_collected_timeout, :upload_completed_timeout */
  failure_reason?: Maybe<Scalars['String']>;
  /** UTC datetime when the last piece of expected data was made available to the user. */
  finished_at?: Maybe<Scalars['String']>;
  /** UTC datetime of the first upload made available to the user. */
  first_upload_received_at?: Maybe<Scalars['String']>;
  /** Unique identifyer of this scrape execution. */
  id: Scalars['ID'];
  /** Cliente that owns all the data returned for this scrape. */
  id_cliente?: Maybe<Scalars['ID']>;
  /** UTC datetime when the scrape process has been started. */
  inserted_at: Scalars['String'];
  /** UTC datetime of the last upload made available to the user. */
  last_upload_received_at?: Maybe<Scalars['String']>;
  /** Time when the requst was sent to Execution Backend. */
  requested_at?: Maybe<Scalars['String']>;
  /** What should be done given the result of this job. */
  required_action?: Maybe<GqlRequiredAction>;
  /** Job id of this scrape in Execution Backend. */
  scrape_job_id?: Maybe<Scalars['String']>;
  /** Unique spider identifier. */
  scraper_name?: Maybe<Scalars['String']>;
  /** Common id to all scrapes in the same scraping request. */
  scraping_request_id?: Maybe<Scalars['ID']>;
  /** Time when the scraping request was created. */
  scraping_request_inserted_at?: Maybe<Scalars['String']>;
  /** Populated with file name if a csv was used to generate the scraping request. */
  scraping_request_name?: Maybe<Scalars['String']>;
  /** One of :requested, :receiving, :canceled, :finished, :failed */
  status?: Maybe<GqlScrapeStatus>;
  /** Number of files uploaded without errors. */
  successful_files_received_count?: Maybe<Scalars['Int']>;
  /** UTC datetime of the last update received from te spider for this scrape. */
  updated_at: Scalars['String'];
  /** Contents of the upload_completed payload `errors` key, if any. */
  upload_completed_errors?: Maybe<Array<Maybe<GqlScrapeResponseError>>>;
  /** List of utc datetimes indicating when each file was made available to the user. */
  upload_completed_received_at_list?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Enriched error information related to the job execution by the spider. */
export type GqlScrapeResponseError = {
  /** Whatever came under `errors` in data_collected, strigifyed. */
  encoded_error?: Maybe<Scalars['String']>;
  /** Friendly message in PT-BR, to be shown to users. */
  error_message: Scalars['String'];
  /** Error types used by all spiders. */
  error_type: Scalars['String'];
  /** Internal: should devs be notified when this error happens? */
  notify_devs: Scalars['Boolean'];
  /** Indicates if this type of error can be retried. Not if this specific error should be retryed. */
  retryable: Scalars['Boolean'];
};

export type GqlScrapeStatus =
  /** The scrape job was cancelled. */
  | 'CANCELED'
  /** The scrape job has never completed. */
  | 'FAILED'
  /** The scrape job has been completed. */
  | 'FINISHED'
  /** The scrape job is happening now. */
  | 'RECEIVING'
  /** The scrape job is in the queue. */
  | 'REQUESTED';

/** Contains the aggregated status of a list of scrapes created at the same time for a single bulk execution. */
export type GqlScrapingRequest = {
  /** Number of scrapes manually cancelled by the user. */
  canceled_scrapes_count?: Maybe<Scalars['Int']>;
  /**
   * Number of scrapes that may have returned partial data and/or some of all
   * files, but returned at least one error in the spider payload, or had execution
   * errors in the server or in execution backend.
   */
  failed_scrapes_count?: Maybe<Scalars['Int']>;
  /** atom that identifies a failure type. */
  failure_reason?: Maybe<Scalars['String']>;
  /** Number of scrapes that must have their credentials fixed. */
  fix_credentials_scrapes_count?: Maybe<Scalars['Int']>;
  /** scraping_request_id that is common to all scrapes in the same scraping request. */
  id: Scalars['ID'];
  /** Cliente that owns all the data returned for this scraping request. */
  id_cliente: Scalars['ID'];
  /** UTC datetime when the process has been started. */
  inserted_at: Scalars['String'];
  /** Populated with file name if a csv was used to generate the scraping request. */
  name?: Maybe<Scalars['String']>;
  /** Number of scrapes that do not require any further action. */
  none_scrapes_count?: Maybe<Scalars['Int']>;
  /** Number of scrapes that failed due to (proable) website change. */
  notify_devs_scrapes_count?: Maybe<Scalars['Int']>;
  /** Number of scrapes that must have their credentials removed from credentials file. */
  remove_credential_scrapes_count?: Maybe<Scalars['Int']>;
  /** Pre-programmed date for scrapig request to be executed. */
  request_at?: Maybe<Scalars['String']>;
  /** Number of scrapes that that must be retried. */
  retry_scrapes_count?: Maybe<Scalars['Int']>;
  /** Number of scrapes that failed due to scraper not found. */
  scraper_not_found_scrapes_count?: Maybe<Scalars['Int']>;
  /** Number of valid scrapes being executed. */
  scrapes_count?: Maybe<Scalars['Int']>;
  /** Friendly numerical unique id for the scraping request. */
  serial_id?: Maybe<Scalars['Int']>;
  /** Status of the request */
  status: GqlScrapingRequestStatus;
  /** Number of scrapes that returned all data and all files without any errors. */
  successful_scrapes_count?: Maybe<Scalars['Int']>;
  /** UTC datetime of the last update received from any scrape activity related to this request. */
  updated_at: Scalars['String'];
};

export type GqlScrapingRequestStatus =
  /** Manually canceled by user */
  | 'CANCELED'
  /** One of the above stages failed */
  | 'FAILED'
  /** File finished processing (all requests returned from scrapers) */
  | 'FINISHED'
  /** All scrapes for this request were initiated. */
  | 'REQUESTED'
  /** Process started (either url was requested or file given for processing) */
  | 'STARTED';

/** Product for which the access policy is aplicable */
export type GqlServiceName =
  /** AKA: Detrans */
  | 'DETRAN'
  /** AKA: Utilities */
  | 'SERVICO_ESSENCIAL'
  /** AKA: Nada Consta */
  | 'SITUACAO_VEICULAR';

export type GqlSession = {
  token: Scalars['String'];
  user: GqlUser;
};

/** Names of the available sheets. */
export type GqlSheetName =
  /** List of `contas` captured by the spiders. */
  | 'CONTA'
  /** List of cronotacografos for all tracked vehicles. */
  | 'DETRAN_CRONOTACOGRAFO'
  /** List of `débitos` captured by the spiders. */
  | 'DETRAN_DEBITO'
  /** List of `multas` captured by the spiders. */
  | 'DETRAN_MULTA'
  /** List of vehicles owned by a given client. */
  | 'DETRAN_VEICULO'
  /** List of zmrc autorizations by a given client branches. */
  | 'DETRAN_ZMRC_AUTORIZACAO'
  /** List of zmrc requirements and autorization by vehicles. */
  | 'DETRAN_ZMRC_BY_VEICULO'
  /** List of zmrc requirements by a given client branches. */
  | 'DETRAN_ZMRC_REQUERIMENTO'
  /** List of scraping requests created for bulk execution. */
  | 'SCRAPE'
  /** List of scraping requests created for bulk execution. */
  | 'SCRAPING_REQUEST'
  /** List of client branches. */
  | 'UNIDADE';

export type GqlSituationResult = {
  /** Number of items found in undesirable situation */
  count?: Maybe<Scalars['Int']>;
  /** List of details related to the current result. */
  details?: Maybe<Array<GqlSituationResultDetail>>;
  /** CONSTA = bad!, NADA_CONSTA = good!  */
  status: GqlStatusDebitoRestricao;
  /** Short description of undesirable things */
  summary?: Maybe<Scalars['String']>;
  /** Total debt. */
  value?: Maybe<Scalars['Float']>;
};

export type GqlSituationResultDetail = {
  /** Information relevant to the context of the title. */
  text?: Maybe<Scalars['String']>;
  /** Each title should appear only once in the details list. */
  title?: Maybe<Scalars['String']>;
};

/** Allows for spider classification in products. */
export type GqlSpiderDetranSubtype =
  /** Detran subtype */
  | 'ESTADUAL'
  /** Detran subtype */
  | 'MUNICIPAL'
  /** Detran subtype */
  | 'NACIONAL';

/** Determines in what product the spider can be used. */
export type GqlSpiderType =
  /** Can be used both for Detrand and Nada Consta products. */
  | 'DETRAN'
  /** Can be used only in Utilities products. */
  | 'SERVICO_ESSENCIAL';

export type GqlStatusDebitoRestricao =
  | 'CONSTA'
  | 'NADA_CONSTA';

/** Status of the results. */
export type GqlStatusRestricoes =
  /**
   * Finalizada a listagem da etapa ao qual o status está associado. A etapa
   * (multas, debito ou debito restrição) está descrita no nome do campo
   */
  | 'FINISHED'
  /**
   * Aguardanda a listagem da etapa ao qual o status está associado. A etapa
   * (multas, debito ou debito restrição) está descrita no nome do campo
   */
  | 'PENDING';

/** Status of the job being done. */
export type GqlStatusVerificacao =
  /** usuário cancelou a solicitação */
  | 'CANCELED'
  /** solicitacão finalizada com erro */
  | 'FAILED'
  /** solicitacão finalizada */
  | 'FINISHED'
  /** verificação solicitada */
  | 'REQUESTED'
  /** ids are available */
  | 'STARTED';

/** Indicates an operation was successful and what happened on its resolution. */
export type GqlSuccess =
  /** An asynchronous event was dispatched. */
  | 'DISPATCHED';

/** Contains tha characteristics of a client branch. */
export type GqlUnidade = {
  /** Neighborhood. */
  bairro?: Maybe<Scalars['String']>;
  /** Zip code */
  cep?: Maybe<Scalars['String']>;
  /** City where the branch is located. */
  cidade?: Maybe<Scalars['String']>;
  /** Unique cnpj of this branch */
  cnpj: Scalars['String'];
  /** Complementary address information */
  complemento?: Maybe<Scalars['String']>;
  /** Identifyes one single branch. */
  id: Scalars['ID'];
  /** Client that owns this branch. */
  id_cliente: Scalars['ID'];
  /** UTC datetime when the info was inserted into the database. */
  inserted_at: Scalars['String'];
  /** Street name. */
  logradouro?: Maybe<Scalars['String']>;
  /** Friandly name of this branch */
  nome: Scalars['String'];
  /** Address number */
  numero?: Maybe<Scalars['String']>;
  /** Brazilian state where the branch is located. */
  uf?: Maybe<Scalars['String']>;
  /** UTC datetime of the last update done to this data. */
  updated_at: Scalars['String'];
};

/** Lifecycle status of an uploaded file as defined in importacoes/csv/projections/csv.ex  */
export type GqlUploadedCsvStatus = {
  content_type?: Maybe<Scalars['String']>;
  failure_reason?: Maybe<GqlUploadFailureReason>;
  file_type: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['ID'];
  inserted_at: Scalars['String'];
  lines_count: Scalars['Int'];
  path?: Maybe<Scalars['String']>;
  processed_lines_count: Scalars['Int'];
  status: GqlUploadStatus;
  updated_at: Scalars['String'];
};

export type GqlUploadFailureReason =
  | 'FILE_DECODING'
  | 'S3_DOWNLOAD_PROCESS'
  | 'S3_UPLOAD_NOT_FOUND'
  | 'S3_UPLOAD_PROCESS'
  | 'S3_UPLOAD_TIMEOUT';

/** Properties related to the upload of a single file. */
export type GqlUploadProps = {
  /** Id of the `cliente` who owns the data being uploaded. */
  cliente?: Maybe<Scalars['String']>;
  /** Id of the file being uploaded. */
  file_id: Scalars['ID'];
  /** Temporary URL created by the backend to with permission to upload into S3. */
  signed_url: Scalars['String'];
  /** Status of a file upload. */
  status?: Maybe<GqlUploadStatus>;
};

/** Status of a file upload. */
export type GqlUploadStatus =
  /** New */
  | 'DOWNLOADED'
  /** File upload or processing has failed. */
  | 'FAILED'
  /** Backend finished processing the file. */
  | 'FINISHED'
  /** Signed url created, but upload not started. */
  | 'NOT_STARTED'
  /** Frontend started uploading to S3. */
  | 'STARTED'
  /** Frontend finished uploading to S3. */
  | 'UPLOADED';

export type GqlUser = {
  cnpj?: Maybe<Scalars['String']>;
  /** Same as client_id. */
  context_id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  full_name: Scalars['String'];
  id: Scalars['ID'];
  id_unidade?: Maybe<Scalars['ID']>;
  telephone?: Maybe<Scalars['String']>;
  /** Defines which permissions this user has. */
  type: GqlUserType;
  username?: Maybe<Scalars['String']>;
};

export type GqlUserType =
  | 'ADMIN'
  | 'BASIC'
  | 'ROOT'
  | 'SUPER_ADMIN';

/** Properties of an existing vehicle verification job. */
export type GqlVerificacao = {
  /** Get pdfs for payment. */
  baixar_boletos: Scalars['Boolean'];
  /** When the verification was cancelled */
  canceled_at?: Maybe<Scalars['String']>;
  /** CNPJ unidade of the user who triggered this verificacao */
  cnpj_usuario_emissor?: Maybe<Scalars['String']>;
  /** Email of the user who triggered this verificacao */
  email_usuario_emissor?: Maybe<Scalars['String']>;
  /** Why the verification failed */
  failure_reason?: Maybe<Scalars['String']>;
  /** File that was saved into s3 consolidating all screeshots and proofs of access. */
  files_bundle?: Maybe<GqlS3File>;
  /** When the verification was finished */
  finished_at?: Maybe<Scalars['String']>;
  /** Name of the user who triggered this verificacao */
  full_name_usuario_emissor?: Maybe<Scalars['String']>;
  /** Id of the verificatio job */
  id: Scalars['ID'];
  /** Id of the `cliente` requesting the verification. */
  id_cliente: Scalars['ID'];
  /** Id of the user who triggered this verificacao */
  id_usuario_emissor?: Maybe<Scalars['ID']>;
  /** Credentials used on this verificacao. */
  lista_fornecedor_credenciais: Array<GqlFornecedorCredencial>;
  /** License plate of the car being verifyied */
  placa: Scalars['String'];
  /** Renevam of the car being verifyied */
  renavam: Scalars['String'];
  requested_at: Scalars['String'];
  /** Current status of the verification. */
  status: GqlStatusVerificacao;
  /** Status of the debito restrições results. */
  status_debito_restricoes?: Maybe<GqlStatusRestricoes>;
  /** Status of the debitos results. */
  status_debitos?: Maybe<GqlStatusRestricoes>;
  /** Status of the multas results */
  status_multas?: Maybe<GqlStatusRestricoes>;
};

export type GqlDownloadRequestChangedVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type GqlDownloadRequestChanged = { download_request_changed: Pick<GqlDownloadRequest, 'id' | 'filename' | 'status' | 'signed_url' | 'inserted_at'> };

export type GqlListActiveDownloadRequestsVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type GqlListActiveDownloadRequests = { list_active_download_requests: Array<Pick<GqlDownloadRequest, 'id' | 'filename' | 'status' | 'signed_url' | 'inserted_at'>> };

export type GqlCreateUploadUrlsVariables = Exact<{
  id_cliente: Scalars['String'];
  file_list: Array<GqlLocalFileProps> | GqlLocalFileProps;
}>;


export type GqlCreateUploadUrls = { create_upload_urls: Array<Pick<GqlUploadProps, 'file_id' | 'signed_url'>> };

export type GqlEndUploadVariables = Exact<{
  file_id: Scalars['String'];
}>;


export type GqlEndUpload = Pick<GqlRootMutationType, 'end_upload'>;

export type GqlCountSheetRowsVariables = Exact<{
  id_cliente: Scalars['String'];
  sheet_name: GqlSheetName;
  query_args?: Maybe<GqlQueryArgs>;
}>;


export type GqlCountSheetRows = Pick<GqlRootQueryType, 'count_sheet_rows'>;


export const DownloadRequestChangedDocument = gql`
    subscription download_request_changed($user_id: String!) {
  download_request_changed(user_id: $user_id) {
    id
    filename
    status
    signed_url
    inserted_at
  }
}
    `;

/**
 * __useDownloadRequestChanged__
 *
 * To run a query within a React component, call `useDownloadRequestChanged` and pass it any options that fit your needs.
 * When your component renders, `useDownloadRequestChanged` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadRequestChanged({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useDownloadRequestChanged(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GqlDownloadRequestChanged, GqlDownloadRequestChangedVariables>) {
        return ApolloReactHooks.useSubscription<GqlDownloadRequestChanged, GqlDownloadRequestChangedVariables>(DownloadRequestChangedDocument, baseOptions);
      }
export type DownloadRequestChangedHookResult = ReturnType<typeof useDownloadRequestChanged>;
export type DownloadRequestChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<GqlDownloadRequestChanged>;
export const ListActiveDownloadRequestsDocument = gql`
    query list_active_download_requests($user_id: String!) {
  list_active_download_requests(user_id: $user_id) {
    id
    filename
    status
    signed_url
    inserted_at
  }
}
    `;

/**
 * __useListActiveDownloadRequests__
 *
 * To run a query within a React component, call `useListActiveDownloadRequests` and pass it any options that fit your needs.
 * When your component renders, `useListActiveDownloadRequests` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActiveDownloadRequests({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useListActiveDownloadRequests(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlListActiveDownloadRequests, GqlListActiveDownloadRequestsVariables>) {
        return ApolloReactHooks.useQuery<GqlListActiveDownloadRequests, GqlListActiveDownloadRequestsVariables>(ListActiveDownloadRequestsDocument, baseOptions);
      }
export function useListActiveDownloadRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlListActiveDownloadRequests, GqlListActiveDownloadRequestsVariables>) {
          return ApolloReactHooks.useLazyQuery<GqlListActiveDownloadRequests, GqlListActiveDownloadRequestsVariables>(ListActiveDownloadRequestsDocument, baseOptions);
        }
export type ListActiveDownloadRequestsHookResult = ReturnType<typeof useListActiveDownloadRequests>;
export type ListActiveDownloadRequestsLazyQueryHookResult = ReturnType<typeof useListActiveDownloadRequestsLazyQuery>;
export type ListActiveDownloadRequestsQueryResult = ApolloReactCommon.QueryResult<GqlListActiveDownloadRequests, GqlListActiveDownloadRequestsVariables>;
export const CreateUploadUrlsDocument = gql`
    mutation create_upload_urls($id_cliente: String!, $file_list: [LocalFileProps!]!) {
  create_upload_urls(id_cliente: $id_cliente, file_list: $file_list) {
    file_id
    signed_url
  }
}
    `;
export type GqlCreateUploadUrlsMutationFn = ApolloReactCommon.MutationFunction<GqlCreateUploadUrls, GqlCreateUploadUrlsVariables>;

/**
 * __useCreateUploadUrls__
 *
 * To run a mutation, you first call `useCreateUploadUrls` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadUrls` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadUrls, { data, loading, error }] = useCreateUploadUrls({
 *   variables: {
 *      id_cliente: // value for 'id_cliente'
 *      file_list: // value for 'file_list'
 *   },
 * });
 */
export function useCreateUploadUrls(baseOptions?: ApolloReactHooks.MutationHookOptions<GqlCreateUploadUrls, GqlCreateUploadUrlsVariables>) {
        return ApolloReactHooks.useMutation<GqlCreateUploadUrls, GqlCreateUploadUrlsVariables>(CreateUploadUrlsDocument, baseOptions);
      }
export type CreateUploadUrlsHookResult = ReturnType<typeof useCreateUploadUrls>;
export type CreateUploadUrlsMutationResult = ApolloReactCommon.MutationResult<GqlCreateUploadUrls>;
export type CreateUploadUrlsMutationOptions = ApolloReactCommon.BaseMutationOptions<GqlCreateUploadUrls, GqlCreateUploadUrlsVariables>;
export const EndUploadDocument = gql`
    mutation end_upload($file_id: String!) {
  end_upload(file_id: $file_id)
}
    `;
export type GqlEndUploadMutationFn = ApolloReactCommon.MutationFunction<GqlEndUpload, GqlEndUploadVariables>;

/**
 * __useEndUpload__
 *
 * To run a mutation, you first call `useEndUpload` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndUpload` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endUpload, { data, loading, error }] = useEndUpload({
 *   variables: {
 *      file_id: // value for 'file_id'
 *   },
 * });
 */
export function useEndUpload(baseOptions?: ApolloReactHooks.MutationHookOptions<GqlEndUpload, GqlEndUploadVariables>) {
        return ApolloReactHooks.useMutation<GqlEndUpload, GqlEndUploadVariables>(EndUploadDocument, baseOptions);
      }
export type EndUploadHookResult = ReturnType<typeof useEndUpload>;
export type EndUploadMutationResult = ApolloReactCommon.MutationResult<GqlEndUpload>;
export type EndUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<GqlEndUpload, GqlEndUploadVariables>;
export const CountSheetRowsDocument = gql`
    query count_sheet_rows($id_cliente: String!, $sheet_name: SheetName!, $query_args: QueryArgs) {
  count_sheet_rows(id_cliente: $id_cliente, sheet_name: $sheet_name, query_args: $query_args)
}
    `;

/**
 * __useCountSheetRows__
 *
 * To run a query within a React component, call `useCountSheetRows` and pass it any options that fit your needs.
 * When your component renders, `useCountSheetRows` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountSheetRows({
 *   variables: {
 *      id_cliente: // value for 'id_cliente'
 *      sheet_name: // value for 'sheet_name'
 *      query_args: // value for 'query_args'
 *   },
 * });
 */
export function useCountSheetRows(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlCountSheetRows, GqlCountSheetRowsVariables>) {
        return ApolloReactHooks.useQuery<GqlCountSheetRows, GqlCountSheetRowsVariables>(CountSheetRowsDocument, baseOptions);
      }
export function useCountSheetRowsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlCountSheetRows, GqlCountSheetRowsVariables>) {
          return ApolloReactHooks.useLazyQuery<GqlCountSheetRows, GqlCountSheetRowsVariables>(CountSheetRowsDocument, baseOptions);
        }
export type CountSheetRowsHookResult = ReturnType<typeof useCountSheetRows>;
export type CountSheetRowsLazyQueryHookResult = ReturnType<typeof useCountSheetRowsLazyQuery>;
export type CountSheetRowsQueryResult = ApolloReactCommon.QueryResult<GqlCountSheetRows, GqlCountSheetRowsVariables>;