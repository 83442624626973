import * as Types from '../../../../../../codegen/gql-types';

import { produce_mutation } from 'app'
import gql from 'graphql-tag';

/**
 * 
 * 
 * 
 * 
 * 
 *         DO NOT EDIT THIS FILE - ANY CHANGES WILL BE LOST
 * 
 * 
 * 
 * 
 * 
 *    This file is automatically generated on every build by codegen plugins
 *    as defined in codegen.yml
 * 
 *    Its contents are defined by its sibling file _operations.graphql.
 * 
 * 
 * 
 */


 const cancelar_verificacao_doc = gql`
    mutation cancelar_verificacao($id_cliente: String!, $id_verificacao: String!) {
  cancelar_verificacao(id_verificacao: $id_verificacao, id_cliente: $id_cliente)
}
    `;

/** Produce all of operations functions for search-status. */
function search_status_ops_factory() {
  const cancelar_verificacao = produce_mutation<GqlCancelarVerificacaoData,GqlCancelarVerificacaoVariables>(cancelar_verificacao_doc)
  
  return {
    cancelar_verificacao,
  }
}
/** Auto-generated operations functions for search-status. */
export type SearchStatusOps = ReturnType<typeof search_status_ops_factory>

let memoized_operations: SearchStatusOps
/** Produces and memoizes all search-status operations. */
export function produce_search_status_ops() {
  if (!memoized_operations) {
    memoized_operations = search_status_ops_factory()
  }
  return memoized_operations
}

/** Types returned as the final result from operations execution.  */

export type GqlCancelarVerificacaoData = GqlCancelarVerificacao['cancelar_verificacao']

export type GqlCancelarVerificacaoVariables = Types.Exact<{
  id_cliente: Types.Scalars['String'];
  id_verificacao: Types.Scalars['String'];
}>;


export type GqlCancelarVerificacao = Pick<Types.GqlRootMutationType, 'cancelar_verificacao'>;
