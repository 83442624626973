import React, { useState } from 'react'
import { Text, Card, Button, Svg, Checkbox } from 'components'
import { useSharedStoredState } from 'controllers/tied'

import { CredentialsState, SearchStarterProps } from './_data'
import styles from './search-starter.module.scss'
import { produce_search_starter_ops } from './_operations'
import { affix, Maybe, useStoredState } from 'helpers'
import { selected_scraper_names_config, services_list_config } from '../../services-list/_shared-state'
import { trace } from 'controllers/tracer'
import { GqlListFornecedoresSituacaoVeicularData } from '../../services-list/_operations'

type ErrorMessages = string[] | undefined

/** User can trigger a new verificacao, which has its id returned in on_change. */
export function SearchStarter(props: SearchStarterProps) {
  const {
    error_messages,
    clear_errors,
    set_error_messages
  } = useErrors()

  const selected_services = useSelectedServices()

  const {
    credentials,
    set_credential,
    required_credentials,
    tipos
  } = useCredentials(clear_errors, selected_services)

  const {
    config,
    handle_solicitar_verificacao,
    handle_toggle_baixar_boletos
  } = useVerificacao(props, selected_services, credentials, clear_errors, set_error_messages)

  if (process.env.NODE_ENV === 'development') trace('search-starter', 'render', { props, required_credentials, selected_services, config })

  return (
    <Card
      title='Nova consulta'
      wrapper_class={props.className}>
      <div className={styles.search_wrapper}>
        <div className={styles.search_text_wrapper}>
          {tipos.map(tipo => {
            const text_class = affix('search_text', tipo === 'chassi', 'LARGE')
            return (
              <Text
                key={tipo}
                type={tipo === 'senha' ? 'password' : 'input'}
                color='BORDERED'
                label={required_credentials[tipo]}
                id={tipo}
                initial_text={credentials && credentials[tipo]}
                className={styles[text_class]}
                on_change={set_credential}
              />)
          })}
        </div>

        <div className={styles.button_verify_wrapper}>
          <Button
            disabled={!selected_services?.length}
            disabled_tooltip='É preciso selecionar ao menos um órgão para consulta.'
            tooltip_alignment='RIGHT'
            className={styles.button_verify}
            onClick={handle_solicitar_verificacao}>
            <Svg icon='search' />
            Consultar
          </Button>
        </div>
      </div>

      <Checkbox
        className={styles.checkbox}
        initial_value={config.baixar_boletos}
        on_change={handle_toggle_baixar_boletos}>
        Baixar boletos
      </Checkbox>

      <SelectedServices selected_services={selected_services} />

      {
        !error_messages?.length ? null : (
          <div className={styles.errors_wrapper}>
            {error_messages.map((error_message) => (
              <p key={error_message}>{error_message}</p>
            ))}
          </div>
        )
      }
    </Card>
  )
}

type SelectedServicesProps = {
  selected_services: Maybe<GqlListFornecedoresSituacaoVeicularData>
}
function SelectedServices({ selected_services }: SelectedServicesProps) {
  if (!selected_services?.length) return (
    <div className={styles.no_selected_services}>
      Nenhum órgão selecionado para consulta.
    </div>
  )

  const services_names = selected_services.map(s => s.nome.replace(' (alpha)', ''))

  return (
    <div className={styles.selected_services_list}>
      {services_names.map(n => <span key={n}>{n}</span>)}
    </div>
  )
}

function useSelectedServices() {
  const [services_list] = useSharedStoredState(
    services_list_config, 'search-starter'
  )
  const [selected_scraper_names] = useSharedStoredState(
    selected_scraper_names_config, 'search-starter'
  )

  if (!(services_list.length && selected_scraper_names.length)) return undefined
  const selected_services_list = services_list.filter(
    s => selected_scraper_names.includes(s.scraper_name)
  )
  return selected_services_list
}

function useCredentials(
  clear_errors: () => void,
  selected_services: Maybe<GqlListFornecedoresSituacaoVeicularData>
) {
  const [credentials, set_credentials] = useState<CredentialsState>()

  const set_credential = (value: string, tipo: string) => {
    const new_credentials = {
      ...credentials,
      [tipo]: value
    }
    clear_errors()
    set_credentials(new_credentials)
  }

  type RequiredCredentials = { [tipo: string]: string }
  const minimum_credentials = {
    renavam: 'Renavam',
    placa: 'Placa'
  } as RequiredCredentials

  const required_credentials = (selected_services || []).reduce(
    (prev_result, { credenciais }) => ({
      ...prev_result,
      ...credenciais.reduce((prev_partial, { nome, tipo }) => {
        if (tipo in prev_result) return prev_partial
        return {
          ...prev_partial,
          [tipo]: nome
        }
      }, {} as RequiredCredentials
      )
    }),
    minimum_credentials
  )
  const tipos = Object.keys(required_credentials) as (keyof RequiredCredentials)[]

  return { credentials, set_credential, required_credentials, tipos }
}

function useErrors() {
  const [error_messages, set_error_messages] = useState<ErrorMessages>()
  const clear_errors = () => {
    if (error_messages?.length) set_error_messages(undefined)
  }
  return { error_messages, clear_errors, set_error_messages }
}

function useVerificacao(
  props: SearchStarterProps,
  selected_services: Maybe<GqlListFornecedoresSituacaoVeicularData>,
  credentials: Maybe<CredentialsState>,
  clear_errors: () => void,
  set_error_messages: React.Dispatch<React.SetStateAction<ErrorMessages>>
) {
  const [config, set_config] = useStoredState('search-starter/config', 0, {
    baixar_boletos: false
  })

  const handle_toggle_baixar_boletos = (is_checked: boolean) => {
    const new_config = { ...config, baixar_boletos: is_checked }
    set_config(new_config)
  }

  const handle_solicitar_verificacao = () => {
    if (!selected_services?.length) {
      set_error_messages([
        'Busca não realizada',
        'Selecione um ou mais um serviços que deseja consultar.'
      ])
      return
    }

    if (!credentials) {
      set_error_messages([
        'Busca não realizada',
        'Preencha os dados do veículo antes de consultar.'
      ])
      return
    }

    const { solicitar_verificacao } = produce_search_starter_ops()
    const { id_cliente } = props
    let missing_credentials: string[] = []

    const lista_fornecedor_credenciais = selected_services.map((
      { scraper_name, credenciais }
    ) => ({
      scraper_name,
      credenciais: credenciais.map(({ tipo_interno, tipo, nome }) => {
        const valor = credentials[tipo]
        if (!valor.trim())
          missing_credentials.push(nome)
        return { tipo_interno, valor }
      })
    }))

    if (missing_credentials.length) {
      set_error_messages([
        'Busca não realizada',
        `Falta informar ${missing_credentials.join(', ')}.`
      ])
      return
    }

    const { renavam = '', placa = '' } = credentials
    const variables = {
      renavam,
      placa,
      lista_fornecedor_credenciais,
      id_cliente,
      ...config
    }

    solicitar_verificacao({
      variables,
      on_completed: (data) => {
        props.on_new_verificacao(data, variables)
      },
      on_errors: ({ networkError, graphQLErrors }) => {
        if (networkError) {
          set_error_messages([
            'Erro na comuninicação com o servidor. Tente novamente.'
          ])
          return
        }
        const new_errors_messages = graphQLErrors.map(({ message }) => {
          let error_message = ''
          if (message.includes('renavam'))
            error_message = 'Renavam inválido. '
          if (message.includes('placa'))
            error_message = `${error_message}Placa inválida.`
          if (error_message.length) return error_message
          return `Erro na requisição: ${message}`
        })
        set_error_messages(new_errors_messages)
      }
    })
    clear_errors()
  }
  return { config, handle_solicitar_verificacao, handle_toggle_baixar_boletos }
}
