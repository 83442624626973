import { Defined, AnyObject } from './types'
import { StateSetters } from 'controllers/tied'
import { ApolloError } from 'apollo-client'

type ApolloResult<T extends string> = {
  [K in T]: readonly [any, { data?: { [U in K]: any }, [i: string]: any }]
}
/** Retrieve deep nested data from Apollo know named result. */
export const get_apollo_data = <
  K extends string, O extends ApolloResult<K>, T extends (keyof O) & K>(
    hook_result: O, key: T
  ) => {
  const result = hook_result[key][1] as O[T][1]
  if (!result.data) { return undefined }

  return result.data[key] as Defined<O[T][1]['data']>[T]
}

/** Retrieve deep nested data from apollo dynamically named result. */
export const get_dynamic_apollo_data = <O extends ApolloResult<any>>(
  hook_result: O, key: keyof O, suffix: string
) => {
  const result = hook_result[key][1]
  if (!result.data) { return undefined }

  /** Keys in dynamic queries must come from a Gql* type enum. They will be 
   * uppercase, but the resulting data object will be lowercase. */
  const lower_suffix = suffix.toLowerCase()
  const ops_key = `${key}_${lower_suffix}`
  return result.data[ops_key]
}

/** Create a variables apollo object, injecting id cliente into it. */
export const produce_cliente_variables = <T extends AnyObject | undefined>(
  id_cliente: string | undefined,
  variables: T,
  new_variables?: Partial<T> | undefined
): { variables: T & { id_cliente: string } } => {
  if (id_cliente === undefined) {
    throw new Error(`Id cliente should have been initialized before apollo call. Variables: ${variables}`)
  }
  return {
    variables: {
      ...variables,
      ...new_variables,
      id_cliente
    }
  }
}

/** Call set functions for all values in phase state. */
export const set_phase_state = <S>(
  i: { set: StateSetters<S> },
  phase_state: Partial<S> | undefined | null
) => {
  if (phase_state) {
    const { set } = i
    for (const key in phase_state) {
      set[key](phase_state[key])
    }
  }
}

export const apollo_errors_to_string = (e: ApolloError) => {
  if (e.networkError)
    return 'Erro na comuninicação com o servidor. Tente novamente em alguns instantes.'
  const errors_messages = e.graphQLErrors.map(({ message }) => message)
  return errors_messages.join('. ')
}