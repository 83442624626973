import { ApiHandler, ApiInterface } from 'controllers/tied'
import { TextApiCore, TextProps, PolymorphicTextEvents } from './_data'

type TextApiHandler = ApiHandler<TextApiCore<unknown>>

/** 
 * Whenever text is overriden by another event that occurs before onChange,
 * text_just_overriden must be set to true. Otherwise handle change will change
 * the overriden text.
 */
let text_just_overriden = false

export const handle_change: TextApiHandler = (
  i, e: React.ChangeEvent<HTMLInputElement>
) => {
  const { value } = e.target

  // prevent new line character leftover from Enter keydown
  if (text_just_overriden) {
    text_just_overriden = false
    if (value === '\r' || value === '\r\n' || value === '\n') return
  }
  i.set.text(value)
  call_event(i.props, 'on_change', value)

  // valid = undefined implies the user just started typing. Do not show
  // validation errors before the typing is finished, to not confuse them.
  if (i.state.is_valid !== undefined) {
    manage_validity(i, value)
  }
}

export const handle_key_down: TextApiHandler = (
  i, e: React.KeyboardEvent<HTMLInputElement>
) => {
  if (i.props.onKeyDown) {
    i.props.onKeyDown(e)
  }
  if (e.key === 'Enter') {
    call_event(i.props, 'on_return', i.state.text)
    const text_override = handle_finish(i)
    if (text_override !== undefined) {
      i.set.text(text_override)
      text_just_overriden = true
    }
  }
}

export const handle_blur: TextApiHandler = (i) => {
  call_event(i.props, 'on_blur', i.state.text)
  const text_override = handle_finish(i)
  if (text_override !== undefined) {
    // do not set text_just_overriden as on_change will not happen after blur
    i.set.text(text_override)
  }
}

export const onApiMount: TextApiHandler = (i) => {
  const { props } = i
  if (props.focus_on_mount) {
    i.ref.current!.focus()
  }
  if (props.check_validity_on_mout) {
    if (!props.on_validity_check) {
      return ['error', 'Cannot chack valitidy without on_validity_check.', i]
    }
    manage_validity(i, props.initial_text ?? '')
  }
}

export const handle_focus_self: TextApiHandler = (i) => {
  i.ref.current!.focus()
}

/**
 * ----------------------------------------------------------------------------
 *                                                        Api support functions
 * 
 * 
 */

/** Handles validity, if validity checker function was provided. */
function manage_validity(
  { set, props }: ApiInterface<TextApiCore<unknown>>,
  value: string
) {
  const validity_result = call_event(props, 'on_validity_check', value)
  // if event handler does not exist => value is always valid
  if (validity_result === undefined) return true

  if (validity_result === true) {
    set.is_valid(true)
    return true
  }
  set.is_valid(false)
  set.error_message(validity_result)
  return false
}

/** User finished typing. */
function handle_finish(i: ApiInterface<TextApiCore<unknown>>) {
  const { text } = i.state
  const is_valid = manage_validity(i, text)
  // invalid results do not raise on_finish
  if (!is_valid) return
  return call_event(i.props, 'on_finish', text)
}

/** Call the chosen event, passing the instance id if it exists. */
function call_event<T, K extends keyof PolymorphicTextEvents<T>>(
  props: TextProps<T>,
  event_name: K,
  value: string
): undefined | EventReturn<T, K> {
  const event = props[event_name]
  if (!event) { return }
  if (props.id !== undefined) {
    return event(value, props.id) as EventReturn<T, K>
  }
  //@ts-ignore
  return event(value)
}

type EventReturn<T, K extends keyof PolymorphicTextEvents<T>> = (
  K extends 'on_validity_check'
  ? string | true
  : (string | void)
)