import { LoginState } from 'app/login/_data'
import { GqlFilter, GqlUser } from 'codegen/gql-types'
import { Maybe } from 'helpers'

export type ArchiveSearchApiCore = {
  props: ArchiveSearchProps
  initialState: ArchiveSearchState
}

export type ArchiveSearchProps = {
  on_search: (term?: string | undefined) => void
  on_filter_change: (filter: GqlFilter) => void
  on_remove_filter: (field_id: GqlFilter['field_id']) => void
  current_search_term: string | undefined
  user: Exclude<LoginState['user'], undefined | null>
}

export const initialState = {
  start_date_iso: '',
  end_date_iso: '',
  search_term: '',
  /** Change this number to rerender the textbox and remove the privious search term. */
  search_term_reset_key: 0,
  selected_date_range: undefined as SelectedDaterange,
  selected_user: undefined as SelectedUser
}
export type ArchiveSearchState = typeof initialState

export type SelectedUser = Maybe<Pick<GqlUser, 'id' | 'full_name'>>

export type SelectedDaterange = undefined | {
  range: DateTimeRange
  label: string
}

export type DateTimeRange =
  'SPECIFIC_DAY'
  | 'TODAY'
  | 'YESTERDAY'
  | 'THIS_WEEK'
  | 'LAST_WEEK'
  | 'SPECIFIC_WEEK'
  | 'THIS_MONTH'
  | 'LAST_MONTH'
  | 'SPECIFIC_MONTH'