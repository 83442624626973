import React, { useEffect, useState } from 'react'
import { useSelection } from 'controllers/tied'


import styles from './tight-progress-bar.module.scss'
import { broadcast_progress } from './broadcast_progress'
import { trace } from 'controllers/tracer'

type ProgressProps = {
  className?: string
  /** Triggered when progress reaches 100%. */
  on_complete?: () => void
}

/** Show a simple progress bar. */
export function TightProgressBar(props: ProgressProps) {
  const progress = useSelection(broadcast_progress) || 0
  const [is_complete, set_is_complete] = useState<boolean>()
  useEffect(() => {
    if (props.on_complete && progress === 1 && !is_complete) {
      set_is_complete(true)
      props.on_complete()
    }
  }, [is_complete, progress])
  const percent_progress = `${Math.round(progress * 100)}%`

  if (process.env.NODE_ENV === 'development') trace('tight-progress-bar', 'render', { percent_progress, is_complete })

  return (
    <div className={props.className}>
      <p className={styles.label}>{`${percent_progress} concluído`}</p>
      <div className={styles.bar}>
        <div style={{ width: percent_progress }}>&nbsp;</div>
      </div>
    </div>
  )
}