import * as Types from '../../../../../codegen/gql-types';

import { produce_query } from 'app'
import gql from 'graphql-tag';

/**
 * 
 * 
 * 
 * 
 * 
 *         DO NOT EDIT THIS FILE - ANY CHANGES WILL BE LOST
 * 
 * 
 * 
 * 
 * 
 *    This file is automatically generated on every build by codegen plugins
 *    as defined in codegen.yml
 * 
 *    Its contents are defined by its sibling file _operations.graphql.
 * 
 * 
 * 
 */


 const list_fornecedores_situacao_veicular_doc = gql`
    query list_fornecedores_situacao_veicular {
  list_fornecedores_situacao_veicular {
    scraper_name
    uf
    credenciais {
      nome
      tipo_interno
      tipo
    }
    nome
    sub_tipo
  }
}
    `;

/** Produce all of operations functions for services-list. */
function services_list_ops_factory() {
  const list_fornecedores_situacao_veicular = produce_query<GqlListFornecedoresSituacaoVeicularData,GqlListFornecedoresSituacaoVeicularVariables>(list_fornecedores_situacao_veicular_doc)
  
  return {
    list_fornecedores_situacao_veicular,
  }
}
/** Auto-generated operations functions for services-list. */
export type ServicesListOps = ReturnType<typeof services_list_ops_factory>

let memoized_operations: ServicesListOps
/** Produces and memoizes all services-list operations. */
export function produce_services_list_ops() {
  if (!memoized_operations) {
    memoized_operations = services_list_ops_factory()
  }
  return memoized_operations
}

/** Types returned as the final result from operations execution.  */

export type GqlListFornecedoresSituacaoVeicularData = GqlListFornecedoresSituacaoVeicular['list_fornecedores_situacao_veicular']

export type GqlListFornecedoresSituacaoVeicularVariables = Types.Exact<{ [key: string]: never; }>;


export type GqlListFornecedoresSituacaoVeicular = { list_fornecedores_situacao_veicular: Array<(
    Pick<Types.GqlFornecedorSituacaoVeicular, 'scraper_name' | 'uf' | 'nome' | 'sub_tipo'>
    & { credenciais: Array<Pick<Types.GqlCredencial, 'nome' | 'tipo_interno' | 'tipo'>> }
  )> };
