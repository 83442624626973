import { useSubscriptionEffect } from 'helpers'
import { navigate } from 'hookrouter'
import { GqlSheetName } from 'codegen/gql-types'
import { FeatureSectionProps, FeatureProps } from './_features-data'

export type SectionsObject = {
  [key: string]: {
    label: string
    counted_sheet_name?: GqlSheetName
    Section: (props: FeatureSectionProps) => JSX.Element
  }
}

/** 
 * Produce the proper section component, according to the route setup.  
 * Reroute to first feature if section is invalid.
 */
export function useSection(
  sections_object: SectionsObject,
  props: FeatureProps) {
  const { route } = props
  const { section } = route
  const is_valid_section = section && (section in sections_object)

  useSubscriptionEffect(() => {
    if (!is_valid_section) {
      const section_keys = Object.keys(sections_object)
      navigate(`/${route.feature_group}/${route.feature}/${section_keys[0]}`)
    }
  }, [is_valid_section])

  if (!is_valid_section) return null
  return sections_object[section!].Section
}
