import React from 'react'
import styles from './labeled-value.module.scss'

type LabeledValueProps = {
  /** If className is defined a wrapper div will be rendered. */
  className?: string
  label: string
  value: string
  value_color?: 'danger' | 'success'
}
/** Renders a   */
export function LabeledValue(props: LabeledValueProps) {
  const { value_color } = props
  const value_class = value_color && styles[value_color]
  return (
    <div className={props.className}>
      <p className={styles.label}>{props.label}</p>
      <span className={value_class}>{props.value}</span>
    </div>
  )
}
