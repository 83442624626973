import React, { useState } from 'react'
import { compose } from 'helpers'

import styles from './card-section.module.scss'
import { Chevron } from './chevron'

type CardSectionProps = React.PropsWithChildren<{
  title: string
  className?: string
  start_open?: boolean
}>

export function CardSection({ start_open = false, ...props }: CardSectionProps) {
  const [is_open, set_is_open] = useState(start_open)
  const wrapper_classes = compose(props.className, styles.card_section)

  const handle_toggle_open = () => {
    set_is_open(!is_open)
  }
  return (
    <div className={wrapper_classes}>
      <div className={styles.header} onClick={handle_toggle_open}>
        <Chevron down={is_open} />
        <h4 className={styles.title}>{props.title}</h4>
      </div>
      {is_open ? props.children : null}
    </div>
  )
}