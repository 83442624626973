import React from 'react'
import { A } from 'hookrouter'
import { RouteParams } from 'app/_data'
import { useSelection } from 'controllers/tied'
import { broadcast_user } from 'app/login/_broadcasts'
import { SectionExport } from 'feature-groups/_features-data'
import { affix, AllOrNothing, format_integer, take, Defined } from 'helpers'
import { useCountSheetRows, GqlCountSheetRowsVariables } from 'codegen/gql-ops'

import styles from './feature-header.module.scss'
import { IssueIcon } from 'components/basic'

type HeaderProps = {
  title: string
} &
  AllOrNothing<{
    route: RouteParams
    sections: {
      [section_key: string]: SectionExport
    }
  }>

/**
 *  Basic: feature header. Injects children bellow the title text.
 */
export function FeatureHeader(props: HeaderProps) {
  const user = useSelection(broadcast_user, 'FeatureHeader')
  const id_cliente = user?.context_id
  if (id_cliente === undefined) { return null }
  const title_css = affix('title', props.route?.section, 'CLOSE')
  return (
    <header className={styles.header}>
      <h1 className={styles[title_css]}>{props.title}</h1>
      <Navigation {...props} id_cliente={id_cliente} />
    </header>
  )
}

function Navigation(props: HeaderProps & { id_cliente: string }) {
  const { route, sections, id_cliente } = props
  if (!(route && sections)) return null

  const root_path = route && `${route.feature_group}/${route.feature}`
  const sections_keys = Reflect.ownKeys(sections) as (keyof typeof sections)[]

  return <>{
    sections_keys.map((section_key) => {
      const { label, count_query, count_issue } = sections[section_key]
      const path = `/${root_path}/${section_key}`
      const a_css = affix('navigation', route!.section === section_key, 'ACTIVE')
      return (
        <A
          className={styles[a_css]}
          key={path}
          href={path}>
          {count_query === undefined
            ? label
            : (
              <CountLabel
                label={label}
                id_cliente={id_cliente}
                count_issue={count_issue}
                count_query={count_query}
              />
            )
          }
        </A>
      )
    })
  }</>
}

type CountLabelProps = {
  label: string
  id_cliente: string
  count_query: Defined<SectionExport['count_query']>
  count_issue: SectionExport['count_issue']
}
/** Label with count number on top. */
function CountLabel(props: CountLabelProps) {
  const { count_query } = props
  const variables: GqlCountSheetRowsVariables = {
    id_cliente: props.id_cliente,
    sheet_name: count_query.sheet_name,
    query_args: take(count_query, 'filter_list')
  }
  const query = useCountSheetRows({ variables })
  const count = query.data?.count_sheet_rows

  if (count === undefined) return <>{props.label}</>
  const { count_issue } = props
  const issue = count_issue && count_issue(count)

  return <>
    <IssueIcon issue={issue} />
    {props.label}&nbsp;
    <sup>{format_integer(count)}</sup>
  </>
}
