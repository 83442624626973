import { capitalize, format_currency, format_datetime, format_day, Maybe } from 'helpers'

/**
 *  Format functions typically used in simple table format_value prop 
 * of table_columns.
 */
export const format = {
  /** From float/int to R$ #,00 */
  currency: (value: Maybe<number>) =>
    value ? format_currency(value) : null,

  /** From UTC Datetime to dd/mm/aaaa */
  day: (value: Maybe<string>) =>
    value ? format_day(value) : null,

  /** From UTC Datetime to dd/mm/aaaa */
  datetime: (value: Maybe<string>) =>
    value ? format_datetime(value) : null,

  /** From 'aaa_BBB_cCc' to 'Aaa Bbb Ccc' */
  snake_to_capitalized: (value: Maybe<string>) => {
    if (value == null) return null
    return value
      .toLowerCase()
      .split('_')
      .map(capitalize)
      .join(' ')
  },
}
