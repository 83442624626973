/* eslint-disable react-hooks/exhaustive-deps */
import { trace } from 'controllers/tracer'
import { useEffect, useRef, useState } from 'react'

import { Selector } from './selectors-types'
import { produceKey, useFirstRender } from '../utils'
import { SubscriptionRef, ReceiverSubscriptionRefs } from './selectors-internal-types'

type AnySelector = Selector<any, any>
const dataMap: Map<AnySelector, unknown> = new Map()
const subscriptionsMap: Map<AnySelector, ReceiverSubscriptionRefs> = new Map()
/** Number of selectors feeding the same subscription. */
const selectorsCount: Map<AnySelector, number> = new Map()

/**
 * evaluates the current result from selector with the provided selectorParams
 */
export function select<R>(
  selector: Selector<any, R>,
  componentId?: string | undefined
): R | undefined {
  return evaluateSelector(selector, componentId)
}

/**
 * Evaluate the result from `selector(data, componentId, prevResult)`
 */
function evaluateSelector<R>(
  selector: Selector<any, R>,
  componentId: string | undefined,
) {
  const data = dataMap.get(selector)
  const newResult = selector(data, componentId)
  return newResult
}

function updateSubscriptions<R>(
  selector: Selector<any, R>,
  componentId: string | undefined,
) {
  const receiverSubscriptionRefs = subscriptionsMap.get(selector)
  // If not subsscribed yet, do nothing.
  if (!receiverSubscriptionRefs) { return }

  const newSelectorResult = evaluateSelector(selector, componentId)
  // Update the selection values in all subscriptons with different results
  receiverSubscriptionRefs.forEach((subscriptionRef: SubscriptionRef<R>) => {
    const { selectorResult } = subscriptionRef.current
    // if results is the same, do nothing
    if (newSelectorResult === selectorResult) { return }
    if (process.env.NODE_ENV === 'development') trace(componentId, 'selectorChanged', { newValue: newSelectorResult, oldValue: selectorResult, subscriptionRef })
    // result changed => trigger selection component rerender
    subscriptionRef.current.selectorResult = newSelectorResult
    subscriptionRef.current.setResult(newSelectorResult)
  })
}

/**
 * Make the component's data available to the selector, recalculating all 
 * subscribed selections and rerendering all components connecting with
 * useSelection for which the selection result has changed.
 * 
 * If the selector new result differs from the last calculation, recalculate 
 * the selection.
 * If the selection new result differs from the last one. Re-render de selecion
 *  component.
 */
export function useSelector<D, R = D>(
  selector: Selector<D, R>,
  data: D,
  componentId?: string
) {
  useSelectorSubscription(selector)
  // react to data changes by updating all susbcriptions
  useEffect(() => {
    // rehydrate selector value
    dataMap.set(selector, data)
    updateSubscriptions(selector, componentId)
  }, [data])
}

/** Broadcast data directly, without a selector subscription. */
export function pushBroadcast<R, D>(
  selector: Selector<D, R>,
  data: D,
  componentId?: string
) {
  // rehydrate selector value
  dataMap.set(selector, data)
  updateSubscriptions(selector, componentId)
}

/** Subscribe selector on component mount and unsibscribe on unmount. */
function useSelectorSubscription<R, D>(
  selector: Selector<D, R>
) {
  useEffect(() => {
    // add selector to count
    const count = selectorsCount.get(selector) || 0
    selectorsCount.set(selector, count + 1)

    const unsubscribe = () => {
      const count = selectorsCount.get(selector) || 0
      if (count > 1) {
        // more selectors are subscribed => just decrease count
        selectorsCount.set(selector, count - 1)
        return
      }
      // this is the last selector of its kind => eliminate all subscriptions
      selectorsCount.delete(selector)
      dataMap.delete(selector)
    }

    return unsubscribe
  }, [])
}

/**
 * Connect the selector function with the api result state setter.
 * Result state setter will be called whenever the selecion result changes.
 */
export function useSelectionApi<R, S extends Selector<any, R>>(
  selector: S,
  resultStateSetter: (result: R | undefined) => unknown,
  componentId?: string,
): void {
  const subscriptionRef = useSubscription(selector, componentId, resultStateSetter)
  const { selectorResult } = subscriptionRef.current
  const newResult = rehydrateSelection(selector, componentId, subscriptionRef)

  if (selectorResult !== newResult) {
    if (process.env.NODE_ENV === 'development') trace(componentId, 'selectionApiChanged', { newValue: newResult, oldValue: selectorResult, subscriptionRef })
    resultStateSetter(newResult)
  }
}


/**
 * Executes the selector function passing  selectorParams, if available, and
 * returns its result.
 */
export function useSelection<R>(
  selector: Selector<any, R>,
  componentId?: string
): R | undefined {
  // resultState is used by useSelector so a change in the component 
  // that owns the data being selected can trigger a re-render on the 
  // component calling useSelection
  const setResult = useState<any>()[1]
  const subscriptionRef = useSubscription(selector, componentId, setResult)
  const newResult = rehydrateSelection(selector, componentId, subscriptionRef)

  if (process.env.NODE_ENV === 'development') trace(componentId, 'selectionRehydrated', { newValue: newResult, subscriptionRef })
  return newResult
}

/** Recalculate the selection result if necessary and return its 
 * current value. */
function rehydrateSelection<R>(
  selector: Selector<any, R>,
  componentId: string | undefined,
  subscriptionRef: SubscriptionRef<R>
): R | undefined {
  const newSelectorResult = evaluateSelector(selector, componentId)
  subscriptionRef.current.selectorResult = newSelectorResult
  return newSelectorResult
}

/** Subscribe selection, so useSelector can push a state change when needed. */
function useSubscription<R>(
  selector: Selector<any, R>,
  componentId: string | undefined,
  setResult: Function
) {
  const subscriptionRef: SubscriptionRef<R> = useRef({
    componentId,
    setResult
  })
  subscriptionRef.current.setResult = setResult

  useFirstRender(() => {
    const subscriptionId = produceKey()
    if (!subscriptionsMap.has(selector)) {
      subscriptionsMap.set(selector, new Map())
    }
    const receiverSubscriptionRefs = subscriptionsMap.get(selector) as ReceiverSubscriptionRefs
    receiverSubscriptionRefs.set(subscriptionId, subscriptionRef)
    return () => {
      receiverSubscriptionRefs.delete(subscriptionId)
      if (receiverSubscriptionRefs.size === 0) {
        subscriptionsMap.delete(selector)
      }
    }
  })

  return subscriptionRef
}
