import { Svg } from 'components'
import React, { useState } from 'react'
import { affix, compose, download_file, UTF8 } from 'helpers'
import { pushBroadcast, useSelection, useSelector } from 'controllers/tied'

import { broadcast_carousel_id, broadcast_carousel_images, broadcast_enlarged_image, CarouselImage } from './broadcast_carousel_images'
import styles from './carousel.module.scss'

export function Carousel(props: { className: string, id_verificacao: string | undefined }) {
  const id = useSelection(broadcast_carousel_id)
  const images_list = useSelection(broadcast_carousel_images)
  const enlarged_image = useSelection(broadcast_enlarged_image)

  if (!props.id_verificacao || !images_list?.length || !id || !enlarged_image)
    return null

  // TODO: refactor - put EnlargedImage directly in app.tsx
  return (
    <EnlargedImage
      images_list={images_list}
      enlarged_image={enlarged_image} />
  )
}

type CarouselThumbnailProps = {
  first_image: CarouselImage
  className: string
  images_count: number
}
export function CarouselThumbnail(props: CarouselThumbnailProps) {
  const { first_image, images_count } = props
  const { url, no_image_message } = first_image
  const wrapper_class = affix('thumbnail', images_count ? 'IMAGE' : 'EMPTY')
  return (
    <div
      onClick={() => {
        pushBroadcast(broadcast_enlarged_image, first_image)
      }}
      className={compose(styles[wrapper_class], props.className)}
      style={{ backgroundImage: url && `url(${url})` }}>
      {!images_count
        ? no_image_message
        : <span>{images_count}</span>
      }
    </div>
  )
}


type EnlargedImageProps = {
  images_list: CarouselImage[]
  enlarged_image: CarouselImage
}
function EnlargedImage(props: EnlargedImageProps) {
  const { enlarged_image } = props
  const [
    current_enlarged_image,
    set_current_enlarged_image
  ] = useState<CarouselImage | undefined>(enlarged_image)

  // only boradcast the change if closing, to avoid re-rendering the 
  // parent component
  const broadcasted_image = current_enlarged_image ? enlarged_image : undefined

  useSelector(broadcast_enlarged_image, broadcasted_image)

  if (!current_enlarged_image) return null

  const close_enlarged = () => {
    // setting it as undefined will trigger a broadcast, reseting the 
    // broadcasted selection and unmounting this compoent
    set_current_enlarged_image(undefined)
  }

  const navigate_enlarged = (direction: 'forward' | 'back') => {
    const { images_list } = props
    const current_img_idx = images_list.findIndex(
      i => i === current_enlarged_image
    )
    const get_new_idx = () => {
      const last_idx = images_list.length - 1
      if (direction === 'back')
        return current_img_idx > 0 ? current_img_idx - 1 : last_idx
      return current_img_idx === last_idx ? 0 : current_img_idx + 1
    }
    const new_idx = get_new_idx()
    const new_enlarged_image = images_list[new_idx]
    set_current_enlarged_image(new_enlarged_image)
  }

  const { url, file_name, label, no_image_message } = current_enlarged_image

  return (
    <div className={styles.enlarged_image_wrapper}>
      <div className={styles.enlarged_image_top_bar}>
        {!url || !file_name ? null : (
          <button onClick={() => { download_file(url, file_name) }}>
            <Svg icon='download' size='regular' />
          </button>
        )}
        <div className={styles.enlarged_image_top_bar_center}>
          <button
            onClick={() => { navigate_enlarged('back') }}
            className={styles.navigate_enlarged}>
            <Svg icon='chevron_left' />
          </button>
          {/* TODO: make width = largest label in list */}
          <span className={styles.image_label}>
            {label}
          </span>
          <button
            onClick={() => { navigate_enlarged('forward') }}
            className={styles.navigate_enlarged}>
            <Svg icon='chevron_right' />
          </button>
        </div>
        <button onClick={close_enlarged} className={styles.close_enlarged}>
          <Svg icon='close' size='regular' />
        </button>
      </div>

      {url
        ? <img src={url} onClick={close_enlarged} />
        : <div className={styles.no_image_message}>{no_image_message}</div>
      }
    </div>
  )
}