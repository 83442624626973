import React from 'react'
import { affix, compose } from 'helpers'

import styles from './info-box.module.scss'

type InfoBoxProps = React.PropsWithChildren<{
  className?: string
  looks: 'SUCCESS' | 'ATTENTION' | 'DANGER' | 'INFO'
}>

export function InfoBox(props: InfoBoxProps) {
  const info_class = affix('info_box', props.looks)
  return (
    <div className={compose(props.className, styles[info_class])}>
      {props.children}
    </div>
  )
}