import { Maybe, Dictionary } from './types'

/** Capitalize the first character in value. */
export function capitalize(value: string | undefined) {
  if (value === undefined) { return '' }
  const first = value[0].toUpperCase()
  const rest = value.slice(1)
  return `${first}${rest}`
}

/** Compare two strings to use in an array sort function. */
export function compare_for_sort(a: Maybe<string>, b: Maybe<string>) {
  const string_a = a == undefined ? '' : a
  const string_b = b == undefined ? '' : b
  return string_a.localeCompare(string_b)
}

/** True if any of the `terms` is contained in value. */
export function like_one_of(value: string, terms: string[]) {
  return terms.findIndex(term => value.includes(term)) > -1
}

/** True if value preffix is equal to term. */
export function match_preffix(value: string, term: string) {
  return value.slice(0, term.length) === term
}

/** 
 * If value === option_1 return option_2.  
 * Otherwise return option_1. (for ANY OTHER value)
 */
export function toggle<T>(current_value: T, option_1: T, option_2: T) {
  return current_value !== option_1 ? option_1 : option_2
}

/** 
 * Replace "#" in base text with the value in `number`.
 * 
 * '(s)' is removed if number = 1, or replaced with "s" if number != 1.
 * 
 * ["a"|"b"] is replaced with "a" if number = 1, or "b" if number != 1.
 */
export function produce_numbered_text(number: number, base_text: string) {
  const text_with_num = base_text.replace('#', '' + number)
  const text_s_replaced = number === 1
    ? replace_all(text_with_num, '(s)', '')
    : replace_all(text_with_num, '(s)', 's')
  const result = replace_numbered_options(text_s_replaced, number)
  return result
}

function replace_numbered_options(text: string, number: number) {
  const sectors = text.split('|')
  if (sectors.length === 1) return text
  const final_sectors = sectors.map(s => {
    if (number === 1) {
      const kept_s = s.replace('[', '')
      const from_idx = kept_s.indexOf(']')
      return kept_s.slice(from_idx + 1)
    }
    const kept_s = s.replace(']', '')
    const to_idx = kept_s.indexOf('[')
    if (to_idx === -1) return kept_s
    return kept_s.slice(0, to_idx)
  })
  return final_sectors.join('')
}

/** Ponyfill for replaceAll string prototype method. */
export const replace_all =
  //@ts-ignore
  ''.replaceAll
    ? (str: string, find: string, rep: string) => str.replaceAll(find, rep)
    : (str: string, find: string, rep: string) => str.split(find).join(rep)

/** Remove all non-number characteres from text. */
export function remove_non_numbers<T extends string | string[]>(
  text: T
): T extends string ? string : string[] {
  if (Array.isArray(text)) {
    //@ts-ignore - typescript limitation
    return text.map(remove_non_numbers)
  }
  //@ts-ignore - typescript limitation
  return text.replace(/[^\d]+/g, '')
}

/** Remove diacritics from a string and make it lowercase
 *  Example: normalize('ï') -> 'i' */
export function normalize(str: string) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

/** 
 * Checks is normalized_search_str is inside match_candidate.
 * (case and diacritics insentitive)  
 */
export function normalized_includes(
  normalized_search_str: string,
  match_candidate: Maybe<string>
) {
  if (match_candidate == undefined) return false
  const normalized_candidate = normalize(match_candidate)
  const result = normalized_candidate.includes(normalized_search_str)
  return result
}


/** Convert any string to snake case. */
export function to_snake(s: string) {
  return (
    normalize(s)
      // replace ordinals
      .replace(/º/g, 'o')
      .replace(/ª/g, 'a')
      // replace special chars by underscore
      .replace(/[-– ./|():+=#!@%&*?<>;,'"]/g, '_')
      // collapse multiple underscores
      .replace(/_{2,}/g, '_')
      // remove underscore leftover from the end, if any
      .replace(/_$/g, '')
  )
}

export type HasDuplicateConfig = {
  /** Funcion applied to transform all values before comparisons are made. */
  transform?: (s: string) => string
  /** Value position in list. */
  value_idx?: number
  /** Already detected duplicates for this list. Used to ensure one of the 
   * items in list will not be marked as duplicate. */
  detected_duplicates?: boolean[]
}
/** 
 * Test if a `value` has duplicates in list. 
 * 
 * If `value_idx` is passed, it means `value` is part of the list in this 
 * position.
 * 
 * `detected_duplicates` contains the already detected duplicates for this 
 * list,this way one of the items in list will not be marked as duplicate. 
 * 
 * The `transform` function, if passed, will be applied to all values 
 * before comparisons are made.
 * 
 */
export function has_duplicate(
  list: string[] | undefined,
  value: string,
  { transform, value_idx, detected_duplicates }: HasDuplicateConfig
) {
  if (!list) return false

  const transformed_value = transform ? transform(value) : value
  return list.some((v, i) => {
    const is_another = i !== value_idx
    const transformed_v = transform ? transform(v) : v
    const is_duplicate = is_another && transformed_v === transformed_value
    const other_showing_error = detected_duplicates && !detected_duplicates[i]
    return is_duplicate && !other_showing_error
  })
}

/** Remone all non-digits and format string as a CNPJ. */
export function format_cnpj(str: string) {
  const numbers = str.replace(/\D/g, '')
  const { length } = numbers
  if (length > 14) return numbers.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})(\d)/,
    '$1.$2.$3/$4-$5 $6'
  )
  if (length > 12) return numbers.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d)/,
    '$1.$2.$3/$4-$5'
  )
  if (length > 8) return numbers.replace(
    /^(\d{2})(\d{3})(\d{3})(\d)/,
    '$1.$2.$3/$4'
  )
  if (length > 5) return numbers.replace(
    /^(\d{2})(\d{3})(\d)/,
    '$1.$2.$3'
  )
  if (length > 2) return numbers.replace(
    /^(\d{2})(\d)/,
    '$1.$2'
  )
  return numbers
}

/**
 *  Limpa texto do cnpj e o valida. 
 *  Fontes:
 *    https://sisweb.tesouro.gov.br/apex/f?p=2470:14:::NO:RP,14:P14_ID_TUTORIAL:3
 *    https://www.geradorcnpj.com/javascript-validar-cnpj.htm
 */
export function is_valid_cnpj(text: string) {
  const cnpj = remove_non_numbers(text)

  if (cnpj === '') return false
  if (cnpj.length !== 14) return false

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) return false

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += +numeros.charAt(tamanho - i) * pos--
    if (pos < 2)
      pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== +digitos.charAt(0))
    return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += +numeros.charAt(tamanho - i) * pos--
    if (pos < 2)
      pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== +digitos.charAt(1)) return false

  return true
}

/** 
 * Transform lower case key with words separated by dashes into pascal case,
 * casting the return type as keyof T.
 */
export function kebab_to_pascal_case<T>(key: string) {
  return to_pascal_case<T>(key, '-')
}

/**
 * Transform lower case key with words separated by dashes into pascal case,
 * casting the return type as keyof T.
 */
export function snake_to_pascal_case<T>(key: string) {
  return to_pascal_case<T>(key, '_')
}

/**
 * Transform upper case key with words separated by underscores into pascal
 * case, casting the return type as keyof T.
 */
export function scream_snake_to_pascal_case<T>(key: string) {
  const lower_key = key.toLowerCase()
  return to_pascal_case<T>(lower_key, '_')
}

/** Convert any lower case key with separators into pascal case. */
export function to_pascal_case<T>(key: string, separator: string) {
  const key_parts = key.split(separator)
  return key_parts.reduce((prev, part) => {
    const new_part = capitalize(part)
    return `${prev}${new_part}`
  }, '') as keyof T
}

/** 
 * Parse a csv conforming with the RFC 4180 enoding.
 * 
 * Default separator is `;`
 * 
 * Adapted from: https://stackoverflow.com/questions/8493195/how-can-i-parse-a-csv-string-with-javascript-which-contains-comma-in-data
 */
export function csv_to_array(text: string, separator = ';') {
  let p = '', row = [''], ret = [row], i = 0, r = 0, s = !0, l
  for (l of text) {
    if ('"' === l) {
      if (s && l === p) row[i] += l
      s = !s
    } else if (separator === l && s) l = row[++i] = ''
    else if ('\n' === l && s) {
      if ('\r' === p) row[i] = row[i].slice(0, -1)
      row = ret[++r] = [l = '']
      i = 0
    } else row[i] += l
    p = l
  }
  return ret;
};

/** Calculate the date in UTC at zero hours. */
export function convert_date_to_utc(date: Date) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0)
}

export function today_utc() {
  return convert_date_to_utc(new Date())
}

export function get_first_day_of_month(date: Date) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), 1, 0, 0, 0)
}

export function get_first_day_of_month_before(date: Date) {
  const first_of_month = get_first_day_of_month(date)
  const last_day_of_month_before = add_days(first_of_month, -1)
  return get_first_day_of_month(last_day_of_month_before)
}

/**
 * Returns the monday immediatelly before date.
 * Returns date if it is a monday.
 */
export function get_monday_of_week(date: Date) {
  var day = date.getDay()
  var prevMonday = today_utc()
  if (date.getDay() == 0)
    prevMonday.setDate(date.getDate() - 7)
  else
    prevMonday.setDate(date.getDate() - (day - 1))
  return prevMonday;
}


export function convert_datetime_to_utc(date: Date) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
}

// From: https://stackoverflow.com/a/5757691
export function add_days(date: Date, amount: number) {
  var tzOff = date.getTimezoneOffset() * 60 * 1000,
    t = date.getTime(),
    d = new Date(),
    tzOff2;

  t += (1000 * 60 * 60 * 24) * amount;
  d.setTime(t);

  tzOff2 = d.getTimezoneOffset() * 60 * 1000;
  if (tzOff != tzOff2) {
    var diff = tzOff2 - tzOff;
    t += diff;
    d.setTime(t);
  }
  return d;
}

/** Join all non-empy args, adding separator between them. */
export function join_non_empy(prev: string, separator: string, next_item?: Maybe<string>, ...args: Maybe<string>[]): string {
  if (!next_item?.length) {
    if (!args.length) return prev
    return join_non_empy(prev, separator, ...args)
  }
  const new_prev = prev.length
    ? `${prev}${separator}${next_item}`
    : next_item
  return join_non_empy(new_prev, separator, ...args)
}

export function is_valid_date_br(text: string) {
  if (text.length < 8) return false

  const date_parts = text.split('/')
  const is_all_numbers = date_parts.find(p => !is_number(p)) === undefined
  if (!is_all_numbers) return false

  const [day, month, year] = date_parts
  return (+day <= 31) && (+month <= 12) && (+year > 1900) && (+year < 2100)
}

export function is_number(str: string) {
  return !isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}


/** Converto local date/time to UTC-Z string. */
export function format_utc(value: string) {
  const datetime = convert_datetime_to_utc(new Date(value))
  return datetime.toISOString()
}

const day_formatter = new Intl.DateTimeFormat('pt-BR', {
  day: 'numeric', year: 'numeric', month: 'numeric'
})
/** Format value as dd/mm/yyyy. */
export function format_day(value: string) {
  const datetime = convert_date_to_utc(new Date(value))
  return day_formatter.format(datetime)
}

/** Format value as dd/mm/yyyy. */
export function date_to_day_string(date: Date) {
  return day_formatter.format(date)
}

const datetime_formatter = new Intl.DateTimeFormat('pt-BR', {
  day: 'numeric', year: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric',
})
/** Format value as dd/mm/yyyy hh:mm. */
export function format_datetime(value: string) {
  const datetime = new Date(value)
  return datetime_formatter.format(datetime)
}

const month_formatter = new Intl.DateTimeFormat('pt-BR', {
  year: 'numeric', month: 'numeric'
})
/** Format values as mm/yy. */
export function format_month(value: string) {
  const datetime = convert_date_to_utc(new Date(value))
  return month_formatter.format(datetime)
}

const long_month_formatter = new Intl.DateTimeFormat('pt-BR', {
  year: 'numeric', month: 'long'
})
/** Format values as mm/yy. */
export function format_long_month(value: string) {
  const datetime = convert_date_to_utc(new Date(value))
  return long_month_formatter.format(datetime)
}

// https://docs.w3cub.com/javascript/global_objects/numberformat/
const integer_formatter = new Intl.NumberFormat('pt-BR', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
})
/** Format number as ###.### */
export function format_integer(value: number) {
  return integer_formatter.format(value)
}

const currency_formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency', currency: 'BRL'
})
/** Format number as R$ ###.###,## */
export function format_currency(value: number) {
  return currency_formatter.format(value)
}

/** 
 * Retrieve the dictionahry value if it exists. Otherwise return the key itself.  
 * `null` and `undefined` keys return an empty string.
 */
export function parse_dictionary<T extends Maybe<string>>(
  key: T, dictionary: Partial<Dictionary<T, string>>
): string {
  if (key === null || key === undefined) return ''
  if (!(key in dictionary)) return key as string
  return dictionary[key as keyof Dictionary<T, string>] as string
}


//  from https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
/**
 * Copy the string in value to the clipboard.
 */
export function copy_to_clipboard(value: string) {
  // Create a <textarea> element
  const el = document.createElement('textarea')
  // Set its value to the string to be copied
  el.value = value
  // Make it readonly to be tamper-proof
  el.setAttribute('readonly', '')
  // Move outside the screen to make it invisible
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  // Append the <textarea> element to the HTML document
  document.body.appendChild(el)

  const selection = document.getSelection()
  const selected = selection !== null && (
    // Check if there is any content selected previously
    selection.rangeCount > 0
      // Store selection if found
      ? selection.getRangeAt(0)
      // false means no selection existed before starting this process
      : false
  )

  // Select the <textarea> content
  el.select()
  // Copy - only works as a result of a user action (e.g. click events)
  document.execCommand('copy')
  // If a selection existed before copying
  if (selected) {
    // Unselect everything on the HTML document
    selection!.removeAllRanges()
    // Restore the original selection
    selection!.addRange(selected)
  }

  // Remove the <textarea> element
  document.body.removeChild(el)
}

/** Produces a pseudo random string with [size] characters. */
export function produce_random_string(size: number) {
  return [...Array(size)].map(i => (~~(Math.random() * 36)).toString(36)).join('')
}