import { affix, compose } from 'helpers'
import React from 'react'
import styles from './button.module.scss'

type ButtonProps = JSX.IntrinsicElements['button'] & {
  /** Default = MAIN */
  color?: (
    'MAIN' |
    'MAIN_SECONDARY' |
    'DANGER' |
    'DANGER_SECONDARY' |
    'TRANSPARENT'
  )
  layout?: (
    'REGULAR' |
    'SLIM' |
    'ICON'
  ),
  disabled_tooltip?: string
  tooltip?: string
  tooltip_alignment?: 'LEFT' | 'RIGHT'
}

export function Button({ disabled_tooltip, tooltip_alignment, color, layout, className, children, tooltip, ...props }: ButtonProps) {
  const { disabled } = props
  const color_key = `color-${disabled ? 'DISABLED' : (color || 'MAIN')}`
  const layout_key = `layout-${layout || 'REGULAR'}`
  const rendered_tooltip = (disabled && disabled_tooltip) || tooltip

  const class_names = compose(
    className,
    styles[color_key],
    styles[layout_key],
    rendered_tooltip && styles.with_tooltip
  )

  return (
    <button
      className={class_names}
      type='button'
      {...props} >
      {children}
      {!rendered_tooltip ? null : (
        <span
          className={styles[affix('tooltip', tooltip_alignment || 'LEFT')]}>
          {rendered_tooltip}
        </span>
      )}
    </button>
  )
}
