import React, { useState } from 'react'
import { trace } from 'controllers/tracer'
import { Defined, format_currency, take } from 'helpers'
import { TightProgressBar, Button, LabeledValue, Svg } from 'components'

import { produce_search_status_ops } from './_operations'
import { SearchStatusProps } from './_data'
import styles from './search-status.module.scss'
import { derive_job_in_progress } from '../../_derivers'
import { GqlSolicitarVerificacaoData } from '../search-starter/_operations'
import { useSelection } from 'controllers/tied'
import { broadcast_selected_debitos_value } from '../../list-debitos/_broadcasts'
import { broadcast_selected_multas_value } from '../../list-multas/_broadcasts'
import { FilesBundleButton } from '../../commom-components'

export function SearchStatus(props: SearchStatusProps) {
  const { verificacao } = props

  const total_debt_currency = useTotalDebtCurrency()
  const [verificacao_in_progress, set_has_completed] = useSearchInProgress(verificacao)

  if (!verificacao) return null

  if (process.env.NODE_ENV === 'development') trace('search-status', 'render', props)

  return (
    <div className={styles.search_status}>
      <div>
        <LabeledValue
          className={styles.credential_wrapper}
          label='Renavam'
          value={verificacao.renavam}
        />
        <LabeledValue
          className={styles.credential_wrapper}
          label='Placa'
          value={verificacao.placa}
        />
        {!total_debt_currency ? null : (
          <LabeledValue
            className={styles.credential_wrapper}
            label='Total Devido'
            value_color='danger'
            value={total_debt_currency}
          />
        )}
      </div>

      <SearchProgress
        verificacao_in_progress={verificacao_in_progress}
        verificacao={verificacao}
        on_change={set_has_completed}
        {...take(props,
          'on_cancel_search',
          'id_cliente',
        )} />

      <ResultsToolbar
        verificacao_in_progress={verificacao_in_progress}
        verificacao={verificacao} />
    </div>
  )
}

function useSearchInProgress(verificacao: SearchStatusProps['verificacao']) {
  const [has_completed, set_has_completed] = useState<boolean>()
  const verificacao_in_progress =
    derive_job_in_progress(verificacao?.status)
    && !has_completed

  return [verificacao_in_progress, set_has_completed] as const
}

/** Calculates the total value of all selected multas + debitos. */
function useTotalDebtCurrency() {
  const selected_debitos_value = useSelection(broadcast_selected_debitos_value) || 0
  const selected_multas_value = useSelection(broadcast_selected_multas_value) || 0
  const total_debt = selected_debitos_value + selected_multas_value
  if (!total_debt) return undefined
  return format_currency(total_debt)
}

type Verificacao = Defined<SearchStatusProps['verificacao']>
type ResultsToolbarProps = {
  verificacao: Verificacao
  verificacao_in_progress: boolean
}
function ResultsToolbar({ verificacao, verificacao_in_progress }: ResultsToolbarProps) {
  if (process.env.NODE_ENV === 'development') trace('search-status', 'ResultsToolbar', { verificacao_in_progress })

  if (verificacao_in_progress) return null

  return (
    <div className={styles.results_toolbar}>
      <FilesBundleButton job={verificacao} className={styles.files_bundle_button} />
    </div>
  )
}

type SearchProgressProps = Pick<SearchStatusProps,
  'on_cancel_search' |
  'id_cliente'
> & {
  verificacao: GqlSolicitarVerificacaoData
  on_change: (has_completed: boolean) => void
  verificacao_in_progress: boolean
}

function SearchProgress(props: SearchProgressProps) {
  if (!props.verificacao_in_progress) return null

  const handle_completion = () => {
    props.on_change(true)
  }

  const handle_cancel_search = () => {
    const { cancelar_verificacao } = produce_search_status_ops()
    const { id_cliente, verificacao } = props
    const id_verificacao = verificacao.id
    const variables = { id_verificacao, id_cliente }
    cancelar_verificacao({ variables })
    props.on_cancel_search()
  }

  return (<>
    <TightProgressBar
      on_complete={handle_completion}
      className={styles.progress_bar} />
    <Button
      onClick={handle_cancel_search}
      className={styles.button_stop}
      color='MAIN_SECONDARY'>
      <Svg icon='search_stop' />
      Cancelar Consulta
    </Button>
  </>)
}