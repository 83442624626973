import React from 'react'

import styles from './situation-card.module.scss'
import { SituationCardProps } from './_data'
import { Header, ResultsSummary, Screenshots } from './_sections'
import { trace } from 'controllers/tracer'
import { useSelector } from 'controllers/tied'
import { broadcast_progress, clear_component_progress } from 'components/tight-progress-bar/broadcast_progress'
import { derive_job_in_progress } from '../../_derivers'
import { useMount } from 'helpers'

/** Shows the vehicle situation details from one information service. */
export function SituationCard({ debitos_restricoes }: SituationCardProps) {
  const id_debito_restricao = debitos_restricoes.id
  const { nome_fornecedor, status } = debitos_restricoes

  const job_in_progress = derive_job_in_progress(status)

  const has_screenshots = (debitos_restricoes.screenshot_files?.length || 0) > 0
  const progress = !job_in_progress
    ? 1
    : has_screenshots ? 0.5 : 0
  const hide_details = !progress || status === 'CANCELED'

  useSelector(broadcast_progress, progress, id_debito_restricao)
  useMount(() => () => { clear_component_progress(id_debito_restricao) })

  if (process.env.NODE_ENV === 'development') trace('situation-card', 'render', { id_debito_restricao, job_in_progress, debitos_restricoes, has_screenshots, progress })

  return (<>
    <Header
      job_in_progress={job_in_progress}
      debitos_restricoes={debitos_restricoes}
      nome_fornecedor={nome_fornecedor}
    />
    {hide_details ? null : (
      <div className={styles.card_results}>
        <ResultsSummary
          className={styles.results_summary}
          debitos_restricoes={debitos_restricoes}
        />
        <Screenshots
          className={styles.screenshots}
          nome_fornecedor={nome_fornecedor}
          screenshot_files={debitos_restricoes.screenshot_files}
          certificate_files={debitos_restricoes.certificate_files}
        />
      </div>
    )}
  </>)
}
