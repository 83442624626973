import { AppHandler } from 'app'
import { select } from 'controllers/tied'
import { copy_to_clipboard } from 'helpers'
import { broadcast_verificacao } from '../../../../_broadcasts'
import { ErrorMessageApiCore } from './_data'
import { GqlResolicitarVerificacaoVariables, produce_error_message_ops } from './_operations'

export const copy_log_to_clipboard: AppHandler<ErrorMessageApiCore> = (i) => {
  const log_string = JSON.stringify(i.props.debitos_restricoes)
  copy_to_clipboard(log_string)
  i.app.show_modal('Message', {
    title: 'Informações técnicas copiadas',
    message: 'Os dados de log foram copiados para a sua área de transferência.',
    ok_label: 'Ok!'
  })
}

/** Retries the job of one singe spider. */
export const retry_job: AppHandler<ErrorMessageApiCore> = (i) => {
  const { id_verificacao, scraper_name } = i.props.debitos_restricoes
  const { show_modal } = i.app

  const verificacao = select(broadcast_verificacao, 'error-message')

  const lista_fornecedor_credenciais = verificacao?.variables?.lista_fornecedor_credenciais
  if (!(verificacao?.variables && Array.isArray(lista_fornecedor_credenciais))) return

  const fornecedor_credeniais = lista_fornecedor_credenciais
    .find(fc => fc.scraper_name === scraper_name)

  if (!fornecedor_credeniais) {
    show_modal('Message', {
      title: 'Credencial não encontrada',
      message: `Não foi possível encontrar uma credencial para ${scraper_name}.`,
      ok_label: 'Ok'
    })
    return
  }

  const variables: GqlResolicitarVerificacaoVariables = {
    ...verificacao.variables,
    lista_fornecedor_credenciais: [fornecedor_credeniais],
    id_verificacao,
  }

  const { resolicitar_verificacao } = produce_error_message_ops()
  i.set.retry_requested(true)
  resolicitar_verificacao({
    variables,
    on_errors: (_errors) => {
      // TODO: handle errors properly
      show_modal('Message', {
        title: 'Ocorreu um erro inesperado',
        message: 'Tente novamente mais tarde. (resolicitar_verificacao)',
        ok_label: 'Ok'
      })
    }
  })
}