import React from 'react'
import { FeatureExport, FeatureProps } from 'feature-groups/_features-data'
import { useSection } from 'feature-groups/helpers'
import { FeatureHeader } from 'components'

import { situation } from './situation'
import { archives } from './archives'

const sections = { situation, archives }
const title = 'Nada Consta'

export const nada_consta: FeatureExport = {
  title,
  icon: 'autos_search',
  Component: NadaConsta,
  menu_placement: 'SIDE_MENU'
}

function NadaConsta(props: FeatureProps) {
  const Section = useSection(sections, props)
  if (!Section) return null

  return (
    <Section {...props}>
      <FeatureHeader
        route={props.route}
        sections={sections}
        title={title} />
    </Section>
  )
}