export function writeStorage<T>(value: T, storageKey: string, storageVersion: number) {
  const newStoredObj = { version: storageVersion, value }
  const newStoredJson = JSON.stringify(newStoredObj)
  localStorage.setItem(storageKey, newStoredJson)
  sessionStorage.setItem(storageKey, newStoredJson)
}

export function readStorage(storageKey: string, storageVersion: number) {
  const sessionStoredJson = sessionStorage.getItem(storageKey)
  const storedJson = sessionStoredJson !== null
    ? sessionStoredJson
    : localStorage.getItem(storageKey)
  if (storedJson === null) return null

  const storedObj = storedJson && JSON.parse(storedJson)
  const storedVersion = storedObj ? storedObj.version : -Infinity
  if (storedVersion !== storageVersion) return null

  return storedObj.value
}
