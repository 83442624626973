import React from 'react'
import { Maybe } from 'helpers'

import { EnrichedebitosRestricoes } from '../../../situation-search'
import styles from './header.module.scss'
import { Donut } from 'components'
import { GqlStatusVerificacao } from 'codegen/gql-types'
import { FilesBundleButton } from '../../../commom-components'


type HeaderProps = {
  nome_fornecedor: Maybe<string>
  job_in_progress: boolean
  debitos_restricoes: EnrichedebitosRestricoes
}

/** Card header and screenshots. */
export function Header(props: HeaderProps) {
  const { debitos_restricoes, job_in_progress } = props

  return (
    <div className={styles.header}>
      <h3>{props.nome_fornecedor}</h3>
      <SituationIcons
        job_in_progress={job_in_progress}
        debitos_restricoes={debitos_restricoes}
      />
    </div>
  )
}

export const status_labels: { [status in GqlStatusVerificacao]: string } = {
  FINISHED: 'Concluída',
  STARTED: 'Requisitada',
  FAILED: 'Falhou',
  CANCELED: 'Cancelada pelo usuário',
  REQUESTED: 'Em andamento',
}

type SituationIconsProps = {
  job_in_progress: boolean
  debitos_restricoes: EnrichedebitosRestricoes
}

function get_progress_label(attempts: number) {
  if (attempts > 1) return `${attempts}ª tentativa`
  return 'Capturando'
}

function SituationIcons(props: SituationIconsProps) {
  const { debitos_restricoes } = props
  return (
    <div className={styles.progress_wrapper}>
      {props.job_in_progress
        ? <SituationProgress debitos_restricoes={debitos_restricoes} />
        : <FilesBundleButton job={debitos_restricoes} />
      }
    </div>
  )
}

type SituationProgressProps = {
  debitos_restricoes: EnrichedebitosRestricoes
}
function SituationProgress(props: SituationProgressProps) {
  const { debitos_restricoes } = props
  const { attempts } = debitos_restricoes
  const progress_label = get_progress_label(attempts)
  return (
    <>
      <p className={styles.progress_label}>{progress_label}</p>
      <Donut spin className={styles.spinner} />
    </>
  )
}
