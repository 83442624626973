import { Fields, ColumnsList } from '../_data'
import { GqlOrderByDirection, GqlOrderBy } from 'codegen/gql-types'

export type HeadersProps = {
  fields: Fields
  visible_columns_ids: ColumnsList<Fields>
  /** List of fields ordering the table. */
  order_by_list: GqlOrderBy[]
  /** User selected a table action from the top left popup menu. */
  on_table_action: HeaderOnTableAction
  /** User request to order a field. */
  on_order_by: HeaderOnOrderBy
}
export type HeaderOnTableAction = (action: TableActionsOption) => void
export type HeaderOnOrderBy = (
  field_id: string,
  direction: GqlOrderByDirection | 'NONE'
) => void

/** Types of actions related to the entire table. */
export enum TableActionTypes {
  CREATE_CSV = 'CREATE_CSV',
  CHANGE_DATA_VISIBILITY = 'CHANGE_DATA_VISIBILITY',
}

/** Options available in the top left popup menu. They must always be actions related to the entire table. */
export const table_actions_options: TableActionsOption[] = [
  {
    label: 'Salvar como csv',
    type: TableActionTypes.CREATE_CSV
  },
  {
    label: 'Alterar dados mostrados',
    type: TableActionTypes.CHANGE_DATA_VISIBILITY
  },
]
type TableActionsOption = {
  label: string,
  type: TableActionTypes
}
