import { useRef, useEffect } from 'react'

let uniqueKeyCounter = 0
/**
 * Produce a non-random number to be used as key.
 * This number is sequencial and guaranteed to be unique during the current 
 * application memmory lifetime.
 */
export function produceKey() {
    return ++uniqueKeyCounter
}

/**
 * Transform any type of value into an Array that can be used as hooks
 * dependency.
 */
export function toDependenciesArray(value: any) {
    if (value === undefined || Array.isArray(value)) {
        return value
    }
    if (value && typeof value === 'object') {
        const keys = Object.keys(value)
        return keys.map(key => value[key])
    }
    return [value]
}

/**
 * Executed callback only once, syncrhronously, during the first render.
 * If the callback returns a function, it will be executed on unmount.
 * 
 * The difference between `useFirstRender` and `useEffect` is:
 * `useEffect` will asynchronously execute the callback after the
 * component has rendered whereas `useFirstRender` does it synchronously
 * and immediatelly.
 * 
 * This is useful, for example, when some setup needs to happen synchronously 
 * during the first render of a component and another logic down the road uses
 * the result of this setup and needs it initialized in the first render.
 */
export function useFirstRender(callback: Function) {
    const resultRef = useRef<boolean | Function>()
    if (!resultRef.current) {
        resultRef.current = callback() || true
    }

    useEffect(() => () => {
        if (typeof resultRef.current === 'function') {
            resultRef.current()
        }
    }, [])
}
