import React from 'react'
import { affix, compose } from 'helpers'
import { Svg } from './svg'
import styles from './chevron.module.scss'

type ChevronProps = {
  down?: boolean
  className?: string
  on_click?: () => void
}
export function Chevron(props: ChevronProps) {
  const chevron_classname = affix('chevron', props.down && 'DOWN')
  const svg_classes = compose(
    styles[chevron_classname],
    props.className
  )
  return (
    <Svg
      on_click={props.on_click}
      icon='chevron_right'
      className={svg_classes} />
  )
}