import React from 'react'
import styles from './card-footer.module.scss'

type CardFooterProps = React.PropsWithChildren<{}>
export function CardFooter(props: CardFooterProps) {
  return (
    <div className={styles.card_footer}>
      {props.children}
    </div>
  )
}