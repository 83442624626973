import { useMount, useMounted } from 'helpers'
import { useState } from 'react'
import { readStorage, writeStorage } from './storage'

export type SharedStateConfig<T> = {
  initialState: T
}

export type SharedStoredStateConfig<T> = {
  initialState: T
  /** If it exists, last value should be kept in the broswer storage */
  storageKey: string
  storageVersion: number
}

type AnySharedConfig = SharedStateConfig<any> | SharedStoredStateConfig<any>

type SharedStateSetters<T> = Map<string, React.Dispatch<T>>
type SharedState<T> = readonly [T, SharedStateSetters<T>]
const sharedStates = new Map<AnySharedConfig, SharedState<any>>()

/** 
 * Use the same config object for all components that share this state. 
 */
export function useSharedState<T>(
  sharedStateConfig: SharedStateConfig<T>, componentId: string
) {
  useUnsubscribeSharedState(sharedStateConfig, componentId)
  const sharedState = getStateSetters(
    sharedStateConfig,
    sharedStateConfig.initialState
  )
  const sharedStateResult = useSyncState(
    sharedState,
    sharedStateConfig,
    componentId
  )
  return sharedStateResult
}

/**
 *  Use the same config object for all components that share this state.
 *  Save to session and local storages on everuy change.
 *  On mount, try to initialize from session storage, then from local storage,
 *  then from the config object.
 */
export function useSharedStoredState<T>(
  sharedStateConfig: SharedStoredStateConfig<T>, componentId: string
) {
  useUnsubscribeSharedState(sharedStateConfig, componentId)
  const storedState = useStoredState(sharedStateConfig)
  const initialState = storedState !== undefined
    ? storedState
    : sharedStateConfig.initialState
  const sharedState = getStateSetters(sharedStateConfig, initialState)
  const sharedStateResult = useSyncStoredState(
    sharedState,
    sharedStateConfig,
    initialState,
    componentId
  )
  return sharedStateResult
}

function useStoredState<T>(sharedStateConfig: SharedStoredStateConfig<T>) {
  const { initialState, storageKey, storageVersion } = sharedStateConfig
  if (!storageKey || storageVersion === undefined) return undefined

  let storedInitialState = initialState
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const is_mounted = useMounted()
  if (!is_mounted) {
    const storedValue = readStorage(storageKey, storageVersion)
    if (storedValue !== null) storedInitialState = storedValue
  }
  return storedInitialState
}

/**
 * Cleanup memory on component unmount
 */
function useUnsubscribeSharedState(
  sharedStateConfig: AnySharedConfig, componentId: string
) {
  useMount(() => () => {
    const sharedState = sharedStates.get(sharedStateConfig)
    if (!sharedState) return
    const [, sharedStateSetters] = sharedState
    // remove this component's state setter from the list
    sharedStateSetters.delete(componentId)
    // if the last subscription was removed - delete the shared state
    if (!sharedStateSetters.size) sharedStates.delete(sharedStateConfig)
  })
}

/**
 * Get the shared state ideitifyed by sharedStateConfig
 */
function getStateSetters<T>(
  sharedStateConfig: SharedStoredStateConfig<T> | SharedStateConfig<T>,
  initialState: T
) {
  const existingSharedState = sharedStates.get(sharedStateConfig)
  if (existingSharedState)
    return existingSharedState as SharedState<T>

  const emptySharedStateSetters: SharedStateSetters<T> = new Map()
  const newSharedState = [initialState, emptySharedStateSetters] as const
  sharedStates.set(sharedStateConfig, newSharedState)
  return newSharedState
}

/**
 * Keep shaed state synchronized in all subscriptions
 */
function useSyncState<T>(
  sharedState: SharedState<T>,
  sharedStateConfig: SharedStateConfig<T>,
  componentId: string
) {
  const [localState, setLocalState] = useState(sharedStateConfig.initialState)
  const [, sharedStateSetters] = sharedState
  sharedStateSetters.set(componentId, setLocalState)

  const setSyncState = (newState: T) => {
    if (newState === localState) return
    sharedStates.set(sharedStateConfig, [newState, sharedStateSetters])
    // update state in all subscriptions to force re-render
    sharedStateSetters.forEach((stateSetter) => {
      stateSetter(newState)
    })
  }

  return [localState, setSyncState] as const
}

/**
 * Keep shaed state synchronized in all subscriptions
 */
function useSyncStoredState<T>(
  sharedState: SharedState<T>,
  sharedStateConfig: SharedStoredStateConfig<T>,
  initialState: T,
  componentId: string
) {
  const [localState, setLocalState] = useState(initialState)
  const [, sharedStateSetters] = sharedState
  sharedStateSetters.set(componentId, setLocalState)

  const setSyncState = (newState: T) => {
    if (newState === localState) return
    sharedStates.set(sharedStateConfig, [newState, sharedStateSetters])
    // update state in all subscriptions to force re-render
    sharedStateSetters.forEach((stateSetter) => {
      stateSetter(newState)
    })
    // update storage
    const { storageKey, storageVersion } = sharedStateConfig
    writeStorage(newState, storageKey, storageVersion)
  }

  return [localState, setSyncState] as const
}

export function setSharedState<T>(
  sharedStateConfig: SharedStateConfig<T>,
  newState: T
) {
  const sharedState = sharedStates.get(sharedStateConfig)
  if (!sharedState) return
  const [, sharedStateSetters] = sharedState
  // update state in all subscriptions to force re-render
  sharedStateSetters.forEach((stateSetter) => {
    stateSetter(newState)
  })
}

export function setSharedStoredState<T>(
  sharedStateConfig: SharedStoredStateConfig<T>,
  newState: T
) {
  const sharedState = sharedStates.get(sharedStateConfig)
  if (!sharedState) return
  const [, sharedStateSetters] = sharedState
  // set shared state even if no setter is available
  // this allows getSharedState to work even with no subscribed components
  sharedStates.set(sharedStateConfig, [newState, sharedStateSetters])
  // update state in all subscriptions to force re-render
  sharedStateSetters.forEach((stateSetter) => {
    stateSetter(newState)
  })
  // update local storage
  const { storageKey, storageVersion } = sharedStateConfig
  writeStorage(newState, storageKey, storageVersion)
}

export function getSharedState<T>(sharedStateConfig: SharedStateConfig<T> | SharedStoredStateConfig<T>) {
  const sharedState = sharedStates.get(sharedStateConfig)
  if (!sharedState) return undefined
  return sharedState[0] as SharedState<T>[0]
}
