import * as Types from '../codegen/gql-types';

import { produce_query } from 'app'
import gql from 'graphql-tag';

/**
 * 
 * 
 * 
 * 
 * 
 *         DO NOT EDIT THIS FILE - ANY CHANGES WILL BE LOST
 * 
 * 
 * 
 * 
 * 
 *    This file is automatically generated on every build by codegen plugins
 *    as defined in codegen.yml
 * 
 *    Its contents are defined by its sibling file _operations.graphql.
 * 
 * 
 * 
 */


 const is_situacao_veicular_inactive_doc = gql`
    query is_situacao_veicular_inactive {
  is_situacao_veicular_inactive
}
    `;

/** Produce all of operations functions for app. */
function app_ops_factory() {
  const is_situacao_veicular_inactive = produce_query<GqlIsSituacaoVeicularInactiveData,GqlIsSituacaoVeicularInactiveVariables>(is_situacao_veicular_inactive_doc)
  
  return {
    is_situacao_veicular_inactive,
  }
}
/** Auto-generated operations functions for app. */
export type AppOps = ReturnType<typeof app_ops_factory>

let memoized_operations: AppOps
/** Produces and memoizes all app operations. */
export function produce_app_ops() {
  if (!memoized_operations) {
    memoized_operations = app_ops_factory()
  }
  return memoized_operations
}

/** Types returned as the final result from operations execution.  */

export type GqlIsSituacaoVeicularInactiveData = GqlIsSituacaoVeicularInactive['is_situacao_veicular_inactive']

export type GqlIsSituacaoVeicularInactiveVariables = Types.Exact<{ [key: string]: never; }>;


export type GqlIsSituacaoVeicularInactive = Pick<Types.GqlRootQueryType, 'is_situacao_veicular_inactive'>;
