import * as Types from '../../../../../codegen/gql-types';

import { produce_query, produce_subscription } from 'app'
import gql from 'graphql-tag';

/**
 * 
 * 
 * 
 * 
 * 
 *         DO NOT EDIT THIS FILE - ANY CHANGES WILL BE LOST
 * 
 * 
 * 
 * 
 * 
 *    This file is automatically generated on every build by codegen plugins
 *    as defined in codegen.yml
 * 
 *    Its contents are defined by its sibling file _operations.graphql.
 * 
 * 
 * 
 */

export const situation_result = gql`
    fragment situation_result on SituationResult {
  value
  status
  count
  summary
  details {
    title
    text
  }
}
    `;
export const debito_restricao = gql`
    fragment debito_restricao on DebitoRestricao {
  id
  scrape_job_id
  id_verificacao
  id_fornecedor
  id_cliente
  scraper_name
  nome_fornecedor
  status
  requested_at
  received_at
  scrape_response_errors {
    error_message
    error_type
    encoded_error
    retryable
  }
  request_error {
    error_message
    error_type
  }
  required_action {
    action_type
  }
  ipva {
    ...situation_result
  }
  licenciamento {
    ...situation_result
  }
  dpvat {
    ...situation_result
  }
  multas {
    ...situation_result
  }
  restricoes {
    ...situation_result
  }
  inspecao_veicular {
    ...situation_result
  }
  vistorias {
    ...situation_result
  }
  divida_ativa {
    ...situation_result
  }
  screenshot_files {
    url
    content_type
    utc_time_of_capture
  }
  certificate_files {
    url
    content_type
    utc_time_of_capture
  }
  files_bundle {
    url
  }
}
    ${situation_result}`;
 const list_debitos_restricoes_doc = gql`
    query list_debitos_restricoes($id_cliente: String!, $id_verificacao: String!) {
  list_debitos_restricoes(id_verificacao: $id_verificacao, id_cliente: $id_cliente) {
    ...debito_restricao
  }
}
    ${debito_restricao}`;
 const debitos_restricoes_created_doc = gql`
    subscription debitos_restricoes_created($id_verificacao: String!) {
  debitos_restricoes_created(id_verificacao: $id_verificacao) {
    id
    id_cliente
    id_verificacao
    id_fornecedor
    nome_fornecedor
    requested_at
    received_at
    status
    files_bundle {
      url
    }
  }
}
    `;
 const debito_restricao_changed_doc = gql`
    subscription debito_restricao_changed($id_verificacao: String!) {
  debito_restricao_changed(id_verificacao: $id_verificacao) {
    ...debito_restricao
  }
}
    ${debito_restricao}`;

/** Produce all of operations functions for list-situation-cards. */
function list_situation_cards_ops_factory() {
  const list_debitos_restricoes = produce_query<GqlListDebitosRestricoesData,GqlListDebitosRestricoesVariables>(list_debitos_restricoes_doc)
  
  const debitos_restricoes_created = produce_subscription<GqlDebitosRestricoesCreatedData,GqlDebitosRestricoesCreatedVariables>(debitos_restricoes_created_doc)
  
  const debito_restricao_changed = produce_subscription<GqlDebitoRestricaoChangedData,GqlDebitoRestricaoChangedVariables>(debito_restricao_changed_doc)
  
  return {
    list_debitos_restricoes,
    debitos_restricoes_created,
    debito_restricao_changed,
  }
}
/** Auto-generated operations functions for list-situation-cards. */
export type ListSituationCardsOps = ReturnType<typeof list_situation_cards_ops_factory>

let memoized_operations: ListSituationCardsOps
/** Produces and memoizes all list-situation-cards operations. */
export function produce_list_situation_cards_ops() {
  if (!memoized_operations) {
    memoized_operations = list_situation_cards_ops_factory()
  }
  return memoized_operations
}

/** Types returned as the final result from operations execution.  */

export type GqlListDebitosRestricoesData = GqlListDebitosRestricoes['list_debitos_restricoes']
export type GqlDebitosRestricoesCreatedData = GqlDebitosRestricoesCreated['debitos_restricoes_created']
export type GqlDebitoRestricaoChangedData = GqlDebitoRestricaoChanged['debito_restricao_changed']

export type GqlSituationResult = (
  Pick<Types.GqlSituationResult, 'value' | 'status' | 'count' | 'summary'>
  & { details?: Types.Maybe<Array<Pick<Types.GqlSituationResultDetail, 'title' | 'text'>>> }
);

export type GqlDebitoRestricao = (
  Pick<Types.GqlDebitoRestricao, 'id' | 'scrape_job_id' | 'id_verificacao' | 'id_fornecedor' | 'id_cliente' | 'scraper_name' | 'nome_fornecedor' | 'status' | 'requested_at' | 'received_at'>
  & { scrape_response_errors?: Types.Maybe<Array<Pick<Types.GqlScrapeResponseError, 'error_message' | 'error_type' | 'encoded_error' | 'retryable'>>>, request_error?: Types.Maybe<Pick<Types.GqlRequestError, 'error_message' | 'error_type'>>, required_action?: Types.Maybe<Pick<Types.GqlEnrichedRequiredAction, 'action_type'>>, ipva?: Types.Maybe<GqlSituationResult>, licenciamento?: Types.Maybe<GqlSituationResult>, dpvat?: Types.Maybe<GqlSituationResult>, multas?: Types.Maybe<GqlSituationResult>, restricoes?: Types.Maybe<GqlSituationResult>, inspecao_veicular?: Types.Maybe<GqlSituationResult>, vistorias?: Types.Maybe<GqlSituationResult>, divida_ativa?: Types.Maybe<GqlSituationResult>, screenshot_files?: Types.Maybe<Array<Pick<Types.GqlS3File, 'url' | 'content_type' | 'utc_time_of_capture'>>>, certificate_files?: Types.Maybe<Array<Pick<Types.GqlS3File, 'url' | 'content_type' | 'utc_time_of_capture'>>>, files_bundle?: Types.Maybe<Pick<Types.GqlS3File, 'url'>> }
);

export type GqlListDebitosRestricoesVariables = Types.Exact<{
  id_cliente: Types.Scalars['String'];
  id_verificacao: Types.Scalars['String'];
}>;


export type GqlListDebitosRestricoes = { list_debitos_restricoes: Array<GqlDebitoRestricao> };

export type GqlDebitosRestricoesCreatedVariables = Types.Exact<{
  id_verificacao: Types.Scalars['String'];
}>;


export type GqlDebitosRestricoesCreated = { debitos_restricoes_created: Array<(
    Pick<Types.GqlDebitoRestricao, 'id' | 'id_cliente' | 'id_verificacao' | 'id_fornecedor' | 'nome_fornecedor' | 'requested_at' | 'received_at' | 'status'>
    & { files_bundle?: Types.Maybe<Pick<Types.GqlS3File, 'url'>> }
  )> };

export type GqlDebitoRestricaoChangedVariables = Types.Exact<{
  id_verificacao: Types.Scalars['String'];
}>;


export type GqlDebitoRestricaoChanged = { debito_restricao_changed: GqlDebitoRestricao };
