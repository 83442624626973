import React from 'react'

import styles from './svg.module.scss'

/** Icon sizes. */
const sizes = {
  small: '0 0 16 16',
  regular: '0 0 24 24',
  large: '0 0 32 32',
}

const small = {
  /** Circle crossed diagonally. */
  disabled: 'M8 .25a7.75 7.75 0 100 15.5A7.75 7.75 0 008 .25zm4.42 3.33a6.248 6.248 0 01.498 8.278L4.142 3.082a6.248 6.248 0 018.277.499zm-8.84 8.84a6.248 6.248 0 01-.498-8.278l8.776 8.776a6.248 6.248 0 01-8.277-.499z',
  /** Circular arrow. */
  redo: 'M15.625 0h-1.48a.375.375 0 00-.375.393l.125 2.586A7.727 7.727 0 007.995.25C3.727.25.247 3.735.25 8.006a7.747 7.747 0 007.745 7.744 7.714 7.714 0 005.19-1.997.375.375 0 00.015-.545l-1.062-1.062a.375.375 0 00-.512-.018 5.494 5.494 0 01-8.514-1.606 5.503 5.503 0 013.623-7.876 5.493 5.493 0 015.823 2.285L9.387 4.78a.375.375 0 00-.393.375v1.482a.375.375 0 00.375.375h6.256A.374.374 0 0016 6.636V.375A.375.375 0 0015.625 0z',
  /** Checkmark for success/ selected/ found. */
  check: 'M6 13L1 8.192l1.4-1.346L6 10.308 13.6 3 15 4.346 6 13z',
  /** X for close/ danger/ error. */
  x: 'M2.104 11.896a.5.5 0 000 .708l1.414 1.414a.5.5 0 00.707 0l3.889-3.89 3.889 3.89a.5.5 0 00.707 0l1.414-1.414a.5.5 0 000-.707l-3.889-3.89 3.89-3.889a.5.5 0 000-.707L12.71 1.997a.5.5 0 00-.707 0l-3.889 3.89-3.89-3.89a.5.5 0 00-.706 0L2.103 3.411a.5.5 0 000 .707l3.89 3.89-3.89 3.889z',
  close: 'M15 2.41L13.59 1 8 6.59 2.41 1 1 2.41 6.59 8 1 13.59 2.41 15 8 9.41 13.59 15 15 13.59 9.41 8 15 2.41z',
  /** Exclamation pooint in a circle. */
  attention: 'M8 16A8 8 0 108 0a8 8 0 000 16zm1-3v-2H7v2h2zm0-4V3H7v6h2z',
  /** Exclamation point in a triangle. */
  danger: 'M9.313.815a1.465 1.465 0 00-2.626 0L.16 13.847C-.334 14.834.377 16 1.473 16h13.054c1.096 0 1.807-1.166 1.313-2.153L9.313.815zM9 12H7v2h2v-2zm0-8H7v6h2V4z',
  /** Centralized small filter. Same as filter small. */
  filter: 'M13.437 2H2.563c-.5 0-.752.606-.398.96L6.5 7.295v4.83c0 .184.09.355.24.46l1.875 1.313a.563.563 0 00.885-.461V7.295l4.335-4.335a.563.563 0 00-.398-.96z',
}
export type SvgSmallIcon = keyof Icons['small']

/** Path centralized in a 24px x 24px square. */
const regular = {
  refresh: 'M12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8c3.7 0 6.8-2.6 8-6h-2c-1.2 2.3-3.4 4-6 4-3.3 0-6-2.7-6-6s2.7-6 6-6c1.7 0 3.1.7 4 2l-3 3h7V4l-2 2c-1.8-1-3.8-2-6-2Z',
  /** Magnifying glass with an X on the left side.  */
  search_stop: 'M14.71 14h.79l4.99 5L19 20.49l-5-4.99v-.79l-.27-.28A6.471 6.471 0 019.5 16c-.17 0-.33-.01-.5-.03v-2.02l.134.017c.122.017.241.033.366.033 2.49 0 4.5-2.01 4.5-4.5S11.99 5 9.5 5C7.18 5 5.3 6.75 5.05 9H3.03c.25-3.36 3.05-6 6.47-6A6.5 6.5 0 0116 9.5c0 1.61-.59 3.09-1.57 4.23l.28.27zM4 13.29l2.47-2.47.71.71L4.71 14l2.47 2.47-.71.71L4 14.71l-2.47 2.47-.71-.71L3.29 14 .82 11.53l.71-.71L4 13.29z',
  /** Magnifying glass.  */
  search: 'M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z',
  /** Half rectangle with an arrow pointing right. */
  exit: 'M17 8l-1.41 1.41L17.17 11H9v2h8.17l-1.58 1.58L17 16l4-4-4-4zM5 5h7V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h7v-2H5V5z',
  /** File with a dollar sign in the middle. */
  file_invoice: 'M13.27 13.848l-2.11-.633c-.241-.073-.41-.318-.41-.597 0-.34.248-.618.553-.618h1.317c.214 0 .42.06.601.174.152.096.345.09.475-.034l.55-.525a.376.376 0 00-.026-.57 2.685 2.685 0 00-1.47-.531V9.375A.375.375 0 0012.373 9h-.75a.375.375 0 00-.375.375v1.13c-1.107.03-2 .964-2 2.113 0 .936.609 1.773 1.48 2.034l2.11.633c.242.073.411.318.411.597 0 .34-.248.618-.553.618h-1.318c-.213 0-.42-.06-.6-.174a.385.385 0 00-.475.034l-.551.525a.376.376 0 00.027.57c.426.32.94.504 1.47.531v1.139c0 .207.168.375.375.375h.75a.375.375 0 00.375-.375v-1.13c1.107-.03 2-.963 2-2.113 0-.936-.608-1.773-1.48-2.034zm7.07-9.255L16.406.66A2.25 2.25 0 0014.82 0h-9.57A2.257 2.257 0 003 2.254V21.75A2.25 2.25 0 005.25 24h13.5A2.25 2.25 0 0021 21.75V6.186c0-.595-.24-1.171-.66-1.593zM15 1.528a.738.738 0 01.347.197l3.932 3.931a.737.737 0 01.197.347h-4.476V1.528zm4.5 20.222c0 .413-.338.75-.75.75H5.25a.752.752 0 01-.75-.75V2.255c0-.412.337-.754.75-.754H13.5v4.878A1.12 1.12 0 0014.626 7.5H19.5v14.25z',
  /** File with a checkbox in the middle. */
  file_proof: 'M16.407.66l3.932 3.933c.422.422.661.998.661 1.593V21.75A2.25 2.25 0 0118.75 24H5.25A2.25 2.25 0 013 21.75V2.255A2.257 2.257 0 015.25 0h9.569c.594 0 1.166.24 1.588.66zm-1.059 1.065a.738.738 0 00-.347-.197v4.475h4.476a.737.737 0 00-.197-.347l-3.932-3.931zM18.75 22.5c.413 0 .75-.337.75-.75V7.5h-4.874a1.12 1.12 0 01-1.125-1.122V1.5H5.25a.756.756 0 00-.75.754V21.75c0 .413.337.75.75.75h13.5zm-8.582-4.684l-3.18-3.293 1.079-1.042 2.138 2.214 5.806-5.607 1.042 1.079-6.885 6.65v-.001z',
  chevron_left: 'M10.842 22.214l.928-.928a.563.563 0 000-.796L3.3 12l8.47-8.49a.562.562 0 000-.796l-.928-.928a.563.563 0 00-.796 0L.23 11.602a.563.563 0 000 .796l9.816 9.816c.22.22.576.22.796 0z',
  chevron_right: 'M1.158 1.786l-.928.928a.563.563 0 000 .796L8.7 12 .23 20.49a.563.563 0 000 .796l.928.928c.22.22.576.22.796 0l9.816-9.816a.562.562 0 000-.796L1.954 1.786a.562.562 0 00-.796 0z',
  /** Hamburger menu */
  menu: 'M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z',
  expand: 'M9.97 13.78l-4.345 4.345 1.543 1.454c.709.71.207 1.921-.795 1.921h-5.25A1.123 1.123 0 010 20.375v-5.25c0-1.002 1.21-1.504 1.918-.796l1.457 1.546L7.72 11.53a.75.75 0 011.06 0l1.19 1.19a.75.75 0 010 1.06zm1.06-5.56l4.345-4.345-1.543-1.454C13.123 1.71 13.625.5 14.627.5h5.25C20.5.5 21 1.004 21 1.625v5.25c0 1.002-1.21 1.504-1.918.796l-1.457-1.546-4.345 4.345a.75.75 0 01-1.06 0l-1.19-1.19a.75.75 0 010-1.06z',
  /** X */
  close: 'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z',
  /** Person shape. */
  user: 'M12 12c3.039 0 5.5-2.461 5.5-5.5S15.039 1 12 1a5.498 5.498 0 00-5.5 5.5C6.5 9.539 8.961 12 12 12zm0 2.75c-3.671 0-11 1.843-11 5.5V23h22v-2.75c0-3.657-7.329-5.5-11-5.5z',
  /** Three dots, vertically disposed. */
  more_vertical: 'M13 7a1 1 0 11-2 0 1 1 0 012 0zM13 12a1 1 0 11-2 0 1 1 0 012 0zM12 18a1 1 0 100-2 1 1 0 000 2z',
  /** Arrow down with an open container at the bottom. */
  download: 'M17 11l-1.4-1.4-2.6 2.6V2h-2v10.2L8.4 9.6 7 11l5 5 5-5zM5 20v-8H3v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-8h-2v8H5z',
  /** Arrow up with an open container at the bottom. */
  upload: 'M8.4 8.4L7 7l5-5 5 5-1.4 1.4L13 5.8V16h-2V5.8L8.4 8.4zM5 12v8h14v-8h2v8c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-8h2z',
  /** Arrow up entering into a box. */
  upload_process: 'M7 13l1.4 1.4 2.6-2.6V22h2V11.8l2.6 2.6L17 13l-5-5-5 5zM19 4v15h-3v2h3c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v15c0 1.1.9 2 2 2h3v-2H5V4h14z',
  /** 3 horiozontal bars. */
  rows: 'M3 22a1 1 0 01-1-1v-1a1 1 0 011-1h18a1 1 0 011 1v1a1 1 0 01-1 1H3zM3 14a1 1 0 01-1-1v-1a1 1 0 011-1h18a1 1 0 011 1v1a1 1 0 01-1 1H3zM2 5a1 1 0 001 1h18a1 1 0 001-1V4a1 1 0 00-1-1H3a1 1 0 00-1 1v1z',
  /** 3 vertical bars. */
  cols: 'M1 3a1 1 0 011-1h1a1 1 0 011 1v18a1 1 0 01-1 1H2a1 1 0 01-1-1V3zM10 3a1 1 0 011-1h1a1 1 0 011 1v18a1 1 0 01-1 1h-1a1 1 0 01-1-1V3zM20 2a1 1 0 00-1 1v18a1 1 0 001 1h1a1 1 0 001-1V3a1 1 0 00-1-1h-1z',
  /** Horizontal ellipse with a dash in the center. */
  link: 'M8 7h3v2H8a3 3 0 100 6h3v2H8A5 5 0 018 7zm8 8h-3v2h3a5 5 0 000-10h-3v2h3a3 3 0 110 6zm1-4H7v2h10v-2z',
  /** Filter shrunk to the bottom left corner, to allow number placement at the 
   * top right. */
  filter_small: 'M15.437 9H4.563c-.5 0-.752.606-.398.96L8.5 14.295v4.83c0 .183.09.355.24.46l1.875 1.313a.563.563 0 00.885-.461v-6.142l4.335-4.335a.563.563 0 00-.398-.96z',
  /** Letter A on top of Z with an arrow pointing down. */
  order_asc: 'M22 15l-1.4-1.4-2.6 2.6V6h-2v10.2l-2.6-2.6L12 15l5 5 5-5zM2.129 22v-1.564l4.512-5.573H2.639V13.41h6.287v1.348L4.22 20.553h4.886V22H2.13zM10.62 11H8.731l-.75-1.951H4.55L3.839 11H2l3.346-8.59H7.18L10.62 11zM7.425 7.602L6.242 4.414l-1.16 3.188h2.344z',
  /** Letter Z on top of A with an arrow pointing down. */
  order_desc: 'M22 15l-1.4-1.4-2.6 2.6V6h-2v10.2l-2.6-2.6L12 15l5 5 5-5zM2.129 11V9.436L6.64 3.863H2.639V2.41h6.287v1.348L4.22 9.553h4.886V11H2.13zM10.62 22H8.731l-.75-1.951H4.55L3.839 22H2l3.346-8.59H7.18L10.62 22zm-3.194-3.398l-1.184-3.188-1.16 3.188h2.344z',
  /** 4 pairs of horizontal lines. */
  pairing: 'M0 .5A.5.5 0 01.5 0h9a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-2zM.5 7a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-9zM0 14.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-2zm0 7a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-2zm14-21a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-2zm.5 6.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-9zm-.5 7.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-2zm0 7a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-2z',
  /** A file sheet with the word LOG coming out of it.  */
  log: 'M3 0a1 1 0 00-1 1v22a1 1 0 001 1h16a1 1 0 001-1v-2h-2v1H4V2h8v5a1 1 0 001 1h5v2h2V5.671a1 1 0 00-.293-.707L15.04.294A1 1 0 0014.333 0H3zm15 6l-4-4v4h4zM7.929 17.892h2.597V19H6.537v-7.11H7.93v6.002zM15.692 16.446c0 .834-.226 1.485-.678 1.953-.453.47-1.055.703-1.807.703s-1.353-.234-1.802-.703c-.449-.468-.674-1.12-.674-1.953v-1.992c0-.83.225-1.481.674-1.953.45-.475 1.049-.713 1.797-.713.749 0 1.351.238 1.807.713.455.472.683 1.123.683 1.953v1.992zm-1.382-2.002c0-.508-.094-.89-.283-1.147-.188-.26-.463-.39-.825-.39-.364 0-.638.128-.82.385-.182.257-.274.641-.274 1.152v2.002c0 .515.093.9.279 1.157.185.258.459.386.82.386.358 0 .631-.128.82-.386.19-.257.284-.642.284-1.157v-2.002zM21.303 17.945c-.192.306-.48.576-.864.81-.385.232-.871.348-1.46.348-.762 0-1.374-.223-1.836-.67-.46-.445-.689-1.08-.689-1.904v-2.168c0-.817.221-1.45.664-1.9.446-.448 1.035-.673 1.768-.673.788 0 1.378.194 1.772.581.394.384.6.936.615 1.655l-.01.03H19.96c-.01-.4-.1-.692-.269-.874-.166-.186-.421-.279-.766-.279-.339 0-.604.12-.796.362-.192.237-.288.6-.288 1.089v2.177c0 .498.096.868.288 1.109.195.237.478.356.85.356.276 0 .48-.037.61-.112a.866.866 0 00.327-.332v-1.275h-.938V15.3h2.325v2.646z'
}
export type SvgRegularIcon = keyof Icons['regular']


/** Path centralized in a 32px x 32px square. */
const large = {
  autos_search: 'M26.667 7.333a5.946 5.946 0 01-.934 3.187l4.16 4.147L28 16.56l-4.16-4.16a5.912 5.912 0 01-3.187.933 5.99 5.99 0 01-5.986-6c0-3.32 2.68-6 6-6s6 2.68 6 6zm-9.334 0a3.335 3.335 0 006.667 0 3.335 3.335 0 00-6.667 0zM7.333 10.667h5.331c.301.72.696 1.393 1.171 2H7.333l-2 6H24l-.995-2.986a8.61 8.61 0 001.89-.781l1.772 5.1v10.667c0 .733-.6 1.333-1.334 1.333H24c-.733 0-1.333-.6-1.333-1.333v-1.334h-16v1.334c0 .733-.6 1.333-1.334 1.333H4c-.733 0-1.333-.6-1.333-1.333V20l2.773-7.987a2.005 2.005 0 011.893-1.346zm0 14.666c-1.106 0-2-.893-2-2 0-1.106.894-2 2-2 1.107 0 2 .894 2 2 0 1.107-.893 2-2 2zm14.667 0c-1.107 0-2-.893-2-2 0-1.106.893-2 2-2s2 .894 2 2c0 1.107-.893 2-2 2z',
  /** 3 horizontal lines with a checkmark at the bottom right. */
  manage_list: 'M.5 1a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h29a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H.5zM0 8.5A.5.5 0 01.5 8h29a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5v-2zM15.354 25.253a.5.5 0 000 .707l3.533 3.534.002.002 1.414 1.414a.498.498 0 00.707 0l1.415-1.414.002-.003 8.483-8.483a.5.5 0 000-.707l-1.414-1.414a.5.5 0 00-.707 0l-8.132 8.132-3.182-3.182a.5.5 0 00-.707 0l-1.414 1.414zM.5 15a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h21a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H.5zM0 22.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5v-2z',
  /** Ellisis cut in half with a dash connecting both ends. */
  join: 'M8.5 8H14v3H8.5a5.5 5.5 0 100 11H14v3H8.5a8.5 8.5 0 010-17zm15 14H18v3h5.5a8.5 8.5 0 000-17H18v3h5.5a5.5 5.5 0 110 11zm-.5-7H9v3h14v-3z',
  /** A single file sheet. */
  files: 'M27.119 6.124L21.875.88A3 3 0 0019.756 0H7a3.01 3.01 0 00-3 3.006V29A3 3 0 007 32h18a3 3 0 003-3V8.249c0-.793-.319-1.562-.881-2.124zm-2.363 1.88H20V3.25l4.756 4.756zM7 29.002V3.006h10v6.498c0 .832.669 1.5 1.5 1.5H25v17.997H7z',
  /** A man using a tie. */
  // client: 'M9.334 9.15c-1.37-6.564 1.76-6.784 1.76-6.784s.472-.909 1.717-1.595c.833-.494 1.994-.877 3.526-.744.496.023.964.119 1.404.267a6.19 6.19 0 011.545.792 8.263 8.263 0 011.59 1.314c.754.771 1.427 1.768 1.718 3.013a5.625 5.625 0 01-.145 3.361c.563-.053 1.227.274.602 2.486-.457 1.616-.883 2.07-1.205 2.095-.36 2.322-1.635 5.339-5.664 5.648-3.965-.155-5.195-3.101-5.602-5.387-.328.223-.875.106-1.475-2.016-.49-1.726-.192-2.305.23-2.45zm20.18 16.82c.101-1.444-.02-4.366-2.55-5.01-3.392-.862-6.149-2.803-6.149-2.803l-2.823 12.799L18 31l-1.234-6.91c2.868-4.001-.76-3.845-.76-3.845s-3.626-.156-.759 3.845l-1.044 6.885.003-.02-3.008-12.798s-2.757 1.941-6.149 2.804c-2.529.643-2.651 3.562-2.549 5.006 0 0-.03 2.008.294 2.822 0 0 4.94 3.211 13.213 3.211l.016-.187-.004.187c8.273 0 13.213-3.21 13.213-3.21.323-.815.294-2.823.294-2.823l-.013.003z',
  /** 3 bars with tunner handles. */
  tune: 'M20.5 3a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V9h6.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H23V3.5a.5.5 0 00-.5-.5h-2zM2 6.5a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-14a.5.5 0 01-.5-.5v-2zm13 9a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-14a.5.5 0 01-.5-.5v-2zM2.5 15a.5.5 0 00-.5.5v2a.5.5 0 00.5.5H9v2.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5V15H2.5zM2 24.5a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-2zm17-3V24h10.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H19v2.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z',
  upload: 'M2 0a1 1 0 00-1 1v1a1 1 0 001 1h29a1 1 0 001-1V1a1 1 0 00-1-1H2zM8.015 14.793a1 1 0 000 1.414l.707.707a1 1 0 001.414 0L15 12.05V31a1 1 0 001 1h1a1 1 0 001-1V12.05l4.864 4.864a1 1 0 001.414 0l.707-.707a1 1 0 000-1.414l-7.778-7.778a1 1 0 00-1.414 0l-7.778 7.778z',
}

const icons = { large, regular, small }
export type Icons = typeof icons
type Sizes = keyof Icons

type SvgProps<T extends Sizes = 'regular'> =
  {
    className?: string
    on_click?: () => void
  } & (
    {
      size?: undefined
      icon: keyof Icons['regular']
    } | {
      size: T
      icon: keyof Icons[T]
    }
  )

type AllIcons = keyof (Icons['regular'] & Icons['large'] & Icons['small'])
const icon_viewboxes: { [T in AllIcons]?: string } = {
  chevron_left: '0 0 12 24',
  chevron_right: '0 0 12 24',
}

/**
 * Svg 1.5rem x 1.5rem for size regular, 2rem x 2rem for large.
 * Dimensions and colors to be changed using `> svg` at the parent element.
 */
export function Svg<T extends Sizes>(props: SvgProps<T>) {
  const { icon } = props
  const size_ = props.size || 'regular'
  const sized_icons = icons[size_]
  //@ts-ignore - TODO: ask about generic default discriminant keyof 
  const d = sized_icons[icon] as string
  const svg_styles = props.className || styles[`svg-${size_}`]
  //@ts-ignore - make it right eventually
  const viewbox = icon_viewboxes[icon] || sizes[size_] as string

  return (
    <svg
      onClick={props.on_click}
      viewBox={viewbox}
      className={svg_styles}>
      <path
        fillRule='evenodd'
        fill='currentColor'
        stroke='none'
        d={d}
      />
    </svg>
  )
}

