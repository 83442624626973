import React, { PropsWithChildren } from 'react'
import { compose } from 'helpers'
import { Donut, Svg, SvgRegularIcon } from '.'

import styles from './link-icon.module.scss'

type LinkIconProps = PropsWithChildren<{
  icon: SvgRegularIcon
  on_click?: React.MouseEventHandler<HTMLAnchorElement>
  show_spinner: boolean
  href: string | undefined
  inactive?: boolean
  tooltip: string
  className: string | undefined
}>
export function LinkIcon(props: LinkIconProps) {
  const { href } = props
  if (props.inactive || !href) return (
    <div className={compose(props.className, styles.inactive_link)}>
      <Svg icon={props.icon} />
      {!props.show_spinner ? null : <Donut spin className={styles.lil_donut} />}
      <span className={styles.tooltip}>{props.tooltip}</span>
    </div>
  )
  return (
    <a
      className={compose(props.className, styles.link)}
      href={href}
      onClick={props.on_click}
      target='_blank'
      rel='noopener noreferrer'>
      <Svg icon={props.icon} />
      <span className={styles.tooltip}>{props.tooltip}</span>
    </a>
  )
}