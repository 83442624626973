import { AppHandler } from 'app'

import { OnEdgeIntersection, OnVoidIntersection } from '../_event-types'
import { GridApiCore, EdgePosition, Edge, LastEdgePosition } from '../_data'
import { TablePhase } from 'components/table/_data'

/** Handle intersection event raised by row observer in one of the edges */
export const handle_edge_intersection: AppHandler<GridApiCore, OnEdgeIntersection> = (
  i, entry, edge
) => {
  const { expanding_edge, edge_position, previous_table_phase } = i.params
  // if already waiting for more data on this edge, do nothing
  if (expanding_edge === edge)
    return ['ok', 'IGNORE - EXPANDING', { i, entry, edge }]
  // if filling a void, ignore intersection
  if (previous_table_phase === TablePhase.REQUESTING_VOID)
    return ['ok', 'IGNORE - FILLING VOID', { i, entry, edge }]

  // if crossed the threshold => trigger event
  const current_position = get_entry_position(entry)
  const trigger_expansion = threshold_crossed(
    edge,
    edge_position[edge],
    current_position
  )
  const { set } = i
  set.edge_position({ ...edge_position, [edge]: current_position })

  if (!trigger_expansion) {
    return ['ok', 'IGNORE - POSITION', { i, entry, edge }]
  }

  set.expanding_edge(edge)
  i.props.on_threshold(edge, set.previous_table_phase)

  return ['ok', 'TRIGGER ON THRESHOLD', { i, entry, edge, current_position }]
}

/** Request data for the visible void. */
export const handle_void_intersection: AppHandler<GridApiCore, OnVoidIntersection> = (
  i, entry, void_row_idx
) => {
  const { set } = i
  const { visible_voids } = i.params
  const is_visible = visible_voids.includes(void_row_idx)
  const position = get_entry_position(entry)

  // void row is visible => add to visible voids list if not there yet
  if (position === 'inside') {
    if (!is_visible) set.visible_voids([...visible_voids, void_row_idx])
    const on_phase_change = (new_phase: TablePhase) => {
      set.previous_table_phase(new_phase)
      // if expanding edge was previously triggered, eliminate it
      if (i.params.expanding_edge) set.expanding_edge(null)
    }
    i.props.on_load_void(void_row_idx, on_phase_change)
    return ['ok', 'VOID INTERSECTED', { void_row_idx, position, is_visible }]
  }

  // row not visible, remove from visible voids list if there
  if (is_visible) {
    const new_visible_voids = visible_voids.filter((idx) => idx !== void_row_idx)
    set.visible_voids(new_visible_voids)
    return ['ok', 'REMOVE INTERSECTED VOID', { void_row_idx, position, is_visible }]
  }

  return ['ok', 'IGNORE VOID INTERSECTION', { void_row_idx, position, is_visible }]
}

/** Check void position when rehydrating. Scroll could be at an invaiid position 
 * on render (with a void on screen). Load data to fix this when it happens. */
export const handle_void_mount: AppHandler<GridApiCore, OnVoidIntersection> = (
  i, entry, void_row_idx
) => {
  // only react to mounting intersections when rehydrating the layout
  if (!i.params.is_rehydrating) return
  handle_void_intersection(i, entry, void_row_idx)
}

/**
 * Calculate target position in relation to its intersection window
 */
function get_entry_position(entry: IntersectionObserverEntry): EdgePosition {
  const { top, bottom } = entry.boundingClientRect

  if (bottom < 0) return 'above'

  // rootBounds is not guranteed to exist, use viewport as fallback
  const container_height = entry.rootBounds
    ? entry.rootBounds.height
    : document.documentElement.clientWidth
  if (top > container_height) return 'bellow'

  return 'inside'
}

/** 
 * Edge left its expected offset position, or an unknown one, and crossed into 
 * the screen or straing into the coposide position.
 */
function threshold_crossed(
  edge: Edge,
  last_position: LastEdgePosition,
  current_position: EdgePosition
) {
  const start_position: EdgePosition = edge === 'lower' ? 'bellow' : 'above'
  const oposite_position: EdgePosition = edge === 'upper' ? 'bellow' : 'above'

  return (
    (last_position === start_position || last_position === undefined) &&
    (current_position === 'inside' || current_position === oposite_position)
  )
}


