import { GridApiCore } from '../_data'
import { ApiHandler } from 'controllers/tied'
import { RowData } from 'components/table/_data'
import { parse_cell_value } from '../_cell-format'

/** User clicked any cell in a tbody element => raise on_click. */
export const handle_click: ApiHandler<GridApiCore<undefined>> = (
  i, { target }: React.MouseEvent<HTMLElement>
) => {
  const { on_click } = i.props
  if (!on_click) return
  //@ts-ignore: typescript does not recognize "getAttribute"
  const className = target.getAttribute("class")
  if (!className.includes('action')) return

  const { visible_columns_ids, fields } = i.props
  const { layout } = i.params

  //@ts-ignore: typescript does not recognize "closest" method
  const td_element = target.closest('td') as HTMLTableCellElement
  const field_idx = td_element.cellIndex - 1
  const field_id = visible_columns_ids[field_idx]

  //@ts-ignore: typescript does not recognize "closest" method
  const tr_element = target.closest('tr') as HTMLTableRowElement
  const { rowIndex } = tr_element
  const header_rows_count = 2 //header + filters
  const first_row_index = layout.top_void_heights.length
  const row_idx = rowIndex - header_rows_count - first_row_index

  const { data_blocks } = layout
  // retrieve data from data block where it is at
  const second_block_start = data_blocks[0].length
  const block = row_idx < second_block_start ? 0 : 1
  const row_in_block = block === 0 ? row_idx : row_idx - second_block_start
  const row_data = data_blocks[block][row_in_block]
  const value = row_data[field_id]

  const { handle_update_row } = i.makeApi(true)
  const update_row = (new_row_data: RowData) => {
    handle_update_row(block, row_in_block, new_row_data)
  }

  const parsed_row = visible_columns_ids.reduce((prev, field_id) => ({
    ...prev,
    [field_id]: parse_cell_value(fields[field_id], row_data[field_id], row_data)
  }), {})

  const event = { value, row_data, field_id, parsed_row, update_row }
  on_click(event)
}

export const handle_update_row: ApiHandler<GridApiCore<undefined>> = (
  i, block: number, row_in_block: number, new_row_data: RowData
) => {
  const { data_blocks } = i.params.layout
  data_blocks[block][row_in_block] = new_row_data
  i.set.update(Date.now())
}