import { Optional } from 'utility-types'
import { useRef, useState } from 'react'
import { affix, useSubscriptionEffect } from 'helpers'

/** Props returned from usePopupMenu, to be used by the child popup cmponent. */
export type PopupHookProps =
  Optional<
    ReturnType<typeof usePopupMenu>['menu_props'],
    'hide'
  >

/**
 * Detect when click outside the popup happens and calls 
 * on_click_anywhere event. 
 */
export function useClickAnywhere(props: PopupHookProps) {
  useSubscriptionEffect(() => {
    if (!props.hidden) {
      document.addEventListener('click', props.on_click_anywhere)
      return () => {
        document.removeEventListener('click', props.on_click_anywhere)
      }
    }
  }, [props.hidden])
}

export type UsePopupMenuProps = {
  styles?: StylesObject,
  parent_class_name?: string,
  /** If true, only closes when `hide` function is called. */
  keep_open?: boolean
  /** Called when popup is colsed on click outside or toggle_visibility. */
  on_hide?: () => void
}
/**
 * Produce properties to be added to the poup menu and its parent.
 * This is necessary for proper visibility toggle handling. The `styles` prop 
 * must have a class named as the value in `parent_class_name` that will be 
 * applied to the parent. The sufffix `POPUP_ACTIVE` will be added to 
 * `parent_class_name` if the popup is being shown.
 */
export function usePopupMenu(config = {} as UsePopupMenuProps) {
  const { styles, parent_class_name, on_hide } = config
  const parent_clicked_ref = useRef<boolean>(false)

  const [hidden, set_hidden] = useState(true)

  /** Hide the popup menu. */
  const hide = () => {
    if (on_hide) on_hide()
    set_hidden(true)
  }

  const toggle_visibility = () => {
    const new_hidden_state = !hidden
    if (new_hidden_state) {
      // if keep open is true, calling `hide` or clicking outside must be the
      // only ways to close the popup
      if (config.keep_open) return
      if (on_hide) on_hide()
    }
    set_hidden(!hidden)
  }

  const handle_click = () => {
    if (!hidden) {
      // register click on parent or one of its descandants
      // so cilck outside is not trigered.
      parent_clicked_ref.current = true
    }
    toggle_visibility()
  }

  const handle_key_down = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      toggle_visibility()
    }
  }

  const handle_click_anywhere = () => {
    if (parent_clicked_ref.current) {
      // click was not outside => do nothing
      parent_clicked_ref.current = false
      return
    }
    // click was outside => hide menu
    hide()
  }

  const parent_styles = parent_class_name && affix(
    parent_class_name,
    !hidden,
    'POPUP_ACTIVE'
  )

  const parent_events = { onKeyDown: handle_key_down, onClick: handle_click }
  const parent_props = !parent_styles || !styles
    ? parent_events
    : { className: styles[parent_styles], ...parent_events }

  return {
    parent_props,
    menu_props: {
      hide,
      /** State indicating if menu should be shown. */
      hidden,
      /** User clicked outside the menu. */
      on_click_anywhere: handle_click_anywhere
    }
  }
}

/** Styles from a .module.scss file.  */
type StylesObject = {
  readonly [key: string]: string;
}