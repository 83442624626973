import React from 'react'
import { A } from 'hookrouter'
import { take, affix, usePopup } from 'helpers'
import { Svg, Icons } from 'components'
import * as feature_groups from 'feature-groups'
import { broadcast_user } from 'app/login/_broadcasts'
import { RouteParams, FeatureGroupsKeys } from 'app/_data'

import styles from './side-bar.module.scss'
import { useSelection } from 'controllers/tied'

type SideBarProps = {
  route: RouteParams
}

/** Map with all features properties. */
const features_props = (Object.keys(feature_groups) as FeatureGroupsKeys[])
  .reduce((prev_groups, group_key) => {
    const group = feature_groups[group_key]
    const feature_keys = Object.keys(group) as (keyof typeof group)[]
    return {
      ...prev_groups,
      [group_key]: feature_keys.map((feature_key) => ({
        feature_key,
        ...take(group[feature_key], 'icon', 'title')
      }), [])
    }
  }, {}) as { [index: string]: FeatureProps[] }


export function SideBar(props: SideBarProps) {
  const { button_events, popup_events, is_hidden } = usePopup()
  const { route } = props
  const { feature_group } = route
  const features = features_props[feature_group]

  const user = useSelection(broadcast_user, 'SideBar')

  if (!user || features.length < 2) return null

  const side_bar_class = affix('side_bar', !is_hidden, 'OPEN')

  return (
    <>
      <button
        {...button_events}
        className={styles.hamburger_menu}>
        <Svg icon='menu' />
      </button>
      <nav
        {...popup_events}
        className={styles[side_bar_class]}>
        {
          features.map(({ feature_key, icon, title }) => (
            <FeatureButton
              icon={icon}
              title={title}
              feature_key={feature_key}
              feature_group={feature_group}
              active={feature_key === route.feature}
              key={`${feature_group}.${feature_key}`}
            />
          ))
        }
      </nav>
    </>
  )
}

function FeatureButton(props: ButtonProps) {
  const feature_button_css = affix('feature_button', props.active, 'ACTIVE')
  return (
    <A
      className={styles[feature_button_css]}
      href={`/${props.feature_group}/${props.feature_key}`}>
      <h4>
        <Svg size='large' icon={props.icon} className={styles.icon} />
        {props.title}
      </h4>
      <p>
        Consulta dívidas e restrições de veículos, capturando a tela dos órgãos consultados.
      </p>
    </A>
  )
}

type ButtonProps = {
  title: string
  active: boolean
  feature_key: string
  icon: keyof Icons['large']
  feature_group: FeatureGroupsKeys
}

type FeatureProps = {
  feature_key: string,
  icon: keyof Icons['large'],
  title: string
}