import { SharedStoredStateConfig } from 'controllers/tied'
import { GqlListFornecedoresSituacaoVeicularData } from './_operations'

/** Raw list of all available services. */
export const services_list_config: SharedStoredStateConfig<GqlListFornecedoresSituacaoVeicularData> = {
  initialState: [],
  storageKey: 'shared_services_list',
  storageVersion: 0
}

export type SelectedScraperNames = string[]
/** List of scraper names selected by the user, to be used in the next new verificacao. */
export const selected_scraper_names_config: SharedStoredStateConfig<SelectedScraperNames> = {
  initialState: [],
  storageKey: 'shared_selected_scraper_names',
  storageVersion: 0
}