import React, { useEffect, useRef, useState } from 'react'
import { format_currency, useMount, useSyncEffect } from 'helpers'
import { SimpleTable, LabeledValue } from 'components'
import { SimpleTableColumns } from 'components/simple-table/_data'
import { format } from 'components/simple-table/formatters'
import { LinkIcon } from 'components/simple-table/renderers'

import { GqlListDebitosData, produce_list_debitos_ops } from './_operations'
import styles from './list-debitos.module.scss'
import { pushBroadcast } from 'controllers/tied'
import { broadcast_selected_debitos_value } from './_broadcasts'

type Data = GqlListDebitosData[number]
const table_columns: SimpleTableColumns<Data> = {
  nome_fornecedor: {
    header: 'Órgão Consultado'
  },
  tipo_debito: {
    header: 'Tipo',
    format_value: (value: Data['tipo_debito']) => {
      if (value === 'IPVA') return value
      return format.snake_to_capitalized(value)
    }
  },
  ano_referencia: {
    header: 'Ano'
  },
  valor: {
    header: 'Valor',
    format_value: format.currency
  },
  data_vencimento: {
    header: 'Vencimento',
    format_value: format.day
  },
  boleto_file_url: {
    header: 'Boleto',
    renderer: LinkIcon,
    derive_icon: () => 'file_invoice'
  },
  parcela: {
    header: 'Parcela',
    centered: true
  },
  status_original: {
    header: 'Status'
  },
  flag_somar_ao_total: {
    hidden: true
  }
}

type ListDebitosProps = {
  id_verificacao: string
  is_hidden?: boolean
  pending: boolean | undefined
}

export function ListDebitos(props: ListDebitosProps) {
  const debitos = useDebitos(props.id_verificacao)
  const [
    selected_debitos_currency,
    selected_debitos,
    handle_selection_change
  ] = useDebitosSelection(debitos)

  if (props.is_hidden) return null
  const is_empty = !debitos?.length

  const empty_table_message = props.pending
    ? 'Aguarde, consolidando a tabela de dívidas...'
    : 'Não há outras dívidas.'

  return (<>
    {is_empty ? null : (
      <LabeledValue
        label='Total selecionado'
        value={selected_debitos_currency}
        className={styles.labeled_total}
        value_color='danger'
      />
    )}

    <SimpleTable
      className={styles.table_wrapper}
      data={debitos}
      columns={table_columns}
      hide_on_empty
      empty_table_message={empty_table_message}
      empty_message_class={styles.empty_table_messagebox}
      item_name='debito'
      selectable_rows
      initial_selected_rows_ids={selected_debitos}
      on_selection_change={handle_selection_change}
    />
  </>)
}


function useDebitos(id_verificacao: string) {
  const [debitos, set_taxas] = useState<GqlListDebitosData>()
  useMount(() => {
    const operations = produce_list_debitos_ops()
    const variables = { id_verificacao }
    operations.list_debitos({
      variables,
      on_completed: set_taxas
    })
  })
  return debitos
}


function useDebitosSelection(debitos: GqlListDebitosData | undefined) {
  type SelectedDebitos = { [id_debito: string]: boolean }
  const selected_debitos_ref = useRef<SelectedDebitos>()
  const [selected_debitos_value, set_selected_debitos_value] = useState<number>(0)
  const selected_debitos_currency = format_currency(selected_debitos_value)



  // initialize selection
  useSyncEffect(() => {
    if (!debitos) return
    const initial_selected_debitos = debitos.reduce(
      (prev, { id, flag_somar_ao_total }) => (
        { ...prev, [id]: flag_somar_ao_total === true }
      ), {} as SelectedDebitos
    )
    selected_debitos_ref.current = initial_selected_debitos
    rehydrate_selected_debitos_value()
  }, debitos)



  useEffect(() => {
    // broadcast total to be shown elsewhere
    pushBroadcast(broadcast_selected_debitos_value, selected_debitos_value)
  }, [selected_debitos_value])

  useMount(() => () => {
    // clear broadcast on unmount
    pushBroadcast(broadcast_selected_debitos_value, 0)
  })



  function rehydrate_selected_debitos_value() {
    if (!debitos) return
    const selected_debitos = selected_debitos_ref.current
    const new_selected_debitos_value = !selected_debitos ? 0 :
      debitos.reduce((prev, debito) => {
        if (!selected_debitos[debito.id]) return prev
        return prev + (debito.valor || 0)
      }, 0)
    set_selected_debitos_value(new_selected_debitos_value)
  }


  /** Recalculate totals when selection changes */
  const handle_selection_change = (row_id: string, is_selected: boolean) => {
    const selected_debitos = selected_debitos_ref.current
    const new_selected_debitos = {
      ...selected_debitos, [row_id]: is_selected
    }
    selected_debitos_ref.current = new_selected_debitos
    rehydrate_selected_debitos_value()
  }



  return [
    selected_debitos_currency,
    selected_debitos_ref.current,
    handle_selection_change
  ] as const
}
