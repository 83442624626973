import { Svg } from 'components'
import React, { useState } from 'react'
import { affix, is_equal } from 'helpers'
import { ModalBehavior } from 'app/modal/_data'
import { OkCancelButtons } from 'components/basic'

import { ColumnsList, Fields } from '../_data'
import styles from './data-visibility-modal.module.scss'

/** Properties required to render the data-visibility modal. */
export type DataVisibilityProps =
  ModalBehavior &
  {
    columns_list: ColumnsList
    visible_columns_ids: ColumnsList
    fields: Fields
    /** User changed the list of visible_columns_ids. */
    on_change: (visible_columns_ids: ColumnsList) => void
  }

export function DataVisibility(props: DataVisibilityProps) {
  const { fields, columns_list } = props
  const [selected_ids, set_selected_ids] = useState(props.visible_columns_ids)

  const toggle_column = (target_id: string) => () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
    const col_idx = selected_ids.indexOf(target_id)
    if (col_idx > -1) {
      // column is visible => remove it.
      const new_state = selected_ids.filter(col_id => col_id !== target_id)
      set_selected_ids(new_state)
      return
    }
    // column not visible => create new selection, keeping the original order
    const new_state = columns_list.filter((col_id) => (
      col_id === target_id || selected_ids.includes(col_id)
    ))
    set_selected_ids(new_state)
  }

  const handle_ok = () => {
    if (!is_equal(props.visible_columns_ids, selected_ids)) {
      props.on_change(selected_ids)
    }
    props.on_close_request()
  }

  return (
    <div>
      <h2>Visibilidade dos campos</h2>
      <ul className={styles.fields_list}>
        {columns_list.map(col_id => {
          const field = fields[col_id]
          const is_active = selected_ids.includes(col_id)
          const item_css = affix('item', is_active, 'ACTIVE')
          return (
            <li
              key={col_id}
              className={styles[item_css]}>
              <button onClick={toggle_column(col_id)}><Svg icon='cols' /> </button>
              {field.label}<span>{col_id}</span>
            </li>
          )
        })}
      </ul>
      <OkCancelButtons
        ok_label='Confirmar'
        on_ok={handle_ok}
        on_cancel={props.on_close_request}
      />
    </div>
  )
}