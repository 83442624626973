import React from 'react'
import { useApi, useSelector } from 'controllers/tied'
import { Button, Card, Text, Logo } from 'components'

import * as api_handlers from './_api'
import styles from './login.module.scss'
import { broadcast_user } from './_broadcasts'
import { initialState, initialParams, LoginApiCore, LoginProps, storedState } from './_data'


export function Login(props: LoginProps) {
  const api_core: LoginApiCore = {
    props,
    initialState,
    initialParams,
    storedState
  }

  const { api, state } = useApi(api_core, api_handlers, 'Login')
  useSelector(broadcast_user, state)

  if (props.is_logged_in) return null

  return <>
    <div className={styles.flex_container}>
      <div className={styles.flex_edges}>
        <Logo color='BLUE' wrapper_class={styles.logo} parent='login' />
      </div>
      {!props.token_expired ? null : (
        <div className={styles.message_box}>
          Sua sessão expirou. É preciso logar novamente.
        </div>
      )}
      <Card wrapper_class={styles.login_card_wrapper}>
        <Text
          className={styles.textbox}
          on_change={api.set_email}
          color='BORDERED'
          focus_on_mount
          label='Email'
          type='email'
        />
        <Text
          on_change={api.set_password}
          className={styles.textbox}
          color='BORDERED'
          type='password'
          label='Senha'
        />

        <ErrorBox error_message={state.error_message} />

        <Button
          onClick={api.handle_login}
          className={styles.button}>
          Entrar
        </Button>
      </Card>
      <div className={styles.flex_edges}></div>
    </div>
  </>
}

/** If error message is not undefined, shows it in a re box. */
function ErrorBox({ error_message }: { error_message: string | undefined }) {
  if (!error_message) return null
  return (
    <div className={styles.error_box}>{error_message}</div>
  )
}