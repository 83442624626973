import React from 'react'
import { ModalBehavior } from '../_data'

import styles from './message.module.scss'
import { Button, OkCancelButtons } from 'components'

export type MessageProps =
  ModalBehavior &
  {
    title: string
    /** Message shown above the text input. */
    message: string
    /** Label of the ok button. */
    ok_label: string
    /** Label of the cancel button. (default = `Cancelar`).
     * If on_cancel or cancel label is provided => cancel button is shown. */
    cancel_label?: string
    /** User clicked ok, after writing something. */
    on_ok?: () => void
    /** User clicked the cancel button. 
     * If on_cancel or cancel label is provided => cancel button is shown. */
    on_cancel?: () => void
  }

/** One single text box, with ok and cancel buttons. */
export function Message(props: MessageProps) {
  return (
    <div className={styles.message_modal}>
      <h2>{props.title}</h2>
      <p>{props.message}</p>
      <ActionButtons {...props} />
    </div>
  )
}

/** 
 * Show Ok only, or Ok/ Cancel buttons.
 * If on_cancel or cancel label is provided => cancel button is shown.
 */
function ActionButtons(props: MessageProps) {
  const handle_ok = () => {
    if (props.on_ok) props.on_ok()
    props.on_close_request()
  }

  const { on_cancel, cancel_label } = props

  if (!(on_cancel || cancel_label)) {
    return (
      <Button onClick={handle_ok}>
        {props.ok_label}
      </Button>
    )
  }


  const handle_cancel = () => {
    if (on_cancel) on_cancel()
    props.on_close_request()
  }

  return (
    <OkCancelButtons
      on_ok={handle_ok}
      ok_label={props.ok_label}
      on_cancel={handle_cancel}
      cancel_label={cancel_label}
    />
  )
}