import { format_day, format_month, format_integer, format_datetime } from 'helpers'

import { Field, RowData, CalculatedField } from '../_data'

/** Format data's raw value into what should be shown to the user according 
 * to the field's cell_type property. */
export function parse_cell_value(
  field: Field | CalculatedField, value: unknown, row_data: RowData
) {
  if (value === null) return null

  // handle calculated field, if this is the case.
  const value_ = value === undefined && field.parse_value
    ? field.parse_value!(row_data)
    : value

  if (value_ === undefined) return null

  switch (field.cell_type) {
    case 'Currency':
      return format_integer(value_ as number)

    case 'Day':
      return format_day(value_ as string)

    case 'DateTime':
      return format_datetime(value_ as string)

    case 'Month':
      return format_month(value_ as string)

    case 'Options':
      return field.config.dictionary[value_ as string]

    case 'Icon':
      return parse_icon(value)

    default:
      return value_ as string
  }
}

function parse_icon(value: unknown) {
  if (value === true) return 'true'
  if (value === false) return 'false'
  return value as string
}