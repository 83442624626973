import React, { useEffect, useState } from 'react'
import { navigate } from 'hookrouter'
import { ApolloProvider } from '@apollo/react-hooks'

import { Modal } from './modal'
import { TopBar } from './top-bar'
import { SideBar } from './side-bar'
import { useRouter } from './_router'
import apollo_client from './_client'
import styles from './app.module.scss'
import { Login } from './login'
import login_styles from './login/login.module.scss'
import { Card, Logo } from 'components'
import { produce_app_ops } from './_operations'

/** 
 * Any attempt to navigate into an invalid rout will be redirected to 
 * this one.
 */
const DEFAULT_ROUTE = '/search/nada-consta'

export function App() {
  const token = localStorage.getItem('auth-token')
  const token_expired = localStorage.getItem('token-status') === 'expired'
  const is_logged_in = (
    token !== null
    && token.length > 0
    && !token_expired
  )

  const is_in_maintenance = useMaintenance()

  if (is_in_maintenance) return <MaintenanceMessage />

  return (
    <ApolloProvider client={apollo_client}>
      <AppLayout is_logged_in={is_logged_in} />
      <Login is_logged_in={is_logged_in} token_expired={token_expired} />
      <Modal />
    </ApolloProvider>
  )
}

function MaintenanceMessage() {
  return (
    <div className={login_styles.flex_container}>
      <div className={login_styles.flex_edges}>
        <Logo color='BLUE' wrapper_class={login_styles.logo} parent='login' />
      </div>
      <Card
        wrapper_class={login_styles.login_card_wrapper}
        card_class={styles.maintenance_card}>
        Sistema em manutenção, voltaremos em breve.
      </Card>
      <div className={login_styles.flex_edges}></div>
    </div>
  )
}

function useMaintenance() {
  const [result, set_result] = useState<boolean>()
  const { is_situacao_veicular_inactive } = produce_app_ops()
  useEffect(() => {
    if (result !== undefined) return
    is_situacao_veicular_inactive({ on_completed: set_result })
  }, [result, set_result])
  return result
}

/** Renders the proper layout based on the current route. */
function AppLayout(props: { is_logged_in: boolean }) {
  const route_match = useRouter()
  if (!route_match) {
    handle_invalid_route()
    return null
  }
  const { Feature, route } = route_match

  if (!props.is_logged_in) return null

  return <>
    <TopBar />
    <SideBar route={route} />
    <Feature
      route={route}
      className={styles.feature}
    />
  </>
}

/** If current route is invalid for the current state of any component, 
 * it should call this function. */
function handle_invalid_route() {
  navigate(DEFAULT_ROUTE)
}