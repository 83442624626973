import React from 'react'
import { LogoStyle, LogoSVGProps } from './_types'

export const logo_style: LogoStyle = {
  login: { width: '15rem', height: '3rem' },
  top_bar: { width: '7.5rem', height: '1.5rem' }
}

export const top_bar_wrapper_css = undefined

export function LogoSVG(props: LogoSVGProps) {
  const size = logo_style[props.parent]
  return (
    <svg className={props.className} style={size} viewBox='0 0 120 24'>
      <path d='M6 11a1 1 0 00-1 1v6a1 1 0 001 1h12a1 1 0 001-1v-6a1 1 0 00-1-1H6z' /><path fillRule='evenodd' clipRule='evenodd' d='M0 2a2 2 0 012-2h20a2 2 0 012 2v20a2 2 0 01-2 2H2a2 2 0 01-2-2V2zm3 1h2v6.05c.162-.033.329-.05.5-.05h13a2.5 2.5 0 012.5 2.5v7a2.5 2.5 0 01-2.5 2.5h-13A2.5 2.5 0 013 18.5V3zM69 3h2v6.05c.162-.033.329-.05.5-.05h13a2.5 2.5 0 012.5 2.5v7a2.5 2.5 0 01-2.5 2.5h-13a2.5 2.5 0 01-2.5-2.5V3zm3 8a1 1 0 00-1 1v6a1 1 0 001 1h12a1 1 0 001-1v-6a1 1 0 00-1-1H72z' /><path d='M115 3h-2v15.5a2.5 2.5 0 002.5 2.5h4.5v-2h-4a1 1 0 01-1-1v-7h5V9h-5V3z' /><path fillRule='evenodd' clipRule='evenodd' d='M91 11.5A2.5 2.5 0 0193.5 9h13a2.5 2.5 0 012.5 2.5v7a2.5 2.5 0 01-2.5 2.5h-13a2.5 2.5 0 01-2.5-2.5v-7zm2 .5a1 1 0 011-1h12a1 1 0 011 1v6a1 1 0 01-1 1H94a1 1 0 01-1-1v-6zM47 11.5A2.5 2.5 0 0149.5 9h13a2.5 2.5 0 012.5 2.5v7a2.5 2.5 0 01-2.5 2.5h-13a2.5 2.5 0 01-2.5-2.5v-7zm2 .5a1 1 0 011-1h12a1 1 0 011 1v6a1 1 0 01-1 1H50a1 1 0 01-1-1v-6z' /><path d='M30.5 9a2.5 2.5 0 00-2.5 2.5V21h2v-9a1 1 0 011-1h12V9H30.5z' />
    </svg>
  )
}