import React, { PropsWithChildren, useState } from 'react'
import { Card } from 'components/card'
import { trace } from 'controllers/tracer'

import styles from './multi-tab-card.module.scss'
import { affix, child_is_component } from 'helpers'

export type TabsConfig = {
  [tab_key: string]: {
    label: string
    hidden?: boolean
    show_spinner?: boolean
  }
}

type MultiTabCardProps = PropsWithChildren<{
  className?: string
  render_hidden_tabs?: boolean
  tabs_config: TabsConfig
}>

export function MultiTabCard(props: MultiTabCardProps) {
  const { children, tabs_config } = props
  const tab_keys = Object.keys(tabs_config) as (keyof typeof tabs_config)[]
  const [active_tab, set_active_tab] = useState<number | undefined>(0)

  if (!Array.isArray(children))
    throw new Error('Multi tab children must be an array of components')

  const [summary, ...tabs] = children
  const { length } = tab_keys
  const tabs_not_ready = tabs.length < length
  if (tabs.length > length)
    throw new Error(`MultiTabCard: too many tabs. Expected ${length}, got ${tabs.length}.`)

  const handle_tab_click = (tab_idx: number) => () => {
    set_active_tab(tab_idx)
  }

  if (process.env.NODE_ENV === 'development')
    trace('multi-tab-card', 'render', { children, summary, tabs, tabs_config, tabs_not_ready })

  const shown_tabs = tab_keys.filter(t => !tabs_config[t].hidden)

  return (
    <Card no_padding wrapper_class={props.className}>
      {summary}

      {tabs_not_ready ? null : (<>
        <div className={styles.tabs_headers_wrapper}>
          {shown_tabs.map((tab_key, tab_idx) => {
            const is_hidden = active_tab === tab_idx
            const nav_css = affix('navigation', is_hidden && 'ACTIVE')
            return (
              <nav
                className={styles[nav_css]}
                onClick={handle_tab_click(tab_idx)}
                key={tab_key}>
                {tabs_config[tab_key].label}
              </nav>
            )
          })}
        </div>

        <Tabs
          tabs={tabs}
          render_hidden_tabs={props.render_hidden_tabs}
          active_tab={active_tab} />

      </>)}
    </Card>
  )
}

type TabsProps = {
  tabs: React.ReactNode[]
  active_tab: number | undefined
  render_hidden_tabs: boolean | undefined
}
function Tabs(props: TabsProps) {
  const { active_tab, render_hidden_tabs } = props
  return <>{props.tabs.map((tab, tab_idx) => {
    const is_hidden = active_tab !== tab_idx

    if (!child_is_component(tab))
      return is_hidden ? null : tab

    // do not render hidden ones, but already give the visible tab a stable key
    if (!render_hidden_tabs)
      return is_hidden ? null : React.cloneElement(tab, { is_hidden, key: tab_idx })

    // render hidden tabs, so they pre-load their data if needed
    return React.cloneElement(tab, { is_hidden, key: tab_idx })
  })}</>
}