import { Text } from '../text'
import { TextProps } from '../text/_data'
import { PopupMenuProps, PopupMenu } from '../popup'
import React, { useState, useRef, useEffect } from 'react'
import { move_in_range, normalize, AllOrNothing, drop, Maybe } from 'helpers'

export type AutoCompleteProps<T> = {
  value_prop: keyof T
  initial_value?: Maybe<T[keyof T]>
} &
  TextProps<string | undefined> &
  Pick<PopupMenuProps<T>,
    'label_prop' |
    'on_select' |
    'options'
  > &
  AllOrNothing<{
    /** If existant, always shows a final add new item option. */
    new_item_label: string
    /** User clicked the new item label. */
    on_new_item: () => void
  }>

type EditableAutoCompleteProps<T> = AutoCompleteProps<T> & {
  options: T[]
}
/** Remove all props that are not used by the Text component. */
function drop_non_text_props<T>(props: AutoCompleteProps<T>) {
  return drop(props,
    'options',
    'on_select',
    'label_prop',
    'on_new_item',
    'new_item_label',
  )
}

function get_initial_text<T>(props: Pick<EditableAutoCompleteProps<T>,
  'value_prop'
  | 'options'
  | 'label_prop'
  | 'initial_value'
  | 'initial_text'
>
): string | undefined {
  const { initial_text } = props
  if (initial_text !== undefined) return initial_text

  const { value_prop, initial_value } = props
  const selected_option = props.options.find(o => o[value_prop] === initial_value)
  if (!selected_option) return undefined

  const selected_label = selected_option[props.label_prop]
  const result = selected_label === null
    ? undefined
    : selected_label as unknown as string
  return result
}

export function AutoComplete<T>(props: AutoCompleteProps<T>) {
  const { options } = props
  if (!options) return null

  return <EditableAutoComplete {...props} options={options} />
}

function EditableAutoComplete<T>(props: EditableAutoCompleteProps<T>) {
  const { parent_props, menu_props, selection } = usePopupText(props)

  /** Get text from current selection. */
  const selected_text = selection
    ? selection[props.label_prop] as unknown as string
    : get_initial_text(props)

  const text_props = drop_non_text_props(props)
  return (
    <Text
      {...text_props}
      {...parent_props}
      key={selected_text}
      initial_text={selected_text}>

      <PopupMenu
        {...menu_props}
        align='LEFT'
        label_prop={props.label_prop}
      >
        {!('new_item_label' in props) ? null : (
          <button onClick={props.on_new_item}>
            {props.new_item_label}
          </button>
        )}
      </PopupMenu>
    </Text>
  )
}

function usePopupText<T>(props: EditableAutoCompleteProps<T>) {
  /** Used to infer when clicked outside. */
  const parent_clicked_ref = useRef<boolean>(false)
  /** Used to infer when blur means the autocomplete lost its focus. */
  const blur_ref = useRef<boolean>(false)
  /** Currently highlighted item in popup. */
  const [highlight, set_highlight] = useState(-1)
  /** Currently selected option. */
  const [selection, set_selection] = useState<T>()
  /** Popup menu is hidden. */
  const [hidden, set_hidden] = useState<boolean>(true)
  /** Current filtered selection. */
  const [options, set_options] = useState(props.options)

  // Keep options list up-to-date
  useEffect(() => { set_options(props.options) }, [props.options])

  /** Update selection. */
  const handle_select = (option: T, idx: number) => {
    set_options(props.options)
    set_selection(option)
    set_hidden(true)
    if (props.on_select) props.on_select(option, idx)
  }

  /** Prevent hiding on click anywhere event. */
  const handle_parent_click = () => {
    if (!hidden) {
      // register click on parent or one of its descendants
      // so cilck outside is not trigered.
      parent_clicked_ref.current = true
    }
  }

  /** Hide options if clicking outside. */
  const handle_click_anywhere = () => {
    if (parent_clicked_ref.current) {
      // click was not outside => do nothing
      parent_clicked_ref.current = false
      return
    }
    // click was outside => hide menu
    set_hidden(true)
  }

  /** Show options on focus. */
  const handle_parent_focus = () => {
    set_hidden(false)
  }

  /** Move popup selection on arrows up/dpwn and hide popup on tab. */
  const handle_parent_key_down = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const direction =
      e.key === 'ArrowDown' ? 1
        : e.key === 'ArrowUp' ? -1
          : undefined

    if (direction === undefined) return

    const new_highlight = move_in_range(highlight, options.length, direction)
    set_highlight(new_highlight)
  }

  /** select current highlighted and reset text input. */
  const handle_parent_return = () => {
    if (highlight > -1) {
      const option = options[highlight]
      handle_select(option, highlight)
    }
  }

  /** Remove highlight when focus moves aways from textbox, as Enter will 
   * not select it anymore. */
  const handle_parent_blur = () => {
    set_highlight(-1)
  }

  /** Filter options as user types. */
  const handle_text_change = (text: string) => {
    const { label_prop } = props
    const normalized_text = normalize(text)
    // filter options that contain `text`
    const new_options = props.options.filter(option => {
      const label = option[label_prop] as unknown as string
      const normalized_label = normalize(label)
      const result = normalized_label.includes(normalized_text)
      return result
    })
    set_options(new_options)
  }

  /**
   * When user is navigating through the popup children, blur event from a 
   * child will be folowed by a focus event from another. So, we register the
   * blur and wait a little bit. If no focus event is raised we know the
   * component lost focus, so we hide the popup. 
   */
  const handle_parent_wrapper_blur = () => {
    blur_ref.current = true
    setTimeout(() => {
      if (blur_ref.current) set_hidden(true)
    }, 200)
  }
  /** Child was focused => signal the component did not loose focus. */
  const handle_parent_wrapper_focus = () => {
    blur_ref.current = false
  }

  return {
    selection,

    parent_props: {
      wrapper_props: {
        onBlur: handle_parent_wrapper_blur,
        onFocus: handle_parent_wrapper_focus,
      },
      on_blur: handle_parent_blur,
      onClick: handle_parent_click,
      onFocus: handle_parent_focus,
      on_change: handle_text_change,
      on_return: handle_parent_return,
      onKeyDown: handle_parent_key_down,
    },

    menu_props: {
      hidden,
      options,
      highlight,
      on_select: handle_select,
      on_click_anywhere: handle_click_anywhere,
    }
  }
}