import * as Types from '../../../../../../../../codegen/gql-types';

import { produce_mutation } from 'app'
import gql from 'graphql-tag';

/**
 * 
 * 
 * 
 * 
 * 
 *         DO NOT EDIT THIS FILE - ANY CHANGES WILL BE LOST
 * 
 * 
 * 
 * 
 * 
 *    This file is automatically generated on every build by codegen plugins
 *    as defined in codegen.yml
 * 
 *    Its contents are defined by its sibling file _operations.graphql.
 * 
 * 
 * 
 */


 const resolicitar_verificacao_doc = gql`
    mutation resolicitar_verificacao($id_cliente: String!, $id_verificacao: String!, $lista_fornecedor_credenciais: [FornecedorCredencialInput!]!) {
  resolicitar_verificacao(id_cliente: $id_cliente, id_verificacao: $id_verificacao, lista_fornecedor_credenciais: $lista_fornecedor_credenciais) {
    id
    status
  }
}
    `;

/** Produce all of operations functions for error-message. */
function error_message_ops_factory() {
  const resolicitar_verificacao = produce_mutation<GqlResolicitarVerificacaoData,GqlResolicitarVerificacaoVariables>(resolicitar_verificacao_doc)
  
  return {
    resolicitar_verificacao,
  }
}
/** Auto-generated operations functions for error-message. */
export type ErrorMessageOps = ReturnType<typeof error_message_ops_factory>

let memoized_operations: ErrorMessageOps
/** Produces and memoizes all error-message operations. */
export function produce_error_message_ops() {
  if (!memoized_operations) {
    memoized_operations = error_message_ops_factory()
  }
  return memoized_operations
}

/** Types returned as the final result from operations execution.  */

export type GqlResolicitarVerificacaoData = GqlResolicitarVerificacao['resolicitar_verificacao']

export type GqlResolicitarVerificacaoVariables = Types.Exact<{
  id_cliente: Types.Scalars['String'];
  id_verificacao: Types.Scalars['String'];
  lista_fornecedor_credenciais: Array<Types.GqlFornecedorCredencialInput> | Types.GqlFornecedorCredencialInput;
}>;


export type GqlResolicitarVerificacao = { resolicitar_verificacao: Pick<Types.GqlVerificacao, 'id' | 'status'> };
