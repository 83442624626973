import { ApiHandler, ApiInterface, select } from 'controllers/tied'
import { drop, parse_apollo_error, StringObject } from 'helpers'
import { broadcast_user } from 'app/login/_broadcasts'

import { DataEntryApiCore } from './_data'

type ThisApiCore = DataEntryApiCore<StringObject>

export const change_selected_entry: ApiHandler<ThisApiCore> = (i) => {
  const { set, props } = i
  const { selected_entry_id } = props
  if (!selected_entry_id) {
    set.error()
    set.entry_id()
    set.entry()
    return
  }

  const { get_entry } = props.produce_entry_ops()
  const api = i.makeApi(true)
  get_entry({
    variables: { entry_id: selected_entry_id },
    on_completed: (new_entry) => {
      const clean_entry = drop(new_entry, '__typename', 'id')
      api.set_new_entry(clean_entry, selected_entry_id)
    }
  })
}

export const set_new_entry: ApiHandler<ThisApiCore> = (
  { set, props }, new_entry: StringObject, entry_id: string
) => {
  set.error()
  set.entry(new_entry)
  set.entry_id(entry_id)
  if (props.on_entry_loaded) props.on_entry_loaded(new_entry)
}

export const add_entry: ApiHandler<ThisApiCore> = (i) => {
  const { set, params, props } = i
  const user = select(broadcast_user, 'add_entry')
  const id_cliente = user?.context_id
  if (!id_cliente) return

  const validation_error = manage_validation_errors(i)
  if (validation_error) {
    set.error(validation_error)
    return
  }

  const { before_entry_mutation } = props
  const new_entry = before_entry_mutation
    ? before_entry_mutation(params.entry!, 'ADD')
    : params.entry!

  const { add_entry } = props.produce_entry_ops()
  add_entry({
    variables: { id_cliente, new_entry },

    on_completed: (new_unidade) => {
      const { id } = new_unidade
      set.entry_id(id)
      const added_entry = { id, ...new_entry }
      props.on_entry_added(added_entry)
    },

    on_errors: (apollo_error) => {
      const parsed_apollo_error = parse_apollo_error(apollo_error)
      set.error(parsed_apollo_error)
    }
  })
}

/** Updte entry fields to reflect current values. */
export const update_entry: ApiHandler<ThisApiCore> = (i) => {
  const { set, params, props, state } = i
  const entry_id = state.entry_id || props.selected_entry_id

  if (!entry_id || state.error) return

  const validation_error = manage_validation_errors(i)
  if (validation_error) {
    set.error(validation_error)
    return
  }

  const { before_entry_mutation } = props
  const updated_entry = before_entry_mutation
    ? before_entry_mutation(params.entry!, 'UPDATE')
    : params.entry!

  const { update_entry } = props.produce_entry_ops()
  update_entry({
    variables: { entry_id, updated_entry },

    on_completed: () => {
      props.on_entry_updated(updated_entry, entry_id)
    },

    on_errors: (apollo_error) => {
      const parsed_apollo_error = parse_apollo_error(apollo_error)
      set.error(parsed_apollo_error)
    }
  })
}

export const remove_entry: ApiHandler<ThisApiCore> = (i) => {
  const { set, props, state } = i
  const entry_id = state.entry_id || props.selected_entry_id
  if (!entry_id) return

  const { remove_entry } = props.produce_entry_ops()
  remove_entry({
    variables: { entry_id },

    on_completed: () => {
      reset_data_entry(i)
      props.on_entry_removed(entry_id)
    },

    on_errors: (apollo_error) => {
      const parsed_apollo_error = parse_apollo_error(apollo_error)
      set.error(parsed_apollo_error)
    }
  })
}

/** User is clearing all to add a new entry. */
export const reset_data_entry: ApiHandler<ThisApiCore> = ({ set, props }) => {
  set.entry_id()
  set.entry()
  set.error()
  props.on_entry_reset()
}

export const handle_field_change: ApiHandler<
  ThisApiCore,
  [string, string]
> = (i, text, field_id) => {
  const new_entry = {
    ...i.params.entry,
    [field_id]: text
  }
  i.set.entry(new_entry)
}


/** Checks if entry is valid and return an error if not.  */
function manage_validation_errors({ params, props }: ApiInterface<ThisApiCore>) {
  const { entry } = params
  const missing_entry_keys = filter_missing_entry_keys(entry, props.required_fields)

  if (is_valid_entry(entry, missing_entry_keys)) return undefined

  const { field_labels } = props
  const enumerated_fields = missing_entry_keys
    .map(k => field_labels[k])
    .join(', ')
  const singular = missing_entry_keys.length === 1
  const message = enumerated_fields
  const title = singular
    ? 'Preencha o campo obrigatório'
    : 'Preencha os campos obrigatórios'
  return { message, title }
}

/** Checks if entry is an objectwith non-empty required fields */
function filter_missing_entry_keys<T extends StringObject>(
  entry: StringObject | undefined,
  required_fields_keys: (keyof T)[]): (keyof T)[] {
  if (!entry) return required_fields_keys
  const required_empties = required_fields_keys.filter(k => !entry[k]?.length)
  return required_empties
}


/** Checks if entry is an objectwith non-empty required fields */
function is_valid_entry<T extends StringObject>(
  entry: StringObject | undefined,
  required_empties: (keyof T)[]): entry is T {
  return required_empties.length === 0
}