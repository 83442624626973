import { ApiHandler, ApiInterface } from 'controllers/tied'
import { add_days, convert_date_to_utc, get_monday_of_week, today_utc, get_first_day_of_month, get_first_day_of_month_before } from 'helpers'
import { GqlListUsersData } from './user-filter/_operations'
import { ArchiveSearchApiCore, DateTimeRange } from './_data'

export const handle_search_term: ApiHandler<ArchiveSearchApiCore> = (i) => {
  reset_date_filters_state(i)
  reset_user_filters_state(i)
  i.props.on_search(i.state.search_term!)
}

export const handle_term_change: ApiHandler<ArchiveSearchApiCore> = (
  i, text: string
) => {
  i.set.search_term(text)

  const { current_search_term, on_search } = i.props
  // same text as last search - keep current results
  if (current_search_term === text) return
  // cleared the text => show unfiltered list
  if (text === '') {
    on_search(text)
    return
  }
  // user is typing => show no results to avoid confusion
  if (current_search_term !== undefined) on_search()
}

/** Reset all state related to date filters */
function reset_date_filters_state({ set }: ApiInterface<ArchiveSearchApiCore>) {
  set.start_date_iso()
  set.end_date_iso()
  set.selected_date_range()
}

/** Wipe term so user understands the search is no longer taking it into consideration */
function reset_search_term_state({ set, state }: ApiInterface<ArchiveSearchApiCore>) {
  const { search_term_reset_key } = state
  set.search_term()
  set.search_term_reset_key(search_term_reset_key + 1)
}

/** Remove all date filters. */
export const clear_range_filter: ApiHandler<ArchiveSearchApiCore> = (i) => {
  reset_date_filters_state(i)
  i.props.on_remove_filter('requested_at')
}

/** Reset all state related to user filters */
function reset_user_filters_state({ set }: ApiInterface<ArchiveSearchApiCore>) {
  set.selected_user(null)
}

export const clear_user_filter: ApiHandler<ArchiveSearchApiCore> = (i) => {
  reset_user_filters_state(i)
  i.props.on_remove_filter('id_usuario_emissor')
}

export const handle_user_filter_change: ApiHandler<ArchiveSearchApiCore> = (
  i, selected_user: GqlListUsersData[number]
) => {
  reset_search_term_state(i)
  i.set.selected_user(selected_user)
  i.props.on_filter_change({
    field_id: 'id_usuario_emissor',
    operation: 'EQ',
    value: [selected_user.id]
  })
}

type IsoRange = { start_date_iso: string, end_date_iso: string }
export const handle_range_filter_change: ApiHandler<ArchiveSearchApiCore> = (
  i, range: DateTimeRange, label: string, start_date_str?: string
) => {
  const range_function = range_functions[range]
  const { start_date_iso, end_date_iso } = range_function(start_date_str)

  const { state, set, props } = i
  const filter_changed = (
    start_date_iso !== state.start_date_iso || end_date_iso !== state.end_date_iso
  )
  if (!filter_changed) return

  reset_search_term_state(i)
  set.start_date_iso(start_date_iso)
  set.end_date_iso(end_date_iso)
  set.selected_date_range({ range, label })

  props.on_filter_change({
    field_id: 'requested_at',
    operation: 'BETWEEN',
    value: [
      start_date_iso,
      end_date_iso
    ]
  })
}

const range_functions: { [range in DateTimeRange]: (start_date_str: string | undefined) => IsoRange } = {
  SPECIFIC_DAY: specific_date_range,
  TODAY: today_range,
  YESTERDAY: yesterday_range,

  THIS_WEEK: this_week_range,
  LAST_WEEK: last_week_range,
  SPECIFIC_WEEK: specific_week_range,

  THIS_MONTH: this_month_range,
  LAST_MONTH: last_month_range,
  SPECIFIC_MONTH: specific_month_range,
}

/** Returns as IsoRange from start_date to start_date + days_count. */
function calc_date_range(start_date: Date, days_count = 1) {
  const end_date = add_days(start_date, days_count)
  const start_date_iso = start_date.toISOString()
  const end_date_iso = end_date.toISOString()
  return { start_date_iso, end_date_iso }
}

function specific_date_range(start_date_str: string | undefined): IsoRange {
  if (start_date_str === undefined) {
    throw new Error('undefined start_date_str is invalid for specific_date_range')
  }
  const start_date = convert_date_to_utc(new Date(start_date_str))
  return calc_date_range(start_date)
}

function today_range(): IsoRange {
  const today = convert_date_to_utc(new Date())
  return calc_date_range(today)
}

function yesterday_range(): IsoRange {
  const today = convert_date_to_utc(new Date())
  const yesterday = add_days(today, -1)
  return calc_date_range(yesterday)
}

function specific_week_range(start_date_str: string | undefined): IsoRange {
  if (start_date_str === undefined) {
    throw new Error('undefined start_date_str is invalid for specific_week_range')
  }
  const start_date = convert_date_to_utc(new Date(start_date_str))
  return calc_date_range(start_date, 7)
}

function this_week_range() {
  const today = today_utc()
  const last_monday = get_monday_of_week(today)
  return calc_date_range(last_monday, 7)
}

function last_week_range() {
  const today = today_utc()
  const seven_days_ago = add_days(today, -7)
  const monday_last_week = get_monday_of_week(seven_days_ago)
  return calc_date_range(monday_last_week, 7)
}


/** Returns as IsoRange from start_date to end of its month. */
function calc_month_range(start_date: Date) {
  const first_day_of_month = get_first_day_of_month(start_date)
  const some_day_of_month_after = add_days(first_day_of_month, 31)
  const first_day_of_month_after = get_first_day_of_month(some_day_of_month_after)
  const end_date_iso = first_day_of_month_after.toISOString()
  const start_date_iso = start_date.toISOString()
  return { start_date_iso, end_date_iso }
}

function specific_month_range(start_date_str: string | undefined): IsoRange {
  if (start_date_str === undefined) {
    throw new Error('undefined start_date_str is invalid for specific_month_range')
  }
  const start_date = convert_date_to_utc(new Date(start_date_str))
  return calc_month_range(start_date)
}

function last_month_range() {
  const today = today_utc()
  const first_day_of_month_before = get_first_day_of_month_before(today)
  return calc_month_range(first_day_of_month_before)
}

function this_month_range() {
  const today = today_utc()
  const first_day_of_month = get_first_day_of_month(today)
  return calc_month_range(first_day_of_month)
}