import { trace } from 'controllers/tracer'
import { useSelection, useSelector } from 'controllers/tied'
import React, { useEffect } from 'react'
import { FeatureSectionProps, SectionExport } from 'feature-groups/_features-data'
import { affix, useGqlSubscription, useMount, useStoredState } from 'helpers'
import { clear_progress } from 'components/tight-progress-bar/broadcast_progress'
import { broadcast_carousel_id, broadcast_enlarged_image } from 'components/carousel/broadcast_carousel_images'
import { Carousel, MultiTabCard, TabsConfig } from 'components'
import { broadcast_user } from 'app/login/_broadcasts'

import { ListMultas } from './list-multas'
import { ListDebitos } from './list-debitos'
import { ListSituationCards } from './list-situation-cards'
import { SituationSearchProps } from './situation-search'
import { ServicesList } from './services-list/services-list'
import { SearchStatus } from './situation-search/search-status'
import { SearchStarter } from './situation-search/search-starter'
import { GqlSolicitarVerificacaoData } from './situation-search/search-starter/_operations'

import { produce_situation_ops } from './_operations'
import { broadcast_verificacao } from './_broadcasts'
import { SharedStorageVerificacao, shared_storage_verifcacao } from './_data'
import styles from './situation.module.scss'

export const situation: SectionExport = {
  label: 'Consulta',
  Section: AutosSituation,
}

function AutosSituation(props: FeatureSectionProps) {
  const user = useSelection(broadcast_user, 'AutosSituation')
  const id_cliente = user?.context_id

  const [
    id_verificacao,
    verificacao,
    verificacao_in_progress,
    handle_verificacao_change
  ] = useVerificacao()

  useCarousel(id_verificacao, verificacao)
  const enlarged_image = useSelection(broadcast_enlarged_image)
  const hide_main = !!enlarged_image
  const main_css = affix('main', hide_main && 'HIDDEN')

  if (process.env.NODE_ENV === 'development') trace('autos-situation', 'render', { user, verificacao, id_verificacao, id_cliente, verificacao_in_progress })

  if (!id_cliente) { return <></> }

  const pending_multas = verificacao?.data.status_multas === 'PENDING'
  const pending_debitos = verificacao?.data.status_debitos === 'PENDING'

  const tabs_config: TabsConfig = {
    capturas: {
      label: 'Capturas',
    },
    multas: {
      label: 'Multas',
      hidden: verificacao_in_progress,
      show_spinner: pending_multas
    },
    outras_dívidas: {
      label: 'Outras Dívidas',
      hidden: verificacao_in_progress,
      show_spinner: pending_debitos
    },
  }

  return (<>
    <main className={styles[main_css]}>
      {props.children}
      <div className={styles.contents}>
        <section className={styles.services_list}>
          <ServicesList />
        </section>

        <section className={styles.search_wrapper}>
          <SearchStarter
            verificacao={verificacao}
            id_cliente={id_cliente}
            className={styles.search_starter}
            on_new_verificacao={handle_verificacao_change} />

          {!id_verificacao ? null : (
            <MultiTabCard
              key={id_verificacao}
              tabs_config={tabs_config}
              render_hidden_tabs={!verificacao_in_progress}>
              <SearchStatus
                id_cliente={id_cliente}
                verificacao={verificacao?.data}
                className={styles.card_wrapper}
                on_cancel_search={handle_verificacao_change} />

              <ListSituationCards
                id_cliente={id_cliente}
                id_verificacao={id_verificacao} />

              <ListMultas
                pending={pending_multas}
                id_verificacao={id_verificacao} />

              <ListDebitos
                pending={pending_debitos}
                id_verificacao={id_verificacao} />
            </MultiTabCard>
          )}
        </section>
      </div>
    </main>
    <Carousel className={styles.carousel} id_verificacao={id_verificacao} />
  </>)
}

function useVerificacao() {
  const ops = produce_situation_ops()
  const [verificacao, set_verificacao] = useStoredState<SharedStorageVerificacao>(
    shared_storage_verifcacao.key,
    shared_storage_verifcacao.version,
    undefined
  )
  const { id: id_verificacao } = verificacao?.data || {}

  useSelector<SharedStorageVerificacao>(broadcast_verificacao, verificacao)

  const status = verificacao?.data.status
  const verificacao_in_progress = status === 'REQUESTED' || status === 'STARTED'

  // if loading a verificacao from storage - refresh its current mutable props
  useMount(() => {
    if (!id_verificacao) return
    ops.get_verificacao({
      variables: { id_verificacao },
      on_completed: (new_verificacao) => {
        if (process.env.NODE_ENV === 'development') trace('autos-situation', 'get_verificacao', { verificacao, id_verificacao, new_verificacao, verificacao_in_progress })
        update_verificacao(new_verificacao)
      }
    })
  })


  useGqlSubscription(() => {
    if (!id_verificacao) return
    const observable = ops.verificacao_changed({
      variables: { id_verificacao },
      on_updated: (verificacao_changed) => {
        if (process.env.NODE_ENV === 'development') trace('autos-situation', 'verificacao_changed', { verificacao, id_verificacao, verificacao_in_progress, verificacao_changed })
        update_verificacao(verificacao_changed)
      }
    })
    return observable
  }, [id_verificacao])


  function update_verificacao(new_props: Partial<GqlSolicitarVerificacaoData>) {
    const original_props = verificacao!.data
    const new_data = {
      ...original_props,
      ...new_props
    }
    const new_verificacao = {
      ...verificacao,
      data: new_data
    }
    set_verificacao(new_verificacao)
  }


  // when a new verificacao is done, or the current one is cleared
  const handle_verificacao_change: SituationSearchProps['on_change'] = (
    data, variables
  ) => {
    if (!data) {
      set_verificacao(undefined)
      return
    }
    set_verificacao({ data, variables })
  }
  return [
    id_verificacao,
    verificacao,
    verificacao_in_progress,
    handle_verificacao_change
  ] as const
}


function useCarousel(
  id_verificacao: string | undefined,
  verificacao: SharedStorageVerificacao) {
  // clear progress bar state whenever a new verificacao is loaded
  useEffect(clear_progress, [verificacao])
  // Reset carousel whenever the verificacao changes
  useSelector(broadcast_carousel_id, id_verificacao)
}
