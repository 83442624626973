import React from 'react'
import styles from './currency.module.scss'

type CurrencyProps = {
  /** Pre-formatted cell value. */
  value: string | null
}

const css_currency = styles.currency

/** Render number as currency: R$ #0, without digits. */
export function Currency({ value }: CurrencyProps) {
  if (value === null) return null
  return <p className={css_currency}>{value}</p>
}
