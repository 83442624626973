import { ApolloError } from 'apollo-client'

/** User friendly error. */
export type FriendlyError = {
  title: string
  message: string
}

export function parse_apollo_error(errors: ApolloError) {
  const is_network_error = errors.networkError
  if (is_network_error) {
    return {
      title: 'Erro na comunicação com o servidor',
      message: 'Sua conexão parece estar com problemas.'
    }
  }
  console.log(errors)
  return {
    title: '',
    message: ''
  }
}