import { AppHandler } from 'app'
import { take, upsert } from 'helpers'
import { debounce } from 'throttle-debounce'
import { ModalProps } from 'app/modal/_data'
import { TextInputProps } from 'app/modal/shapes'
import { GqlFilterTerms } from 'codegen/gql-types'
import { ApiInterface, ApiHandler } from 'controllers/tied'

import { FiltersOnFilter } from '../filters/_data'
import { DataVisibilityProps } from '../data-visibility-modal'
import { TableApiCore, TablePhase, rows_count } from '../_data'
import { GridOnThreshold, GridOnLoadVoid } from '../grid/_event-types'
import {
  HeaderOnTableAction,
  TableActionTypes,
  HeaderOnOrderBy
} from '../headers/_data'

/** Update the orderby list in response to a user action. */
export const handle_order_by: AppHandler<TableApiCore, HeaderOnOrderBy> = (
  i, field_id, direction
) => {
  const { state } = i
  const order_by_list = direction === 'NONE'
    ? state.order_by_list.filter(o => o.field_id !== field_id)
    : upsert(state.order_by_list, { field_id, direction }, 'field_id')
  const { change_phase } = i.makeApi()
  change_phase(TablePhase.REQUESTING_NEW, { order_by_list })
}

/** Update filters list and apply new filters. */
export const handle_filter: AppHandler<TableApiCore, FiltersOnFilter> = (
  i, filter_terms, active
) => {
  const { state } = i
  const filter_terms_list = !active
    ? state.filter_terms_list.filter(t => t.field_id !== filter_terms.field_id)
    : upsert(state.filter_terms_list, filter_terms, 'field_id')

  const { change_phase } = i.makeApi()
  change_phase(TablePhase.REQUESTING_NEW, { filter_terms_list })
  return ['ok', 'FILTERED', { filter_terms_list, filter_terms, active }]
}

/** Reset all filters and refetch. */
export const clear_filters: ApiHandler<TableApiCore> = (i) => {
  const filter_terms_list = [] as GqlFilterTerms[]
  const filters_key = i.state.filters_key + 1
  const { change_phase } = i.makeApi()
  change_phase(TablePhase.REQUESTING_NEW, { filter_terms_list, filters_key })
}

/** Fetch [rows_count]/2 rows to expand the intersected edge */
export const handle_threshold: AppHandler<TableApiCore, GridOnThreshold> = (
  i, edge, on_phase_change
) => {
  const current_offset = i.state.offset

  // if nothing to be fetched => do nothing
  if (edge === 'upper' && current_offset === 0) {
    return ['ok', 'IGNORED', { i, edge }]
  }

  const { rows_count } = i.params
  const limit = rows_count / 2
  const offset = edge === 'lower'
    ? current_offset + rows_count
    : current_offset - rows_count

  on_phase_change(TablePhase.REQUESTING_MORE)
  const { change_phase } = i.makeApi()
  change_phase(TablePhase.REQUESTING_MORE, { offset, limit })
  return ['ok', 'REQUESTED_MORE', { i, edge }]
}


/** Fecth rows to fill the currently visible void. */
const handle_load_void: AppHandler<TableApiCore, GridOnLoadVoid> = (
  i, void_row_idx, on_phase_change
) => {
  const offset = void_row_idx * rows_count
  // do nothing if not changing the current offset 
  // (could be already loading from an threshold event)
  if (offset === i.state.offset)
    return ['ok', 'VOID_REQUEST_IGNORED', { offset, state: i.state }]

  const limit = rows_count
  on_phase_change(TablePhase.REQUESTING_VOID)
  const { change_phase } = i.makeApi(true)
  change_phase(TablePhase.REQUESTING_VOID, { offset, limit })
  // i.flush() - may go wrong: test
  return ['ok', 'REQUESTED_VOID', { i, void_row_idx }]
}
/** Request data for the visible void 300ms after the user stops scrolling. */
export const debounced_handle_load_void = debounce(300, handle_load_void)

type TableActions = {
  [K in TableActionTypes]: AppHandler<TableApiCore>
}
const table_actions: TableActions = {
  /** Request backend to create a csv file using the current table query 
   * variables, except for offset and limit. File will be made available 
   * asynchrnously once  ready in S3. */
  [TableActionTypes.CREATE_CSV]: (i) => {
    const { state } = i
    /** Once the user defined a name for the file => create it. */
    const handle_modal_ok: TextInputProps['on_ok'] = (text) => {
      /** Initialize download process. The download-requests component will be 
       * listening to a subscription that tracks its progress and delivers the 
       * file once it is ready. */
      const { create_sheet_csv } = i.operations
      const { filter_terms_list } = state
      const { id_cliente } = i.props
      const variables = {
        id_cliente,
        filename: `${text}.csv`,
        filter_terms_list,
      }
      const { handle_error } = i.makeApi(true)
      create_sheet_csv({
        variables,
        on_errors: (errors) => {
          handle_error('create_sheet_csv', variables, errors)
        }
      })
    }

    const modal_props: ModalProps<TextInputProps> = {
      message: 'Defina o nome do arquivo:',
      ok_label: 'Criar csv',
      on_ok: handle_modal_ok
    }
    i.app.show_modal('TextInput', modal_props)

  },

  /** Change visibility of columns. */
  [TableActionTypes.CHANGE_DATA_VISIBILITY]: (i) => {
    const [visible_columns_ids] = i.visible_columns_ids_state
    const modal_props: ModalProps<DataVisibilityProps> = {
      ...take(i.props, 'columns_list', 'fields'),
      visible_columns_ids,
      on_change: handle_change_data_visibility(i)
    }
    i.app.show_modal('DataVisibility', modal_props)
  }
}

/** User selected an option in the table actions popup menu. */
export const handle_table_action: AppHandler<TableApiCore, HeaderOnTableAction> =
  (i, action) => table_actions[action.type](i)




/**
 * ----------------------------------------------------------------------------
 *                                                        Api support functions
 *
 *
 */

/**
 * Change visible columns and remove active filters from columns that are 
 * becomming invisible.
 */
function handle_change_data_visibility(i: ApiInterface<TableApiCore>) {
  const change_data_visibility: DataVisibilityProps['on_change'] = (
    new_visible_columns_ids
  ) => {
    const [, set_visible_columns_ids] = i.visible_columns_ids_state

    // Remove invisible columns from filter terms list
    const { filter_terms_list } = i.state
    if (filter_terms_list.length) {
      const visible_filter_terms_list = filter_terms_list.filter(
        ({ field_id }) => new_visible_columns_ids.includes(field_id)
      )
      if (visible_filter_terms_list.length < filter_terms_list.length) {
        // Variables do not need to be set in change_pahse's phase_state 
        // argument because query will happen only after a new render.
        i.set.filter_terms_list(visible_filter_terms_list)
      }
    }

    // `undefined` columns widths prevents the table from flickering 
    // with the columns list not maching the widths.
    i.set.column_widths(undefined)
    // The changing fields phase is required because the table nedds to be 
    // re-rendered before executing a new query. The query currently in 
    // the interface has the old columns list.
    const { change_phase } = i.makeApi(true)
    change_phase(TablePhase.CHANGING_FIELDS, {})
    set_visible_columns_ids(new_visible_columns_ids)
  }
  return change_data_visibility
}