import React from 'react'
import { HelpProps, Help, HelpTopic } from 'components'

export function NUMBER(props: Omit<HelpProps, 'title' | 'component_key' | 'version'>) {
  return (
    <Help
      {...props}
      title='Como filtrar campos numéricos.'
      component_key='filter_number'
      version={1}
    >
      <HelpTopic tag='(sem operadores)' title='Número exato.'>
        <p>
          Qualquer número sem os operadores <mark>&gt;</mark><mark>&gt;=</mark><mark>&lt;</mark><mark>&lt;=</mark><mark>[...]</mark><mark>!</mark>.
        </p>
        <p>
          <mark>42</mark> exatamente o número <em>42</em>.
        </p>
      </HelpTopic>

      <HelpTopic tag='>' title='Maior que.'>
        <mark>&gt;314</mark> valor estritamente maior que 314, ou seja, sem
        incluir 314.
      </HelpTopic>

      <HelpTopic tag='>=' title='Maior que ou igual a.'>
        <mark>&gt;=314</mark> valor maior que <em>314</em>, ou igual
        a <em>314</em>.
      </HelpTopic>

      <HelpTopic tag='<' title='Menor que.'>
        <mark>&lt;314</mark> valor estritamente menor que <em>314</em>, ou
        seja, sem incluir <em>314</em>.
      </HelpTopic>

      <HelpTopic tag='<=' title='Menor que ou igual a.'>
        <mark>&lt;=314</mark> valor menor que <em>314</em>, ou igual
        a <em>314</em>.
      </HelpTopic>

      <HelpTopic tag='[x a y]' title='Entre x e y, inclusivo.'>
        <mark>[8 a 80]</mark> valor maior ou igual a <em>8</em>, e menor ou
        igual a <em>80</em>.
      </HelpTopic>

      <HelpTopic tag='[x/ y/ z]' title='Lista de valores.'>
        <p>
          <mark>[3/ 32/ 1]</mark> valor igual a <em>3</em>, ou <em>32</em>,
          ou <em>1</em>.
        </p>
      </HelpTopic>

      <HelpTopic tag='!' title='Negação: tudo, exceto os valores definidos pela operação.'>
        <p>
          <mark>!42</mark> qualquer valor diferente de <em>42</em>.
        </p>
        <p>
          <mark>!&gt;314</mark> qualquer valor que não seja estritamente maior
          que <em>314</em>. Isso é equivalente a <mark>&lt;=314</mark>.
        </p>
        <p>
          <mark>![8 a 80]</mark> qualquer valor fora da faixa, ou seja, estritamente
          menor que <em>8</em> e maior que <em>80</em>. (8 e 80 não estão inclusos)
        </p>
        <p>
          <mark>![3/ 32/ 1]</mark> qualquer valor que não seja <em>3</em>,
          nem <em>32</em>, nem <em>1</em>.
        </p>
        <p>
          <em>O operador de negação funciona somente se for o primeiro caractere do texto. <mark>&gt;!32</mark> não é uma negação, é uma busca inválida.</em>
        </p>
      </HelpTopic>

      <HelpTopic tag='[ ]' title='Campo vazio.'>
        <p>
          Abrir e fechar colchetes, sem espaço entre eles. Retorna somente os
          registros que não tenham informação alguma nesse campo.
        </p>
        <p>
          <mark>![ ]</mark> funciona em conjunto com a negação. Retorna todos os
          campos que tenham algum valor, seja qual for.
        </p>
      </HelpTopic>
    </Help>
  )
}