
export type TextApiCore<T> = {
  props: TextProps<T>
  initialState: TextState
  ref: React.RefObject<HTMLInputElement>
}

export const text_icons = {
  SEARCH: '\u26B2',
  ARROW_DOWN: '\u25BE'
}

export type TextProps<T> = React.PropsWithChildren<
  Omit<JSX.IntrinsicElements['input'], 'id'> &
  PolymorphicTextEvents<T> & {
    id?: T
    /** Render component as a multiline textarea if > 1. */
    rows?: number
    /** Label shown above the text input. */
    label?: string
    initial_text?: string
    /** Message to be shown if text is valid. */
    valid_message?: string
    /** Set focus to text input when it mounts. */
    focus_on_mount?: boolean
    /** Text-box appearance. */
    color: 'BORDERED' | 'TRANSPARENT' | 'NAV' | 'BORDERED_ON_HOVER'
    /** icon to be shown on the righ side. */
    icon?: keyof typeof text_icons
    /** If true, component will check its value validity upon mounted. */
    check_validity_on_mout?: boolean
    /** Props to be injected into the div wrapper element. */
    wrapper_props?: JSX.IntrinsicElements['div']
    /** Casses to be applied ot the input tag. */
    inputClassName?: string
  }
>

/** Events that have id as second argument only when props has a valid id. */
export type PolymorphicTextEvents<T> = {
  /** Text input lost its focus. */
  on_blur?: TextEvent<T>
  /** User typed something - not debounced. */
  on_change?: TextEvent<T>
  /** User clicked the return key. */
  on_return?: TextEvent<T>
  /** User has finished typing and value is not invalid. */
  on_finish?: TextOnFinish<T>
  /** Check if input value is valid. */
  on_validity_check?: TextOnValidityCheck<T>
}

export const initialState = {
  text: '' as string,
  is_valid: undefined as boolean | undefined,
  error_message: undefined as undefined | string
}
export type TextState = typeof initialState

export type TextEvent<T = undefined> = T extends undefined
  ? (value: string) => void
  : (value: string, id: T) => void

/** User finished typing, if a string is returned, the contents of the 
 * text input will be replaced by it. */
export type TextOnFinish<T = undefined> = T extends undefined
  ? (value: string) => string | void
  : (value: string, id: T) => string | void

/** Check if the provided value is valid for this field. 
 * If valid, return true.
 * If invalid, return the error message.
 */
export type TextOnValidityCheck<T = undefined> = T extends undefined
  ? (value: string) => string | true
  : (value: string, id: T) => string | true
