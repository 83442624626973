import { Maybe } from 'helpers'
import { StoredState } from 'controllers/tied'

import { GqlSigninData } from './_operations'

export type LoginApiCore = {
  props: LoginProps
  initialState: LoginState
  initialParams: LoginParams
  storedState: typeof storedState
}

export type LoginProps = {
  is_logged_in: boolean
  token_expired: boolean
}

export const initialState = {
  error_message: undefined as string | undefined,
  user: undefined as Maybe<GqlSigninData['user']>
}
export type LoginState = typeof initialState

export const initialParams = {
  email: undefined as string | undefined,
  password: undefined as string | undefined,
}
export type LoginParams = typeof initialParams

export const storedState: StoredState<LoginState> = {
  v: 1,
  keys: [
    'user'
  ]
}
