import React from 'react'
import { Svg } from 'components'
import { Maybe } from 'helpers'
import { SvgRegularIcon } from 'components/basic'

import style from './renderers.module.scss'

/** Lis of all possible renderer funtions */
export type Renderers = [
  Label,
  LinkIcon,
]

/** Clickable icon that opens the link in another tab. */
type LinkIcon = typeof LinkIcon
export function LinkIcon({ value, derive_icon }: RendererProps) {
  if (!value) return <>&nbsp;</>

  const icon = derive_icon && derive_icon(value)
  if (!icon) throw new Error(`LinkIcon cells must have an icon. value: ${value}`)

  return (
    <a
      // @ts-ignore (value could be a number - no problem)
      href={value}
      className={style.link}
      target='_blank'
      rel='noopener noreferrer'>
      <Svg size='regular' icon={icon} />
    </a>
  )
}

/** Default renderer - plain text  */
type Label = typeof Label
export function Label({ value }: RendererProps) {
  if (value == null) return <>&nbsp;</>
  return <>{value}</>
}

export type DataValue = Maybe<string | number>
type RendererProps = {
  value: DataValue,
  derive_icon?: (value: DataValue) => SvgRegularIcon
}