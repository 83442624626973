import React from 'react'
import { compose } from 'helpers'
import { get_white_label_host } from 'app/_router'

import { LogoParent } from './logos-by-host/_types'
import { logos_by_host } from './logos-by-host'
import styles from './logo.module.scss'

type LogoProps = {
  color: 'WHITE' | 'BLUE'
  wrapper_class?: string
  parent: LogoParent
}

export function Logo(props: LogoProps) {
  const color_class = styles[`logo-${props.color}`]
  const class_name = compose(color_class, props.wrapper_class)
  const host = get_white_label_host()
  const { LogoSVG } = logos_by_host[host]

  return <LogoSVG className={class_name} parent={props.parent} />
}
