/** Download file naming it as file_name. */
export function download_file(url: string, file_name: string) {
  const handle_file_ready = (object_url: string) => {
    const a = document.createElement('a')
    a.href = object_url
    a.download = file_name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
  to_object_url(url, handle_file_ready)
}

/** Convert the file found in url into an object url and pass it to on_file_reay when done. */
function to_object_url(url: string, on_file_ready: (object_url: string) => void) {
  return fetch(url).then((response) => {
    response
      .blob()
      .then(blob => {
        const object_url = URL.createObjectURL(blob)
        on_file_ready(object_url)
      }
      )
  })
}
