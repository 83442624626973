import { DebitosRestricoes } from 'feature-groups/search/nada-consta/situation/situation-search'

export type ErrorMessageApiCore = {
  props: ErrorMessageProps
  initialState: ErrorMessageState
}

export type ErrorMessageProps = {
  debitos_restricoes: DebitosRestricoes
}

export const initialState = {
  retry_requested: false
}

type ErrorMessageState = typeof initialState