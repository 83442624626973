/* eslint react-hooks/rules-of-hooks: 0 */
import { trace } from 'controllers/tracer'
import { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { AnyObject, MakeUndefined } from 'helpers/types'

import {
  Api,
  TiedApi,
  SetParams,
  StoredValue,
  AnyFunction,
  ApiStateCore,
  ParamSetters,
  StateSetters,
  ApiParamsCore,
  GenericApiCore,
  FunctionsObject,
  TiedHandlersKeys,
  TiedHandlersTuple,
  ExtendedCoreState,
  ExtendedCoreParams,
  GenericApiInterface,
  IsolatedApiInterface,
  GenericIsolatedApiInterface,
} from './api-types'

/** Subscriptions for all components instances. */
const subscriptions = new Map<{}, ExtendedApiCore>()

/** Functions available to all components interfaces. */
const appMethods: { [i: string]: (...args: any) => any } = {}

/** Add function to the app methods object in all components interfaces. */
export const addAppMethods = (methods: FunctionsObject) => {
  for (const key in methods) {
    appMethods[key] = methods[key]
  }
}

export type UseApiReturn<A, H extends FunctionsObject> =
  { api: Api<H> }
  & (A extends ApiStateCore<any> ? { state: A['initialState'] } : {})
  & (A extends ApiParamsCore<any> ? { params: A['initialParams'] } : {})

export function useApi<A, H extends FunctionsObject>(
  apiCore: A,
  apiHandlers: H,
  componentId?: string
): UseApiReturn<A, H> {

  const [isFirstRender, extendedCore] = useExtendedCore(apiCore, componentId)

  const apis = produceApis(extendedCore, apiHandlers, componentId)
  useTiedEvents(extendedCore, apis, isFirstRender)

  // build return object
  const params = isParamsExtendedCore(extendedCore)
    ? extendedCore.params.current
    : undefined
  const state = isStateExtendedCore(extendedCore)
    ? extendedCore.state
    : undefined
  const { api } = apis

  const result =
    (params && state) ? { api, params, state }
      : params ? { api, params }
        : state ? { api, state }
          : { api }

  if (process.env.NODE_ENV === 'development') trace(componentId, 'render', { ...result, ...apiCore })

  return result as unknown as UseApiReturn<A, H>
}

/** Execute tied api component lifecycle events. */
function useTiedEvents(
  extendedCore: ExtendedApiCore,
  { tiedApi }: FactoredApis<any>,
  isFirstRender: boolean
) {
  if (isFirstRender) {
    if ('onApiFirstRender' in tiedApi) tiedApi.onApiFirstRender()
    if ('onApiStateChange' in tiedApi) {
      const { initialState: state, initialParams: params } = extendedCore.apiCore
      const diff = { state, params }
      tiedApi.onApiStateChange(diff)
    }
  }

  if ('onApiRender' in tiedApi) tiedApi.onApiRender()

  if ('onApiMount' in tiedApi || 'onApiUnmount' in tiedApi) {
    useEffect(() => {
      if ('onApiMount' in tiedApi) tiedApi.onApiMount()
      return () => {
        if ('onApiUnmount' in tiedApi) tiedApi.onApiUnmount()
        subscriptions.delete(extendedCore.instanceKey)
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
  }

  if ('onApiEffect' in tiedApi)
    useEffect(() => { tiedApi.onApiEffect() })

  if ('onApiLayoutEffect' in tiedApi)
    useLayoutEffect(() => { tiedApi.onApiLayoutEffect() })
}

type ExtendedApiCore = {
  apiCore: GenericApiCore,
  instanceKey: {}
  params?: React.MutableRefObject<AnyObject>
  setParams?: SetParams
  state?: AnyObject
  setState?: React.Dispatch<React.SetStateAction<AnyObject>>
}

type ExtendedApiCoreState<S> = {
  apiCore: {
    initialParams?: AnyObject
    initialState: S
  }
  instanceKey: {}
  state: S
  setState: React.Dispatch<React.SetStateAction<S>>
  params?: React.MutableRefObject<AnyObject>
  setParams?: SetParams
}

type ExtendedApiCoreParams<P> = {
  apiCore: {
    initialParams: P
    initialState?: AnyObject
  }
  instanceKey: {}
  params: React.MutableRefObject<P>
  setParams: SetParams<P>
  state?: AnyObject
  setState?: React.Dispatch<React.SetStateAction<AnyObject>>
}

type State<A> = A extends ApiStateCore<infer S> ? S : never
type Params<A> = A extends ApiParamsCore<infer P> ? P : never

/** The apiCoreRef contains params in its extended core */
function isParamsExtendedCore<P = AnyObject>(value: AnyObject):
  value is ExtendedApiCoreParams<P> {
  return 'initialParams' in value.apiCore
}

/** The apiCoreRef contains params in its extended core */
function isStateExtendedCore<S = AnyObject>(value: AnyObject):
  value is ExtendedApiCoreState<S> {
  return 'initialState' in value.apiCore
}

/**
 * Build the apiCoreRef extended core and keep it in sync
 */
function useExtendedCore(
  apiCore: GenericApiCore, componentId: string | undefined
) {
  const instanceKeyRef = useRef<{}>()
  const isFirstRender = instanceKeyRef.current === undefined
  if (isFirstRender) instanceKeyRef.current = {}
  const instanceKey = instanceKeyRef.current!

  const apiCoreRef = useRef<ExtendedApiCore>()

  const storedApiCore = retrieveStoredState(apiCore, isFirstRender, componentId)
  const stateCore = useStateCore(storedApiCore)
  const paramsCore = useParamsCore(storedApiCore)

  const extendedCore: ExtendedApiCore = {
    apiCore: storedApiCore,
    instanceKey,
    ...stateCore,
    ...paramsCore,
  }

  subscriptions.set(instanceKey!, extendedCore)

  apiCoreRef.current = extendedCore
  return [
    isFirstRender,
    extendedCore
  ] as const
}

/** If storedState key exists, retrieve initial state overrides from
 * local storage. */
function retrieveStoredState<A extends GenericApiCore<any, any>>(
  apiCore: A,
  isFirstRender: boolean,
  componentId: string | undefined
): A {
  // no stored state => just use static apiCore
  if (!isFirstRender || !('storedState' in apiCore)) return apiCore

  // First render => retrieve stored state
  if (!isApiStateCore(apiCore)) throw new Error('Components with storedState must have their initialState defined when calling useApi.')

  if (!componentId) throw new Error('Components with storedState must have their componentId defined when calling useApi.')

  const storageKey = produceStorageKey(componentId, apiCore)
  const storedString = localStorage.getItem(storageKey)

  // If nothing stored locally for this component, return unchanged core.
  if (storedString === null) return apiCore

  const storedValue = JSON.parse(storedString) as StoredValue

  // If a breaking change was made to the stored state shape, disregard 
  // the stored value.
  if (apiCore.storedState!.v !== storedValue.v) return apiCore

  return {
    ...apiCore,
    initialState: {
      ...apiCore.initialState,
      ...storedValue.state
    }
  }
}

/** Return state and setState is initialState exist in apiCore  */
function useStateCore(apiCore: GenericApiCore)
  : ExtendedCoreState | null {
  if (!isApiStateCore(apiCore)) { return null }
  const [state, setState] = useState(apiCore.initialState)
  return { state, setState }
}

/** Return params and setParams is initialParams exist in apiCore  */
function useParamsCore(apiCore: GenericApiCore)
  : ExtendedCoreParams | null {
  if (!apiCore.initialParams) { return null }
  const params = useRef(apiCore.initialParams)
  const setParams: SetParams = newParams => { params.current = newParams }
  return { params, setParams }
}

/** If object has initialState, it is an ApiStateCore */
function isApiStateCore<S extends AnyObject>(value: AnyObject): value is ApiStateCore<S> {
  return 'initialState' in value
}

/** List of all functions related to tied events. */
const tiedHandlersKeys = [
  'onApiFirstRender',
  'onApiRender',
  'onApiMount',
  'onApiStateChange',
  'onApiUnmount',
  'onApiEffect',
  'onApiLayoutEffect',
] as TiedHandlersTuple
/** Callable portion of the api. (Excludes tied handlers) */
type SplitApi<H extends FunctionsObject> = Omit<H, TiedHandlersKeys>
/** Tied event handlers. */
type SplitTiedApi<H extends FunctionsObject> = Pick<H, TiedHandlersKeys>

type FactoredApis<H extends FunctionsObject> = { api: Api<H>, tiedApi: TiedApi<H> }
/**
 * Create the memoized api.
 * 
 * If createSetters is true, injects a `set` object with one setter function 
 * per entry in initialState.
 */
function produceApis<H extends FunctionsObject>(
  extendedCore: ExtendedApiCore,
  allHandlers: H,
  componentId: string | undefined
): FactoredApis<H> {
  // isolate private lifecycle handlers
  const { tiedHandlers, apiHandlers } = splitApi(allHandlers)
  const { onApiStateChange } = tiedHandlers

  const api = produceApi(
    extendedCore.instanceKey,
    apiHandlers,
    onApiStateChange,
    componentId
  )

  const tiedApi = produceTiedApi(
    extendedCore,
    tiedHandlers,
    apiHandlers,
    onApiStateChange,
    componentId
  )

  return {
    api: api as Api<H>,
    tiedApi
  }
}

/** Produce event handlers for the tied api lifecycle. */
function produceTiedApi<TH extends FunctionsObject>(
  extendedCore: ExtendedApiCore,
  tiedHandlers: TH,
  apiHandlers: FunctionsObject,
  onApiStateChange: AnyFunction | undefined,
  componentId: string | undefined
): TiedApi<TH> {
  const { instanceKey } = extendedCore
  const tiedApi: AnyObject = {}
  for (const key in tiedHandlers) {
    tiedApi[key] = (...args: any) => {
      executeInterfaced(
        instanceKey,
        onApiStateChange,
        componentId,
        apiHandlers,
        (apiInterface, buffers) => {
          const handler = tiedHandlers[key as keyof typeof tiedHandlers]

          if (key === 'onApiStateChange') {
            /**
             * Isolated event that cannot change state.
             */
            // extract params setters from set
            const paramsKeys = isParamsExtendedCore(extendedCore)
              ? Object.keys(extendedCore.apiCore.initialParams)
              : []
            const paramSetters = paramsKeys.reduce((prev, key) => ({
              ...prev, [key]: apiInterface.set![key]
            }), {})
            const isolatedApiInterface = produceIsolatedInterface(
              apiInterface,
              paramSetters
            )

            if (process.env.NODE_ENV === 'development') trace(componentId, key, { i: apiInterface, args })
            return handler(isolatedApiInterface, ...args)
          }

          /** Create a new api instance.  
           * If async is true, buffer will be set to empty, making it a root 
           * api call that will flush state/params changes after execution.
           */
          const makeApi = (async = false) => produceApi(
            instanceKey,
            apiHandlers,
            onApiStateChange,
            componentId,
            async ? undefined : buffers,
          )
          apiInterface.makeApi = makeApi

          // Prevent side effects in other components during render phase
          if (key === 'onApiFirstRender' || key === 'onApiRender') {
            const noAppInterface: MakeUndefined<typeof apiInterface, 'app'> = {
              ...apiInterface,
              app: undefined
            }
            if (process.env.NODE_ENV === 'development') trace(componentId, key, { i: noAppInterface, args })
            return handler(noAppInterface, ...args)
          }

          if (process.env.NODE_ENV === 'development') trace(componentId, key, { i: apiInterface, args })
          return handler(apiInterface, ...args)
        },
      )
    }
  }
  return tiedApi as TiedApi<TH>
}

/** 
 * Produce regular api handlers, recursivelly injecting access to api handlers
 * into their api interface.  
 * All state and params changes will accumulate in the same buffer and they 
 * will be pushed by the root api call. The root api call will be the only one
 * that calling executeInterfaced with undefined sharedBuffers. 
 */
function produceApi<H extends FunctionsObject>(
  instanceKey: ExtendedApiCore['instanceKey'],
  apiHandlers: H,
  onApiStateChange: AnyFunction | undefined,
  componentId: string | undefined,
  sharedBuffers?: Buffers
): Api<H> {
  const api: AnyObject = {}
  for (const key in apiHandlers) {
    api[key] = (...args: any) => {
      return executeInterfaced(
        instanceKey,
        onApiStateChange,
        componentId,
        apiHandlers,
        (apiInterface, buffers) => {
          /** Create a new api instance.  
           * If async is true, buffer will be set to empty, making it a root 
           * api call that will flush state/params changes after execution.
           */
          const makeApi = (async = false) => produceApi(
            instanceKey,
            apiHandlers,
            onApiStateChange,
            componentId,
            async ? undefined : buffers,
          )
          apiInterface.makeApi = makeApi

          if (process.env.NODE_ENV === 'development') trace(componentId, key, { i: apiInterface, args, buffers, sharedBuffers })

          return apiHandlers[key](apiInterface, ...args)
        },
        sharedBuffers,
      )
    }
  }
  return api as Api<H>
}

/**
 * Separate tied handlers from api handlers.
 */
function splitApi<H extends FunctionsObject>(allHandlers: H) {
  const apiHandlers: Partial<H> = {}
  const tiedHandlers: Partial<H> = {}
  for (const key in allHandlers) {
    if (tiedHandlersKeys.includes(key as TiedHandlersKeys)) {
      tiedHandlers[key] = allHandlers[key]
    } else {
      apiHandlers[key] = allHandlers[key]
    }
  }
  return {
    tiedHandlers: tiedHandlers as SplitTiedApi<H>,
    apiHandlers: apiHandlers as SplitApi<H>
  }
}

/** Changing values in state and params. */
type Diff<S, P> = {
  /** Changing values in state. */
  state: Partial<S>
  /** Changing values in params. */
  params: Partial<P> | undefined
}
/** 
 * Tied event raised whenever a state change is about to be applied.
 * Interface contains the interface values before changes are applieed.
 * Diff contains the new values for state and params.
 */
export type OnApiStateChange<A> = {
  (i: IsolatedApiInterface<A>, diff: Diff<State<A>, Params<A>>): void
}

/**
 * Generate the component interface, state e params buffers and execute the
 * handler callback. Once execution is finished, push changes to params 
 * and state.
 */
function executeInterfaced(
  instanceKey: ExtendedApiCore['instanceKey'],
  onApiStateChange: AnyFunction | undefined,
  componentId: string | undefined,
  apiHandlers: FunctionsObject,
  handlerCallback: (
    apiInterface: GenericApiInterface,
    buffers: Buffers
  ) => unknown,
  sharedBuffers?: Buffers
) {

  const extendedCore = subscriptions.get(instanceKey)
  if (!extendedCore) {
    if (process.env.NODE_ENV === 'development') console.log(`Api function called after component unmount.`, { componentId, apiHandlers })
    return
  }

  const [apiInterface, buffers, paramsSetters] = produceInterfaceTuple(
    extendedCore,
    sharedBuffers,
    onApiStateChange,
    componentId,
    apiHandlers
  )

  // calls back the function that manages the type of handler
  const result = handlerCallback(apiInterface, buffers)

  // if a log tuple is returned => handle it
  if (Array.isArray(result)) {
    if (result[0] === 'error') {
      const errorMessage = `${componentId}: ${result[1]}`
      console.error(`${errorMessage} - ${result[2]}`)
      if (process.env.NODE_ENV === 'development') throw new Error(errorMessage)
    }
    if (process.env.NODE_ENV === 'development' && (result[0] === 'error' || result[0] === 'ok')) trace(componentId, result[1], result[2])
  }

  // No shared buffers implies this is a root api call, all recursive calls 
  // will pass down the shared buffers object.
  if (!sharedBuffers) {
    pushBuffers(
      extendedCore,
      apiInterface,
      buffers,
      paramsSetters,
      onApiStateChange,
      componentId
    )
  }
  // return api handler result
  return result
}

function pushBuffers(
  extendedCore: ExtendedApiCore,
  partialApiInterface: GenericApiInterface,
  buffers: Buffers,
  paramsSetters: ParamSetters<AnyObject> | undefined,
  onApiStateChange: AnyFunction | undefined,
  componentId: string | undefined,
) {
  const { stateBuffer, paramsBuffer } = buffers

  // if onApiStateChange exists and state is about to be changed => call it
  if (stateBuffer && onApiStateChange && Object.keys(stateBuffer).length) {
    const isolatedInterface =
      produceIsolatedInterface(partialApiInterface, paramsSetters)
    const diff = {
      state: stateBuffer,
      params: paramsBuffer
    }
    onApiStateChange(isolatedInterface, diff)
  }

  if (paramsBuffer) pushParams(extendedCore, paramsBuffer)
  if (stateBuffer) pushState(extendedCore, stateBuffer, componentId)
}

/** Remove state setters from a component interface. */
function produceIsolatedInterface(
  apiInterface: GenericApiInterface,
  paramsSetters: ParamSetters | undefined
): GenericIsolatedApiInterface {
  return {
    ...apiInterface,
    resetState: undefined,
    set: paramsSetters,
  }
}

type Buffers = {
  stateBuffer: AnyObject | undefined,
  paramsBuffer: AnyObject | undefined,
}
/** Result from produceInterfaceTuple function */
type InterfaceTuple = [
  GenericApiInterface,
  Buffers,
  ParamSetters<AnyObject> | undefined,
]
/**
 * Produces the object to be passed as interface to api and event handlers, 
 * including state setters.
 */
function produceInterfaceTuple(
  extendedCore: ExtendedApiCore,
  sharedBuffers: Buffers | undefined,
  onApiStateChange: AnyFunction | undefined,
  componentId: string | undefined,
  apiHandlers: FunctionsObject,
): InterfaceTuple {
  const stateInterface = produceStateSetters(extendedCore, sharedBuffers)
  const paramsInterface = produceParamsSetters(extendedCore, sharedBuffers)

  const paramsSetters = paramsInterface?.set
  const stateSetters = stateInterface?.set
  const setters = (paramsSetters || stateSetters) && {
    set: {
      ...paramsSetters,
      ...stateSetters,
    }
  }

  const buffers = {
    stateBuffer: stateInterface?.stateBuffer,
    paramsBuffer: paramsInterface?.paramsBuffer,
  }

  // /** Produce a function to be called asynchronously keeping access to the 
  //  * most recent component interface. */
  // const produceCallback = (asyncFn: Function) => produceCallbackFactory(
  //   asyncFn,
  //   extendedCore.instanceKey,
  //   apiHandlers,
  //   onApiStateChange,
  //   componentId
  // )

  const apiInterface: GenericApiInterface = {
    app: appMethods,
    ...extendedCore.apiCore,
    ...setters,
    ...stateInterface && {
      resetState: stateInterface.reset
    },
    ...isStateExtendedCore(extendedCore) && {
      state: extendedCore.state
    },
    ...isParamsExtendedCore(extendedCore) && {
      params: extendedCore.params.current
    }
  }

  return [
    apiInterface,
    buffers,
    paramsSetters,
  ]
}

/**
 * Creates the `set` object to be passed to functions interested in changing
 * the values of params.
 * If setter is called with no arguments, param is reset to its initial state.
 */
function produceParamsSetters(
  extendedCore: ExtendedApiCore,
  sharedBuffers: Buffers | undefined
) {
  if (!isParamsExtendedCore(extendedCore)) { return null }

  const { initialParams } = extendedCore.apiCore
  const set: Partial<ParamSetters> = {}
  const paramsBuffer: AnyObject = sharedBuffers
    ? sharedBuffers.paramsBuffer || {}
    : {}

  for (const key in initialParams) {
    set[key] = (value = initialParams[key]): void => {
      if (key in paramsBuffer) {
        throw new Error(`No double dips! Params value for ${key} was already set in this call.`)
      }
      if (value !== extendedCore.params.current[key]) {
        paramsBuffer[key] = value
      }
    }
  }
  return {
    set: set as ParamSetters,
    paramsBuffer
  }
}

/**
 * Creates the `set` object to be passed to functions insterested in setting 
 * state and the `stateBuffer` to be later used in pushState so all changes
 * in state happen once.
 */
function produceStateSetters(
  extendedCore: ExtendedApiCore,
  sharedBuffers: Buffers | undefined
) {
  if (!isStateExtendedCore(extendedCore)) { return null }
  const { initialState } = extendedCore.apiCore
  const set: Partial<StateSetters> = {}
  // store changes in buffer to avoid multiple renders in a single api call
  const stateBuffer: AnyObject = sharedBuffers
    ? sharedBuffers.stateBuffer || {}
    : {}
  for (const key in initialState) {
    set[key] = (value = initialState[key]) => {

      if (key in stateBuffer)
        throw new Error(`No double dips! State value for ${key} was already set in this call.`)

      if (value !== extendedCore.state[key])
        stateBuffer[key] = value
    }
  }
  /** Reset all state, changing only the values in new_state. */
  const reset = (newState?: AnyObject) => {
    for (const key in initialState) {
      if (key in stateBuffer) {
        throw new Error(`No double dips! State value for ${key} was already set in this call.`)
      }
      if (newState && key in newState) {
        stateBuffer[key] = newState[key]
        continue
      }
      stateBuffer[key] = initialState[key]
    }
  }
  return {
    set: set as StateSetters,
    stateBuffer,
    reset
  }
}

/**
 * If state buffer has anything, spread its contents into the state object.
 */
function pushParams(
  extendedCore: ExtendedApiCore,
  paramsBuffer: AnyObject
) {
  if (Object.keys(paramsBuffer).length) {
    const newParams = {
      ...extendedCore.params!.current,
      ...paramsBuffer
    }
    extendedCore.setParams!(newParams)
    for (const key of Object.keys(paramsBuffer)) {
      delete paramsBuffer[key]
    }
  }
}

/**
 * If state buffer has anything, spread its contents into the state object.
 */
function pushState(
  extendedCore: ExtendedApiCore,
  stateBuffer: AnyObject,
  componentId: string | undefined
) {
  const bufferKeys = Object.keys(stateBuffer)

  if (bufferKeys.length) {
    const newState = {
      ...extendedCore.state,
      ...stateBuffer
    }
    // This state definition hack is necessary to ensure the state does not 
    // get out of sync if another api function is called before the component 
    // re-renders.
    extendedCore.state = newState
    extendedCore.setState!(newState)

    // update stored state, if necessary
    const { apiCore } = extendedCore
    if (apiCore.storedState && componentId) {
      const { keys, v } = apiCore.storedState
      const storedDiff = bufferKeys.filter(k => keys.includes(k))
      if (storedDiff.length) {
        let state: AnyObject = {}
        for (const key of keys) {
          state[key] = newState[key]
        }
        const localKey = produceStorageKey(componentId, apiCore)
        const storedValue = JSON.stringify({ v, state })
        localStorage[localKey] = storedValue
      }
    }

    for (const key of bufferKeys) {
      delete stateBuffer[key]
    }
  }
}

/** 
 * Produce a local storage key for the current component instance. 
 * 
 * If no `storageKey` prop in state or apiCore, state is the same for all 
 * instances of the component.
 * 
 * If `storageKey` is provided, a different state is kept for each 
 * component/storageKey.
 * 
 * `storageKey` in state overrides `storageKey` in apiCore.
 */
function produceStorageKey(componentId: string, apiCore: GenericApiCore) {
  return 'storageKey' in apiCore
    ? `${componentId}-${apiCore.storageKey}`
    : componentId
}