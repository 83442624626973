import * as Types from '../../../../../codegen/gql-types';

import { produce_query } from 'app'
import gql from 'graphql-tag';

/**
 * 
 * 
 * 
 * 
 * 
 *         DO NOT EDIT THIS FILE - ANY CHANGES WILL BE LOST
 * 
 * 
 * 
 * 
 * 
 *    This file is automatically generated on every build by codegen plugins
 *    as defined in codegen.yml
 * 
 *    Its contents are defined by its sibling file _operations.graphql.
 * 
 * 
 * 
 */


 const list_debitos_doc = gql`
    query list_debitos($id_verificacao: String!) {
  list_debitos(id_verificacao: $id_verificacao) {
    id
    nome_fornecedor
    tipo_debito
    ano_referencia
    parcela
    data_vencimento
    valor
    status_original
    boleto_file_url
    flag_somar_ao_total
  }
}
    `;

/** Produce all of operations functions for list-debitos. */
function list_debitos_ops_factory() {
  const list_debitos = produce_query<GqlListDebitosData,GqlListDebitosVariables>(list_debitos_doc)
  
  return {
    list_debitos,
  }
}
/** Auto-generated operations functions for list-debitos. */
export type ListDebitosOps = ReturnType<typeof list_debitos_ops_factory>

let memoized_operations: ListDebitosOps
/** Produces and memoizes all list-debitos operations. */
export function produce_list_debitos_ops() {
  if (!memoized_operations) {
    memoized_operations = list_debitos_ops_factory()
  }
  return memoized_operations
}

/** Types returned as the final result from operations execution.  */

export type GqlListDebitosData = GqlListDebitos['list_debitos']

export type GqlListDebitosVariables = Types.Exact<{
  id_verificacao: Types.Scalars['String'];
}>;


export type GqlListDebitos = { list_debitos: Array<Pick<Types.GqlDetranDebito, 'id' | 'nome_fornecedor' | 'tipo_debito' | 'ano_referencia' | 'parcela' | 'data_vencimento' | 'valor' | 'status_original' | 'boleto_file_url' | 'flag_somar_ao_total'>> };
