import { compose } from 'helpers'
import React from 'react'
import { Button } from './button'

import styles from './ok-cancel-buttons.module.scss'

export type OkCancelProps = {
  ok_label: string
  /** Label of the cancel button. (default = `Cancelar`) */
  cancel_label?: string
  /** User clicked the ok button. */
  on_ok: React.DOMAttributes<HTMLButtonElement>['onClick']
  /** User clicked the cancel button. */
  on_cancel: React.DOMAttributes<HTMLButtonElement>['onClick']
}

/** Ok and cancel buttons, aligned to the right. */
export function OkCancelButtons(props: OkCancelProps) {
  return (
    <div className={styles.buttons}>
      <Button
        color='MAIN_SECONDARY'
        onClick={props.on_cancel}>
        {props.cancel_label || 'Cancelar'}
      </Button>
      <Button onClick={props.on_ok}>
        {props.ok_label}
      </Button>
    </div>
  )
}