import React, { useRef } from 'react'
import { useApi } from 'controllers/tied'
import { compose, affix, drop } from 'helpers'

import * as api_handlers from './_api'
import styles from './text.module.scss'
import { TextProps, initialState, TextApiCore, TextState, text_icons } from './_data'

function initialize_state<T>(props: TextProps<T>) {
  return {
    ...initialState,
    text: props.initial_text ?? ''
  }
}

/** Render and control only the text input, nothing else. */
export function Text<T>(props: TextProps<T>) {
  const ref = useRef<HTMLInputElement>(null)
  const initialState = initialize_state(props)
  const api_core: TextApiCore<T> = {
    initialState,
    props,
    ref,
  }
  const { state, api } = useApi(api_core, api_handlers)

  const input_type = props.rows ? 'textarea' : 'input'
  const input_css = affix('input', props.color)
  const { icon } = props
  const wrapper_class = affix('wrapper', icon, 'WITH_ICON')

  return (
    <div
      {...props.wrapper_props}
      className={compose(props.className, styles[wrapper_class])}>
      {!props.label ? null : (
        <p className={styles.label}>{props.label}</p>
      )}

      {React.createElement(input_type, {
        type: input_type === 'input' ? 'text' : undefined,
        ...drop_component_props(props),
        ref,
        value: state.text,
        onBlur: api.handle_blur,
        onChange: api.handle_change,
        onKeyDown: api.handle_key_down,
        className: compose(
          styles[input_css],
          state.is_valid === false && styles.invalid,
          props.inputClassName
        ),
      })}

      {!icon ? null : (
        <span
          className={styles[`icon-${icon}`]}
          onClick={api.handle_focus_self} >
          {text_icons[icon]}
        </span>
      )}

      <ValidityMessage
        is_valid={state.is_valid}
        error_message={state.error_message}
        valid_message={props.valid_message}
        on_validity_check={props.on_validity_check}
      />

      {props.children}
    </div>
  )
}

type ValidityMessageProps<T> =
  Pick<TextProps<T>, 'on_validity_check' | 'valid_message'> &
  Pick<TextState, 'is_valid' | 'error_message'>
/** Shows error_message or valid_message if required. */
function ValidityMessage<T>(props: ValidityMessageProps<T>) {
  const track_error = props.on_validity_check
  if (!track_error) { return null }

  const validity_css = affix(
    'validity_message',
    props.is_valid,
    'VALID',
    'INVALID'
  )
  const message = props.is_valid
    ? props.valid_message
    : props.error_message
  return (
    <p className={styles[validity_css]}>
      <span>{message}</span>
    </p>
  )
}

/** Remove all props that are not native to the react input/ textarea prop. */
function drop_component_props<T>(props: TextProps<T>) {
  return drop(props,
    'id',
    'icon',
    'color',
    'label',
    'on_blur',
    'children',
    'on_finish',
    'on_return',
    'on_change',
    'initial_text',
    'wrapper_props',
    'focus_on_mount',
    'inputClassName',
    'on_validity_check',
    'check_validity_on_mout',
  )
}
