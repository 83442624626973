import React from 'react'
import { Svg } from 'components'
import { useApi, useSelection } from 'controllers/tied'
import { DebitosRestricoes } from 'feature-groups/search/nada-consta/situation/situation-search'
import { broadcast_verificacao } from '../../../../_broadcasts'

import * as api_handlers from './_api'
import { ErrorMessageProps, ErrorMessageApiCore, initialState } from './_data'
import styles from './error-message.module.scss'

/** Renders the error message. */
export function ErrorMessage(props: ErrorMessageProps) {
  const { error_message, can_retry } = produce_error_props(props.debitos_restricoes)
  const api_core: ErrorMessageApiCore = {
    props,
    initialState
  }
  const { api, state } = useApi(api_core, api_handlers)

  return (
    <div className={styles.error_message}>
      <button
        className={styles.error_info_button}
        onClick={api.copy_log_to_clipboard}>
        {error_message}
      </button>
      <RetryAction
        retry_requested={state.retry_requested}
        on_retry={api.retry_job}
        can_retry={can_retry} />
    </div>
  )
}

type RetryActionProps = {
  retry_requested: boolean
  on_retry: () => void
  can_retry: boolean

}
function RetryAction(props: RetryActionProps) {
  const verificacao = useSelection(broadcast_verificacao)
  if (!verificacao?.variables?.lista_fornecedor_credenciais)
    return null

  if (props.retry_requested) return (
    <span className={styles.no_retry_button_like}>
      Solicitação enviada
    </span>
  )
  // if (!props.can_retry) return (
  //   <span className={styles.no_retry_button_like}>
  //     Tentar novamente não funcionaria
  //   </span>
  // )
  return (
    <button
      className={styles.retry_button}
      onClick={props.on_retry}>
      <Svg icon='redo' size='small' />
      Tentar novamente
    </button>
  )
}

/** Transforms the contents of the errors list into a single error to 
 * be comunicated. */
function produce_error_props(debitos_restricoes: DebitosRestricoes): {
  error_message: string, can_retry: boolean
} {

  const produce_error_message = () => {
    const { scrape_response_errors, request_error } = debitos_restricoes
    const failure_without_errors =
      !scrape_response_errors?.length &&
      !request_error

    if (failure_without_errors) return 'Ocorreu um erro desconhecido.'
    if (request_error) return request_error.error_message
    return scrape_response_errors![0].error_message
  }

  const { action_type } = debitos_restricoes.required_action || {}
  const can_retry = action_type === 'RETRY' || !action_type

  return {
    error_message: produce_error_message(),
    can_retry
  }
}