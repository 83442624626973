import { Selector } from 'controllers/tied'
import { trace } from 'controllers/tracer'

let progress_map: { [component_id: string]: number } = {}

/**
 * Basic progress bar that accumulates progress of multiple components 
 * simultaneously.
 * PS: it only allows for one progress bar to be rendered. A new one can only
 * be rendered accuratelly after all components of the old one have been 
 * unmounted.
 * 
 * component_progress must be between 0 and 1.
 */
export const broadcast_progress: Selector<number> = (
  component_progress, component_id
) => {
  const numeric_component_progress = component_progress || 0

  // no component_id means the evaluation is being made by the selection,
  // not the selector components.
  if (component_id) {
    progress_map[component_id] = numeric_component_progress
  }

  const keys = Object.keys(progress_map)
  if (!keys.length) {
    if (process.env.NODE_ENV === 'development') trace('tight-progress-bar', 'broadcast_progress-ZERO', { progress_map, component_id })
    return 0
  }

  const progress_sum = keys.reduce((total, key) => total + progress_map[key], 0)
  const final_progress = progress_sum / keys.length

  if (process.env.NODE_ENV === 'development') trace('tight-progress-bar', 'broadcast_progress', { final_progress, progress_map, component_id })

  return final_progress
}

/** Clear all progress information from previous components. */
export function clear_progress() {
  progress_map = {}
}

/** Clear all progress for a given component. */
export function clear_component_progress(component_id?: string) {
  if (component_id)
    delete progress_map[component_id]
}