import { Svg } from 'components'
import React from 'react'

import styles from './logout.module.scss'

export function Logout() {
  const logout = () => {
    localStorage.removeItem('auth-token')
    window.location.reload()
  }
  // token exists => allow logout (TBD)
  return (
    <button className={styles.action_button} onClick={logout}>
      <Svg icon='exit' />
      <span>Sair</span>
    </button>
  )
}