import * as Types from '../../../../codegen/gql-types';

import { produce_subscription, produce_query } from 'app'
import gql from 'graphql-tag';

/**
 * 
 * 
 * 
 * 
 * 
 *         DO NOT EDIT THIS FILE - ANY CHANGES WILL BE LOST
 * 
 * 
 * 
 * 
 * 
 *    This file is automatically generated on every build by codegen plugins
 *    as defined in codegen.yml
 * 
 *    Its contents are defined by its sibling file _operations.graphql.
 * 
 * 
 * 
 */


 const verificacao_changed_doc = gql`
    subscription verificacao_changed($id_verificacao: String!) {
  verificacao_changed(id_verificacao: $id_verificacao) {
    id
    requested_at
    canceled_at
    finished_at
    status
    status_multas
    status_debitos
    failure_reason
    files_bundle {
      url
    }
  }
}
    `;
 const get_verificacao_doc = gql`
    query get_verificacao($id_verificacao: String!) {
  get_verificacao(id_verificacao: $id_verificacao) {
    id
    requested_at
    canceled_at
    finished_at
    status
    status_multas
    status_debitos
    failure_reason
    files_bundle {
      url
    }
  }
}
    `;

/** Produce all of operations functions for situation. */
function situation_ops_factory() {
  const verificacao_changed = produce_subscription<GqlVerificacaoChangedData,GqlVerificacaoChangedVariables>(verificacao_changed_doc)
  
  const get_verificacao = produce_query<GqlGetVerificacaoData,GqlGetVerificacaoVariables>(get_verificacao_doc)
  
  return {
    verificacao_changed,
    get_verificacao,
  }
}
/** Auto-generated operations functions for situation. */
export type SituationOps = ReturnType<typeof situation_ops_factory>

let memoized_operations: SituationOps
/** Produces and memoizes all situation operations. */
export function produce_situation_ops() {
  if (!memoized_operations) {
    memoized_operations = situation_ops_factory()
  }
  return memoized_operations
}

/** Types returned as the final result from operations execution.  */

export type GqlVerificacaoChangedData = GqlVerificacaoChanged['verificacao_changed']
export type GqlGetVerificacaoData = GqlGetVerificacao['get_verificacao']

export type GqlVerificacaoChangedVariables = Types.Exact<{
  id_verificacao: Types.Scalars['String'];
}>;


export type GqlVerificacaoChanged = { verificacao_changed: (
    Pick<Types.GqlVerificacao, 'id' | 'requested_at' | 'canceled_at' | 'finished_at' | 'status' | 'status_multas' | 'status_debitos' | 'failure_reason'>
    & { files_bundle?: Types.Maybe<Pick<Types.GqlS3File, 'url'>> }
  ) };

export type GqlGetVerificacaoVariables = Types.Exact<{
  id_verificacao: Types.Scalars['String'];
}>;


export type GqlGetVerificacao = { get_verificacao: (
    Pick<Types.GqlVerificacao, 'id' | 'requested_at' | 'canceled_at' | 'finished_at' | 'status' | 'status_multas' | 'status_debitos' | 'failure_reason'>
    & { files_bundle?: Types.Maybe<Pick<Types.GqlS3File, 'url'>> }
  ) };
