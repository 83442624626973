import { readStorage, writeStorage } from 'controllers/tied/selectors/storage'
import { useEffect, useRef, useState } from 'react'

/** True only before component is mounted. */
export function useMounted() {
  const mounted_ref = useRef<boolean>(false)
  useEffect(() => {
    mounted_ref.current = true
    return () => {
      mounted_ref.current = false
    }
  }, [])
  return mounted_ref.current
}

/** Execute api function effect on dependencies change. */
export function useApiEffect(
  api_function: (...args: unknown[]) => unknown,
  dependencies: unknown[]
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { api_function() }, dependencies)
}

/** Execute on_mount when component mounts. */
export function useMount(callback: () => (void | (() => void))) {
  useEffect(callback, [])
}

/** 
 * Execute useEffect as a subscription tool.  
 * This allows the dependencies to not be exaustive, ESLint won't complain.
 */
export const useSubscriptionEffect = useEffect

/**
 * Synchronously execute callback whenever any value in dependencies change.
 */
export function useSyncEffect(callback: () => void, dependency: unknown) {
  const prev_dependency_ref = useRef<unknown>()
  if (dependency === prev_dependency_ref.current) return
  prev_dependency_ref.current = dependency
  callback()
}

/** Use state and keep it in sync with a local storage initializer. */
export function useStoredState<T>(
  storage_key: string, version: number, initial_state: T
) {
  const initial_state_ = useInitializeFromStorage(initial_state, storage_key, version)
  const [value, set_value] = useState(initial_state_)

  /** Set state and local storage values. */
  const set_stored_value = (value: T) => {
    writeStorage(value, storage_key, version)
    set_value(value)
  }

  return [value, set_stored_value] as const
}

export function useInitializeFromStorage<T>(initialValue: T, storageKey: string, storageVersion: number) {
  let storedInitialState = initialValue
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const is_mounted = useMounted()
  if (!is_mounted) {
    const storedValue = readStorage(storageKey, storageVersion)
    if (storedValue !== null) storedInitialState = storedValue
  }
  return storedInitialState
}

export type UsePopupConfig = {
  /** 
   * If true, only closes when `hide` function is called. 
   * This allows for multiple selections in a popup without closing it after 
   * something is clicked. 
   */
  keep_open_on_click?: boolean
  /** Called whenever the popup is closed. */
  on_hide?: () => void
}

/** 
 * Produce button and popup events to be used by a button to open a popup and 
 * by the popup container to control its visibility based on clicks inside 
 * and outside these elements.
 */
export function usePopup(
  config = {} as UsePopupConfig
) {
  const { on_hide } = config
  const [is_hidden, set_is_hidden] = useState(true)
  /** true means the last click was inside the button or popup elements. */
  const clicked_inside_ref = useRef<boolean>(false)

  /** Hide the element. */
  const hide = () => {
    if (on_hide) on_hide()
    set_is_hidden(true)
  }

  const handle_click_anywhere = () => {
    if (clicked_inside_ref.current) {
      // click was not outside => do nothing
      clicked_inside_ref.current = false
      return
    }
    // click was outside => hide menu
    hide()
  }

  /**
  * Detect when click outside the element happens and handles it
  */
  useEffect(() => {
    if (!is_hidden) {
      document.addEventListener('click', handle_click_anywhere)
      return () => {
        document.removeEventListener('click', handle_click_anywhere)
      }
    }
  }, [is_hidden, handle_click_anywhere])


  const toggle_visibility = (is_button: boolean) => {
    const new_is_hidden_state = !is_hidden
    if (new_is_hidden_state) {
      // if keep open is true, calling `hide` or clicking outside must be the
      // only ways to close the popup
      if (!is_button && config.keep_open_on_click) return
      if (on_hide) on_hide()
    }
    set_is_hidden(!is_hidden)
  }

  const handle_click = (is_button: boolean) => (e: React.MouseEvent) => {
    // register click on parent or one of its descandants
    // so cilck outside is not trigered.
    if (!is_hidden) clicked_inside_ref.current = true
    // if clicking the popup container => do nothing
    if (!is_button && e.target === e.currentTarget) return
    toggle_visibility(is_button)
  }

  const handle_key_down = (is_button: boolean) => (
    e: React.KeyboardEvent
  ) => {
    if (e.key === 'Enter') {
      toggle_visibility(is_button)
    }
  }

  /** Props to be spread into the button that toggles the popup */
  const button_events = {
    onKeyDown: handle_key_down(true),
    onClick: handle_click(true)
  }
  /** Props to be spread into the popup container */
  const popup_events = {
    onKeyDown: handle_key_down(false),
    onClick: handle_click(false)
  }
  return {
    button_events,
    popup_events,
    is_hidden,
    hide
  }
}

export function useGqlSubscription(
  subscription_callback: () => ZenObservable.Subscription | undefined,
  dependencies: unknown[]
) {
  const subscription_ref = useRef<ZenObservable.Subscription | undefined>()
  useEffect(() => {
    subscription_ref.current = subscription_callback()
    return () => {
      if (subscription_ref.current) subscription_ref.current.unsubscribe()
    }
  }, dependencies)
}