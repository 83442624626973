import { Card, CardSection, Chevron } from 'components'
import React, { useEffect, useRef, useState } from 'react'

import { GqlListFornecedoresSituacaoVeicularData, produce_services_list_ops } from './_operations'
import { SelectedScraperNames, selected_scraper_names_config, services_list_config } from './_shared-state'
import styles from './services-list.module.scss'
import { affix, append_unique_list, group_by_list, remove_item_from_list, remove_list_from_list, date_to_day_string, useSyncEffect, Maybe } from 'helpers'
import { useSharedState, useSharedStoredState } from 'controllers/tied'

type ServicesListsByState = {
  [uf: string]: GqlListFornecedoresSituacaoVeicularData
}

/** List of services from which the user can choose for verification. */
export function ServicesList() {
  const card_title = 'Consultar órgãos'

  const {
    is_loading_services_list,
    list_nacional,
    lists_estadual,
    lists_municipal,
    services_list
  } = useServicesList()

  const {
    selected_scraper_names,
    set_selected_scraper_names
  } = useSelectedScraperNames(services_list)

  if (is_loading_services_list)
    return <Card title={card_title}>Listando órgãos ...</Card>

  return (
    <Card title={card_title} no_padding card_class={styles.services_list}>
      <ServicesNacionais
        services_list={list_nacional}
        on_selection_change={set_selected_scraper_names}
        selected_scraper_names={selected_scraper_names} />
      <ServicesByState
        title='Estaduais'
        services_lists={lists_estadual}
        on_selection_change={set_selected_scraper_names}
        selected_scraper_names={selected_scraper_names}
      />
      <ServicesByState
        title='Municipais'
        services_lists={lists_municipal}
        on_selection_change={set_selected_scraper_names}
        selected_scraper_names={selected_scraper_names}
      />
    </Card>
  )
}

function ServicesNacionais(props: ServicesSubListProps) {
  if (!props.services_list) return (
    <div className={styles.loading_mesage}>
      Carregando...
    </div>
  )

  return (
    <CardSection title='Nacionais' start_open>
      <ServicesSubList {...props} className={styles.list} />
    </CardSection>
  )
}

type ServicesSubListProps = {
  selected_scraper_names: SelectedScraperNames
  services_list: GqlListFornecedoresSituacaoVeicularData | undefined
  on_selection_change: (new_selected_scraper_names: string[]) => void
  className?: string
}
function ServicesSubList(props: ServicesSubListProps) {
  const { selected_scraper_names, services_list } = props

  const handle_toggle_item = (scraper_name: string) => {
    const new_selected_scraper_names =
      selected_scraper_names.includes(scraper_name)
        ? remove_item_from_list(selected_scraper_names, scraper_name)
        : [...selected_scraper_names, scraper_name]
    props.on_selection_change(new_selected_scraper_names)
  }

  return (
    <ul className={props.className}>
      {services_list!.map(s => {
        const { scraper_name } = s
        const is_selected = selected_scraper_names.includes(scraper_name)
        const item_classname = affix('item', is_selected && 'CHECKED')
        return (
          <li
            key={s.scraper_name}
            onClick={() => { handle_toggle_item(scraper_name) }}
            className={styles[item_classname]}>
            <VersionedFriendlyName name={s.nome} />
          </li>
        )
      })}
    </ul>
  )
}

function VersionedFriendlyName({ name }: { name: string }) {
  const splitted_name = name.split('(')
  if (splitted_name.length === 1)
    return <span className={styles.item_text}>{name}</span>

  const scraper_friendly_name = splitted_name[0].trim()
  const version = `(${splitted_name[1]}`
  return (
    <span className={styles.item_text}>
      {scraper_friendly_name}
      <span className={styles.item_text_version}>{version}</span>
    </span>
  )
}

const state_names = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
}
const state_keys = Object.keys(state_names)

type ServicesByStateProps =
  Pick<ServicesSubListProps, 'selected_scraper_names' | 'on_selection_change'> & {
    services_lists: ServicesListsByState | undefined
    title: string
  }

function ServicesByState(props: ServicesByStateProps) {
  const { selected_scraper_names, services_lists, on_selection_change } = props
  if (!services_lists) return null
  return (
    <CardSection title={props.title} start_open>
      <ul className={styles.list}>
        {state_keys.map(uf => {
          if (!(uf in services_lists)) return null
          //@ts-expect-error
          const state_name = state_names[uf] as string
          return (
            <ServicesGroup
              key={uf}
              title={state_name}
              services_list={services_lists[uf]}
              on_selection_change={on_selection_change}
              selected_scraper_names={selected_scraper_names}
            />
          )
        })}
      </ul>
    </CardSection>
  )
}

type ServicesGroupProps = Pick<ServicesSubListProps,
  'services_list' | 'on_selection_change' | 'selected_scraper_names'> & {
    title: string
  }
function ServicesGroup(props: ServicesGroupProps) {
  const [is_open, set_is_open] = useState(false)
  const group_selection_ref = useRef<'' | 'CHECKED' | 'PARTIAL'>('')
  const toggle_open = () => { set_is_open(!is_open) }

  const { services_list, on_selection_change, selected_scraper_names } = props

  useSyncEffect(() => {
    if (!services_list) return
    const group_scraper_names = services_list.map(s => s.scraper_name)
    const selected_in_group = selected_scraper_names
      .filter(s => group_scraper_names.includes(s))
    const selection_count = selected_in_group.length
    const group_count = services_list.length
    if (selection_count === 0) {
      group_selection_ref.current = ''
      return
    }
    group_selection_ref.current =
      group_count === selection_count ? 'CHECKED' : 'PARTIAL'

  }, selected_scraper_names)

  if (!services_list) return null

  const handle_toggle_group = () => {
    const group_scraper_names = services_list.map(s => s.scraper_name)
    const new_selected_scraper_names = group_selection_ref.current === 'CHECKED'
      ? remove_list_from_list(selected_scraper_names, group_scraper_names)
      : append_unique_list(selected_scraper_names, group_scraper_names)
    on_selection_change(new_selected_scraper_names)
  }

  const checkbox_item_class = affix('item', group_selection_ref.current)

  return (
    <li>
      <p className={styles.sublist_header}>
        <Chevron
          down={is_open}
          on_click={toggle_open}
          className={styles.chevron} />
        <span
          onClick={handle_toggle_group}
          className={styles[checkbox_item_class]}>
          <span className={styles.item_text}>{props.title}</span>
        </span>
      </p>
      {!is_open ? null : (
        <ServicesSubList
          className={styles.sublist}
          services_list={services_list}
          on_selection_change={on_selection_change}
          selected_scraper_names={selected_scraper_names}
        />
      )}
    </li>
  )
}

function useServicesList() {
  const [services_list, set_services_list] =
    useSharedState(services_list_config, 'services-list')
  const is_loading_services_list = !services_list

  const [list_nacional, set_list_nacional] =
    useState<GqlListFornecedoresSituacaoVeicularData>()
  const [lists_estadual, set_list_estadual] = useState<ServicesListsByState>()
  const [lists_municipal, set_list_municipal] = useState<ServicesListsByState>()

  // ensures the list is refreshed daily at least
  // if the user keeps a tab open indefinitelly
  const today = date_to_day_string(new Date())
  useEffect(() => {
    const { list_fornecedores_situacao_veicular } = produce_services_list_ops()
    list_fornecedores_situacao_veicular({
      on_completed: (new_services_list) => {
        function list_ordered_by_uf(grouped_lists: typeof grouped_lists_estadual) {
          return state_keys.reduce((prev, uf) => {
            const uf_list = grouped_lists[uf]
            if (!uf_list) return prev
            return [...prev, ...uf_list]
          }, [] as typeof new_services_list)
        }

        const flat_list_nacional =
          new_services_list.filter(s => s.sub_tipo === 'NACIONAL')

        const flat_list_estadual =
          new_services_list.filter(s => s.sub_tipo === 'ESTADUAL')
        const grouped_lists_estadual =
          group_by_list(flat_list_estadual, 'uf', state_keys)
        const ordered_list_estadual =
          list_ordered_by_uf(grouped_lists_estadual)

        const flat_list_municipal =
          new_services_list.filter(s => s.sub_tipo === 'MUNICIPAL')
        const grouped_lists_municipal =
          group_by_list(flat_list_municipal, 'uf', state_keys)
        const ordered_list_municipal =
          list_ordered_by_uf(grouped_lists_municipal)


        set_list_nacional(flat_list_nacional)
        set_list_estadual(grouped_lists_estadual)
        set_list_municipal(grouped_lists_municipal)
        // order in the same way the user will see
        // other components (like search-starter) will need this ordered
        set_services_list([
          ...flat_list_nacional,
          ...ordered_list_estadual,
          ...ordered_list_municipal
        ])
      },
      on_errors: (e) => { console.log(e) }
    })
  }, [today])

  return {
    is_loading_services_list,
    lists_estadual,
    list_nacional,
    lists_municipal,
    services_list
  }
}

function useSelectedScraperNames(
  services_list: Maybe<GqlListFornecedoresSituacaoVeicularData>
) {
  const [selected_scraper_names, set_selected_scraper_names] =
    useSharedStoredState(selected_scraper_names_config, 'services-list')

  useEffect(() => {
    if (!(services_list?.length && selected_scraper_names.length)) return
    const valid_scraper_names = services_list.map(s => s.scraper_name)
    const valid_selected_scraper_names = selected_scraper_names.filter(n => valid_scraper_names.includes(n))
    // all selected names are valid => do nothing
    if (selected_scraper_names.length === valid_scraper_names.length) return
    // one or more invalid selections 
    // => remove them to avoid bugs related to this condition
    set_selected_scraper_names(valid_selected_scraper_names)
  }, [services_list])

  return {
    selected_scraper_names,
    set_selected_scraper_names
  }
}