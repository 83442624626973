import React from 'react'

import styles from './results-summary.module.scss'
import { ErrorMessage } from './error-message'
import { DebitosRestricoes } from '../../../situation-search'
import { SituationsBlock } from './situations-block'
import { GqlStatusDebitoRestricao } from 'codegen/gql-types'

export type ResultsSummaryProps = {
  debitos_restricoes: DebitosRestricoes
  className: string
}

export const all_situation_keys = [
  'licenciamento',
  'dpvat', // TODO: remove this
  'ipva',
  'multas',
  'divida_ativa',
  'restricoes',
  'inspecao_veicular', // TODO: remove this
  'vistorias',
] as const

/** Summary of a specific situation. */
export function ResultsSummary(props: ResultsSummaryProps) {
  const { debitos_restricoes } = props
  const { status } = debitos_restricoes

  const has_failed = (
    status === 'FAILED'
    || debitos_restricoes.scrape_response_errors?.length
    || debitos_restricoes.request_error
  )
  if (has_failed)
    return <ErrorMessage debitos_restricoes={debitos_restricoes} />

  const hack_merge_status = (
    s1: GqlStatusDebitoRestricao | undefined,
    s2: GqlStatusDebitoRestricao
  ) => {
    if (!s1) return s2
    if (!s2) return s1
    if (s1 === 'CONSTA') return s1
    return s2
  }

  const join_dpvat_hack = () => {
    const { licenciamento, dpvat } = debitos_restricoes
    if (!dpvat?.value) return debitos_restricoes
    const result = {
      ...debitos_restricoes,
      licenciamento: {
        ...licenciamento,
        status: hack_merge_status(licenciamento?.status, dpvat.status),
        value: (licenciamento?.value || 0) + dpvat.value
      }
    }
    return result
  }

  const join_vistorias_hack = () => {
    const { vistorias, inspecao_veicular } = debitos_restricoes
    if (!inspecao_veicular?.details) return debitos_restricoes
    const result = {
      ...debitos_restricoes,
      vistorias: {
        ...vistorias,
        status: hack_merge_status(vistorias?.status, inspecao_veicular.status),
        details: !inspecao_veicular.details.length
          ? vistorias?.details
          : !vistorias?.details?.length
            ? inspecao_veicular.details
            : [...vistorias.details, ...inspecao_veicular.details]
      }
    }
    return result
  }

  // prevent showing inconsistent result when no data is available
  const has_no_data = all_situation_keys.findIndex(
    k => debitos_restricoes[k] != null
  ) === -1
  if (has_no_data) return (
    <div className={props.className}>
      Dados em processo de captura...
    </div>
  )

  return (
    <div className={props.className}>
      <SituationsBlock
        className={styles.situations_block}
        selected_situations_keys={['multas', 'divida_ativa']}
        debitos_restricoes={debitos_restricoes}
      />
      <SituationsBlock
        className={styles.situations_block}
        selected_situations_keys={['ipva', 'licenciamento']}
        debitos_restricoes={join_dpvat_hack()}
      />
      <SituationsBlock
        className={styles.situations_block}
        selected_situations_keys={['vistorias', 'restricoes']}
        debitos_restricoes={join_vistorias_hack()}
      />
    </div>
  )
}