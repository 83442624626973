import { PropsWithChildren } from 'react'
import { FriendlyError, StringObject, Widen } from 'helpers'
import { ProduceMutationConfig, ProduceQueryConfig } from 'app/_client'
import { Exact, GqlSuccess } from 'codegen/gql-types'

export type DataEntryApiCore<T extends StringObject> = {
  initialParams: DataEntryParams<T>
  initialState: DataEntryState
  props: DataEntryProps<T>
}

export type Entry<T> = Omit<T, '__typename' | 'id'>

type EntryOpsProducer<T> = () => {
  add_entry: (ops_config: ProduceMutationConfig<
    { id: string },
    Exact<{ id_cliente: string, new_entry: T }>>
  ) => void
  update_entry: (ops_config: ProduceMutationConfig<
    GqlSuccess,
    Exact<{ entry_id: string, updated_entry: T }>>
  ) => void
  get_entry: (ops_config: ProduceQueryConfig<T, { entry_id: string }>) => void
  remove_entry: (ops_config: ProduceMutationConfig<
    GqlSuccess,
    Exact<{ entry_id: string }>>
  ) => void
}

/** Props required to the parent of a DataEntry, responsible for . */
export type SpecificEntryProps<T extends StringObject> = Pick<DataEntryProps<T>,
  'on_entry_added'
  | 'on_entry_removed'
  | 'on_entry_reset'
  | 'selected_entry_id'
  | 'on_entry_updated'
>

export type FieldProps<T extends StringObject, V = string> =
  Required<{ [k in keyof Entry<T>]: V }>

export type BeforeEntryMutation<T extends StringObject> =
  (entry: T, mutation_type: 'ADD' | 'UPDATE') => T
export type OnEntryAdded<T extends StringObject> =
  (added_entry: Widen<Entry<T>> & { id: string }) => void
export type OnEntryUpdated<T extends StringObject> =
  (updated_entry: Widen<T>, updated_entry_id: string) => void
export type OnEntryRemoved = (removed_entry_id: string) => void
export type OnEntryLoaded<T extends StringObject> = (entry: T) => void

export type DataEntryProps<T extends StringObject> = PropsWithChildren<{
  className?: string
  /** Porperties of text inputs for each editable field. */
  field_labels: FieldProps<T>
  selected_entry_id: string | undefined
  required_fields: (keyof Omit<T, 'id'>)[]
  /** User is clearing all to add a new entry. */
  on_entry_reset: () => void
  /** User just added a new entry. */
  on_entry_added: OnEntryAdded<T>
  /** User requested to delete this entry. */
  on_entry_removed: OnEntryRemoved
  /** User saved changes. */
  on_entry_updated: OnEntryUpdated<T>
  /** Allow changing the entry value before adding/ updating. */
  before_entry_mutation?: BeforeEntryMutation<T>
  /** Entry data was just loaded. */
  on_entry_loaded?: OnEntryLoaded<T>
  /** Gql operations that all data entries use. */
  produce_entry_ops: EntryOpsProducer<T>
  /** How entry is called in coomunications with the user */
  entry_label: string
  card_titles: {
    /** Card title to be shown if no existing entry is being edited */
    new_entry: string
    /** Card title to be shown if an existing entry is being edited */
    selected_entry: string
  }
}>

export const initialState = {
  error: undefined as FriendlyError | undefined,
  entry_id: undefined as string | undefined
}
export type DataEntryState = typeof initialState

export type DataEntryParams<T extends StringObject> = {
  entry: Entry<T> | undefined
}