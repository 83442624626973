import { StoredState } from 'controllers/tied'
import { GqlOrderBy } from 'codegen/gql-types'
import { ObservableQuery } from 'apollo-client'
import { FooterStatus } from 'app/footer-bar/status-text'
import { TableOps, TableQueryVariables, ListSheetResult } from './_dynamic_ops'
import {
  GqlFilterTerms,
  GqlDataTypes,
  GqlSheetName
} from 'codegen/gql-ops'
import { CellClickEvent } from './grid/_data'

export type TableApiCore<GqlRow = undefined, C extends ColumnsList = ColumnsList> = {
  props: TableProps<GqlRow, C>
  storageKey: string
  operations: TableOps
  initialState: TableState
  initialParams: TableParams
  storedState: typeof storedState
  /** List of columns to be shown. */
  visible_columns_ids_state: [
    readonly (keyof GqlRow | string)[],
    React.Dispatch<React.SetStateAction<readonly (keyof GqlRow | string)[]>>
  ]
}

export type TableProps<GqlRow, C extends ColumnsList> = {
  /** Current `cliente` selection. */
  id_cliente: string,
  /** Name of the query as defined in the backend interface. */
  sheet_name: GqlSheetName
  /** All fields configuration information. */
  fields: FieldsDefinition<C, GqlRow>
  /** All fields available for this sheet. */
  columns_list: C
  /** Default fields to be shown in the table's columns. */
  visible_columns_ids: readonly (keyof GqlRow | string)[]
  /** Default fields to be shown in the table's columns. */
  required_fields_ids?: (keyof GqlRow)[]
  /** User clicked a cell. */
  on_click?: TableOnClick<GqlRow>
}

/** Table on_click event. */
export type TableOnClick<T = undefined> = (e: CellClickEvent<T>) => void


// The order of these values is important!
// Phase progression from request to fetch phases is done adding 1 to the 
// current value.
export enum TablePhase {
  /** 0 - Wating for initial data do be loaded from other components. */
  MOUNTING,
  /** 1 - Loading data for the first time for the current query parameters.  */
  REQUESTING_NEW,
  /** 2 - Loaded the first set of data for the current query parameters. */
  FETCHED_NEW,
  /** 3 - Requesting an expansion on the date set. */
  REQUESTING_MORE,
  /** 4 - Received a data set expansion. */
  FETCHED_MORE,
  /** 5 - Requesting an expansion on the date set. */
  REQUESTING_VOID,
  /** 6 - Received a data set expansion. */
  FETCHED_VOID,
  /** 7 - User changed the list of visible columns. */
  CHANGING_FIELDS,
  /** 8 - Error returned from server. */
  ERROR,
}
/** Whenever change_phase is called to enter a request phase, more data is fetched. */
export const REQUEST_PHASES = [
  TablePhase.REQUESTING_NEW,
  TablePhase.REQUESTING_MORE,
  TablePhase.REQUESTING_VOID,
]
/** Whenever new data arrives, phase is changed to a fetch phase so the ui can 
 * be updated. */
export const FETCH_PHASES = [
  TablePhase.FETCHED_NEW,
  TablePhase.FETCHED_MORE,
  TablePhase.FETCHED_VOID,
]

/** Marximun automatically calculated width for a col in rems. */
export const max_col_auto_width = 25
export const rows_count = 200

export const initialState = {
  /** Data comming from the list_sheet query. */
  data: undefined as Data | undefined,

  /** Current phase of the Table component. */
  phase: TablePhase.MOUNTING,

  /** Variables used on the last request. */
  offset: 0 as TableQueryVariables['offset'],
  limit: rows_count as TableQueryVariables['limit'],
  filter_terms_list: [] as TableQueryVariables['filter_terms_list'],
  order_by_list: [] as GqlOrderBy[],
  /** Change this to force all filters state  reinitialization. */
  filters_key: 0,

  /** Defined or calculated widths for all columns. */
  column_widths: undefined as undefined | number[],

  /** Text to be shown by the footer bar. */
  status_text: undefined as FooterStatus
}
export type TableState = typeof initialState

export const storedState: StoredState<TableState> = {
  v: 1,
  keys: [
    // offset and limit are not kept due to the complexity to initialize the 
    // grid parameters
    'filter_terms_list',
    'order_by_list',
  ]
}

export const initialParams = {
  /** Total number of rows to be rendered. */
  rows_count: rows_count,
  /** Hold last loaded data so it is possible to identify when changed. */
  last_data: undefined as undefined | Data,
  /** Observable query responsible for retreiving the table's data.  */
  list_sheet: undefined as undefined | {
    query: ObservableQuery<ListSheetResult, TableQueryVariables>
    observable: ZenObservable.Subscription
    /** Dynamically generated graphql document. */
    document: object
  }
}
export type TableParams = typeof initialParams

/**
 * Object will all fields available to a table.
 */
export type Fields = { readonly [index: string]: Field | CalculatedField }

/** Min widths by cell type have a higher priority. */
const min_widths_by_cell_type: { [K in CellTypes]?: number } = {
  Month: 5.125,
  Day: 6.75,
  Currency: 6,
}
/** Min widths by data type have a lower priority. */
const min_widths_by_data_type: { [K in GqlDataTypes]?: number } = {
  BINARY_ID: 18.5,
  FLOAT: 5,
}
export const MIN_COL_WIDTHS = {
  by_cell_type: min_widths_by_cell_type,
  by_data_type: min_widths_by_data_type,
}
/** Function used to define a fields object, making sure it is complete, as defied by the columns list tuple T. */
export type FieldsDefinition<
  ColumnsTuple extends readonly [...(string | keyof GqlRow)[]],
  GqlRow
  > = {
    [K in ColumnsTuple[number]]: K extends keyof GqlRow ? Field : CalculatedField
  }

/**
 * Properties that instruct how headers, filters and cells should be rendered for each field.
 */
export type Field<T extends CellTypes = CellTypes> =
  T extends 'Options'
  ? ({ cell_type: 'Options' } & FieldProps & GridOptionsConfig)
  : ({ cell_type: T } & FieldProps & GridCellConfig)

export type CalculatedField<T extends CellTypes = CellTypes> =
  T extends 'Options'
  ? ({ cell_type: 'Options' } & CalculatedFieldProps<T> & GridOptionsConfig)
  : ({ cell_type: T } & CalculatedFieldProps<T> & GridCellConfig)

/** Props that are commom to all cell types */
type CommomFieldProps = {
  /** Label to be shown in the table header. */
  label: string
  /** Explanation so the user better understands the nature of the field. */
  tooltip?: string
  /** If true, always include in query, even if column is not shown. */
  is_required?: boolean
  /** Calculate the cell value based on all values in the current row. */
  parse_value?: (row_data: RowData) => CellValue<CellTypes>
  /** Comma separated list of subfields to be used for map and maps_list data types. */
  subfields?: string
}

/** Props for fields comming from the server. */
type FieldProps =
  CommomFieldProps & {
    /** Data type is used by the backend for data normalization */
    data_type: GqlFilterTerms['data_type']
  }

/** Props for fiields with locally calculated values. */
type CalculatedFieldProps<T extends CellTypes> =
  CommomFieldProps & {
    /** Calculate the cell value based on all values in the current row. */
    parse_value: (row_data: RowData) => CellValue<T>
  }

/** Value types for each cell type. */
type CellValue<T extends CellTypes> =
  T extends 'Check' ? CheckCellValue
  : T extends 'Currency' ? number
  : string

/** Value of Check typed cells.  */
export type CheckCellValue = 'CHECKED' | 'UNCHECKED' | null

/** 
 * See parse_cell_value to understand how each cell type is formatted.
 * If a renderer named after a given cell_type exists in grid/renderers, it 
 * will be used to render the contents of this type of field.
 */
export type CellTypes =
  | 'Day'
  | 'Month'
  | 'DateTime'
  | 'Text'
  | 'Number'
  | 'Options'
  | 'Currency'
  | 'Check'
  | 'Icon'

/** List of all celltypes that perform an action on click. */
export const action_cell_types: CellTypes[] = [
  'Check'
]

type GridCellConfig = {
  config?: {}
  /** default => center */
  align?: 'left' | 'center' | 'right' | undefined
}
type GridOptionsConfig = GridCellConfig & {
  config: {
    dictionary: GridOptionsDictionary
  }
}
/** Dictionary with labels to replace each value in a field with 
 * cell_type =  'Options' */
export type GridOptionsDictionary = {
  [option: string]: string
}

/** Determines which fields are rendered in columns, and their order. */
export type ColumnsList<F extends Fields = Fields> = readonly (keyof F)[]
// export type ColumnsList<F extends Fields = Fields> = ((keyof F) & 'string')[]

/**
 * Columns in a row are uniquely identified by their keys. Position is defined in visible_columns_ids.
 */
export type RowData<T = undefined> = T extends undefined
  ? {
    [index: string]: any
    /** unique row id */
    id: string
  }
  : T

/**
 * 2-dimensional data table to be rendered, each item in the list is a row.
 */
export type Data<T = undefined> = RowData<T>[]

/** Extract from GqlRow the fields in RequiredFieldsTuple and make
 *  them non-optional. */
export type RequiredFields<
  GqlRow,
  RequiredFieldsTuple extends (keyof GqlRow)[]
  > = Required<Pick<GqlRow, RequiredFieldsTuple[number]>>