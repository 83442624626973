// TODO: invert this. Create the types here and import in the parent.
import { Data, ColumnsList, Fields, TablePhase, RowData } from '../_data'
import { GridOnThreshold, GridOnLoadVoid } from './_event-types'

const threshold_distance = 30

export type GridApiCore<GqlRow = undefined> = {
  props: GridProps<GqlRow>
  initialParams: GridParams
  initialState: typeof initialState
}

export type GridProps<GqlRow> = {
  /** Current phase of the table component. */
  table_phase: TablePhase
  /** All data displayed in the window. */
  data: Data<GqlRow> | undefined
  /** Total number of rows to be rendered. */
  rows_count: number
  /** Definition of all possible fields, not just the ones in visible_columns_ids. */
  fields: Fields
  /** Columns being shown. */
  visible_columns_ids: readonly (string | keyof GqlRow)[]
  /** Raised when the user scrolls from between twot thresholds over one of 
   * them. */
  on_threshold: GridOnThreshold
  /** Raised when user scrolls into a top void row. */
  on_load_void: GridOnLoadVoid
  /** Event raised when user clicks a cell. */
  on_click: ((e: CellClickEvent<GqlRow>) => void) | undefined
}

/** Target cell information. */
export type CellClickEvent<T = undefined> = {
  /** Value of the clicked cell. */
  value: unknown
  /** Fidl id of the clicked cell. */
  field_id: string
  /** Raw row data. */
  row_data: RowData<T>
  /** Parsed visible columns, as seen by the user. */
  parsed_row: Record<keyof T, string>
  /** Callback to asynchronouly update the row's raw data. */
  update_row: (new_row_data: RowData<T>) => void
}

export const initialState = {
  // change this to force grid to update
  update: 0
}

export const initialParams = {
  /** Holds the table phase value from laste render. */
  previous_table_phase: TablePhase.MOUNTING,

  /** ClassName for cells of each column. */
  col_css_list: undefined as undefined | string[],

  /** Columns list used to generate the current structure. */
  visible_columns_ids: undefined as undefined | ColumnsList,

  /** Reference row holding the largest strgins for each column. To ensure the table column sizes grow, but do not shrink with new data. */
  largest_cells: undefined as undefined | Record<ColumnsList[number], string>,

  layout: {
    /** Heights in pixels of all blocks previously rendered above the currently
     * block zero. */
    top_void_heights: [] as number[],
    /** Groups of rows to be rendered in each tbody (top/ bottom) */
    data_blocks: [[], []] as [Data | [], Data | []],
    /* used as key to preserve data block between renders  */
    first_block: 0,
    /** Defines what row in each block will be the threshold */
    threshold_in_block: [threshold_distance, Infinity] as [
      /** Upper threshold, in block 0 */
      number,
      /** Lower threshold, in block 1 */
      number
    ],
    /** Number of the first row in each block. */
    first_rows: [1, 1] as [number, number],
  },
  /** Indicates which edge is being currently expanded. */
  expanding_edge: null as Edge | null,
  /** Indicates the grisd is rehydrating its layout in the current render. */
  is_rehydrating: false,
  threshold_distance,
  edge_position: {
    upper: undefined as LastEdgePosition,
    lower: undefined as LastEdgePosition
  },
  /** Index of voids visible on screen. */
  visible_voids: [] as number[],

  block_0_ref: undefined as undefined | React.RefObject<HTMLTableSectionElement>
}
export type GridParams = typeof initialParams
export type Layout = typeof initialParams['layout']
export type EdgePosition = 'bellow' | 'inside' | 'above'
export type LastEdgePosition = EdgePosition | undefined

/** Where in the data window the intersection is happening. */
export type Edge = 'lower' | 'upper'
