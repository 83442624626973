import { WhiteLabelHost } from 'app/_router'
import { LogoSVG } from './_types'

import * as brobot from './brobot'
import * as vericar from './vericar'

export const logos_by_host: LogosByHost = {
  brobot,
  vericar
}

type LogosByHost = {
  [host in WhiteLabelHost]: {
    LogoSVG: LogoSVG
    top_bar_wrapper_css: string | undefined
  }
}