import React from 'react'
import { useApi } from 'controllers/tied'
import { Card, Text, Button, Svg } from 'components'

import styles from './archive-search.module.scss'
import * as api_handlers from './_api'
import { ArchiveSearchApiCore, ArchiveSearchProps, initialState } from './_data'
import { RangeFilter } from './range-filter'
import { UserFilter } from './user-filter'

export function ArchiveSearch(props: ArchiveSearchProps) {
  const api_core: ArchiveSearchApiCore = {
    props,
    initialState
  }
  const { api, state } = useApi(api_core, api_handlers)

  return (
    <Card
      wrapper_class={styles.archive_search}>
      <div className={styles.search_row}>
        <Text
          key={state.search_term_reset_key}
          label='Placa ou renavam'
          className={styles.search_text}
          on_return={api.handle_search_term}
          on_change={api.handle_term_change}
          color='BORDERED' />
        <div className={styles.search_button_wrapper}>
          <Button
            className={styles.search_button}
            onClick={api.handle_search_term}
            layout='SLIM'>
            <Svg icon='search' />
            Buscar
          </Button>
        </div>
      </div>
      <div className={styles.filters_row}>
        <div className={styles.organization_filters}>
          <UserFilter
            user={props.user}
            on_clear={api.clear_user_filter}
            on_change={api.handle_user_filter_change}
            selected_user={state.selected_user}
          />
        </div>
        <div className={styles.date_filters}>
          <RangeFilter
            label='Dia'
            selected_date_range={state.selected_date_range}
            on_clear={api.clear_range_filter}
            on_change={api.handle_range_filter_change} />
          <RangeFilter
            label='Semana'
            limit_height
            selected_date_range={state.selected_date_range}
            on_clear={api.clear_range_filter}
            on_change={api.handle_range_filter_change} />
          <RangeFilter
            label='Mês'
            limit_height
            selected_date_range={state.selected_date_range}
            on_clear={api.clear_range_filter}
            on_change={api.handle_range_filter_change} />
        </div>
      </div>
    </Card>
  )
}
