import React from 'react'

import { Svg } from './svg'
import styles from './issue-icon.module.scss'

export type Issue = undefined | {
  type: 'danger' | 'attention'
  tooltip: string
}

type IssueIconProps = {
  issue: Issue
  className?: string
}

export function IssueIcon(props: IssueIconProps) {
  const { issue } = props
  if (!issue) return null

  const icon_css = `icon-${issue.type}`
  return (
    <div className={styles.issue_icon}>
      <Svg size='small' icon={issue.type} className={styles[icon_css]} />
      <span className={styles.tooltip}>{issue.tooltip}</span>
    </div>
  )
}