import { compose, UTF8, affix, useStoredState } from 'helpers'
import React, { useState } from 'react'

import styles from './help.module.scss'
import { PopupHookProps, useClickAnywhere } from '../_hooks'
import { Svg } from 'components/basic'

export type HelpProps = React.PropsWithChildren<
  PopupHookProps & {
    /** Composed class applied to the component wrapper. */
    added_styles?: string
    /** String to be shown at the top of the help container. */
    title: string
    /** Unique component key identificator. */
    component_key: string
    /** Help version. When changed, stored collapsed state for this
     * component_key status is reset. */
    version: number
  }
>

export function Help(props: HelpProps) {
  if (props.hidden) { return null }
  return <HelpActive {...props} />
}

function HelpActive(props: HelpProps) {
  const [collapsed, set_collapsed] = useStoredState(
    `help@${props.component_key}`, props.version, false
  )
  const toggle_collapsed = () => { set_collapsed(!collapsed) }

  useClickAnywhere(props)

  const help_css = affix('help', collapsed, 'COLLAPSED')
  const help_styles = compose(props.added_styles, styles[help_css])

  if (collapsed) return (
    <dl className={help_styles}>
      <div><button onClick={toggle_collapsed}>?</button></div>
    </dl>
  )

  return (
    <dl className={help_styles}>
      <div className={styles.header}>
        <button onClick={toggle_collapsed}>
          <Svg icon='close' />
        </button>
        {props.title}
        <i>Clique nas setas à esquerda para ver detalhes e exemplos.</i>
      </div>
      {props.children}
    </dl>
  )
}

type HelpTopicProps = React.PropsWithChildren<{
  tag: string
  title: string
}>
export function HelpTopic(props: HelpTopicProps) {
  const [is_collapsed, set_is_collapsed] = useState(true)
  const toggle_collapsed = () => { set_is_collapsed(!is_collapsed) }
  return <>
    <dt>
      <button
        className={styles.toggle_collapse_button}
        onClick={toggle_collapsed}>
        {is_collapsed ? UTF8.right_triangle : UTF8.down_triangle}
      </button>
      <mark>{props.tag}</mark>
      {props.title}
    </dt>
    {is_collapsed ? null : (
      <dd>
        {props.children}
      </dd>
    )}
  </>
}

