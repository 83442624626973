import React from 'react'
import { useSelector } from 'controllers/tied'
import { CarouselThumbnail } from 'components'
import { format_datetime, Maybe, to_snake } from 'helpers'
import { broadcast_carousel_images, CarouselImage } from 'components/carousel/broadcast_carousel_images'

import { FileInfo } from '../_data'
import styles from './screenshots.module.scss'

type ScreenshotsProps = {
  nome_fornecedor: Maybe<string>
  screenshot_files: Maybe<FileInfo[]>
  certificate_files: Maybe<FileInfo[]>
  className: string
}
/** Show screenshots and manege elargement on click */
export function Screenshots(props: ScreenshotsProps) {
  const { screenshot_files } = props

  const snake_nome_fornecedor = to_snake(props.nome_fornecedor || '')
  const images_count = screenshot_files?.length || 0
  const carousel_images: CarouselImage[] | undefined = !images_count
    ? get_no_image_props(props)
    : screenshot_files!.map(get_image_props(props, snake_nome_fornecedor))

  // broadcast images to be shown by the carousel at the top of the page
  useSelector(broadcast_carousel_images, carousel_images, snake_nome_fornecedor)

  const first_image = carousel_images && carousel_images[0]
  if (!first_image) return null

  return (
    <div className={props.className}>
      <CarouselThumbnail
        className={styles.thumbnail}
        first_image={first_image}
        images_count={images_count}
      />
    </div>
  )
}

function get_no_image_props({ certificate_files, nome_fornecedor }: ScreenshotsProps) {
  const no_image_message = certificate_files?.length
    ? 'Comprovante no pdf.'
    : 'Tela não capturada.'
  return [{ label: `${nome_fornecedor}`, no_image_message }]
}

function get_image_props(
  { screenshot_files, nome_fornecedor }: ScreenshotsProps,
  snake_nome_fornecedor: string
) {
  const has_multiple_files = screenshot_files && screenshot_files.length > 1

  return (img: FileInfo, img_idx: number) => {
    const { url, utc_time_of_capture } = img
    // build file name
    const datetime_of_capture = format_datetime(utc_time_of_capture)
    const snake_date_time = to_snake(datetime_of_capture)
    const file_name = has_multiple_files
      ? `${snake_nome_fornecedor}_${snake_date_time} (${img_idx + 1})`
      : `${snake_nome_fornecedor}_${snake_date_time}`
    // build label
    const label = has_multiple_files
      ? `${nome_fornecedor} (${img_idx + 1})`
      : `${nome_fornecedor}`
    return { url, file_name, label }
  }
}