import { GqlStatusVerificacao } from 'codegen/gql-types'
import { Maybe } from 'helpers'

/** Given a status from a verificacao, derives if the job is in progress. */
export const derive_job_in_progress = (status: Maybe<GqlStatusVerificacao>) => {
  const job_in_progress =
    !status
    || status === 'REQUESTED'
    || status === 'STARTED'
  return job_in_progress
}