import React, { useEffect } from 'react'
import { Button, Card, CardFooter, ErrorBox, Text } from 'components'

import {
  DataEntryApiCore,
  DataEntryProps,
  Entry,
  initialState,
} from './_data'
import * as api_handlers from './_api'
import styles from './data-entry.module.scss'
import { useApi } from 'controllers/tied'
import { CardHeader } from 'components/card/card-header'
import { child_is_component, StringObject, compose, AnyObject } from 'helpers'
import { TextProps } from 'components/text/_data'
import { AutoComplete, AutoCompleteProps } from 'components/basic'

export function DataEntry<T extends StringObject>(props: DataEntryProps<T>) {
  const { selected_entry_id, card_titles, entry_label } = props
  const api_core: DataEntryApiCore<T> = {
    props,
    initialParams: { entry: undefined },
    initialState
  }
  const { api, state, params } = useApi(api_core, api_handlers)

  useEffect(() => { api.change_selected_entry() }, [selected_entry_id])

  const { entry_id } = state
  const { error } = state
  const { entry } = params

  const is_new_entry = !entry_id
  const card_title = is_new_entry
    ? card_titles.new_entry
    : card_titles.selected_entry

  const submit_label = entry_id
    ? 'Salvar alterações'
    : `Adicionar ${entry_label}`

  return (
    <Card key={entry_id} on_submit={entry_id ? api.update_entry : api.add_entry}>
      <CardHeader title={card_title} hide_children={is_new_entry}>
        <Button
          layout='SLIM'
          color='MAIN_SECONDARY'
          onClick={api.reset_data_entry}
          className={styles.new_entry_button}>
          {card_titles.new_entry}
        </Button>
      </CardHeader>

      {enrich_children(props.children, api.handle_field_change, entry)}

      <ErrorBox error={error} />

      <CardFooter>
        {!entry_id ? null : (
          <Button
            color='DANGER_SECONDARY'
            onClick={api.remove_entry}>
            {`Remover ${entry_label}`}
          </Button>
        )}
        <Button type='submit'> {submit_label}</Button>
      </CardFooter>
    </Card>
  )
}

type ChangeHandler = (text: string, field_id: string) => void
const component_enrichers = {
  text_input: (
    props: TextProps<string>,
    change_handler: ChangeHandler,
    initial_value: string
  ) => {
    const { id } = props
    if (id === undefined)
      throw new Error(`Missing id in text input data entry child.`)
    return (
      <Text
        {...props}
        key={id}
        className={compose(props.className, styles.text_input)}
        on_change={change_handler}
        initial_text={initial_value} />
    )
  },

  autocomplete: (
    props: AutoCompleteProps<AnyObject>,
    change_handler: ChangeHandler,
    initial_value: string
  ) => {
    const { id } = props
    if (id === undefined)
      throw new Error(`Missing id in autocomplete data entry child.`)
    return (
      <AutoComplete
        {...props}
        key={id}
        initial_value={initial_value}
        className={compose(props.className, styles.text_input)}
        on_select={(option) => {
          const selected_value = option[props.value_prop] as string
          change_handler(selected_value, id)
        }} />
    )
  }
}

type CustomComponent<T = any> = React.JSXElementConstructor<T>
const components_map = new Map<CustomComponent, keyof typeof component_enrichers>()
components_map.set(Text, 'text_input')
components_map.set(AutoComplete, 'autocomplete')

function enrich_children<T extends StringObject>(
  children_: React.ReactNode | undefined,
  handle_change: ChangeHandler,
  entry: Entry<T> | undefined,
): React.ReactNode {
  // https://frontarm.com/james-k-nelson/passing-data-props-children/
  const children = React.Children.toArray(children_)

  const enriched_children = children.map(child => {
    // if a scalar => do nothing
    if (!child_is_component(child)) return child

    // check if it is a native react component
    if (typeof child.type === 'string') {
      // native component with no child => do nothing
      if (!('children' in child.props)) return child

      const enriched_child_children =
        enrich_children(child.props.children, handle_change, entry)
      const cloned_result =
        React.cloneElement(child, { children: enriched_child_children })
      return cloned_result
    }

    const component_key = components_map.get(child.type)
    if (!component_key) return child

    const enrich_component = component_enrichers[component_key]

    const id = child.props.id as string | undefined
    const initial_value = ((id && entry && entry[id]) || '') as string
    const result = enrich_component(child.props, handle_change, initial_value)
    return result
  })

  return enriched_children
}
