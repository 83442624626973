import React, { useEffect, useRef, useState } from 'react'
import { format_currency, useMount, useSyncEffect } from 'helpers'
import { SimpleTable, LabeledValue } from 'components'
import { SimpleTableColumns } from 'components/simple-table/_data'
import { format } from 'components/simple-table/formatters'
import { LinkIcon } from 'components/simple-table/renderers'

import { GqlListMultasData, produce_list_multas_ops } from './_operations'
import styles from './list-multas.module.scss'
import { pushBroadcast } from 'controllers/tied'
import { broadcast_selected_multas_value } from './_broadcasts'

const table_columns: SimpleTableColumns<GqlListMultasData[number]> = {
  nome_fornecedor: {
    header: 'Órgão Consultado'
  },
  ait: {
    header: 'AIT'
  },
  valor: {
    header: 'Valor',
    format_value: format.currency
  },
  data_vencimento: {
    header: 'Vencimento',
    format_value: format.day
  },
  boleto_file_url: {
    header: 'Boleto',
    renderer: LinkIcon,
    derive_icon: () => 'file_invoice'
  },
  orgao_infracao: {
    header: 'Órgão Autuador'
  },
  data_hora_infracao: {
    header: 'Data Infração',
    format_value: format.datetime
  },
  descricao_infracao_original: {
    header: 'Descrição'
  },
  municipio_infracao: {
    header: 'Cidade'
  },
  local_infracao: {
    header: 'Local'
  },
}

type ListMultasProps = {
  id_verificacao: string
  is_hidden?: boolean
  pending: boolean | undefined
}

export function ListMultas(props: ListMultasProps) {
  const multas = useMultas(props.id_verificacao)
  const [
    selected_multas_currency,
    selected_multas,
    handle_selection_change
  ] = useMultasSelection(multas)

  if (props.is_hidden) return null
  const is_empty = !multas?.length

  const empty_table_message = props.pending
    ? 'Aguarde, consolidando a tabela de penalidades...'
    : 'Não há penalidades.'

  return (<>
    {is_empty ? null : (
      <LabeledValue
        label='Total selecionado'
        value={selected_multas_currency}
        className={styles.labeled_total}
        value_color='danger'
      />
    )}

    <SimpleTable
      className={styles.table_wrapper}
      data={multas}
      columns={table_columns}
      hide_on_empty
      empty_table_message={empty_table_message}
      empty_message_class={styles.empty_table_messagebox}
      item_name='multa'
      selectable_rows
      initial_selected_rows_ids={selected_multas}
      on_selection_change={handle_selection_change}
    />
  </>)
}

function useMultas(id_verificacao: string) {
  const [multas, set_multas] = useState<GqlListMultasData>()
  useMount(() => {
    const operations = produce_list_multas_ops()
    const variables = { id_verificacao }
    operations.list_multas({
      variables,
      on_completed: set_multas
    })
  })
  return multas
}

function useMultasSelection(multas: GqlListMultasData | undefined) {
  type SelectedMultas = { [id_multa: string]: boolean }
  const selected_multas_ref = useRef<SelectedMultas>()
  const [selected_multas_value, set_selected_multas_value] = useState<number>(0)
  const selected_multas_currency = format_currency(selected_multas_value)



  // initialize selection
  useSyncEffect(() => {
    if (!multas) return
    const initial_selected_multas = multas.reduce((prev, { id }) =>
      ({ ...prev, [id]: true }), {} as SelectedMultas
    )
    selected_multas_ref.current = initial_selected_multas
    rehydrate_selected_multas_value()
  }, multas)



  useEffect(() => {
    // broadcast total to be shown elsewhere
    pushBroadcast(broadcast_selected_multas_value, selected_multas_value)
  }, [selected_multas_value])

  useMount(() => () => {
    // clear broadcast on unmount
    pushBroadcast(broadcast_selected_multas_value, 0)
  })



  function rehydrate_selected_multas_value() {
    if (!multas) return
    const selected_multas = selected_multas_ref.current
    const new_selected_multas_value = !selected_multas ? 0 :
      multas.reduce((prev, multa) => {
        if (!selected_multas[multa.id]) return prev
        return prev + (multa.valor || 0)
      }, 0)
    set_selected_multas_value(new_selected_multas_value)
  }


  /** Recalculate totals when selection changes */
  const handle_selection_change = (row_id: string, is_selected: boolean) => {
    const selected_multas = selected_multas_ref.current
    const new_selected_multas = {
      ...selected_multas, [row_id]: is_selected
    }
    selected_multas_ref.current = new_selected_multas
    rehydrate_selected_multas_value()
  }



  return [
    selected_multas_currency,
    selected_multas_ref.current,
    handle_selection_change
  ] as const
}
