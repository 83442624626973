import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal } from 'react'

/** Checks if a node is the same type as component.  */
export function child_type_matches(
  chilld: ReactNode, component: JSXElementConstructor<any>
) {
  return (
    chilld != null
    && (typeof chilld === 'object')
    && ('type' in chilld)
    && chilld.type === component
  )
}

export type NonEmptyChild = ReactElement<any, string | JSXElementConstructor<any>> | ReactPortal

/** Checks if a node is a component */
export function child_is_component(
  chilld: ReactNode
): chilld is NonEmptyChild {
  return (
    chilld != null
    && (typeof chilld === 'object')
  )
}