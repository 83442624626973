import * as Types from '../../../../../../codegen/gql-types';

import { produce_query } from 'app'
import gql from 'graphql-tag';

/**
 * 
 * 
 * 
 * 
 * 
 *         DO NOT EDIT THIS FILE - ANY CHANGES WILL BE LOST
 * 
 * 
 * 
 * 
 * 
 *    This file is automatically generated on every build by codegen plugins
 *    as defined in codegen.yml
 * 
 *    Its contents are defined by its sibling file _operations.graphql.
 * 
 * 
 * 
 */


 const list_users_doc = gql`
    query list_users($id_cliente: ID!) {
  list_users(id_cliente: $id_cliente) {
    id
    full_name
  }
}
    `;

/** Produce all of operations functions for user-filter. */
function user_filter_ops_factory() {
  const list_users = produce_query<GqlListUsersData,GqlListUsersVariables>(list_users_doc)
  
  return {
    list_users,
  }
}
/** Auto-generated operations functions for user-filter. */
export type UserFilterOps = ReturnType<typeof user_filter_ops_factory>

let memoized_operations: UserFilterOps
/** Produces and memoizes all user-filter operations. */
export function produce_user_filter_ops() {
  if (!memoized_operations) {
    memoized_operations = user_filter_ops_factory()
  }
  return memoized_operations
}

/** Types returned as the final result from operations execution.  */

export type GqlListUsersData = GqlListUsers['list_users']

export type GqlListUsersVariables = Types.Exact<{
  id_cliente: Types.Scalars['ID'];
}>;


export type GqlListUsers = { list_users: Array<Pick<Types.GqlUser, 'id' | 'full_name'>> };
