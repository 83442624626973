import React, { useRef } from 'react'
import { ModalBehavior } from '../_data'

import styles from './text-input.module.scss'
import { Text, TextEvent, OkCancelButtons } from 'components'

export type TextInputProps =
  ModalBehavior &
  {
    /** Message shown above the text input. */
    message: string
    /** Label of the ok button. */
    ok_label: string
    /** Label of the cancel button. (default = `Cancelar`) */
    cancel_label?: string
    /** User clicked ok, after writing something. */
    on_ok: (text: string) => void
    /** User clicked the cancel button. */
    on_cancel?: () => void
  }

/** One single text box, with ok and cancel buttons. */
export function TextInput(props: TextInputProps) {
  const text_ref = useRef('')
  const handle_text_change: TextEvent = (value) => {
    text_ref.current = value
  }

  const handle_ok = () => {
    props.on_ok(text_ref.current)
    props.on_close_request()
  }

  const handle_cancel = () => {
    if (props.on_cancel) {
      props.on_cancel()
    }
    props.on_close_request()
  }

  return (
    <div className={styles.text_input}>
      <p>{props.message}</p>
      <Text
        color='BORDERED'
        focus_on_mount
        required={true}
        on_return={handle_ok}
        className={styles.text}
        on_change={handle_text_change}
      />
      <OkCancelButtons
        on_ok={handle_ok}
        ok_label={props.ok_label}
        on_cancel={handle_cancel}
        cancel_label={props.cancel_label}
      />
    </div>
  )
}