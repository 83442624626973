import { match_preffix } from 'helpers'
import { TreeSelection } from 'components'
import { NodeSelection, ItemsState, Tree, TreeLeaf } from 'components/selection-tree'


const month_names = {
  '01': 'Janeiro',
  '02': 'Fevereiro',
  '03': 'Março',
  '04': 'Abril',
  '05': 'Maio',
  '06': 'Junho',
  '07': 'Julho',
  '08': 'Agosto',
  '09': 'Setembro',
  '10': 'Outubro',
  '11': 'Novembro',
  '12': 'Dezembro',
}

/** Transform an ordered list of UCT formatted dates into a DatesMap. 
 * The datesmap order will be the same found in ordered_dates. */
export function produce_dates_map(ordered_dates: string[]): Tree {
  const result: Tree = []
  let months: Tree = []
  let days: TreeLeaf[] = []

  const first_date = ordered_dates[0].split('-')
  let current_year = first_date[0]
  let current_month = first_date[1]

  const append_month = () => {
    const month_id = `${current_year}-${current_month}`
    const month_name = month_names[current_month as keyof typeof month_names]
    const month_label = `${month_name} - ${current_month}/ ${current_year}`
    months.push([month_id, month_label, days])
    days = []
  }
  const append_year = () => {
    result.push([current_year, current_year, months])
    months = []
  }

  ordered_dates.forEach(date => {
    const [year, month, day] = date.split('-')
    if (month !== current_month || year !== current_year) {
      append_month()
      current_month = month
    }
    if (year !== current_year) {
      append_year()
      current_year = year
    }
    const date_label = `${day}/ ${month}/ ${year}`
    days.push([date, date_label])
  })
  // add leftovers
  append_month()
  append_year()

  return result
}

/** Convert a filter term into equivalent state. */
export function parse_filter_term(
  filter_term: string | undefined, iso_dates: string[]
) {
  if (!filter_term) return { value: null, selection: undefined }

  const value = JSON.parse(filter_term) as FilterValue
  const selection = produce_selection(value, iso_dates)
  return { value, selection }
}
export type FilterValue = ReturnType<typeof produce_filter_value>

let date_ranges: string[]
let dates_list: string[] | undefined
let last_selected_date: Date | undefined = undefined
/** Transform the datepicker tree selection into selection parameters. */
export function produce_filter_value(
  selection: TreeSelection | undefined, iso_dates: string[]
) {
  if (!selection || !Object.keys(selection).length) return null

  date_ranges = []
  dates_list = undefined
  last_selected_date = undefined

  scan_dates('__root__', selection, iso_dates)

  // if a list was created, it is not a continuous range, just return it
  if (dates_list)
    return { dates_list: dates_list as string[] }

  // nothing was selected
  if (!date_ranges.length)
    return null

  const range_start = date_ranges[0]

  // exactly one date selected
  if (date_ranges.length === 1 && range_start.length === 10)
    return { date: range_start }

  // continuous date ranges => conver them to a single range
  const range_end = date_ranges[date_ranges.length - 1]
  const from = to_iso_date(range_start, 'START')
  const to = to_iso_date(range_end, 'END')
  return { from, to }
}

/** Convert a partial iso date */
function to_iso_date(iso_date_part: string, edge: 'START' | 'END') {
  switch (iso_date_part.length) {
    case 10:
      return iso_date_part

    case 7:
      if (edge === 'START') return `${iso_date_part}-01`
      const date_parts = iso_date_part.split('-')
      return last_day_in_month(date_parts[0], +date_parts[1] - 1)

    case 4:
      if (edge === 'START') return `${iso_date_part}-01-01`
      return last_day_in_month(iso_date_part, 11)

    default:
      throw new Error('Invalid iso_date_part.')
  }
}

/** 
 * Produce a complete ISO date with the last day in the requested month.  
 * `month` is zero-based: January = 0
 */
function last_day_in_month(year: string | number, month: string | number) {
  const iso_date = new Date(+year, +month + 1, 0)
    .toISOString()
    .slice(0, 10)
  return iso_date
}

/** Scan all dates in the group key. */
function scan_dates<T extends TreeSelection>(
  group_key: keyof T, selection: T, iso_dates: string[]
) {
  const group = selection[group_key]
  const date_parts = Object.keys(group).sort()
  date_parts.forEach(date_part => {
    const selection_state = group[date_part]
    switch (selection_state) {
      case 'UNSELECTED':
      case undefined:
        break

      case 'SELECTED':
        const selected_date = to_date(date_part, 'START')
        const is_broken_range =
          dates_list !== undefined || (
            last_selected_date !== undefined &&
            date_diff(selected_date, last_selected_date) !== 1
          )

        if (is_broken_range) {
          append_to_dates_list(date_part, iso_dates)
          break
        }

        last_selected_date = to_date(date_part, 'END')
        // non-broken range => add to list of continuous ranges
        date_ranges.push(date_part)
        break

      case 'PARTIALLY_SELECTED':
        scan_dates(date_part, selection, iso_dates)
    }
  })
}

/** Convert a partial ISO date into a Date in the edge of the period defined 
 * by date_part. */
function to_date(date_part: string, edge: 'END' | 'START') {
  const [year, month, day] = to_iso_date(date_part, edge).split('-')
  const date = new Date(+year, +month - 1, +day)
  return date
}

/** Difference in days between start and end dates. */
function date_diff(start_date: Date, end_date: Date) {
  const diff_time = Math.abs(+end_date - +start_date)
  const diff_days = Math.ceil(diff_time / (1000 * 60 * 60 * 24))
  return diff_days
}

/** Add all dates in the range to the dates_list  */
function append_to_dates_list(date_part: string, iso_dates: string[]) {
  if (!dates_list) {
    // initialize date list
    dates_list = []
    if (date_ranges.length) {
      // add date ranges to list
      date_ranges.forEach((d) => append_to_dates_list(d, iso_dates))
      // clear date ranges
      date_ranges = []
    }
  }

  // if complete date => just add it to the list
  if (date_part.length === 10) {
    dates_list.push(date_part)
    return
  }

  // its a date range => add all dates to the list
  const matching_dates = iso_dates.filter(iso_date =>
    match_preffix(iso_date, date_part)
  )
  dates_list.push(...matching_dates)
}

function produce_selection(
  filter_value: FilterValue, iso_dates: string[]
): TreeSelection | undefined {
  if (!filter_value) return undefined
  const { date, dates_list, from, to } = filter_value
  // single date
  if (date) {
    const [year, month] = date.split('-')
    const month_key = `${year}-${month}`
    const selected_leaves: TreeSelection = {
      [month_key]: {
        [date]: 'SELECTED'
      }
    }
    return select_parents(selected_leaves, iso_dates)
  }

  // dates list
  if (dates_list) {
    const selected_leaves = select_list(dates_list)
    return select_parents(selected_leaves, iso_dates)
  }

  // dates range
  return select_range(from!, to!, iso_dates)
}

/** Produce a number like yyyymmdd. */
function to_date_number(year: string, month: string, day: string) {
  return +year * 10000 + +month * 100 + +day
}

function select_range(from: string, to: string, iso_dates: string[]) {
  let selection: TreeSelection = { __root__: {} }

  const [start_year, start_month, start_day] = from.split('-')
  const [end_year, end_month, end_day] = to.split('-')
  const start_date_number = to_date_number(start_year, start_month, start_day)
  const end_date_number = to_date_number(end_year, end_month, end_day)

  /** Year currently active in the selection range. 
   * (Last date was in this year and inside the range) */
  let selecting_year: string | undefined
  /** Last selection state of selecting year. */
  let selecting_year_selection: NodeSelection
  /** Month currently active in the selection range.
   * (Last date was in this month and inside the range) */
  let selecting_month_key: string | undefined
  /** Last selection state of selecting month. */
  let selecting_month_key_selection: NodeSelection
  let last_month_key: string | undefined
  let last_year: string | undefined
  /** All dates selected for the current month. */
  let partial_month_dates: ItemsState<NodeSelection> = {}
  /** All months selected for the current year. */
  let partial_year_months: ItemsState<NodeSelection> = {}

  iso_dates.forEach((iso_date) => {
    const iso_parts = iso_date.split('-') as [string, string, string]
    const date_number = to_date_number(...iso_parts)
    const out_of_range =
      date_number < start_date_number ||
      date_number > end_date_number

    const month_key = iso_date.substring(0, 7)
    const year = iso_parts[0]

    if (out_of_range) {
      // if no selection happenning => do notthing
      if (!selecting_year) {
        last_year = year
        last_month_key = month_key
        return
      }
      if (last_month_key === month_key)
        selecting_month_key_selection = 'PARTIALLY_SELECTED'
      if (last_year === year)
        selecting_year_selection = 'PARTIALLY_SELECTED'
    }

    const changing_month = selecting_month_key !== month_key
    if (changing_month || out_of_range) {
      // set month selection, if it exists
      if (selecting_month_key) {
        selecting_month_key_selection = !changing_month
          // going out of range in the same month => partial selection
          ? 'PARTIALLY_SELECTED'
          // month is changing => keep last valid selection state
          : selecting_month_key_selection
        // add to month selection (will be used if year is partially selected)
        partial_year_months[selecting_month_key] = selecting_month_key_selection
        // add dates selections if month is partial
        if (selecting_month_key_selection === 'PARTIALLY_SELECTED')
          selection[month_key!] = partial_month_dates
        // reset partial dates accumulator
        partial_month_dates = {}
      }
      selecting_month_key = out_of_range ? undefined : month_key
      // if not the first day in the month this year => start as partial
      selecting_month_key_selection =
        last_month_key === month_key ? 'PARTIALLY_SELECTED' : 'SELECTED'
    }

    const changing_year = selecting_year !== year
    if (changing_year || out_of_range) {
      // set year selection, if it exists
      if (selecting_year) {
        selecting_year_selection = !changing_year
          // going out of range in the same year => partial selection
          ? 'PARTIALLY_SELECTED'
          // year is changing => keep last valid selection state
          : selecting_year_selection
        // add year selection state
        selection.__root__[selecting_year] = selecting_year_selection
        // if partially selected => add months selection state
        if (selecting_year_selection === 'PARTIALLY_SELECTED')
          selection[selecting_year] = partial_year_months
        // reset month selection accumulator
        partial_year_months = {}
      }
      selecting_year = out_of_range ? undefined : year
      // if not the first day in the list this year => start as partial
      selecting_year_selection =
        last_year === year ? 'PARTIALLY_SELECTED' : 'SELECTED'
    }

    last_year = year
    last_month_key = month_key

    if (!out_of_range) partial_month_dates[iso_date] = 'SELECTED'
  })
  return selection
}

/** Mark all selected keys into the tree selection object. */
function select_list(partial_iso_dates: string[]) {
  let selection: TreeSelection = {}
  partial_iso_dates.forEach((partial_iso_date) => {
    const [year, month, day] = partial_iso_date.split('-')
    const parent_key = !month
      ? '__root__'
      : (!day ? year : `${year}-${month}`)
    selection[parent_key] = {
      ...selection[parent_key],
      [partial_iso_date]: 'SELECTED'
    }
  })
  return selection
}

/** Define all parent items selection states. */
function select_parents(selected_leaves: TreeSelection, iso_dates: string[]) {
  let selection: TreeSelection = { ...selected_leaves }
  let current_year = iso_dates[0].substring(0, 4)
  let current_year_selection: NodeSelection
  let current_month_key = iso_dates[0].substring(0, 7)
  let current_month_selection: NodeSelection

  if (!selection.__root__) selection.__root__ = {}

  iso_dates.forEach((iso_date) => {
    const year = iso_date.substring(0, 4)
    const month_key = iso_date.substring(0, 7)

    const year_selection = (selected_leaves.__root__ || {})[year]
    const month_selection = (selected_leaves[year] || {})[month_key]
    const date_selection = (selected_leaves[month_key] || {})[iso_date]

    // if changing months, set previous month's parent selection state
    if (month_key !== current_month_key) {
      if (current_month_selection) {
        if (!selection[current_year]) selection[current_year] = {}
        if (!selection[current_year][current_month_key])
          selection[current_year][current_month_key] = current_month_selection
      }
      current_month_selection = date_selection
      current_month_key = month_key
    }

    // if changing years, set previous year's parent selection state
    if (year !== current_year) {
      if (current_year_selection && !selection.__root__[current_year])
        selection.__root__[current_year] = current_year_selection
      current_year_selection = date_selection
      current_year = year
    }

    // ancestor already marked as selected in original selection
    if (year_selection === 'SELECTED' || month_selection === 'SELECTED') return

    // handle ancestors partial selection
    const invalid_parent_selection: NodeSelection = date_selection
      // if this date is selected, ancestor cannot be unselected
      ? undefined
      // if this date is unselected, ancestor cannot be selected
      : 'SELECTED'

    if (current_year_selection === invalid_parent_selection)
      current_year_selection = 'PARTIALLY_SELECTED'
    if (current_month_selection === invalid_parent_selection)
      current_month_selection = 'PARTIALLY_SELECTED'
  })

  return selection
}