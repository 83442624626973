import React from 'react'
import { HelpProps, Help, HelpTopic } from 'components'

export function BINARY_ID(props: Omit<HelpProps, 'title' | 'component_key' | 'version'>) {
  return (
    <Help
      {...props}
      title='Como filtrar chaves de identificação.'
      component_key='filter_binary_id'
      version={1}
    >
      <HelpTopic tag='(sem operadores)' title='Chave exata.'>
        <p>
          <mark>bd796b5f-3a98-5aa4-9d2b-e1fb305c065b</mark> busca exatamente essa chave.
        </p>
        <p>
          Não é possível utilizar operadores para buscar por padrões em chaves
          pois elas são geradas aleatoriamente, não há padrões.
        </p>
      </HelpTopic>

      <HelpTopic tag='!' title='Negação: todas as chaves, exceto uma.'>
        <p>
          <mark>!bd796b5f-3a98-5aa4-9d2b-e1fb305c065b</mark> qualquer
          chave, exceto <em>bd796b5f-3a98-5aa4-9d2b-e1fb305c065b</em>.
        </p>
      </HelpTopic>

      <HelpTopic tag='[ ]' title='Campo vazio.'>
        <p>
          Abrir e fechar colchetes, sem espaço entre eles. Retorna somente os
          registros que não tenham informação alguma nesse campo.
        </p>
        <p>
          <mark>![ ]</mark> funciona em conjunto com a negação. Retorna todos os
          campos que tenham alguma chave, seja qual for.
        </p>
      </HelpTopic>
    </Help>
  )
}