import React from 'react'
import styles from './card-header.module.scss'

type CardHeaderProps = React.PropsWithChildren<{
  title?: string | undefined
  hide_children?: boolean
}>
export function CardHeader(props: CardHeaderProps) {
  const { title = '', children } = props
  if (!(title || children)) return null
  const no_children = props.hide_children || !children

  return (
    <div className={styles.header}>
      <h4 className={styles.title}>{title}</h4>
      {no_children ? null : (
        <div className={styles.chidren_wrapper}>
          {children}
        </div>
      )}
    </div>
  )
}
