import { ApiHandler } from 'controllers/tied'

import store from '../_store'
import { SelectionTreeApiCore } from '../_data'


/** Expand/collapse item. */
export const toggle_expanded: ApiHandler<SelectionTreeApiCore> = (
  i, item_id: string
) => {
  const { expanded } = i.state
  const new_expanded = { ...expanded, [item_id]: !expanded[item_id] }
  i.set.expanded(new_expanded)

  // send state to store, so it is not lost on unmount
  const component_item_id = i.props.item_id
  if (component_item_id)
    store.set('expanded', component_item_id, new_expanded)
}