import React from 'react'
import { addAppMethods, setSharedState, SharedStateConfig, useSharedState } from 'controllers/tied'

import styles from './modal.module.scss'
import * as shapes from './shapes'
import { trace } from 'controllers/tracer'
import { Defined } from 'helpers'

type Shapes = typeof shapes
type ModalState<T extends keyof Shapes> = undefined | {
  shape: T,
  modal_props: Omit<React.ComponentProps<Shapes[T]>, 'on_close_request'>
}

type ModalSharedState = SharedStateConfig<ModalState<keyof Shapes>>
const modal_shared_state_config: ModalSharedState = {
  initialState: undefined
}

export type AppModalMethods = {
  /** Shows a modal window on top of the interface. */
  show_modal: <T extends keyof Shapes>(
    shape: Defined<ModalState<T>>['shape'],
    modal_props: Defined<ModalState<T>>['modal_props']
  ) => void
}

/** Shows a modal on top of the screen. */
export function show_modal(props: ModalState<keyof Shapes>) {
  setSharedState(modal_shared_state_config, props)
}

/** Modal window - called with the `show_modal` app method. */
export function Modal() {
  const [state, set_state] = useSharedState(modal_shared_state_config, 'Modal')
  if (process.env.NODE_ENV === 'development') trace('Modal', 'render', { state })

  const show_modal_method: AppModalMethods['show_modal'] = (shape, modal_props) => {
    set_state({ shape, modal_props })
  }
  addAppMethods({ show_modal: show_modal_method })

  const close_modal = () => { set_state(undefined) }

  if (!state) { return null }

  const Shape = shapes[state.shape]
  return (
    <aside className={styles.modal}>
      {/* @ts-ignore */}
      <Shape
        {...state.modal_props}
        on_close_request={close_modal} />
    </aside>
  )
}
