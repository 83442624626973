import React from 'react'
import ReactDOM from 'react-dom'

import { App } from './app'
import './styles/index.css'

/** Local storage will be cleared if current version is lower than this one. */
const storage_version = 6
manage_version(storage_version)

require('./styles/global.scss')

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

/**
 * Manage local storage version.
 */
function manage_version(storage_version: number) {
  /** Version in user's local storage. */
  const stored_version = localStorage.getItem('storage_version')
  if (stored_version === null || +stored_version !== storage_version) {
    localStorage.clear()
    sessionStorage.clear()
    localStorage.setItem('storage_version', '' + storage_version)
  }
}