import { AnyObject } from 'helpers'
import React, { useEffect, useRef } from 'react'

type RulerProps = {
  /** Texts to me measured. */
  texts: (string | number | undefined)[]
  /** Called when measufre is done. */
  on_measured: RulerOnMeasured
  /** Classes will be applied recursivelly to children components. */
  class_list: string[]
  /** Style applied to the topmost component. */
  style?: AnyObject
}

/** Callback that returns the measured width. */
export type RulerOnMeasured = (widths: number[]) => void

/** Component used to measure the size of rendered strings. */
export function Ruler(props: RulerProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const widths = produce_widths(ref)
    props.on_measured(widths)
  }, [...props.texts])

  const { class_list } = props
  const style: React.CSSProperties = {
    ...props.style,
    position: 'absolute',
    display: 'block',
    top: '-99999999px',
    left: '-99999999px',
    opacity: 0,
  }
  return (
    <div ref={ref}>
      {props.texts.map((text, idx) => (
        <RulerItem
          key={`${text}.${idx}`}
          style={style}
          class_list={class_list}
          text={text}
          idx={idx}
        />
      ))}
    </div>
  )
}

type RulerItemProps = {
  text: React.ReactText | undefined
  idx: number
  class_list: string[]
  style?: React.CSSProperties
}

function RulerItem({ style, class_list, ...props }: RulerItemProps) {
  const [current_class, ...class_list_rest] = class_list
  return (
    <span
      className={current_class}
      style={style}>
      {class_list_rest.length === 0
        ? props.text
        : (
          <RulerItem
            {...props}
            class_list={class_list_rest}
          />
        )
      }
    </span>
  )
}

function produce_widths(ref: React.RefObject<HTMLDivElement>) {
  if (!ref.current) {
    throw new Error(`Ruler ref should have been mounted before measuring.`)
  }
  const nodes = [...ref.current.children]
  const widths = nodes.map((el) => {
    const rect = el.getBoundingClientRect()
    return rect.width
  })
  return widths
}