import * as Types from '../../../../../../codegen/gql-types';

import { produce_mutation } from 'app'
import gql from 'graphql-tag';

/**
 * 
 * 
 * 
 * 
 * 
 *         DO NOT EDIT THIS FILE - ANY CHANGES WILL BE LOST
 * 
 * 
 * 
 * 
 * 
 *    This file is automatically generated on every build by codegen plugins
 *    as defined in codegen.yml
 * 
 *    Its contents are defined by its sibling file _operations.graphql.
 * 
 * 
 * 
 */


 const solicitar_verificacao_doc = gql`
    mutation solicitar_verificacao($renavam: String!, $placa: String!, $lista_fornecedor_credenciais: [FornecedorCredencialInput!]!, $id_cliente: String!, $baixar_boletos: Boolean!) {
  solicitar_verificacao(renavam: $renavam, placa: $placa, lista_fornecedor_credenciais: $lista_fornecedor_credenciais, id_cliente: $id_cliente, baixar_boletos: $baixar_boletos) {
    id
    renavam
    placa
    status
    status_multas
    status_debitos
    requested_at
    files_bundle {
      url
    }
  }
}
    `;

/** Produce all of operations functions for search-starter. */
function search_starter_ops_factory() {
  const solicitar_verificacao = produce_mutation<GqlSolicitarVerificacaoData,GqlSolicitarVerificacaoVariables>(solicitar_verificacao_doc)
  
  return {
    solicitar_verificacao,
  }
}
/** Auto-generated operations functions for search-starter. */
export type SearchStarterOps = ReturnType<typeof search_starter_ops_factory>

let memoized_operations: SearchStarterOps
/** Produces and memoizes all search-starter operations. */
export function produce_search_starter_ops() {
  if (!memoized_operations) {
    memoized_operations = search_starter_ops_factory()
  }
  return memoized_operations
}

/** Types returned as the final result from operations execution.  */

export type GqlSolicitarVerificacaoData = GqlSolicitarVerificacao['solicitar_verificacao']

export type GqlSolicitarVerificacaoVariables = Types.Exact<{
  renavam: Types.Scalars['String'];
  placa: Types.Scalars['String'];
  lista_fornecedor_credenciais: Array<Types.GqlFornecedorCredencialInput> | Types.GqlFornecedorCredencialInput;
  id_cliente: Types.Scalars['String'];
  baixar_boletos: Types.Scalars['Boolean'];
}>;


export type GqlSolicitarVerificacao = { solicitar_verificacao: (
    Pick<Types.GqlVerificacao, 'id' | 'renavam' | 'placa' | 'status' | 'status_multas' | 'status_debitos' | 'requested_at'>
    & { files_bundle?: Types.Maybe<Pick<Types.GqlS3File, 'url'>> }
  ) };
