import { Falsy } from './types'

const identity = (arg: any) => arg

/** Composes multiple classNames. */
export function compose(...args: (string | Falsy)[]) {
  return args.filter(identity).join(' ')
}

/** 
 * If condition_modifier is truhy and truthy_modifier exists 
 *   => add truthy_modifier to classname.
 * 
 * If condition_modifier is truhy and NO truthy_modifier exists
 *   => add condition_modifier to classname.
 * 
 * If condition_mofifier is undefined => return classname.
 * 
 * If condition_modifier is falsy and falsy_modifier exists
 *   => add falsy_modifier to classname.
 * 
 * If condition_modifier is falsy and NO falsy_modifier exists
 *   => return classname.
 */
export function affix<T>(
  className: string,
  /** If no truthy_modifier is passed, condition must return a string when truthy. */
  condition_modifier: T extends string ? any : (string | Falsy),
  truthy_modifier?: T,
  falsy_modifier?: string
) {
  if (condition_modifier) {
    return `${className}-${truthy_modifier || condition_modifier}`
  }
  if (!falsy_modifier || condition_modifier === undefined) {
    return className
  }
  return `${className}-${falsy_modifier}`
}