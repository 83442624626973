import React, { useState } from 'react'
import { affix, compose } from 'helpers'

import styles from './checkbox.module.scss'

type CheckboxProps = React.PropsWithChildren<{
  className?: string
  on_change: (is_checked: boolean) => void
  /** Value to be shown - this component does not hold state. */
  initial_value: boolean | 'TRUE' | 'FALSE' | undefined
}>
export function Checkbox(props: CheckboxProps) {
  const { initial_value } = props
  const initial_state = !!initial_value && initial_value !== 'FALSE'
  const [is_checked, set_is_checked] = useState<boolean>(initial_state)

  const handle_change = () => {
    const new_state = !is_checked
    set_is_checked(new_state)
    props.on_change(new_state)
  }

  const checkbox_class = affix('button', is_checked && 'CHECKED')
  const className = compose(props.className, styles[checkbox_class])

  return (
    <button
      type='button'
      onClick={handle_change}
      className={className}>
      {!props.children ? null : (
        <span className={styles.label_text}>{props.children}</span>
      )}
    </button>
  )
}