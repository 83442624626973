import { toggle } from 'helpers'
import { ApiHandler } from 'controllers/tied'

import store from '../_store'
import {
  SelectionTreeApiCore,
  NodeSelection,
  SelectionState,
  SelectionTreeChildProps,
} from '../_data'
import { produce_parent_selection } from '../_helpers'

/** 
 * Change items selection state in reaction to parent selection changes 
 * when necessary.
 */
export const parent_selection_effect: ApiHandler<SelectionTreeApiCore, () => void> = (i) => {
  const { parent_selection, item_id } = i.props

  // changing to partially selected does not trigger and bulk action
  if (parent_selection === 'PARTIALLY_SELECTED') return

  // if parent is selected => all children must be as well
  if (parent_selection === 'SELECTED') {
    let new_selection: SelectionState = {}
    i.props.items.forEach(([id]) => { new_selection[id] = 'SELECTED' })
    store.set('selection', item_id, new_selection)
    i.set.selection(new_selection)
    return
  }

  // Parent is unselected => remove all selections, if any
  const { selection } = i.state
  if (!Object.keys(selection).length) return
  store.set('selection', item_id, null)
  i.set.selection({})
}

/** Toggle selection state of one item and raise on_change event. */
export const handle_selection: ApiHandler<SelectionTreeApiCore> = (
  i, item_id: string
) => {
  const selected_value = toggle(
    i.state.selection[item_id],
    'SELECTED',
    'UNSELECTED'
  )
  change_selection(i, item_id, selected_value)

  // raise the meta-component on_change event
  const selection = store.get_selection_tree()
  i.props.on_change(selection)
}

/**
 *  Produce side effects from selection change comming from a child list.  
 *  Return indicates wether or not the change_selection was called a a 
 *  side effect.
 */
export const handle_child_change: ApiHandler<SelectionTreeApiCore, SelectionTreeChildProps['on_child_change']> = (
  i, child_selection, items, parent_item_id
) => {
  const parent_selection = produce_parent_selection(child_selection, items)

  if (parent_selection === 'UNSELECTED') {
    const selection_state = i.state.selection[parent_item_id!]
    const has_selections =
      selection_state === 'PARTIALLY_SELECTED' ||
      selection_state === 'SELECTED'
    // only if descendants are selected let item to be marked as unselected
    if (!has_selections) return
  }

  change_selection(i, parent_item_id!, parent_selection)
}

/** Change item_id's selection value and return the new selection state.  */
const change_selection: ApiHandler<SelectionTreeApiCore> = (
  i, item_id: string, value: NodeSelection
) => {
  const { props, state } = i
  const { selection } = state
  if (selection[item_id] === value) return
  const new_selection: SelectionState = {
    ...selection,
    [item_id]: value
  }

  const component_item_id = props.item_id
  props.on_child_change(
    new_selection,
    props.items,
    component_item_id
  )
  i.set.selection(new_selection)

  // send state to store, so it is not lost on unmount and can be accessed as 
  // consumable meta-component state.
  store.set('selection', component_item_id, new_selection)
}