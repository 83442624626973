//@ts-nocheck
// https://schneider.dev/blog/elixir-phoenix-absinthe-graphql-react-apollo-absurdly-deep-dive/
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { Socket as PhoenixSocket } from 'phoenix'
import * as AbsintheSocket from '@absinthe/socket'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { hasSubscription } from '@jumpn/utils-graphql'
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link'
import { ApolloClient, QueryOptions, ApolloError } from 'apollo-client'

const HTTP_ENDPOINT = process.env.NODE_ENV === 'production'
  ? 'https://main.brobot.com.br/api'
  : 'http://localhost:4000/api'

const WS_ENDPOINT = process.env.NODE_ENV === 'production'
  ? 'wss://main.brobot.com.br/socket'
  : 'ws://localhost:4000/socket'

// Create an HTTP link to the Phoenix app's HTTP endpoint URL.
const httpLink = createHttpLink({
  uri: HTTP_ENDPOINT
})

// Create a WebSocket link to the Phoenix app's socket URL.
const socketLink = createAbsintheSocketLink(
  AbsintheSocket.create(new PhoenixSocket(WS_ENDPOINT))
)

const include_authorization_header = operation => {
  const token = localStorage.getItem('auth-token')
  return token
    ? (operation.setContext({
      headers: {
        authorization: 'Bearer ' + token,
      }
    }), operation)
    : operation
}

// Create a link that 'splits' requests based on GraphQL operation type.
// Queries and mutations go through the HTTP link.
// Subscriptions go through the WebSocket link.
const link = new ApolloLink((operation, forward) => {
  return forward(include_authorization_header(operation));
}).split(
  operation => hasSubscription(operation.query),
  socketLink,
  httpLink
)

// Create the Apollo Client instance.
export const apollo_client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
})

export default apollo_client


function produce_operation_error_handler(on_errors: (errors: ApolloError) => void) {
  return (error: ApolloError) => {
    if (error.networkError) {
      handle_network_error(error.networkError)
    }
    if (on_errors) on_errors(error)
    else console.error(error)
  }
}

function handle_network_error({ statusCode }: Error) {
  switch (statusCode) {
    // session has expired
    case 401:
      localStorage.removeItem('auth-token')
      localStorage.setItem('token-status', 'expired')
      // reload with expired status will make the login modal to be shown
      window.location.reload()
      return
  }
}


export type ProduceMutationConfig<Result, Variables> = {
  variables: Variables,
  on_completed?: (data: Result) => void,
  on_errors?: (errors: ApolloError) => void
}
/** 
 * Produce a mutation function that calls on_completed callback when the 
 * mutation is done and on_error if it failed.
 */
export function produce_mutation<Result, Variables>(
  mutation_document: unknown
) {
  const mutation_name = mutation_document.definitions[0].selectionSet.selections[0].name.value

  return ({
    variables,
    on_completed,
    on_errors
  }: ProduceMutationConfig<Result, Variables>
  ) => {
    apollo_client.mutate<Result, Variables>({
      mutation: mutation_document,
      variables
    }).then(({ data }) => {
      if (on_completed) {
        const mutation_data = data[mutation_name]
        on_completed(mutation_data)
      }
    }).catch(produce_operation_error_handler(on_errors))
  }
}

export type ProduceQueryConfig<Result, Variables> = {
  on_completed: (data: Result) => void
  variables?: Variables
  on_errors?: (errors: ApolloError) => void
  options?: Partial<QueryOptions<Variables>>
}
/**
 * Produce a query function that calls on_completed callback when the
 * query is resolved and on_error if it failed.
 */
export function produce_query<Result, Variables = never>(
  query_document: unknown
) {
  const query_name = query_document.definitions[0].name.value

  return ({
    on_completed,
    variables,
    on_errors,
    options,
  }: ProduceQueryConfig<Result, Variables>
  ) => {
    apollo_client.query<Result, Variables>({
      query: query_document,
      variables,
      errorPolicy: 'all',
      ...options,
    }).then(({ data }) => {
      const query_data = data[query_name]
      on_completed(query_data)
    }).catch(produce_operation_error_handler(on_errors))
  }
}


export type QueryObservableConfig<Result, Variables> = {
  on_completed: (data: Result) => void,
  variables?: Variables,
  on_errors?: (errors: ApolloError) => void
  options?: Partial<QueryOptions<Variables>>
}
/**
 * Produce a query function that calls on_completed callback when the
 * query is resolved and on_error if it failed.
 */
export function produce_observable_query<Result, Variables = never>(
  query_document: unknown
) {
  return ({
    on_completed,
    variables,
    on_errors,
    options,
  }: QueryObservableConfig<Result, Variables>
  ) => {
    const query_name = query_document.definitions[0].name.value

    const query = apollo_client.watchQuery<Result, Variables>({
      variables,
      query: query_document,
      // This is what enables cancelation
      queryDeduplication: false,
      errorPolicy: 'all',
      ...options,
    })

    const observable = query.subscribe((result) => {
      const { data, errors } = result
      if (errors) {
        produce_operation_error_handler(on_errors)(errors)
        return
      }

      const query_data = data[query_name]
      on_completed(query_data)
      return
    })

    return { query, observable }
  }
}

type ProduceSubscriiptionConfig<Result, Variables> = {
  on_updated: (data: Result) => void,
  variables?: Variables,
  on_error?: (error: ApolloError) => void
}
/**
 * Produce a subscription observable that calls on_updated callback whenever the
 * susbcription receives new data and on_error if it failed.
 * 
 * Returns the observable subscription. So unsusbcribe can de done when 
 * necessary.
 */
export function produce_subscription<Result, Variables = never>(
  query_document: unknown
) {
  return ({
    on_updated,
    variables,
    on_error
  }: ProduceSubscriiptionConfig<Result, Variables>
  ) => {
    const query_name = query_document.definitions[0].name.value
    return apollo_client.subscribe<Result, Variables>({
      query: query_document,
      variables,
    }).subscribe({
      next({ data }) {
        const query_data = data[query_name]
        on_updated(query_data)
      },
      error: produce_operation_error_handler(on_error)
    })
  }
}
