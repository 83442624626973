const metric_prefixes = ['', 'K', 'M', 'G', 'T']
/** Convert value into the largest possible metric prefixed equivalent 
 * for which the prefixed number is > 1. 
 * 
 * metric_value = value * metric_ratio
 */
export function produce_metric(value: number) {
  let prefix_idx = 0
  for (let i = 4; i > 0; i--) {
    if (prefix_idx === 0 && value > Math.pow(10, 3 * i)) {
      prefix_idx = i
    }
  }
  const metric_ratio = 1 / Math.pow(10, 3 * prefix_idx)
  return {
    metric_ratio,
    metric_prefix: metric_prefixes[prefix_idx],
    metric_value: value * metric_ratio
  }
}

/** Round the number, keeping one decimal if value < 10. */
export function round(value: number) {
  if (value < 10) {
    return Math.round(value * 10) / 10
  }
  return Math.round(value)
}

let next_key = 0
/** 
 * Produces a globally sequential key. Unique in the current execution.
 * Not to be used as unique key in any persisted data.
 */
export function produce_key(prefix?: string) {
  if (!prefix) { return `${next_key++}` }
  return `${prefix}${next_key++}`
}

/** Move index inside the range from -1 to length-1. */
export function move_in_range(value: number, length: number, direction: number) {
  const new_value = value + direction
  if (new_value >= length) return -1
  if (new_value < -1) return length - 1
  return new_value
}
