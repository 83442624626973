import React from 'react'
import DayPicker from 'react-day-picker'
import { usePopupMenu, PopupMenu, ClearableButton, Svg } from 'components'
import { add_days, capitalize, get_first_day_of_month_before, format_day, format_long_month, get_monday_of_week, today_utc, convert_date_to_utc, date_to_day_string, compose } from 'helpers'

import styles from './range-filter.module.scss'
import { SelectedDaterange, DateTimeRange } from '../_data'

type RangeOption = { label: string, range: DateTimeRange, start_date_str?: string }
const options_by_label: { [label in RangeFilterProps['label']]: RangeOption[] } = {
  Dia: [
    { label: 'Hoje', range: 'TODAY' },
    { label: 'Ontem', range: 'YESTERDAY' },
  ],
  Semana: [
    { label: 'Semana atual', range: 'THIS_WEEK' },
    { label: 'Semana passada', range: 'LAST_WEEK' },
  ],
  Mês: [
    { label: 'Mês atual', range: 'THIS_MONTH' },
    { label: 'Mês passado', range: 'LAST_MONTH' },
  ]
}

type RangeFilterProps = {
  label: 'Dia' | 'Semana' | 'Mês'
  on_change: (range: DateTimeRange, label: string, start_date_str?: string) => void
  selected_date_range: SelectedDaterange
  on_clear: () => void
  limit_height?: boolean
}
export function RangeFilter(props: RangeFilterProps) {
  const { label } = props

  const friendly_options: RangeOption[] = options_by_label[label]
  const specific_options: RangeOption[] = get_specific_options(label)
  const options = [...friendly_options, ...specific_options]

  const popup = usePopupMenu({ keep_open: true })
  const handle_select = ({ range, label, start_date_str }: RangeOption) => {
    popup.menu_props.hide()
    props.on_change(range, label, start_date_str)
  }

  const granularities = { Dia: 'DAY', Semana: 'WEEK', Mês: 'MONTH' }
  const granularity = granularities[label]
  const is_selected =
    props.selected_date_range != undefined
    && props.selected_date_range.range.includes(granularity)
  const shown_label = is_selected
    ? props.selected_date_range!.label
    : label

  const added_styles = compose(
    styles.popup_menu_added_styles,
    props.limit_height && styles.popup_menu_height_limit
  )

  return (
    <ClearableButton
      is_cleararble={is_selected}
      on_clear={props.on_clear}
      className={styles.filter_button}>
      <div {...popup.parent_props} className={styles.popup}>
        {is_selected ? <Svg size='small' icon='filter' /> : null}
        {shown_label}
        <PopupMenu
          {...popup.menu_props}
          added_styles={added_styles}
          options={options}
          label_prop='label'
          on_select={handle_select}>
          {label !== 'Dia' ? null : (
            <DaySelector on_change={handle_select} />
          )}
        </PopupMenu>
      </div>
    </ClearableButton>
  )
}

const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]
const WEEKDAYS_SHORT = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
const WEEKDAYS_LONG = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
]
function DaySelector(props: { on_change: (option: RangeOption) => void }) {
  const handle_change = (date: Date) => {
    const label = date_to_day_string(date)
    const date_utc = convert_date_to_utc(date)
    const date_utc_iso = date_utc.toISOString()
    const value: RangeOption = {
      range: 'SPECIFIC_DAY',
      label,
      start_date_str: date_utc_iso
    }
    props.on_change(value)
  }

  return (
    <DayPicker
      months={MONTHS}
      weekdaysShort={WEEKDAYS_SHORT}
      weekdaysLong={WEEKDAYS_LONG}
      onDayClick={handle_change}
    />
  )
}

function get_specific_options(label: RangeFilterProps['label']): RangeOption[] {
  switch (label) {
    case 'Semana':
      return get_specific_weeks(10)
    case 'Mês':
      return get_specific_months(10)
    default:
      return []
  }
}

function get_specific_weeks(number_of_weeks: number): RangeOption[] {
  const today = today_utc()
  const last_monday = get_monday_of_week(today)
  const weeks_list = [...Array(number_of_weeks).keys()].map(k => {
    const days_added = -(k + 2) * 7
    const monday_of_week = add_days(last_monday, days_added)
    const monday_of_week_iso = monday_of_week.toISOString()
    const monday_of_week_str = format_day(monday_of_week_iso)
    const label = `Semana de ${monday_of_week_str}`
    const start_date_str = monday_of_week_iso.substring(0, 10)
    const option: RangeOption = {
      label,
      start_date_str,
      range: 'SPECIFIC_WEEK'
    }
    return option
  })
  return weeks_list
}

function get_specific_months(number_of_months: number): RangeOption[] {
  const today = today_utc()
  let first_day_of_month = get_first_day_of_month_before(today)
  const months_list = [...Array(number_of_months).keys()].map(() => {
    first_day_of_month = get_first_day_of_month_before(first_day_of_month)
    const first_day_of_month_iso = first_day_of_month.toISOString()
    const month_str = format_long_month(first_day_of_month_iso)
    const start_date_str = first_day_of_month_iso.substring(0, 10)
    const option: RangeOption = {
      label: capitalize(month_str),
      start_date_str,
      range: 'SPECIFIC_MONTH'
    }
    return option
  })
  return months_list
}