import { AppHandler } from 'app'
import { ApiHandler } from 'controllers/tied'

import { TablePhase, TableApiCore, REQUEST_PHASES } from '../_data'

/**
 * Whenever data arrives in a request phase, move to the next phase so the 
 * new data is properly handled by Table's children.
 */
export const onApiRender: ApiHandler<TableApiCore> = (i) => {
  const { phase, data } = i.state

  if (
    data === undefined ||
    data === i.params.last_data ||
    !REQUEST_PHASES.includes(phase)
  ) {
    return ['ok', 'NOTHING_HAPPENED', { data, last_data: i.params.last_data, phase, REQUEST_PHASES, i }]
  }
  // A request fase was just fecthed => move to the next fetch phase
  i.set.last_data(data)
  const { change_phase } = i.makeApi()
  change_phase(phase + 1)

  return ['ok', 'REQUEST_FINISHED', { i, data }]
}

/**
 * Deal with effects that update outside components, as these cannot be 
 * triggered during the rendering phase.
 */
export const onApiEffect: AppHandler<TableApiCore> = i => {
  if (i.state.phase === TablePhase.CHANGING_FIELDS) {
    // After fields are changed, it was necessary to wait for a re-render 
    // before running the query so the new operation can be created in Apollo.
    // At this point, the operation is the correct one, so we change the phase 
    // to`requesting_new` so the query is executed.
    const { change_phase } = i.makeApi()
    change_phase(TablePhase.REQUESTING_NEW, {})
    return ['ok', 'REQUESTED_NEW', { i }]
  }
}

/** Set selected columns list and widths on mount. */
export const onApiMount: AppHandler<TableApiCore> = (i) => {
  const { change_phase } = i.makeApi()
  change_phase(TablePhase.REQUESTING_NEW, {})
}

/** Unsubscribe observable on unmount to prevent memory leak on pending requests. */
export const onApiUnmount: AppHandler<TableApiCore> = (i) => {
  const { list_sheet } = i.params
  if (list_sheet) {
    list_sheet.observable.unsubscribe()
  }
}