import { Maybe } from 'helpers'
import { GqlSituationResult } from 'codegen/gql-types'

import { DebitosRestricoes, EnrichedebitosRestricoes } from '../../situation-search'

export type SituationCardProps = {
  debitos_restricoes: EnrichedebitosRestricoes
}

export type SituationKeys = keyof Pick<DebitosRestricoes, 'divida_ativa' | 'ipva' | 'licenciamento' | 'multas' | 'restricoes' | 'vistorias'>
// export type SituationKeys = SituationKey[number]

/** Config for each situation type. */
export const situations_labels: { [K in SituationKeys]: string } = {
  licenciamento: 'Licenciamento/ DPVAT',
  ipva: 'IPVA',
  multas: 'Multas',
  divida_ativa: 'Dívida Ativa',
  restricoes: 'Restrições',
  vistorias: 'Vistorias',
}

export type SituationResult = {
  [K in SituationKeys]?: Maybe<GqlSituationResult>
}

export type FileInfo = {
  url: string,
  utc_time_of_capture: string
}
