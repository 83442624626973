import {
  ItemsState,
  ExpandedState,
  SelectionState,
  SelectionTreeState,
} from './_data'

const initial_store = {
  expanded: {} as ItemsState<ExpandedState>,
  selection: {} as ItemsState<SelectionState>,
}
/** 
 * Complete state tree, used to avoid data loss on child unmount on collapse. 
 * TODO: implement store by instance - generate instance id on mount and 
 * destroy it on unmount.
 */
let store = initial_store
/** Piece of component state that is held in the store. */
export type SelectionTreeStore = typeof store
/** Selection state for the complete Selection Tree meta-component. */
export type TreeSelection = SelectionTreeStore['selection']

type GetResult<T> =
  T extends SelectionTreeStore['expanded']
  ? ExpandedState
  : SelectionState
/** Retrieve state initializer values for an instance identifyed by item_id. */
function get<T extends keyof SelectionTreeStore>(
  type: T, item_id: string
): GetResult<T> {
  return (store[type][item_id] || {}) as GetResult<T>
}

/** Retrieve the complete selection tree. */
function get_selection_tree() {
  return store.selection
}

/**
 * Save state initializer values for an instance identifyed by item_id.  
 * state === null => remove item_id from store.
 */
function set<T extends keyof SelectionTreeStore>(
  type: T,
  item_id: string | undefined,
  state: Partial<SelectionTreeState> | null
) {
  const key = item_id || '__root__'
  if (state === null) {
    delete store[type][key]
    return
  }

  const instance_state = store[type]
  //@ts-ignore
  instance_state[key] = {
    ...instance_state[key],
    ...state
  }
}

/** Replace the store completely. */
function reset(new_store?: Partial<SelectionTreeStore> | undefined) {
  store = {
    ...initial_store,
    ...new_store
  }
}

export default {
  get_selection_tree,
  get,
  set,
  reset,
}