import { is_equal } from 'helpers'
import { ApiHandler } from 'controllers/tied'
import { DatepickerMenuCore, SelectionTreeOnChange } from './_data'
import { produce_dates_map, parse_filter_term, produce_filter_value } from './_parsers'

let list_distinct_subscription: undefined | ZenObservable.Subscription

/** Popup is unmounting =>  commit chages and unsubscribe from dates listing query. */
export const onApiUnmount: ApiHandler<DatepickerMenuCore> = (i) => {
  if (list_distinct_subscription) {
    list_distinct_subscription.unsubscribe()
    list_distinct_subscription = undefined
  }

  const new_value = produce_filter_value(i.params.selection, i.state.iso_dates)
  if (is_equal(new_value, i.state.value)) return
  // filter has been changed => set state and raise event
  i.set.value(new_value)
  i.props.on_change(new_value)
}

/** Popup is being shown => retrieve and parse valid dates and initial selection. */
export const onApiMount: ApiHandler<DatepickerMenuCore> = (i) => {
  const { field_id } = i.props
  const { parse_dates } = i.makeApi(true)

  list_distinct_subscription = i.props.on_list_distinct(field_id, {
    on_completed: parse_dates,
  })
}

/** Dates list returned from server => parse them. */
export const parse_dates: ApiHandler<DatepickerMenuCore> = (
  i, data: ListDistinctData
) => {
  const { props, set } = i
  const { field_id } = props

  const iso_dates = data.map(bucket => bucket[field_id]) as string[]
  const new_dates_map = produce_dates_map(iso_dates)
  const { value, selection } =
    parse_filter_term(props.filter_terms?.value, iso_dates)
  set.value(value)
  set.iso_dates(iso_dates)
  set.selection(selection)
  set.dates_map(new_dates_map)
}
type ListDistinctData = { [field_id: string]: unknown }[]

/** Selection state changed in SelectionTree. Put change in buffer. */
export const handle_selection_change:
  ApiHandler<DatepickerMenuCore, SelectionTreeOnChange> = (i, selection) => {
    i.set.selection(selection)
  }
