import * as Types from '../../../../codegen/gql-types';

import { produce_query } from 'app'
import gql from 'graphql-tag';

/**
 * 
 * 
 * 
 * 
 * 
 *         DO NOT EDIT THIS FILE - ANY CHANGES WILL BE LOST
 * 
 * 
 * 
 * 
 * 
 *    This file is automatically generated on every build by codegen plugins
 *    as defined in codegen.yml
 * 
 *    Its contents are defined by its sibling file _operations.graphql.
 * 
 * 
 * 
 */


 const list_verificacoes_doc = gql`
    query list_verificacoes($id_cliente: String!, $query_opts: QueryOpts) {
  list_verificacoes(id_cliente: $id_cliente, query_opts: $query_opts) {
    id
    renavam
    placa
    status
    status_multas
    status_debitos
    baixar_boletos
    full_name_usuario_emissor
    files_bundle {
      url
    }
    lista_fornecedor_credenciais {
      scraper_name
      credenciais {
        nome
        tipo_interno
        tipo
        valor
      }
    }
    requested_at
  }
}
    `;

/** Produce all of operations functions for archives. */
function archives_ops_factory() {
  const list_verificacoes = produce_query<GqlListVerificacoesData,GqlListVerificacoesVariables>(list_verificacoes_doc)
  
  return {
    list_verificacoes,
  }
}
/** Auto-generated operations functions for archives. */
export type ArchivesOps = ReturnType<typeof archives_ops_factory>

let memoized_operations: ArchivesOps
/** Produces and memoizes all archives operations. */
export function produce_archives_ops() {
  if (!memoized_operations) {
    memoized_operations = archives_ops_factory()
  }
  return memoized_operations
}

/** Types returned as the final result from operations execution.  */

export type GqlListVerificacoesData = GqlListVerificacoes['list_verificacoes']

export type GqlListVerificacoesVariables = Types.Exact<{
  id_cliente: Types.Scalars['String'];
  query_opts?: Types.Maybe<Types.GqlQueryOpts>;
}>;


export type GqlListVerificacoes = { list_verificacoes: Array<(
    Pick<Types.GqlVerificacao, 'id' | 'renavam' | 'placa' | 'status' | 'status_multas' | 'status_debitos' | 'baixar_boletos' | 'full_name_usuario_emissor' | 'requested_at'>
    & { files_bundle?: Types.Maybe<Pick<Types.GqlS3File, 'url'>>, lista_fornecedor_credenciais: Array<(
      Pick<Types.GqlFornecedorCredencial, 'scraper_name'>
      & { credenciais: Array<Pick<Types.GqlCredencial, 'nome' | 'tipo_interno' | 'tipo' | 'valor'>> }
    )> }
  )> };
