import React, { PropsWithChildren } from 'react'
import { affix, compose, Maybe } from 'helpers'
import { Svg } from './svg'

import styles from './clearable-button.module.scss'

type ClearableButtonProps = PropsWithChildren<{
  /** If true, shows an x on the right side and calls on_clear when the user clicks it. */
  is_cleararble: Maybe<boolean>
  /** Called when user wants the buttun to be cleaared.  */
  on_clear: () => void
  className?: string
}>
export function ClearableButton(props: ClearableButtonProps) {
  const { is_cleararble } = props
  const wrapper_classname = affix('clearable_button', is_cleararble && 'CLEARABLE')
  const classNames = compose(
    styles[wrapper_classname],
    props.className
  )
  return (
    <div className={classNames}>
      {props.children}

      {!is_cleararble ? null : <>
        <button
          onClick={props.on_clear}
          className={styles.clear_button}>
          <Svg size='small' icon='close' />
        </button>
      </>}
    </div>
  )
}