import { Selector } from 'controllers/tied'

let images_by_component: { [image_sublist_id: string]: CarouselImage[] } = {}
let images_list: CarouselImage[] = []
let current_carousel_id: string | undefined

export type CarouselImage = {
  label: string
  url?: string
  file_name?: string
  no_image_message?: string
}

/** Add the images list to the carousel. */
export const broadcast_carousel_images: Selector<CarouselImage[] | undefined> = (
  images, image_sublist_id
) => {
  if (!image_sublist_id || !images) return images_list
  images_by_component = {
    ...images_by_component,
    [image_sublist_id]: images
  }
  const component_keys = Object.keys(images_by_component)
  images_list = component_keys.reduce((prev_list, key) => [
    ...prev_list,
    ...images_by_component[key]
  ], [] as CarouselImage[])
  return images_list
}

/** Clear the carousel images list whenever its id changes. */
export const broadcast_carousel_id: Selector<string | undefined> = (id) => {
  if (id === current_carousel_id) return id
  current_carousel_id = id
  images_by_component = {}
  images_list = []
  return id
}

/** Selects one image to be enlarged. */
export const broadcast_enlarged_image: Selector<CarouselImage | undefined> = image => image