import React, { useState } from 'react'
import { affix, DefinedProps, format_currency, Maybe } from 'helpers'
import { GqlSituationResult } from 'codegen/gql-types'

import { DebitosRestricoes } from '../../../situation-search'
import { SituationKeys, situations_labels } from '../_data'
import styles from './situations-block.module.scss'
import { ShowMore, Svg, SvgSmallIcon } from 'components'
import { trace } from 'controllers/tracer'

export function produce_icon_props(
  situation: Maybe<GqlSituationResult>
): SvgSmallIcon {
  if (!situation) return 'disabled'
  if (situation.value) return 'danger'
  if (situation.status === 'NADA_CONSTA') return 'check'
  return 'attention'
}

type SituationsBlockProps = {
  className?: string
  selected_situations_keys: SituationKeys[]
  debitos_restricoes: DebitosRestricoes
}
export function SituationsBlock(props: SituationsBlockProps) {
  const { debitos_restricoes, selected_situations_keys } = props
  return (
    <ul className={props.className}>
      {selected_situations_keys.map((situation_key) => {
        const situation = debitos_restricoes[situation_key]
        const label = situations_labels[situation_key]
        const icon = produce_icon_props(situation)
        const icon_class = `icon_${icon}`

        if (process.env.NODE_ENV === 'development') if (situation?.status === 'CONSTA') trace('situations-block', 'render', { situation, label, icon, debitos_restricoes })

        return (
          <li className={styles.situation_wrapper} key={label}>
            <p className={styles.label}>
              <Svg size='small' icon={icon} className={styles[icon_class]} />
              {label}
            </p>
            <div className={styles.situation_value}>
              {!situation
                ? <>Não se aplica</>
                : <SituationValue {...situation} />
              }
            </div>
          </li>
        )
      })}
    </ul>
  )
}

type SituationValueProps = GqlSituationResult
function SituationValue(props: SituationValueProps) {
  const { summary, details, value } = props

  const nada_consta = !value && !summary && !details?.length
  if (nada_consta)
    return <>Nada consta</>

  return <>
    <Value value={value} />
    <SummaryDetails summary={summary} details={details} />
  </>
}

/** Renders only the value, if it exists. */
function Value({ value }: Pick<GqlSituationResult, 'value'>) {
  if (!value) return null
  return (
    <span className={styles.debt_value}>
      {format_currency(value)}
    </span>
  )
}

/** Renders summary and/or details if they exist. */
function SummaryDetails(
  { summary, details }: Pick<GqlSituationResult, 'summary' | 'details'>
) {
  const no_detalis = !details?.length
  const no_summary = !summary

  // nothing to be shown
  if (no_summary && no_detalis)
    return null

  // no summary => render details
  if (no_summary)
    return <Details details={details!} />

  // no details => render summary
  if (no_detalis)
    return <>{summary}</>

  // has both => show summary only and user can expand
  return <ExpandableSummary summary={summary!} details={details!} />
}

function ExpandableSummary(
  { summary, details }: DefinedProps<Pick<GqlSituationResult, 'summary' | 'details'>>
) {
  const [expanded, set_expanded] = useState<boolean>(false)
  const toggle_details = () => { set_expanded(!expanded) }

  const button_css = affix('expand_details_button', expanded && 'EXPANDED')
  return (<>
    <button onClick={toggle_details} className={styles[button_css]}>
      <ShowMore expanded={expanded} />
      {summary}
    </button>
    {!expanded ? null : <Details details={details} indent />}
  </>)
}

type DetailsProps = DefinedProps<Pick<GqlSituationResult, 'details'>> & {
  /** If true, visual parent is an expandable summary and this needs to be indented. */
  indent?: boolean
}
function Details(props: DetailsProps) {
  return (
    <ul className={props.indent ? styles.indented_details : undefined}>
      {props.details.map(({ text, title }) => {
        const label = title ? `${title}: ${text}` : text
        return (
          <li key={label}>
            {label}
          </li>
        )
      })}
    </ul>
  )
}