import { ApolloError } from 'apollo-client'
import { AppHandler } from 'app/_app-interface'
import { get_white_label_host } from 'app/_router'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { ApiHandler } from 'controllers/tied'
import { LoginApiCore } from './_data'

import { produce_login_ops, GqlSigninData, GqlSigninVariables } from './_operations'

export const handle_login: ApiHandler<LoginApiCore> = (i) => {
  const { email, password } = i.params

  if (!(email?.length && password?.length)) return

  const variables: GqlSigninVariables = { email, password }
  const sign_in = produce_sign_in()
  sign_in(i, variables)
}

/** Produces the sign-in function used by the current host. */
function produce_sign_in() {
  const white_label_host = get_white_label_host()
  const sign_in_map: { [k in typeof white_label_host]: SignInFunction } = {
    brobot: sign_into_brobot,
    vericar: sign_into_vericar
  }
  return sign_in_map[white_label_host]
}

type SignInFunction = ApiHandler<LoginApiCore, [GqlSigninVariables]>
const sign_into_brobot: SignInFunction = (i, variables) => {
  const { signin } = produce_login_ops()
  const { handle_new_session, handle_gql_errors } = i.makeApi(true)
  signin({
    variables,
    on_completed: handle_new_session,
    on_errors: handle_gql_errors
  })
}

type VericarResponse = {
  usuarioEmail: string
  token: string
  emitidoEm: number
  expiraEm: number
}
const sign_into_vericar: SignInFunction = (i, variables) => {
  const urls = {
    mock: 'https://virtserver.swaggerhub.com/T8353/SWI/1.0.0/usuario/auth/brobot',
    // Usuário e senha ambiente stage
    //    homologa@brobot.tige.com.br
    //    S3nhaH0molo;açãoBroBot
    stage: 'https://homologaapiswi.tige.com.br/api/v1/usuario/auth/brobot',
    prod: 'https://apiswi.tige.com.br/api/v1/usuario/auth/brobot'
  }
  const api_url = process.env.NODE_ENV === 'production' ? urls.prod : urls.stage
  const { email, password: senha } = variables
  const headers: AxiosRequestConfig['headers'] = {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
  const {
    handle_new_session,
    handle_gql_errors,
    raise_network_error,
    set_error_message
  } = i.makeApi(true)
  axios.post<VericarResponse>(api_url, { email, senha }, { headers })
    .then(result => {
      const { get_current_user } = produce_login_ops()
      const { token } = result.data
      const context = {
        headers: {
          authorization: 'Bearer ' + token,
        }
      }
      get_current_user({
        options: { context },
        on_completed: user => {
          handle_new_session({ user, token })
        },
        on_errors: handle_gql_errors
      })
    })
    .catch((error: Error | AxiosError) => {
      if ('response' in error && error.response) {
        const { status, statusText } = error.response
        const message = [401, 403].includes(status)
          ? 'Email ou senha inválidos.'
          : `Ocorreu um erro: (${status}) ${statusText}.`
        set_error_message(message)
        return
      }
      raise_network_error()
    })
}

export const set_password: ApiHandler<LoginApiCore> = (i, text) => {
  i.set.password(text)
  i.set.error_message(undefined)
}

export const set_email: ApiHandler<LoginApiCore> = (i, text) => {
  i.set.email(text)
  i.set.error_message(undefined)
}

export const handle_new_session: ApiHandler<LoginApiCore, [GqlSigninData]> = (
  i, { user, token }
) => {
  i.set.user(user)

  const stored_token = localStorage.getItem('auth-token')
  if (stored_token === token) return

  localStorage.setItem('auth-token', token)
  localStorage.setItem('token-status', 'fresh')
  window.location.reload()
}

export const handle_gql_errors: AppHandler<LoginApiCore> = (i, errors: ApolloError) => {
  const is_network_error = errors.networkError
  if (is_network_error) {
    console.log(errors)
    const { raise_network_error } = i.makeApi(true)
    raise_network_error()
    return
  }
  // TODO: handle multiple erros properly
  const { message } = errors.graphQLErrors[0]
  const is_wrong_credentials = message && message.includes(':invalid_credentials')
  const error_message = is_wrong_credentials
    ? 'Email ou senha inválidos.'
    : `Ocorreu um erro: ${message}.`
  i.set.error_message(error_message)
}

export const raise_network_error: AppHandler<LoginApiCore> = i => {
  i.app.show_modal('Message', {
    title: 'Erro na comunicação com o servidor',
    message: 'Sua conexão parece estar com problemas.',
    ok_label: 'Ok'
  })
}

export const set_error_message: AppHandler<LoginApiCore> = (i, message: string) => {
  i.set.error_message(message)
}