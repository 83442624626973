import React, { useRef, useState } from 'react'
import { affix, compose, Defined, Maybe, take } from 'helpers'
import { Text, Checkbox, AutoComplete, Button, Donut, InfoBox, SvgRegularIcon, Ruler } from 'components'

import { DataValue, Label, Renderers } from './renderers'
import { SimpleTableProps, EditableFields, EditableAutoComplete, ObjectWithId, EditableField, SimpleTableColumns } from './_data'
import styles from './simple-table.module.scss'

type SimpleTableRow<T extends ObjectWithId, P extends EditableFields<T>> =
  Defined<SimpleTableProps<T, P>['data']>[number]

type ColumnMinWidths<T> = { [k in keyof T]: string }

export function SimpleTable<T extends ObjectWithId, P>(
  props: SimpleTableProps<T, P>
) {
  const {
    data,
    columns,
    item_name,
    selectable_rows,
    editable,
    editable_fields = {}
  } = props

  const {
    handle_add_item,
    handle_item_added,
    handle_row_updated,
    show_add_item_button,
    rows_last_update,
    is_adding_row
  } = useEditTable(editable)

  const {
    table_classes,
    column_keys,
    measure_pending,
    column_min_widths,
    handle_headers_measured
  } = useTableLayout(columns)


  // if headers size is dynamic, measure before rendering table
  // const a = true
  // if (a) return (
  if (measure_pending) return (
    <HeadersRuler
      columns={columns}
      column_keys={column_keys}
      on_measured={handle_headers_measured} />
  )

  // If data is undefined, it is still not loaded => block all functionality.
  if (!data) return null

  // Data was loaded, but it is an empty list.
  const is_empty = !data.length
  return (
    <>
      {is_empty && props.hide_on_empty ? null : (
        <div className={compose(styles.table_wrapper, props.className)}>
          <table className={table_classes}>
            <TableHeader
              columns={columns}
              column_min_widths={column_min_widths}
              column_keys={column_keys}
              editable={editable}
              editable_fields={editable_fields}
              selectable_rows={selectable_rows}
            />

            <tbody>
              {!data ? null : data.map((row) => {
                const { id } = row
                return (
                  <TableRow
                    key={id}
                    row={row}
                    columns={columns}
                    last_updated={rows_last_update[id]}
                    column_keys={column_keys}
                    editable_fields={editable_fields}
                    selectable_rows={selectable_rows}
                    initial_selected_rows_ids={props.initial_selected_rows_ids}
                    on_selection_change={props.on_selection_change}
                    on_remove_row={props.on_remove_row}
                    on_row_updated={handle_row_updated}
                    item_name={item_name}
                    editable={editable} />
                )
              })}

              {!is_adding_row ? null : (
                <NewRow
                  on_row_updated={handle_item_added}
                  item_name={item_name}
                  column_keys={column_keys}
                  editable_fields={editable_fields}
                  selectable_rows={selectable_rows}
                />
              )}
            </tbody>
          </table>
        </div>
      )}

      {(!is_empty || is_adding_row) ? null :
        <EmptyTable
          className={props.empty_message_class}
          empty_table_message={props.empty_table_message} />
      }

      {!show_add_item_button ? null : (
        <Button
          onClick={handle_add_item}
          className={styles.add_item_button}
          layout='SLIM'
          color='MAIN_SECONDARY'>
          {`Adicionar ${props.item_name}`}
        </Button>
      )}
    </>
  )
}


function useTableLayout<T extends ObjectWithId>(columns: SimpleTableColumns<T>) {
  const column_keys = (
    Object.keys(columns)
      .filter(k => !columns[k]?.hidden)
  ) as (keyof T)[]

  const is_fixed_width = !!columns[column_keys[0]]?.th_style?.width

  const [column_min_widths, set_column_min_widths] = useState<ColumnMinWidths<T>>()
  // if not fixed width => prevent rendering before measuring the columns
  const measure_pending = !is_fixed_width && !column_min_widths
  const handle_headers_measured = (widths: number[]) => {
    const min_widths = widths.reduce((prev, w, idx) => {
      const key = column_keys[idx]
      return {
        ...prev,
        [key]: `${w / 16}rem`
      }
    }, {} as ColumnMinWidths<T>)
    set_column_min_widths(min_widths)
  }

  const table_classes = compose(
    styles.table,
    is_fixed_width && styles.fixed_layout
  )

  return {
    table_classes,
    column_keys,
    measure_pending,
    column_min_widths,
    handle_headers_measured
  }
}


function useEditTable(editable: boolean | undefined) {
  const [rows_last_update, set_rows_last_update] = useState<{ [row_id: string]: number }>({})
  const handle_row_updated = (updated_row_id: Maybe<string>) => {
    if (!updated_row_id) return
    const new_rows_last_update = {
      ...rows_last_update,
      [updated_row_id]: Date.now()
    }
    set_rows_last_update(new_rows_last_update)
  }

  const [is_adding_row, set_is_adding_row] = useState<boolean>()
  const show_add_item_button = editable && !is_adding_row
  const handle_add_item = () => {
    set_is_adding_row(true)
  }
  const handle_item_added = (added_row_id: Maybe<string>) => {
    set_is_adding_row(false)
    handle_row_updated(added_row_id)
  }

  return {
    handle_add_item,
    handle_item_added,
    handle_row_updated,
    show_add_item_button,
    rows_last_update,
    is_adding_row
  }
}

type HeadersRulerProps<T extends ObjectWithId> = {
  on_measured: (widths: number[]) => void
  column_keys: (keyof T)[]
  columns: SimpleTableColumns<T>

}
function HeadersRuler<T extends ObjectWithId>(props: HeadersRulerProps<T>) {
  const { columns } = props
  const texts = props.column_keys.map(k => columns[k]!.header)
  const class_list = [styles['column_header-EDITABLE']]
  return (
    <Ruler
      texts={texts}
      class_list={class_list}
      on_measured={props.on_measured}
    />
  )
}

type TableHeaderProps<T extends ObjectWithId, P> = Pick<SimpleTableProps<T, P>,
  'editable' |
  'selectable_rows' |
  'columns'
> & {
  column_keys: (keyof T)[]
  editable_fields: P | {},
  column_min_widths: ColumnMinWidths<T> | undefined
}
function TableHeader<T extends ObjectWithId, P>(props: TableHeaderProps<T, P>) {
  const { columns, editable_fields, column_min_widths } = props
  return (
    <thead>
      <tr className={styles.header_row}>
        {/* if rows can be selected, add checkbox column. */}
        {!props.selectable_rows ? null :
          <th className={compose(
            styles.icon_cell_header,
            styles.cell_checkbox
          )}>&nbsp;</th>
        }
        {/* if editable, add remove button column. */}
        {!props.editable ? null :
          <th className={styles.icon_cell_header}>&nbsp;</th>
        }
        {props.column_keys.map(key => {
          const column = columns[key]
          if (!column)
            throw new Error(`Missing column ${key}`)

          const { header, th_style } = column!
          const is_editable = key in editable_fields
          const class_name = affix('column_header', is_editable && 'EDITABLE')

          const style = !column_min_widths ? th_style : {
            ...th_style,
            minWidth: column_min_widths[key]
          }
          return (
            <th key={key} className={styles[class_name]} style={style}>
              {header}
            </th>
          )
        }
        )}
      </tr>
    </thead>
  )
}

type TableRowProps<T extends ObjectWithId, P extends EditableFields<T>> = {
  column_keys: (keyof T)[]
} &
  CellCheckboxProps<T, P> &
  CellRemoveRowProps<T, P> &
  Pick<CellContentsProps<T>, 'on_row_updated'> &
  Pick<SimpleTableProps<T, P>,
    'editable_fields' |
    'editable' |
    'selectable_rows' |
    'columns' |
    'on_selection_change'
  > & {
    row: SimpleTableRow<T, P>
  }
function TableRow<T extends ObjectWithId, P extends EditableFields<T>>(
  props: TableRowProps<T, P>
) {
  const { row, editable_fields, editable, on_row_updated, columns } = props
  return (
    <tr>
      <CellCheckbox {...take(props,
        'selectable_rows',
        'row',
        'last_updated',
        'on_selection_change',
        'initial_selected_rows_ids'
      )} />

      <CellRemoveRow {...take(props,
        'editable',
        'item_name',
        'on_remove_row',
        'row',
      )} />

      {props.column_keys.map((key) => {
        //TableHeader already validates that the column definition exists
        const value = row[key]
        const editable_field = editable_fields && editable_fields[key]

        const column_config = columns[key]!
        const { format_value } = column_config
        const formatted_value = format_value ? format_value(value) : value

        return (
          <CellContents
            key={key}
            row_id={row.id}
            field_key={key}
            renderer={column_config.renderer}
            derive_icon={column_config.derive_icon}
            value={formatted_value}
            centered={column_config.centered}
            on_row_updated={on_row_updated}
            table_is_editable={editable}
            editable_field={editable_field} />
        )
      })}
    </tr>
  )
}

type NewRowProps<T extends ObjectWithId, P extends EditableFields<T>> = {
  column_keys: (keyof T)[]
} &
  CellCheckboxProps<T, P> &
  CellRemoveRowProps<T, P> &
  Pick<SimpleTableProps<T, P>, 'editable_fields' | 'item_name' | 'selectable_rows'> &
  Pick<CellContentsProps<T>, 'on_row_updated'>
function NewRow<T extends ObjectWithId, P extends EditableFields<T>>(
  props: NewRowProps<T, P>
) {
  const { editable_fields, column_keys, on_row_updated } = props
  const new_row_ref = useRef<T>()
  const { current } = new_row_ref
  const first_editable_key =
    editable_fields && column_keys.find(k => editable_fields[k])

  return (
    <tr>
      <CellCheckbox
        is_adding_row
        {...take(props, 'selectable_rows', 'last_updated')}
      />

      <CellRemoveRow editable is_adding_row />

      {props.column_keys.map((key, idx) => {
        const value = current && current[key]
        const is_first_editable = key === first_editable_key
        const editable_field = editable_fields && editable_fields[key]
        return (
          <CellContents
            key={key}
            is_new_row
            value={value}
            row_id={null}
            field_key={key}
            on_row_updated={on_row_updated}
            table_is_editable
            focus_on_mount={is_first_editable}
            editable_field={editable_field} />
        )
      })}
    </tr>
  )
}

type CellContentsProps<T extends ObjectWithId> = {
  field_key: keyof T
  value: DataValue
  editable_field: EditableField<T> | undefined
  focus_on_mount?: boolean
  is_new_row?: boolean
  row_id: string | null
  table_is_editable: boolean | undefined
  on_row_updated: (row_id: Maybe<string>) => void
  renderer?: Renderers[number]
  derive_icon?: (value: DataValue) => SvgRegularIcon
  centered?: boolean
}
function CellContents<T extends ObjectWithId>(props: CellContentsProps<T>) {
  const { value, editable_field } = props
  const initial_text = value === null
    ? undefined
    : value as unknown as string | undefined

  if (!editable_field || !props.table_is_editable) {
    const Renderer = props.renderer || Label
    const td_classes = compose(
      styles.non_editable_cell,
      props.centered && styles.centered
    )
    return (
      <td className={td_classes}>
        <Renderer value={value} derive_icon={props.derive_icon} />
      </td>
    )
  }

  const placeholder = editable_field.placeholder
  const color = props.is_new_row ? 'BORDERED' : 'BORDERED_ON_HOVER'

  switch (editable_field.type) {
    case Text:
      return (
        <td className={styles.editable_cell}>
          <Text
            color={color}
            focus_on_mount={props.focus_on_mount}
            initial_text={initial_text}
            placeholder={placeholder}
          />
        </td>
      )

    case AutoComplete:
      const { auto_complete } = editable_field as EditableAutoComplete<T>
      const handle_select = (option: T) => {
        const { row_id } = props
        const { ok, updated_id } = auto_complete.on_select(option, row_id)
        if (ok) {
          props.on_row_updated(updated_id)
        }
      }
      return (
        <td className={styles.editable_cell}>
          <AutoComplete
            {...auto_complete}
            on_select={handle_select}
            color={color}
            icon='ARROW_DOWN'
            placeholder={placeholder}
            initial_text={initial_text}
          />
        </td>
      )

    // Fallback to unexisting component
    default:
      if (process.env.NODE_ENV === 'development')
        console.log(`Editable field type does not exist: ${props.field_key}`)
      return <td>{initial_text}</td>
  }
}

type CellRemoveRowProps<T extends ObjectWithId, P extends EditableFields<T>> =
  Partial<Pick<SimpleTableProps<T, P>,
    'editable'
    | 'on_remove_row'
    | 'item_name'
  >> & {
    row?: SimpleTableRow<T, P>
    is_adding_row?: boolean
  }
function CellRemoveRow<T extends ObjectWithId, P extends EditableFields<T>>(
  props: CellRemoveRowProps<T, P>
) {
  if (!props.editable) return null
  if (props.is_adding_row) return <td></td>

  const { row } = props
  const remove_toolip_text = `Remover ${props.item_name}`
  const handle_remove_row = () => {
    if (!props.on_remove_row)
      throw new Error(`Not able to delete. Missing prop on_remove_row in simple table.`)
    if (!row)
      throw new Error(`Not able to delete. Missing prop row in first cell.`)
    props.on_remove_row(row.id)
  }
  return (
    <td className={styles.cell_close}>
      <button onClick={handle_remove_row}>&times;</button>
      <span className='tooltip tooltip_danger'>{remove_toolip_text}</span>
    </td>
  )
}

type CellCheckboxProps<T extends ObjectWithId, P extends EditableFields<T>> =
  Pick<SimpleTableProps<T, P>,
    'selectable_rows' |
    'on_selection_change' |
    'initial_selected_rows_ids'
  > & {
    row?: SimpleTableRow<T, P>
    is_adding_row?: boolean
    last_updated?: number
  }

function CellCheckbox<T extends ObjectWithId, P extends EditableFields<T>>(
  props: CellCheckboxProps<T, P>
) {
  const [spin, set_spin] = useState<boolean>()
  const { last_updated, initial_selected_rows_ids } = props
  if (last_updated && !spin) {
    const time_lapsed = Date.now() - last_updated
    if (time_lapsed < 3000) {
      set_spin(true)
      setTimeout(() => { set_spin(false) }, 3000)
      return <td><Donut spin /></td>
    }
  }
  if (spin) return <td className={styles.cell_spinner}><Donut spin /></td>
  if (!props.selectable_rows) return null
  if (props.is_adding_row) return <td></td>

  const id = props.row?.id
  if (!id)
    throw new Error(`Not able to select. Missing prop row in first cell.`)

  const is_selected = initial_selected_rows_ids && initial_selected_rows_ids[id]
  const handle_change = (is_selected: boolean) => {
    if (props.on_selection_change)
      props.on_selection_change(id, is_selected)
  }
  return (
    <td className={styles.cell_checkbox}>
      <Checkbox
        initial_value={is_selected}
        on_change={handle_change}
      />
    </td>
  )
}

type EmptyTableProps = Pick<SimpleTableProps<any, any>,
  'empty_table_message'
> & {
  className: string | undefined
}
function EmptyTable(props: EmptyTableProps) {
  const { empty_table_message } = props
  if (!empty_table_message) return null

  return (
    <InfoBox looks='INFO' className={props.className}>
      {empty_table_message}
    </InfoBox>
  )
}