import { AppHandler, produce_observable_query } from 'app'
import { ApolloError } from 'apollo-client'
import { set_phase_state, take } from 'helpers'

import { TableQueryVariables, ListSheetResult } from '../_dynamic_ops'
import { TableApiCore, TablePhase, TableState, REQUEST_PHASES, Data } from '../_data'
import { ApiInterface } from 'controllers/tied'

/** Change phase state and run query to request data. */
export const change_phase: AppHandler<TableApiCore, [TablePhase, Partial<TableState>]> = (
  i, new_phase, phase_state
) => {

  // const complete_phase_state = handle_variables_completeness(i, phase_state)
  set_phase_state(i, phase_state)
  i.set.phase(new_phase)

  if (REQUEST_PHASES.includes(new_phase)) {
    const has_pending_request = REQUEST_PHASES.includes(i.state.phase)
    // if another request is pending, cancel it before executing a new one
    if (has_pending_request) i.params.list_sheet!.observable.unsubscribe()

    // clear status before query, so past errors are not shown anymore
    i.set.status_text({ text: '', type: 'REGULAR' })

    const variables: TableQueryVariables = {
      ...take(i.state, 'offset', 'limit', 'filter_terms_list', 'order_by_list'),
      ...take(phase_state, 'offset', 'limit', 'filter_terms_list', 'order_by_list'),
      id_cliente: i.props.id_cliente
    }
    fetch_list_sheet(i, variables, has_pending_request)

    return ['ok', 'QUERY-RAN', { variables, new_phase, i, phase_state }]
  }

  return ['ok', 'NO-QUERY-RAN', { new_phase, REQUEST_PHASES, i, phase_state }]
}

/** Retrieve list_sheet data. */
function fetch_list_sheet(
  i: ApiInterface<TableApiCore>,
  variables: TableQueryVariables,
  resubscribe: boolean
) {
  // if subscription already exists, just reftch
  const { list_sheet } = i.params
  const { list_sheet_doc } = i.operations

  if (!resubscribe && list_sheet && list_sheet.document === list_sheet_doc) {
    list_sheet.query.refetch(variables)
    return
  }

  // create new subscription
  const { sheet_name } = i.props
  const query_name = `list_sheet_${sheet_name.toLowerCase()}`
  const { handle_error, parse_data } = i.makeApi(true)

  const observable_query =
    produce_observable_query<ListSheetResult, TableQueryVariables>(list_sheet_doc)
  const subscription = observable_query({
    variables,
    on_completed: parse_data,
    on_errors: (errors) => {
      handle_error(query_name, variables, errors.graphQLErrors[0])
    }
  })

  const new_list_sheet = {
    ...subscription,
    document: list_sheet_doc
  }

  i.set.list_sheet(new_list_sheet)
}

/** Parse the data received from server.  */
export const parse_data: AppHandler<TableApiCore> = (
  i, result: Data<undefined> | undefined
) => {
  const data = result ?? []
  i.set.data(data)
}

/** Show error in status text, generate its payload and log it if in debug mode. */
export const handle_error: AppHandler<TableApiCore> = (
  i, query_name: string, variables: TableQueryVariables, errors: ApolloError
) => {
  const error_log = {
    errors,
    variables,
    query_name,
    params: take(i.params, 'last_data', 'rows_count'),
    ...take(i, 'props', 'state', 'visible_columns_ids_state'),
  }
  // show in footer status text
  // TODO: handle multiple errors and network errors properly
  i.set.status_text({
    text: errors.graphQLErrors[0].message,
    type: 'ERROR',
    payload: error_log
  })
}