import { AnyObject, Widen } from './types'
import { Assign } from 'utility-types'
import { LabeledValue } from 'components'

/** Return true for null, undefined, [] and {} */
export function is_empty(value: any) {
  // eslint-disable-next-line eqeqeq
  if (value == undefined) { return true }
  if (Array.isArray(value) && !value.length) { return true }
  if (typeof value === 'object' && !Object.keys(value).length) { return true }
  return false
}

/** Partially clones an object, extracting the properties listed in keys.
 * False or undefined keys will be ignored.
 */
export function take<T, K extends keyof T>(
  object: T, ...keys: (K | undefined)[]
) {
  let new_object: Partial<T> = {}
  for (const key of keys) {
    if (key !== undefined && key in object) {
      new_object[key] = object[key]
    }
  }
  return new_object as { [I in K]: T[I] }
}


/** Removes the key from object. */
export function drop<T extends AnyObject, K extends keyof T>(
  object: T, ...keys: K[]
) {
  let result: Partial<T> = {}
  for (const k of Object.keys(object) as (keyof T)[]) {
    //@ts-ignore
    if (!keys.includes(k)) {
      result[k] = object[k]
    }
  }
  return result as Omit<T, K>
}

type OmitMaybe<T, K> = K extends keyof T ? Omit<T, K> : T
export function deep_drop<T extends AnyObject, K extends string>(
  obj: T, dropped_key: K
): OmitMaybe<T, K> {
  const keys = Object.keys(obj) as (keyof T)[]
  return keys.reduce((prev, k) => {
    if (k === dropped_key) return prev
    const value = obj[k]

    const clean_value = is_object(value)
      ? deep_drop(value, dropped_key)
      : value

    return { ...prev, [k]: clean_value }
  }, {} as OmitMaybe<T, K>)
}

export function is_object(value: unknown): value is AnyObject {
  return (
    typeof value === 'object' &&
    !Array.isArray(value) &&
    value !== null
  )
}

/** Merges all objects in the same key across all arguments and ignore nulls */
export function spreadKey<
  F extends { [i: string]: { [j: string]: any } },
  S extends { [i: string]: { [j: string]: any } }, //AnyObject,
  K extends string
>(
  key: K,
  first: F | null,
  second: S | null
): null | F[K] | S[K] | Assign<F[K], S[K]> {
  if (first && (key in first)) {
    if (!(second && (key in second))) {
      return first[key] as F[K]
    }
    return {
      ...first[key],
      ...second[key]
    }
  }
  if (second && (key in second)) {
    return second[key]
  }
  return null
}

/** Clone `array`, changing the value at position `idx` to `value`. */
export function set_entry<T>(array: T[], idx: number, value: T) {
  let new_array = [...array]
  new_array[idx] = value
  return new_array
}

/** Sum all entries in an array of numbers, ignoring undefineds. */
export function sum_array(array: (number | undefined)[]) {
  return array.reduce<number>((prev, value) => (
    value ? prev + value : prev
  ), 0)
}

/** Return a new list with the item updated, or added to the end of the list 
 * if no items with the same key are found. */
export function upsert<T>(list: T[], item: T, key: keyof T): T[] {
  const search_value = item[key]
  const item_idx = list.findIndex(i => i[key] === search_value)
  const target_idx = item_idx > -1 ? item_idx : list.length

  const new_list = [...list]
  new_list[target_idx] = item
  return new_list
}

/** Pick elements from a tuple, keeping their order. */
export function pick<T extends readonly [any, ...any[]], U extends T[number]>(base_list: T, arg1: U, ...args: U[]): U[];
export function pick<T extends readonly [any, ...any[]], U extends T[number]>(base_list: T, ...args: readonly U[]): U[] {
  const result = base_list.filter(id => args.includes(id))
  return result as U[]
}

/** Groups items in list by valyes found in key. */
export function group_by<T>(list: T[], group_key: keyof T): { [i: string]: T[] } {
  const result: { [i: string]: T[] } = {}
  list.forEach(item => {
    const key = item[group_key] as unknown as string
    result[key] = key in result
      ? [...result[key], item]
      : [item]
  })
  return result
}

/** Groups items in list by a predetermined list values found in key. */
export function group_by_list<T extends AnyObject>(list: T[], key: keyof T, group_keys: string[]): { [i: string]: T[] } {
  const result = group_keys.reduce((prev, k) => {
    const filtered_list = list.filter(i => i[key] === k)
    if (!filtered_list.length) return prev
    return { ...prev, [k]: filtered_list }
  }, {} as { [i: string]: T[] })
  return result
}

/** 
 * If `override` is defined, return the `initial` object keys with the 
 * values in `override`.
 * 
 * If `override` is undefined return the `initial` object.
*/
export function pick_override<T extends object>(
  initial: T,
  override: Widen<T> | undefined
) {
  if (override) {
    const keys_ = Object.keys(initial) as (keyof T)[]
    return take(override, ...keys_)
  }
  return initial
}

/** Insert array `b` in between two parts of array `a`, split at `position`.
 *  If position is negative, it is counted from the end of array `a`.
 *  Inclusive = true means the first element in `b` must replace the element in 
 * `position` at `a`. */
export function interpose<T>(a: T[], b: T[], position: number, inclusive?: boolean) {
  const start = a.slice(0, position)
  const end_position = inclusive
    ? position + 1
    : position
  const end = a.slice(end_position)
  return [...start, ...b, ...end]
}

/** Add one element to an array, if it is not already there. */
export function append_unique_item<T>(list: T[] | undefined, item: T): T[] {
  if (!list) return [item]
  if (list.includes(item)) return list
  return [...list, item]
}

/**
 *  Add a list of elemnets to an array, if they are not already there. 
 *  If new_list has duplicated entries, only one of each unique entry will be added.
 */
export function append_unique_list<T>(list: T[] | undefined, new_list: T[]): T[] {
  if (!list) return new_list
  if (!new_list.length) return list
  const list_plus_one = append_unique_item(list, new_list[0])
  return append_unique_list(list_plus_one, new_list.slice(1))
}

export function remove_position_from_list<T>(list: T[], position: number) {
  const first_half = list.slice(0, position)
  const second_half = list.slice(position + 1)
  return [...first_half, ...second_half]
}

/** Searches for the first occurrence of item in list and returns a list without it. */
export function remove_item_from_list<T>(list: T[], item: T): T[] {
  if (!list.includes(item)) return list
  const item_position = list.findIndex(i => i === item)
  return remove_position_from_list(list, item_position)
}

/** Remove all items in removed_list from list, if they are found in it. */
export function remove_list_from_list<T>(list: T[], removed_list: T[]): T[] {
  if (!removed_list.length) return list
  const list_minus_one = remove_item_from_list(list, removed_list[0])
  return remove_list_from_list(list_minus_one, removed_list.slice(1))
}

/** Remove the first item from list where item[key] = value. */
export function remove_item_from_list_by_key<T>(list: T[], key: keyof T, value: unknown) {
  const item_position = list.findIndex(i => i[key] === value)
  return remove_position_from_list(list, item_position)
}