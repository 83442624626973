import React, { useEffect, useState } from 'react'
import { trace } from 'controllers/tracer'
import { compare_for_sort, Maybe, useGqlSubscription } from 'helpers'

import { SituationCard } from './situation-card'
import styles from './list-situation-cards.module.scss'
import { DebitosRestricoes, EnrichedebitosRestricoes } from '../situation-search'
import { produce_list_situation_cards_ops } from './_operations'

type ListSituationCardsProps = {
  id_cliente: string
  id_verificacao: string
  is_hidden?: boolean
}
/** List all card currently related to a verification id. */
export function ListSituationCards(props: ListSituationCardsProps) {
  const { id_verificacao, id_cliente } = props
  const filtered_debitos_restricoes_list =
    useFilteredDebitosRestricoes(id_verificacao, id_cliente)

  if (process.env.NODE_ENV === 'development') trace('autos-situation', 'ListSituationCards', { filtered_debitos_restricoes_list, id_verificacao, id_cliente, props })

  // no list - show nothing
  if (props.is_hidden || !filtered_debitos_restricoes_list?.length) return null

  return (
    <div className={styles.tab_wrapper}>
      {filtered_debitos_restricoes_list.map((debitos_restricoes) =>
        <SituationCard
          key={debitos_restricoes.id}
          debitos_restricoes={debitos_restricoes}
        />
      )}
    </div>
  )
}

function useFilteredDebitosRestricoes(id_verificacao: string, id_cliente: string) {
  const operations = produce_list_situation_cards_ops()

  // complete list to be shown (may have duplicate suppliers if a retry happened)
  const [debitos_restricoes_list, set_debitos_restricoes_list] = useState<DebitosRestricoes[]>([])

  // list to be shown
  const [filtered_debitos_restricoes_list, set_filtered_debitos_restricoes_list] = useState<EnrichedebitosRestricoes[]>([])

  useGqlSubscription(() => {
    const observable = operations.debitos_restricoes_created({
      variables: { id_verificacao },
      // might be a partial list to be appended on retries
      on_updated: (new_debitos_restricoes_list) => {
        const first_new_id = new_debitos_restricoes_list[0].id
        const is_adding_to_the_list = debitos_restricoes_list &&
          debitos_restricoes_list.findIndex(dr => dr.id === first_new_id) === -1

        const final_debitos_restricoes_list = is_adding_to_the_list
          ? [...debitos_restricoes_list || [], ...new_debitos_restricoes_list]
          : new_debitos_restricoes_list

        if (process.env.NODE_ENV === 'development') trace('list-situation-cards', 'debitos_restricoes_created', { new_debitos_restricoes_list, id_verificacao, debitos_restricoes_list, final_debitos_restricoes_list })

        update_lists(final_debitos_restricoes_list)
      }
    })
    return observable
  }, [id_verificacao, debitos_restricoes_list])

  // reload list when ids change
  useEffect(() => {
    operations.list_debitos_restricoes({
      variables: { id_verificacao, id_cliente },
      options: {
        // without this, the cards will not reflect the current state if
        // we go to history and back while an execution is in progress.
        fetchPolicy: 'no-cache'
      },
      on_completed: (new_list) => {
        if (process.env.NODE_ENV === 'development') trace('list-situation-cards', 'list_debitos_restricoes', { id_verificacao, debitos_restricoes_list, new_list })

        // TODO: remove this - hack to fix race condition
        // add debitos_restricoes_created subsciption to search starter and 
        // use it to inform the rest of the code that a list can be shown.
        if (!new_list.length) {
          setTimeout(() => {
            if (!new_list.length) window.location.reload()
          }, 2000)
          if (!debitos_restricoes_list.length) return
        }
        update_lists(new_list)
      }
    })

  }, [id_verificacao])

  function update_lists(new_debitos_restricoes_list: DebitosRestricoes[]) {
    const new_new_debitos_restricoes_list = filter_debitos_restricoes_list(new_debitos_restricoes_list)
    set_debitos_restricoes_list(new_debitos_restricoes_list)
    set_filtered_debitos_restricoes_list(new_new_debitos_restricoes_list)

  }

  // filter list to show only the most recent attempt for each fornecedor
  function filter_debitos_restricoes_list(
    new_debitos_restricoes_list: Maybe<DebitosRestricoes[]>
  ) {
    if (!new_debitos_restricoes_list) return []

    const attempts_by_fornecedor: { [id_fornecedor: string]: number } = {}
    const filtered_debitos_restricoes_list = new_debitos_restricoes_list
      .filter(({ id_fornecedor, id }) => {
        const sorted_attempts = new_debitos_restricoes_list
          .filter(dr => dr.id_fornecedor === id_fornecedor)
          .sort((d1, d2) => compare_for_sort(d1.requested_at, d2.requested_at))

        const { length } = sorted_attempts
        attempts_by_fornecedor[id_fornecedor!] = length
        // only one attempt was made for this fornecedor => show it
        if (length < 2) return true
        // more than one attempt was made for this fornecedor => show if this is the last one
        const last_dr = sorted_attempts[length - 1]
        return id === last_dr.id
      })
      .reduce((prev, dr) => [...prev,
      { ...dr, attempts: attempts_by_fornecedor[dr.id_fornecedor!] }
      ], [] as EnrichedebitosRestricoes[])

    const in_progerss_jobs = filtered_debitos_restricoes_list
      .filter(({ status }) => status === 'REQUESTED' || status === 'STARTED')
      .sort((d1, d2) => compare_for_sort(d1.nome_fornecedor, d2.nome_fornecedor))
    const finished_jobs = filtered_debitos_restricoes_list
      .filter(({ status }) => status !== 'REQUESTED' && status !== 'STARTED')
      .sort((d1, d2) => compare_for_sort(d1.nome_fornecedor, d2.nome_fornecedor))

    const result = [...in_progerss_jobs, ...finished_jobs]

    if (process.env.NODE_ENV === 'development') trace('list-situation-cards', 'useSyncEffect', { result, id_verificacao, debitos_restricoes_list })

    return result
  }

  useGqlSubscription(() => {
    const { debito_restricao_changed } = operations
    const variables = { id_verificacao }
    const observable = debito_restricao_changed({
      variables,
      on_updated: (debitos_restricoes_update) => {
        const updated_id = debitos_restricoes_update.id
        function update_item<T extends EnrichedebitosRestricoes | DebitosRestricoes>(dr: T) {
          if (dr.id !== updated_id) return dr
          const new_debitos_restricoes = {
            ...dr,
            ...debitos_restricoes_update
          }
          return new_debitos_restricoes
        }
        const new_debitos_restricoes_list = debitos_restricoes_list.map(update_item)

        if (process.env.NODE_ENV === 'development') trace('list-situation-cards', 'handle_debitos_restricoes_update', { filtered_debitos_restricoes_list, debitos_restricoes_list, new_debitos_restricoes_list, debitos_restricoes_update })

        update_lists(new_debitos_restricoes_list)
      }
    })
    return observable
  }, [
    id_verificacao, debitos_restricoes_list, set_debitos_restricoes_list, filtered_debitos_restricoes_list, set_filtered_debitos_restricoes_list
  ])

  return filtered_debitos_restricoes_list
}
