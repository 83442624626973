import { useRoutes } from 'hookrouter'
import * as feature_groups from '../feature-groups'

import { RouteParams, FeatureGroupsKeys } from './_data'

const routes = {
  '/search/:feature': handle_search_group,
  '/search/:feature/:section': handle_search_group,
  // '/admin/:feature': handle_admin_group,
  // '/admin/:feature/:section': handle_admin_group,
}

/** Create route params from current route and choose the appropriate 
 * feature component to be rendered. */
export function useRouter() {
  const route_match = useRoutes(routes) as ReturnType<typeof handle_feature>
  return route_match
}

// /** Setup an admin route. */
// function handle_admin_group(params: Partial<RouteParams>) {
//   return handle_feature('admin', params)
// }

/** Setup a search route. */
function handle_search_group(params: Partial<RouteParams>) {
  return handle_feature('search', params)
}

/** Produces a section component from the current route path. */
function handle_feature(
  feature_group: FeatureGroupsKeys,
  params: Partial<RouteParams>
) {
  const { feature } = params
  if (!feature) { return null }

  const group = { ...feature_groups[feature_group] }
  const feature_key = feature.replace('-', '_')
  if (!(feature_key in group)) { return null }
  //@ts-ignore typescript is ignoring result of guard clause
  const Feature = group[feature_key].Component

  const route: RouteParams = {
    ...params,
    feature,
    feature_group
  }
  return { Feature, route }
}

/** Identify current host for white label usage. */
export type WhiteLabelHost = 'vericar' | 'brobot'
export function get_white_label_host(): WhiteLabelHost {
  const hostmane = window.location.hostname
  if (hostmane.includes('vericar')) 
    return 'vericar'
  return 'brobot'
}

/** Update header for whitelabel. */
const title_elemtnt = document.getElementsByTagName('title')[0] as HTMLElement
const host = get_white_label_host()
const host_name = host[0].toUpperCase() + host.substring(1)
title_elemtnt.innerHTML = `Nada Consta - ${host_name}`
const favicon_element = document.querySelector("link[rel~='icon']") as HTMLLinkElement
favicon_element.type = 'image/png'
favicon_element.href = favicon_element.href.replace('favicon.ico', `${host}.png`)
