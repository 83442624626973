import React, { ReactNode } from 'react'
import { affix, AllOrNothing, compose, child_type_matches } from 'helpers'

import styles from './card.module.scss'
import { CardFooter } from './card-footer'
import { CardHeader } from './card-header'

export type CardProps<T> = React.PropsWithChildren<{
  /** Title to be shown above the card. If the first child is a CardHeader, this property is ignored. The title in CardHeader is used instead. */
  title?: string
  /** Class for the wrapper that positions and limits the card size. */
  wrapper_class?: string
  /** Class for the card container element. */
  card_class?: string
  /** Remove regular padding from card. */
  no_padding?: boolean
  /** Wrapper div ref. */
  wrapper_ref?: React.RefObject<any>
  /** If defined - card acts as a form. */
  on_submit?: () => void
}> & AllOrNothing<{
  /** Payload to be returned when user clicks the card. */
  click_payload: T
  /** User clicked anywhere in the card. */
  on_click: (click_payload: T) => void
}>
/** 
 * Standard card with or without a title on top
 */
export function Card<T>(props: CardProps<T>) {
  if (props.on_submit) {
    const handle_submit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      props.on_submit!()
    }
    return (
      <form
        onSubmit={handle_submit}
        ref={props.wrapper_ref}
        className={props.wrapper_class}>
        <CardChildren {...props} />
      </form>
    )
  }

  const on_card_click = () => {
    props.on_click!(props.click_payload!)
  }
  return (
    <div
      className={props.wrapper_class}
      ref={props.wrapper_ref}
      onClick={props.on_click && on_card_click}>
      <CardChildren {...props} />
    </div>
  )
}

function CardChildren<T>(props: CardProps<T>) {
  const { title } = props
  const { content, header } = split_header(props.children)
  return <>
    {header ? header : <CardHeader title={title} />}

    <CardContents
      no_padding={props.no_padding}
      card_class={props.card_class}>
      {content}
    </CardContents>
  </>
}

type CardContentsProps = Pick<CardProps<any>, 'card_class' | 'no_padding' | 'children'>
function CardContents(props: CardContentsProps) {
  const { content, footer } = split_footer(props.children)
  if (footer === null) {
    const is_padded = !props.no_padding
    const card_class = affix('card', is_padded && 'PADDED')
    return (
      <div className={compose(props.card_class, styles[card_class])}>
        {content}
      </div>
    )
  }

  // CardFooter is the last child => render it properly
  return (
    <div className={styles.card}>
      <div className={compose(props.card_class, styles.content_above_footer)}>
        {content}
      </div>
      {footer}
    </div>
  )
}

function split_header(children: ReactNode | undefined) {
  if (!Array.isArray(children))
    return { content: children, header: null }

  const first_child = children[0]
  if (child_type_matches(first_child, CardHeader)) {
    const content = children.slice(1)
    return { content, header: first_child }
  }
  return { content: children, header: null }
}

/** If the last child is a CardFooter, split children between content and footer. */
function split_footer(children: ReactNode | undefined) {
  if (!Array.isArray(children))
    return { content: children, footer: null }

  const last_child = children.slice(-1)[0]
  if (child_type_matches(last_child, CardFooter)) {
    const content = children.slice(0, -1)
    return { content, footer: last_child }
  }
  return { content: children, footer: null }
}
