import * as Types from '../../../../../codegen/gql-types';

import { produce_query } from 'app'
import gql from 'graphql-tag';

/**
 * 
 * 
 * 
 * 
 * 
 *         DO NOT EDIT THIS FILE - ANY CHANGES WILL BE LOST
 * 
 * 
 * 
 * 
 * 
 *    This file is automatically generated on every build by codegen plugins
 *    as defined in codegen.yml
 * 
 *    Its contents are defined by its sibling file _operations.graphql.
 * 
 * 
 * 
 */


 const list_multas_doc = gql`
    query list_multas($id_verificacao: String!) {
  list_multas(id_verificacao: $id_verificacao) {
    id
    nome_fornecedor
    ait
    data_hora_infracao
    data_vencimento
    descricao_infracao_original
    local_infracao
    municipio_infracao
    orgao_infracao
    valor
    boleto_file_url
  }
}
    `;

/** Produce all of operations functions for list-multas. */
function list_multas_ops_factory() {
  const list_multas = produce_query<GqlListMultasData,GqlListMultasVariables>(list_multas_doc)
  
  return {
    list_multas,
  }
}
/** Auto-generated operations functions for list-multas. */
export type ListMultasOps = ReturnType<typeof list_multas_ops_factory>

let memoized_operations: ListMultasOps
/** Produces and memoizes all list-multas operations. */
export function produce_list_multas_ops() {
  if (!memoized_operations) {
    memoized_operations = list_multas_ops_factory()
  }
  return memoized_operations
}

/** Types returned as the final result from operations execution.  */

export type GqlListMultasData = GqlListMultas['list_multas']

export type GqlListMultasVariables = Types.Exact<{
  id_verificacao: Types.Scalars['String'];
}>;


export type GqlListMultas = { list_multas: Array<Pick<Types.GqlDetranMulta, 'id' | 'nome_fornecedor' | 'ait' | 'data_hora_infracao' | 'data_vencimento' | 'descricao_infracao_original' | 'local_infracao' | 'municipio_infracao' | 'orgao_infracao' | 'valor' | 'boleto_file_url'>> };
