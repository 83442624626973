import { ItemsState, NodeSelection, Tree } from './_data'

export const produce_parent_selection = (
  child_selection: ItemsState<NodeSelection> | undefined, items: Tree
) => {
  if (!child_selection) return
  const keys = Object.keys(child_selection)
  /** Number of children with `PARTIALLY_SELECTED` state. */
  let partials_count = 0
  /** Number of children with `SELECTED` state. */
  let selected_count = 0
  keys.forEach((key) => {
    if (child_selection[key] === 'SELECTED') selected_count++
    if (child_selection[key] === 'PARTIALLY_SELECTED') partials_count++
  })

  // if all children are selected => mark item as selected
  if (selected_count === items.length) return 'SELECTED'
  // if children are partially selected => mark item as partially selected
  if (partials_count > 0 || selected_count > 0) return 'PARTIALLY_SELECTED'

  return 'UNSELECTED'
}