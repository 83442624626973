import React, { useEffect, useRef, useState } from 'react'
import { FeatureSectionProps, SectionExport } from 'feature-groups/_features-data'
import { useSelection } from 'controllers/tied'
import { broadcast_user } from 'app/login/_broadcasts'
import { Card, LabeledValue, CardProps, Svg, Button } from 'components'

import { status_labels } from '../situation/list-situation-cards/situation-card/_sections/header'
import { GqlListVerificacoesData, GqlListVerificacoesVariables, produce_archives_ops } from './_operations'
import styles from './archives.module.scss'
import { format_datetime, format_day, Maybe, useStoredState, upsert, take, apollo_errors_to_string } from 'helpers'
import { navigate } from 'hookrouter'
import { produce_search_starter_ops } from '../situation/situation-search/search-starter/_operations'
import { SharedStorageVerificacao, shared_storage_verifcacao } from '../situation/_data'
import { situation } from '../situation'
import { GqlFilter } from 'codegen/gql-types'
import { ArchiveSearch } from './archive-search'
import { LoginState } from 'app/login/_data'
import { show_modal } from 'app/modal'

export const archives: SectionExport = {
  label: 'Histórico de Consultas',
  Section: Archives,
}

function Archives(props: FeatureSectionProps) {
  const user = useSelection(broadcast_user, 'Archives')
  if (!user) return <></>
  return <ArchivesReady {...props} user={user} />
}

type ArchivesReadyProps = FeatureSectionProps & {
  user: Exclude<LoginState['user'], undefined | null>
}
function ArchivesReady(props: ArchivesReadyProps) {
  const { user } = props
  const id_cliente = user?.context_id
  const initial_filters: GqlFilter[] = []
  const [filters, set_filters] = useState<Maybe<GqlFilter[]>>(initial_filters)

  // undefined => user is typing - clear results
  // empty string => show unfiltered results
  const [search_term, set_search_term] = useState<string | undefined>('')
  // used to reset the search contents whenever new criterion are set
  const [search_session, set_search_session] = useState<number>(0)
  const show_search_contents = id_cliente && search_term !== undefined

  const handle_search = (new_search_term?: string) => {
    set_search_term(new_search_term)
    set_filters(undefined)
    set_search_session(search_session + 1)
  }

  const handle_remove_filter = (field_id: GqlFilter['field_id']) => {
    if (!filters) return
    const new_filters = filters.filter(f => f.field_id !== field_id)
    set_filters(new_filters)
    set_search_session(search_session + 1)
    set_search_term('')
  }

  const handle_filter_change = (new_filter: GqlFilter) => {
    const new_filters = !filters
      ? [new_filter]
      : upsert(filters, new_filter, 'field_id')
    set_filters(new_filters)
    set_search_session(search_session + 1)
    set_search_term('')
  }

  return (
    <main className={styles.main}>
      {props.children}
      <div className={styles.page_wrapper}>
        <ArchiveSearch
          user={user}
          current_search_term={search_term}
          on_search={handle_search}
          on_remove_filter={handle_remove_filter}
          on_filter_change={handle_filter_change} />
        {!show_search_contents ? null : (
          <ArchivesContents
            key={search_session}
            id_cliente={id_cliente!}
            search_term={search_term}
            filters={filters}
          />
        )}
      </div>
    </main>
  )
}

type ArchivesContentsProps = {
  search_term: string | undefined
  filters: Maybe<GqlFilter[]>
  id_cliente: string
}
function ArchivesContents(props: ArchivesContentsProps) {
  const [verificacoes, set_verificacoes] = useState<GqlListVerificacoesData>()
  const [loaded, set_loaded] = useState<boolean>(false)
  const [offset, set_offset] = useState(0)
  const { search_term, filters, id_cliente } = props

  const limit = 20
  const intersection_buffer = 5
  const intersection_position = offset + limit - intersection_buffer
  const handle_load_more: ListVerificacoesProps['on_load_more'] = (
    intersected_position
  ) => {
    // Check if intersecting something that already triggered load_more
    if (intersected_position < offset) return
    const new_offset = offset + limit
    set_offset(new_offset)
  }

  useEffect(() => {
    const prefix = search_term?.substring(0, 3)
    // if only numbers it's a renavam. Leading zeros do not matter for the 
    // equality. As the backend will cast the value before comparison.
    const field_id = isNaN(Number(prefix)) ? 'placa' : 'renavam'
    const filter_list: Maybe<GqlFilter[]> = !search_term ? filters : [{
      field_id,
      value: [search_term],
      operation: 'EQ'
    }]

    const operations = produce_archives_ops()
    const variables: GqlListVerificacoesVariables = {
      id_cliente,
      query_opts: {
        limit,
        offset,
        filter_list,
        order_by_list: [{
          direction: 'DESC',
          field_id: 'requested_at'
        }]
      }
    }

    operations.list_verificacoes({
      variables,
      options: {
        fetchPolicy: 'no-cache'
      },
      on_completed: (new_verificcoes) => {
        const all_verificacoes = offset === 0
          ? new_verificcoes
          : [...verificacoes || [], ...new_verificcoes]
        set_verificacoes(all_verificacoes)
        set_loaded(true)
      }
    })
  }, [search_term, filters, offset])

  if (!loaded)
    return <>Carregando histórico...</>

  if (!verificacoes?.length) {
    const has_searched = search_term !== undefined || filters?.length
    if (has_searched)
      return <>Nenhuma verificação encontrada.</>

    return (<>{
      `Histórico vazio. Execute uma consulta na aba "${situation.label}".`
    }</>)
  }

  return (
    <ListVerificacoes
      id_cliente={props.id_cliente}
      intersection_position={intersection_position}
      verificacoes={verificacoes}
      on_load_more={handle_load_more} />
  )
}

type ListVerificacoesProps = {
  verificacoes: GqlListVerificacoesData
  /** User is reaching the end of the list => load more. */
  on_load_more: (intersected_position: number) => void
  /** on_load_more is triggered when card [intersection_position] is shown */
  intersection_position: number
  id_cliente: string
}
const status_styles = {
  STARTED: 'in_progress',
  REQUESTED: 'in_progress',
  FINISHED: 'success',
  FAILED: 'danger',
  CANCELED: 'attention'
}
function ListVerificacoes(props: ListVerificacoesProps) {
  const { intersection_position } = props
  const [_verificacao, set_verificacao] = useStoredState<SharedStorageVerificacao>(
    shared_storage_verifcacao.key,
    shared_storage_verifcacao.version,
    undefined
  )
  const [trigger_navigate, set_trigger_navigate] = useState<boolean>()

  const extract_data_and_variables = (v: GqlListVerificacoesData[number]) => {
    const lista_fornecedor_credenciais = v.lista_fornecedor_credenciais.map(
      ({ scraper_name, credenciais }) => ({
        scraper_name,
        credenciais: credenciais.map(
          ({ tipo_interno, valor }) => ({ tipo_interno, valor })
        )
      })
    )

    const variables = {
      ...take(v, 'renavam', 'placa', 'baixar_boletos'),
      lista_fornecedor_credenciais,
      id_cliente: props.id_cliente
    }

    const data = take(v, 'id', 'renavam', 'placa', 'status', 'status_multas', 'status_debitos', 'requested_at', 'files_bundle')
    return { data, variables }
  }

  const handle_card_click = (v: GqlListVerificacoesData[number]) => {
    const shared_storage_value = extract_data_and_variables(v)
    set_verificacao(shared_storage_value)

    // trigger indirect navigatation to avoid memory leaks
    // (trying to set state while unmouting the component)
    set_trigger_navigate(true)
  }

  const handle_search_again = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    v: GqlListVerificacoesData[number]
  ) => {
    // prevent handle_card_click navigation
    e.stopPropagation()

    const { variables } = extract_data_and_variables(v)
    const { solicitar_verificacao } = produce_search_starter_ops()
    solicitar_verificacao({
      variables,
      on_completed: (data) => {
        set_verificacao({ data, variables })
        set_trigger_navigate(true)
      },
      on_errors: (e) => {
        const message = apollo_errors_to_string(e)
        show_modal({
          shape: 'Message',
          modal_props: {
            title: 'Erro ao solicitar nova consulta',
            message,
            ok_label: 'ok'
          }
        })
      }
    })
  }

  useEffect(() => {
    // navigate to the situation tab, to see all details from 
    // the selected verificacao
    if (trigger_navigate) navigate('/search/nada-consta/situation')
  }, [trigger_navigate])


  /** Request date of all cards in the current block */
  let request_date_block_title: string
  return (
    <ul>
      {props.verificacoes.map((v, idx) => {
        const status_label = status_labels[v.status]
        const request_time = format_datetime(v.requested_at)
        const status_style = `bullet_wrapper_${status_styles[v.status]}`

        /** Whenever the date changes, render it as title. */
        const should_render_block_title = () => {
          const request_date = format_day(v.requested_at)
          if (request_date !== request_date_block_title) {
            request_date_block_title = request_date
            return true
          }
          return false
        }
        const render_title = should_render_block_title()

        return (
          <li key={`${v.id}${idx}`}>
            {!render_title ? null : (
              <div className={styles.block_title}>
                <span>{request_date_block_title}</span>
              </div>
            )}
            <IntersectableCard
              intersect={intersection_position === idx}
              intersection_payload={intersection_position}
              on_intersection={props.on_load_more}
              click_payload={v}
              card_class={styles.verificacao_card}
              on_click={handle_card_click}
              wrapper_class={styles.verificacao_card_wrapper}>
              <div className={styles.info_wrapper}>
                <LabeledValue label='Placa' value={v.placa} />
                <LabeledValue label='Renavam' value={v.renavam} />
                <LabeledValue label='Consultado em' value={request_time} />
                {!v.full_name_usuario_emissor?.length ? null : (
                  <LabeledValue
                    label='Consultado por'
                    value={v.full_name_usuario_emissor} />
                )}
              </div>
              <div className={styles.status_wrapper}>
                <span>{status_label}</span>
                <span className={styles[status_style]}>{'\u2022'}</span>
                <Button
                  layout='ICON'
                  color='TRANSPARENT'
                  tooltip='Repetir consulta agora'
                  onClick={(e) => handle_search_again(e, v)}>
                  <Svg icon='refresh' />
                </Button>
              </div>
            </IntersectableCard>
          </li>
        )
      })}
    </ul>
  )
}
type IntersectableCardProps<T, P> = CardProps<T> & {
  /** Only observe intersection if intersect = true. */
  intersect: boolean
  on_intersection: (
    intersection_payload: P,
    entry: IntersectionObserverEntry
  ) => void,
  intersection_payload: P
}
function IntersectableCard<T, P>(props: IntersectableCardProps<T, P>) {
  if (props.intersect) return <ThresholdCard {...props} />
  return <Card {...props} />
}

function ThresholdCard<T, P>({
  children,
  on_intersection,
  intersection_payload,
  ...props
}: IntersectableCardProps<T, P>) {
  const card_ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const entry = entries[0]
        if (!entry.intersectionRatio) return
        on_intersection(intersection_payload, entry)
      }
    )
    const el = card_ref.current
    if (el) { observer.observe(el) }
    return () => {
      if (el) { observer.unobserve(el) }
    }
  })

  return (
    <Card {...props} wrapper_ref={card_ref}>
      {children}
    </Card>
  )
}
