import * as Types from '../../codegen/gql-types';

import { produce_mutation, produce_query } from 'app'
import gql from 'graphql-tag';

/**
 * 
 * 
 * 
 * 
 * 
 *         DO NOT EDIT THIS FILE - ANY CHANGES WILL BE LOST
 * 
 * 
 * 
 * 
 * 
 *    This file is automatically generated on every build by codegen plugins
 *    as defined in codegen.yml
 * 
 *    Its contents are defined by its sibling file _operations.graphql.
 * 
 * 
 * 
 */


 const signin_doc = gql`
    mutation signin($email: String!, $password: String!) {
  signin(email: $email, password: $password) {
    token
    user {
      id
      full_name
      email
      context_id
      type
    }
  }
}
    `;
 const get_current_user_doc = gql`
    query get_current_user {
  get_current_user {
    id
    full_name
    email
    context_id
    type
  }
}
    `;

/** Produce all of operations functions for login. */
function login_ops_factory() {
  const signin = produce_mutation<GqlSigninData,GqlSigninVariables>(signin_doc)
  
  const get_current_user = produce_query<GqlGetCurrentUserData,GqlGetCurrentUserVariables>(get_current_user_doc)
  
  return {
    signin,
    get_current_user,
  }
}
/** Auto-generated operations functions for login. */
export type LoginOps = ReturnType<typeof login_ops_factory>

let memoized_operations: LoginOps
/** Produces and memoizes all login operations. */
export function produce_login_ops() {
  if (!memoized_operations) {
    memoized_operations = login_ops_factory()
  }
  return memoized_operations
}

/** Types returned as the final result from operations execution.  */

export type GqlSigninData = GqlSignin['signin']
export type GqlGetCurrentUserData = GqlGetCurrentUser['get_current_user']

export type GqlSigninVariables = Types.Exact<{
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type GqlSignin = { signin: (
    Pick<Types.GqlSession, 'token'>
    & { user: Pick<Types.GqlUser, 'id' | 'full_name' | 'email' | 'context_id' | 'type'> }
  ) };

export type GqlGetCurrentUserVariables = Types.Exact<{ [key: string]: never; }>;


export type GqlGetCurrentUser = { get_current_user: Pick<Types.GqlUser, 'id' | 'full_name' | 'email' | 'context_id' | 'type'> };
