import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { trace } from 'controllers/tracer'
import { Maybe } from 'helpers'
import { LinkIcon } from 'components'

import { GqlStatusVerificacao } from 'codegen/gql-types'
import { GqlListDebitosRestricoesData } from '../list-situation-cards/_operations'
import { GqlSolicitarVerificacaoData } from '../situation-search/search-starter/_operations'

type FileBundleJob = GqlSolicitarVerificacaoData | GqlListDebitosRestricoesData[number]
type FilesBundleButtonProps = {
  job: FileBundleJob
  className?: string
}
export function FilesBundleButton(props: FilesBundleButtonProps) {
  const { job } = props
  const files_bundle_url = job.files_bundle?.url

  const files_bundle_ready = useFilesBundleReady(job)

  const {
    files_bundle_tooltip,
    show_spinner
  } = derive_icon_props(job.status, files_bundle_ready, files_bundle_url)

  if (process.env.NODE_ENV === 'development') trace('search-status', 'FilesBundleButton', { files_bundle_ready, files_bundle_url, job })

  return (
    <LinkIcon
      icon='file_proof'
      href={files_bundle_url}
      tooltip={files_bundle_tooltip}
      inactive={!files_bundle_ready}
      className={props.className}
      show_spinner={show_spinner} />
  )
}


function derive_icon_props(
  status: GqlStatusVerificacao,
  files_bundle_ready: boolean,
  files_bundle_url: Maybe<string>
): { files_bundle_tooltip: string, show_spinner: boolean } {
  switch (status) {
    case 'FINISHED':
      if (!files_bundle_url) return {
        files_bundle_tooltip: 'Captura antiga. Não houve consolidação.',
        show_spinner: false
      }
      if (files_bundle_ready) return {
        files_bundle_tooltip: 'Comprovantes consolidados.',
        show_spinner: false
      }
      return {
        files_bundle_tooltip: 'Aguarde, consolidando os arquivos capturados...',
        show_spinner: true
      }

    case 'REQUESTED':
    case 'STARTED':
      return {
        files_bundle_tooltip: 'Aguarde, comprovantes sendo coletados...',
        show_spinner: true
      }

    case 'CANCELED':
    case 'FAILED':
      return {
        files_bundle_tooltip: 'Captura incompleta, sem comprovantes consolidados.',
        show_spinner: false
      }
  }
}

function useFilesBundleReady(job: FileBundleJob) {
  const [files_bundle_ready, set_files_bundle_ready] = useState<boolean>(true)
  const active_id_ref = useRef<string>()

  useEffect(() => {
    active_id_ref.current = job.id
    const has_finished = job.status === 'FINISHED'
    const url = job.files_bundle?.url

    if (process.env.NODE_ENV === 'development') trace('search-status', 'useFilesBundleReady', { has_finished, job, files_bundle_ready })

    if (has_finished && url) {
      if (files_bundle_ready) return
      const check_bundle_readiness = (wait_time: number) => {
        setTimeout(() => {
          axios
            .head(url)
            .then(result => {
              if (process.env.NODE_ENV === 'development') trace('search-status', 'useFilesBundleReady-head-result', { result, current_id: active_id_ref.current, id: job.id })

              if (active_id_ref.current !== job.id) return
              if (result.status === 200) set_files_bundle_ready(true)
              else check_bundle_readiness(10 * 1000)
            })
            .catch(error => {
              if (process.env.NODE_ENV === 'development') trace('search-status', 'useFilesBundleReady-head-error', { error, current_id: active_id_ref.current, id: job.id })

              if (active_id_ref.current !== job.id) return
              check_bundle_readiness(10 * 1000)
            })
        }, wait_time)
      }
      // check immediattely as the user might be loading a previously 
      // finished job
      check_bundle_readiness(0)
    }

    // if not finished, this cannot be true (in case react is reusing the component)
    if (files_bundle_ready) set_files_bundle_ready(false)
    return () => {
      active_id_ref.current = undefined
    }

  }, [set_files_bundle_ready, job, files_bundle_ready, active_id_ref.current])
  return files_bundle_ready
}
