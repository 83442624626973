import { AppHandler } from 'app'
import { affix } from 'helpers'
import { ApiInterface, ApiHandler } from 'controllers/tied'
import { Data, FETCH_PHASES, TablePhase, Field, CalculatedField } from '../../_data'

import styles from '../grid.module.scss'
import { GridApiCore, Layout } from '../_data'
import { parse_cell_value } from '../_cell-format'

/**
 * Recalculates all layout parameters based on the new props values
 */
export const onApiRender: AppHandler<GridApiCore> = (i) => {
  const { table_phase } = i.props
  const { previous_table_phase } = i.params
  const should_rehydrate = (
    previous_table_phase !== table_phase &&
    FETCH_PHASES.includes(table_phase)
  )

  const { set } = i
  set.previous_table_phase(table_phase)
  set.is_rehydrating(should_rehydrate)

  handle_structural_changes(i)

  if (!should_rehydrate) {
    return ['ok', 'NO-REHYDRTAE', { previous_table_phase, table_phase, FETCH_PHASES, i }]
  }

  const block_size = i.props.rows_count / 2
  // const is_expanding = table_phase === TablePhase.FETCHED_MORE
  if (table_phase === TablePhase.FETCHED_MORE) {
    // clear the last threshold marker
    set.expanding_edge(null)
  }

  const data_blocks = produce_data_blocks(i, block_size, table_phase)
  const top_void_heights = produce_top_void_heights(i, table_phase)
  const first_block = produce_first_block(i, table_phase)
  const threshold_in_block = produce_threshold_in_block(i, data_blocks, block_size)
  const first_rows = produce_first_rows(first_block, block_size)
  const layout = {
    data_blocks,
    top_void_heights,
    first_block,
    threshold_in_block,
    first_rows
  }
  set.layout(layout)
  // reset voids crossing controller
  set.visible_voids([])

  return ['ok', 'REHYDRTAE', { layout, i }]
}

export const onApiEffect: ApiHandler<GridApiCore> = (i) => {
  // The reference row does not need to take into search the largets cells
  // of the current data batch as they are already in the table. Therefore, this
  // value can be calculated after rendering to avoid slowing down the render 
  // process.
  const largest_cells = produce_largest_cells(i)
  i.set.largest_cells(largest_cells)
}

/** Calculate the number for the first row in each block. */
function produce_first_rows(
  first_block: Layout['first_block'],
  block_size: number
): Layout['first_rows'] {
  const first_row = 1 + block_size * first_block
  const first_row_second_block = first_row + block_size
  return [first_row, first_row_second_block]
}

/**
 * Calculate the heights of all hidden rows at the top.
 */
function produce_top_void_heights(
  { params }: ApiInterface<GridApiCore>, table_phase: TablePhase
): Layout['top_void_heights'] {
  const { top_void_heights } = params.layout

  if (table_phase === TablePhase.FETCHED_NEW)
    return top_void_heights

  if (table_phase === TablePhase.FETCHED_MORE) {
    // expanding upwards => just delete the last height
    if (params.expanding_edge === 'upper')
      return top_void_heights.slice(0, -1)

    // expanding downwards => add the latest block zero height to the list
    const block_0_element = params.block_0_ref!.current!
    const { height } = block_0_element.getBoundingClientRect()
    return [...top_void_heights, height]
  }

  //  Data fecthed to fill a visible void
  const { visible_voids } = params
  // not expanding, nor filling voids => keep as it is
  if (!visible_voids.length) return top_void_heights
  // filling voids
  const last_top_void = visible_voids[visible_voids.length - 1]
  // loaded the first void => eliminate voids
  if (last_top_void === 0) return []
  // voids still exist, eliminate the ones bellow
  return top_void_heights.slice(0, last_top_void - 1)
}

/**
 * Manages the placement of fetched data into data blocks
 */
function produce_data_blocks(
  i: ApiInterface<GridApiCore>,
  block_size: number,
  table_phase: TablePhase
): Layout['data_blocks'] {
  const data = i.props.data as Data
  // component is receiving a full data batch, replace both blocks
  if (table_phase !== TablePhase.FETCHED_MORE) {
    return [
      data.slice(0, block_size),
      data.slice(block_size)
    ]
  }

  // component is expanding from a theshold crossing
  const { params } = i
  const { data_blocks } = params.layout
  // merge the incoming data and throw out the farthest block from the edge
  return params.expanding_edge === 'lower'
    ? [data_blocks[1], data]
    : [data, data_blocks[0]]
}

/** 
 * Calculate the new value for first block based on the last threshold that 
 * triggered an on_threshold event
 */
function produce_first_block(
  { params }: ApiInterface<GridApiCore>,
  table_phase: TablePhase
): Layout['first_block'] {
  if (table_phase === TablePhase.FETCHED_NEW) {
    // component is receiving a new data batch
    return 0
  }

  if (table_phase === TablePhase.FETCHED_VOID) {
    // component is receiving a new void filler
    const { visible_voids } = params
    if (visible_voids.length === 0) return 0
    const last_intersected_void = visible_voids.length - 1
    return visible_voids[last_intersected_void]
  }

  // component is expanding from a theshold crossing
  const { expanding_edge } = params
  const { first_block } = params.layout
  if (expanding_edge === 'upper') { return first_block - 1 }
  if (expanding_edge === 'lower') { return first_block + 1 }
  // not going up or down, keep first block unchanged
  return first_block
}

/** Calculate which rows in each block will be used as threshold */
function produce_threshold_in_block(
  i: ApiInterface<GridApiCore>,
  new_data_blocks: Layout['data_blocks'],
  block_size: number
): Layout['threshold_in_block'] {
  const { threshold_distance } = i.params

  const block_0_threshold = threshold_distance

  const second_block_length = new_data_blocks[1].length
  const block_1_threshold = (
    second_block_length === block_size
      ? second_block_length - threshold_distance
      : i.initialParams.layout.threshold_in_block[1]
  )

  return [block_0_threshold, block_1_threshold]
}

/** Control parameters that are related to the grid structure. */
function handle_structural_changes(i: ApiInterface<GridApiCore>) {
  if (i.props.visible_columns_ids === i.params.visible_columns_ids) {
    // grid structure did not change => do nothing
    return
  }
  const { fields, visible_columns_ids } = i.props
  // calculate columns styles
  const col_css_list = visible_columns_ids.map(
    (col_id) => produce_class_name(fields[col_id])
  )
  i.set.col_css_list(col_css_list)
  i.set.visible_columns_ids(visible_columns_ids)
}


/** Pre-calculate cell class name to be used by all rowns in a column. */
function produce_class_name(field_props: Field | CalculatedField) {
  const cell_class_name = affix('cell', field_props.align)
  return styles[cell_class_name]
}

/** Create virtual row with the largest known text of each column. */
function produce_largest_cells(i: ApiInterface<GridApiCore>) {
  const { data } = i.props
  const { largest_cells } = i.params
  if (!data) return largest_cells

  const { fields, visible_columns_ids } = i.props
  let new_largest_cells = visible_columns_ids.reduce(
    (prev, field_id) => ({ ...prev, [field_id]: prev[field_id] || '' }),
    largest_cells || {}
  )
  let max_lengths = visible_columns_ids.map(field_id =>
    new_largest_cells[field_id].length
  )
  for (const row of data) {
    visible_columns_ids.forEach((field_id, idx) => {
      const value = parse_cell_value(fields[field_id], row[field_id], row)
      if (value == null) return
      const { length } = value
      if (length > max_lengths[idx]) {
        max_lengths[idx] = length
        new_largest_cells[field_id] = value
      }
    })
  }
  return new_largest_cells
}