import React from 'react'
import { Donut } from '../basic'
import { compose, affix, Maybe } from 'helpers'
import { Svg, SvgSmallIcon } from '../basic/svg'

import styles from './popup-menu.module.scss'
import { useClickAnywhere, PopupHookProps } from './_hooks'

export type PopupOptionItem = {
  [i: string]: any
  progress?: number | undefined
  spin?: boolean | undefined
  signed_url?: Maybe<string>
  icon?: SvgSmallIcon | undefined
  icon_color?: 'DANGER' | 'SUCCESS' | 'ATTENTION' | undefined
}

export type PopupMenuOnClick<T extends PopupOptionItem> =
  (option: T, option_idx: number) => void

export type PopupMenuProps<T extends PopupOptionItem> = React.PropsWithChildren<
  PopupHookProps &
  {
    /** PopupMenu options labels and icons. */
    options: T[] | undefined,
    /** Name of the property that will be used as label. */
    label_prop: keyof T,
    /** Name of the property to be rendered bellow the label with extra 
     * information */
    details_prop?: keyof T,
    /** A menu option was selected by the user. */
    on_select?: PopupMenuOnClick<T>
    /** Composed class applied to the component wrapper. */
    added_styles?: string
    /** If true, activate spinner in option. */
    option_is_waiting?: (option: T) => boolean
    /** Item to be highlighted. */
    highlight?: number
    /** Change options alignment. Default is `left`. */
    align?: 'LEFT' | 'RIGHT'
  }
>

export function PopupMenu<T extends PopupOptionItem>(props: PopupMenuProps<T>) {
  useClickAnywhere(props)
  const { options } = props

  if (props.hidden || !(options?.length || props.children)) { return null }

  const handle_click = !props.on_select ? undefined : (
    (idx: number) => () => {
      if (props.on_select) {
        const clicked_option = options![idx]
        props.on_select(clicked_option, idx)
      }
    }
  )

  const { label_prop, option_is_waiting, highlight, details_prop } = props
  const align = props.align ?? 'LEFT'
  const alignment_css = `align-${align}`
  const first_option = options![0]
  const has_icons =
    first_option && (
      'icon' in first_option ||
      'progress' in first_option ||
      'spin' in first_option
    )
  const details_css = affix('details', has_icons, `HAS_ICONS-${align}`)

  return (
    <ul
      className={compose(styles.menu, props.added_styles)}>
      {options?.map((option, idx) => {
        const label = option[label_prop] as unknown as string
        const details = details_prop && option[details_prop] as unknown as string
        const list_item_css = affix('list_item', highlight === idx, 'HIGHLIGHT')
        return (
          <li
            key={`${label}.${idx}`}
            className={compose(
              styles[list_item_css],
              styles[alignment_css],
            )}>
            <Option
              idx={idx}
              label={label}
              option={option}
              details={details}
              details_css={details_css}
              option_is_waiting={option_is_waiting}
              on_click={handle_click} />
          </li>
        )
      })}
      {!props.children ? null : (
        <li className={compose(styles.new_item, styles[alignment_css])}>
          {props.children}
        </li>
      )}
    </ul>
  )
}

type OptionProps<T extends PopupOptionItem> = {
  idx: number
  label: string
  option: T
  details: string | undefined
  on_click: undefined | ((idx: number) => () => void)
  details_css: string
  option_is_waiting: PopupMenuProps<T>['option_is_waiting']
}
function Option<T extends PopupOptionItem>(props: OptionProps<T>) {
  const {
    idx,
    label,
    option,
    details,
    on_click,
    details_css,
    option_is_waiting,
  } = props
  const { icon_color } = option
  const item_main_styles = compose(
    styles.item_main,
    icon_color && styles[`icon_color-${icon_color}`]
  )

  // inactive option, waiting for something to be activated
  if (option_is_waiting && option_is_waiting(option)) {
    return (
      <p>
        <span className={item_main_styles}>
          <Icon spin />
          {label}
        </span>
        <Details details={details} details_css={details_css} />
      </p>
    )
  }

  // downloadable link
  if (option.signed_url) {
    return (
      <a href={option.signed_url} onClick={on_click && on_click(idx)}>
        <span className={item_main_styles}>
          <Icon {...option} />
          {label}
        </span>
        <Details details={details} details_css={details_css} />
      </a>
    )
  }

  // click only
  if (on_click) {
    return (
      <button
        onClick={on_click(idx)}>
        <span className={item_main_styles}>
          <Icon {...option} />
          {label}
        </span>
        <Details details={details} details_css={details_css} />
      </button>
    )
  }

  // Just a label
  return (
    <p>
      <span className={item_main_styles}>
        <Icon {...option} />
        {label}
      </span>
      <Details details={details} details_css={details_css} />
    </p>
  )
}

/** Display progress donut, spinner or icon if required. */
function Icon(props: PopupOptionItem) {
  if ('progress' in props)
    return <Donut progress={props.progress} />
  if ('spin' in props)
    return <Donut spin={props.spin} />
  if (props.icon)
    return <Svg icon={props.icon} size='small' />
  return null
}

type DetailsProps = {
  details_css: string
  details: string | undefined
}
function Details({ details, details_css }: DetailsProps) {
  if (details === undefined) return null
  return (
    <span className={styles[details_css]}>{details}</span>
  )
}