import React, { useEffect, useState } from 'react'
import { ClearableButton, PopupMenu, Svg, usePopupMenu } from 'components'

import { SelectedUser } from '../_data'
import { produce_user_filter_ops, GqlListUsersData, GqlListUsersVariables } from './_operations'
import styles from './user-filter.module.scss'
import { LoginState } from 'app/login/_data'

type UserFilterProps = {
  selected_user: SelectedUser
  on_clear: () => void
  on_change: (selected_user: GqlListUsersData[number]) => void
  user: Exclude<LoginState['user'], undefined | null>
}

export function UserFilter(props: UserFilterProps) {
  const { user } = props

  const [users, set_users] = useState<GqlListUsersData>()
  useEffect(() => {
    const id_cliente = user.context_id
    const { list_users } = produce_user_filter_ops()
    const variables: GqlListUsersVariables = { id_cliente }
    list_users({
      variables,
      on_completed: set_users
    })
  }, [user])

  const popup = usePopupMenu()

  const { selected_user } = props
  const is_selected = !!selected_user
  const button_label = selected_user?.full_name || 'Usuário'

  return (
    <ClearableButton
      is_cleararble={is_selected}
      on_clear={props.on_clear}>
      <div {...popup.parent_props} className={styles.popup}>
        {is_selected ? <Svg size='small' icon='filter' /> : null}
        {button_label}
        {!users ? null : (
          <PopupMenu
            {...popup.menu_props}
            added_styles={styles.popup_menu_added_styles}
            options={users}
            label_prop='full_name'
            on_select={props.on_change} />
        )}
      </div>
    </ClearableButton>
  )
}