import React from 'react'
import { HelpProps, Help, HelpTopic } from 'components'

export function STRING(props: Omit<HelpProps, 'title' | 'component_key' | 'version'>) {
  return (
    <Help
      {...props}
      title='Como filtrar campos de texto.'
      component_key='filter_string'
      version={1}
    >
      <HelpTopic tag='Aa' title='Diferenciar maiúsculas e minúsculas.'>
        Ative a opção <mark>Aa</mark> à direita da caixa de texto para que a
        busca leve em consideração se as letras são maiúsculas ou minúsculas.
        Por padrão ela inicia desativada, ou seja, maiúsculas e minúsculas não
        fazem diferença.
      </HelpTopic>

      <HelpTopic tag='(sem operadores)' title='Texto exato.'>
        <p>
          Qualquer texto sem os operadores <mark>*</mark><mark>?</mark><mark>[...]</mark><mark>!</mark>.
        </p>
        <p>
          <mark>Monza</mark> exatamente a palavra <em>Monza</em>. Não retornaria
          "Monza Hatch", por exemplo.
        </p>
      </HelpTopic>

      <HelpTopic tag='*' title='Quaisquer caracteres, ou nenhum caractere.'>
        <p>
          <mark>*0</mark> palavras terminando em "0". Ex: F15<em>0</em>,
          F25<em>0</em>, F100<em>0</em>, F400<em>0</em>.
        </p>
        <p>
          <mark>F1*</mark> palavras começando com "F1". Ex.: <em>F1</em>50,
          <em>F1</em>000.
        </p>
        <p>
          <mark>*F1*</mark> palavras com "F1" em qualquer parte. Ex.: <em>F1</em>50,
          <em>F1</em>000, McLaren <em>F1</em>.
        </p>
        <p>
          <mark>*F1*0</mark> palavras com "F1" em qualquer parte, terminando em "0". Ex.: <em>F1</em>50, <em>F1</em>000, .
        </p>
      </HelpTopic>

      <HelpTopic tag='?' title='Um caractere qualquer.'>
        <p>
          <mark>F?50</mark> palavras com exatamente um caractere qualquer no
          lugar do <em>?</em>. Ex: F<em>1</em>50, F<em>2</em>50.
        </p>
        <p>
          <mark>F??0*</mark> usado em conjunto com o operador <mark>*</mark>
          Palavras iniciadas com <em>F</em>, seguido por dois caracteres
          quaisquer <em>??</em>, depois um <em>0</em>, terminando
          com <em>*</em> zero ou mais caracteres adicionais quaisquer.
          Ex: F<em>15</em>0, F<em>25</em>0, F<em>10</em>0<em>0</em>, F
          <em>40</em>0<em>0</em>.
        </p>
      </HelpTopic>

      <HelpTopic tag='[.../.../...]' title='Lista de palavras.'>
        <p>
          <mark>[F150/F4000/F250]</mark> exatamente as palavras da lista.
          Ex: <em>F150</em>, <em>F4000</em>, <em>F250</em>.
        </p>
        <p>
          <em>Funciona somente se os colchetes estiverem envolvendo o texto
          todo. <mark>Mon[za/deo]</mark> não busca
          por "Monza" ou "Mondeo". Ao invés disso, busca
          exatamente pelo termo "Mon[za/deo]"</em>.
        </p>
      </HelpTopic>

      <HelpTopic tag='!' title='Negação: tudo, exceto o padrão definido.'>
        <p>
          <mark>!Monza</mark> qualquer texto, exceto aqueles cujo valor sejam exatamente <em>Monza</em>.
        </p>
        <p>
          <mark>!F1*</mark> qualquer coisa que não começe com <em>F1</em>.
        </p>
        <p>
          <mark>!*100*</mark> qualquer valor que não contenha <em>100</em>.
        </p>
        <p>
          <mark>!??0*</mark> qualquer valor, desde que não contenha <em>0</em> no
          terceiro caractere.
        </p>
        <p>
          <mark>![F150/F4000/F250]</mark> qualquer texto cujo valor exato não
          seja <em>F150</em>, <em>F4000</em> ou <em>F250</em>.
        </p>
        <p>
          <em>O operador de negação funciona somente se for o primeiro caractere do texto. <mark>Monza!</mark> não é uma negação, é apenas uma busca exata pelo termo "Monza!"</em>.
        </p>
      </HelpTopic>

      <HelpTopic tag='[ ]' title='Campo vazio.'>
        <p>
          Abrir e fechar colchetes, sem espaço entre eles. Retorna somente os
          registros que não tenham informação alguma nesse campo.
        </p>
        <p>
          <mark>![ ]</mark> funciona em conjunto com a negação. Retorna todos os
          campos que tenham algum valor, seja qual for.
        </p>
      </HelpTopic>
    </Help>
  )
}