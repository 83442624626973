import { GqlSolicitarVerificacaoData, GqlSolicitarVerificacaoVariables } from './situation-search/search-starter/_operations'

export const shared_storage_verifcacao = {
  key: 'shared-verificacao',
  version: 1
}
export type SharedStorageVerificacao = {
  data: GqlSolicitarVerificacaoData
  variables?: GqlSolicitarVerificacaoVariables
} | undefined
